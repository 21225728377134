import React, {useEffect, useState} from "react";
import {Col, Row, Spin, Table} from "antd";
import {
    DataSourceFilterApplier,
    DataSourceFiltersEditor,
    isObjectMatchFilters
} from "../../../../Misc/DataSourceFilter";
import {CommonFilterEditor} from "../../../../Misc/CommonFilterEditor/CommonFilterEditor";
import {SimpleFilterEditor} from "../../../../Misc/CommonFilterEditor/SimpleFilterEditor";
import {CnStatusFilterEditor} from "./CnResultsTableFilters/CnStatusFilterEditor";
import {CnDetailsMatchFilter} from "./CnResultsTableFilters/Model/CnDetaisMatchFilter";
import {SafeAnimatedCnDetails} from "../../Model/Safe/SafeAnimatedCnDetails";
import {AnimatedCnDetails} from "../../Model/AnimatedCnDetails/AnimatedCnDetails";
import {ColoredColumns} from "../../../../../Utilities";
import {CnStatusFilter} from "./CnResultsTableFilters/Model/CnStatusFilter";

export function CnResultsTable({cnResults, selectedCnResult, onCnResultSelect, onFiltersChanged}) {
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [filters, setFilters] = useState([
        new CnStatusFilter(["ADDED", "REMOVED", "MOVED", "MODIFIED"])
    ]);
    const [filterSchemas, setFilterSchemas] = useState([]);

    const [statusOptions, setStatusOptions] = useState([]);

    useEffect(() => {
        onFiltersChanged(filters);
    }, [onFiltersChanged, filters]);

    useEffect(() => {
        const allAttrKeys = [...new Set(cnResults.values().flatMap(r => new AnimatedCnDetails(r.details()).keys()))];

        const schemas = allAttrKeys.map(key => ({
            type: "attribute",
            attribute: key,
            allowedValues: cnResults.values().flatMap(r => new SafeAnimatedCnDetails(new AnimatedCnDetails(r.details())).values(key)),
            constructor(value) {
                return new CnDetailsMatchFilter(value, key, key);
            }
        }));

        const statusNames = [...new Set(cnResults.values().map(el => el.status().name()))];

        setFilterSchemas(schemas);
        setStatusOptions(statusNames.map(name => ({value: name})));
    }, [cnResults]);

    useEffect(() => {
        const idx = cnResults.values().filter(el => isObjectMatchFilters(el, filters))
            .findIndex(el => el.id() === selectedCnResult.id());

        if (idx >= 0) {
            setCurrentPage(Math.floor(idx / pageSize) + 1);
        }
    }, [pageSize, cnResults, selectedCnResult]);

    const filtersRenderer = (tagsList, editor) => (
        <Row justify={"space-between"} style={{marginBottom: 8}}>
            <Col span={15}>
                {tagsList}
            </Col>
            <Col>
                {editor}
            </Col>
        </Row>
    );

    const coloredColumns = new ColoredColumns(
        [
            {
                title: "Object",
                render: (_, record) => record.toTag()
            },
            {
                title: "Status",
                render: (_, record) => record.status().toTag()
            },
            {
                title: "Actions",
                render: (_, record) => {
                    return (
                        <a onClick={() => {
                            record.selectOnCanvas();
                            onCnResultSelect(record);
                        }}>
                            Details
                        </a>
                    );
                }
            }
        ],
        (text, record, idx) => record.id() === selectedCnResult.id() ? "#eee" : ""
    );

    return (
        <Spin spinning={!cnResults.loaded()}>
            <DataSourceFiltersEditor
                itemFilters={filters}
                onItemFiltersChanged={f => setFilters([...f])}
                filterSchemas={filterSchemas}
                contentRenderer={filtersRenderer}
                fieldEditorRenderer={(filterSchemas, addNewFilter) => (
                    <CommonFilterEditor
                        editors={[
                            {
                                type: "cnStatus",
                                tabName: "Status",
                                render: (schemasByType, onEditedFilterChanges) => (
                                    <CnStatusFilterEditor
                                        possibleOptions={statusOptions}
                                        onEditedFilterChanged={onEditedFilterChanges}
                                    />
                                )
                            },
                            {
                                type: "attribute",
                                tabName: "Attribute",
                                render: (schemasByType, onEditedFilterChanges) => (
                                    <SimpleFilterEditor
                                        filterSchemas={schemasByType}
                                        onEditedFilterChanged={onEditedFilterChanges}
                                    />
                                )
                            }
                        ]}
                        filterSchemas={filterSchemas}
                        onNewFilter={addNewFilter}
                    />
                )}
            />
            <DataSourceFilterApplier
                itemFilters={filters}
                dataSource={cnResults.values()}
                dataSourceRenderer={
                    (filteredDataSource) => (
                        <Table
                            id="cn-results-table"
                            scroll={{x: 300}}
                            size="small"
                            dataSource={filteredDataSource}
                            columns={coloredColumns.values()}
                            pagination={{
                                current: currentPage,
                                pageSize: pageSize,
                                onChange: (number, size) => {
                                    setCurrentPage(number);
                                    setPageSize(size);
                                }
                            }}
                        />
                    )
                }
            />
        </Spin>
    );
}