import React, {useEffect, useState} from "react";
import {CnResultsTable} from "./CnResultsTable";
import {CnResultDetails} from "./CnResultDetails";
import {CnResult} from "../../Model/CnResult";
import {CnResults} from "../../Model/CnResults";
import {Button, Card, Radio, Row, Space, Tooltip} from "antd";
import {SwapOutlined, SyncOutlined, ArrowLeftOutlined} from "@ant-design/icons";
import {SmartCnResults} from "../../Model/Smart/SmartCnResults";


export function CnSummary({cnIntent, selectedCanvasObject, onCnResultsChanged, onFiltersChanged,
                              anotherSelectedCanvasObject, onCloseClick, renderObjectData}) {
    const [selectedCnResult, setSelectedCnResult] = useState(new CnResult());
    const [cnResults, setCnResults] = useState(new CnResults());

    const [detailsMode, setDetailsMode] = useState("cn-details");

    useEffect(() => {
        onCnResultsChanged(cnResults);
    }, [cnResults, onCnResultsChanged]);

    useEffect(() => {
        setCnResults(prevState => {
            new SmartCnResults(prevState).removeFromCanvas();

            return new CnResults();
        });

        cnIntent.cnResults().then(results => {
            new SmartCnResults(results).addToCanvas();

            setCnResults(results);
        });
    }, [cnIntent]);

    useEffect(() => {
        if (!selectedCanvasObject) return;

        const cnResultToSelect = cnResults.values()
            .find(el => el.relatedToCanvasObject(selectedCanvasObject)) || new CnResult();

        setSelectedCnResult(cnResultToSelect);
    }, [cnResults, selectedCanvasObject]);

    useEffect(() => {
        if (!anotherSelectedCanvasObject) return;

        const cnResultToSelect = cnResults.values()
            .find(el => el.relatedToCanvasObject(anotherSelectedCanvasObject)) || new CnResult();

        setSelectedCnResult(cnResultToSelect);
    }, [cnResults, anotherSelectedCanvasObject]);

    const handleSwitch = () => cnIntent.switch(selectedCnResult);

    const handleRefresh = () => {
        setCnResults(prevState => {
            new SmartCnResults(prevState).removeFromCanvas();

            return new CnResults();
        });

        cnIntent.cnResults().then(results => {
            new SmartCnResults(results).addToCanvas();

            setCnResults(results);
        });
    };

    const handleClose = (e) => {
        onCloseClick(e);

        const results = new SmartCnResults(cnResults);
        results.removeFromCanvas();
    };

    const renderDetailsByMode = (mode) => {
        if (mode === "cn-details") {
            return (
                <Card title="Details" size="small">
                    <CnResultDetails detailsTarget={selectedCnResult}/>
                </Card>
            );
        } else if (mode === "object-data") {
            return renderObjectData();
        } else {
            throw new Error(`Unknown details mode (${mode})`);
        }
    };

    return (
        <>
            <Row justify="space-between" style={{marginBottom: "16px"}}>
                <Tooltip title="Close">
                    <Button shape="circle" size="medium" icon={<ArrowLeftOutlined />}
                            onClick={handleClose}
                    />
                </Tooltip>
                <Space>
                    <Tooltip title="Swap drawings">
                        <Button id="swap-cn-results-button" shape="circle" size="medium" icon={<SwapOutlined />}
                                onClick={handleSwitch}
                        />
                    </Tooltip>
                    <Tooltip title="Refresh comparison results. (Save changes first!)">
                        <Button shape="circle" size="medium" icon={<SyncOutlined />}
                                onClick={handleRefresh}
                        />
                    </Tooltip>
                </Space>
            </Row>
            <CnResultsTable selectedCnResult={selectedCnResult}
                            onCnResultSelect={setSelectedCnResult}
                            cnResults={cnResults}
                            onFiltersChanged={onFiltersChanged}
            />
            <Radio.Group onChange={e => setDetailsMode(e.target.value)} value={detailsMode} buttonStyle="solid" size="small">
                <Radio.Button value="object-data">Object data</Radio.Button>
                <Radio.Button value="cn-details">Details</Radio.Button>
            </Radio.Group>
            {renderDetailsByMode(detailsMode)}
        </>
    );
}