import React, {useEffect, useState} from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Radio,
  Select,
  Divider,
  Tag,
  Popconfirm,
  message,
  Checkbox, Tooltip
} from "antd";
import {DragSortingTable} from "../Misc/DragSortingTable";
import {EditOutlined, DeleteOutlined, PlusOutlined, CloseOutlined} from "@ant-design/icons";
import WorkspacesService from "../../services/WorkspacesService";
import EditableTagGroup from "../Misc/EditableTagGroup";
import {DeleteWorkspaceReportAction} from "../Misc/DeleteWorkspaceReportAction";

const customColumnNames = ["node_text", "node_label", "parent_text", "parent_label", 
  "file_name", "page_number", "link", "empty", "attributes"];

class ColumnData {
  constructor(name, value, value_type) {
    this.name = name;
    this.value = value;
    this.value_type = value_type;
  }

  toFormValues() {
    const formValues = {
      name: this.name,
      column_type: "",
      custom_field_name: "",
      page_field_name: "",
      attribute_name: "",
    };
    if (this.value_type === "custom") {
      formValues.column_type = "custom_field";
      formValues.custom_field_name = this.value;
    } else if (this.value_type === "field") {
      formValues.column_type = "page_field";
      formValues.page_field_name = this.value;
    } else if (this.value_type === "attribute") {
      formValues.column_type = "attribute";
      formValues.attribute_name = this.value;
    }
    return formValues;
  }

  static fromFormValues(formValues) {
    const value = formValues[`${formValues.column_type}_name`];
    let value_type;
    if (formValues.column_type === "custom_field") {
      value_type = "custom";
    } else if (formValues.column_type === "page_field") {
      value_type = "field";
    } else if (formValues.column_type === "attribute") {
      value_type = "attribute";
    }
    return new ColumnData(formValues.name, value, value_type);
  }

  static fromObject(obj) {
    return new ColumnData(obj.name, obj.value, obj.value_type);
  }
}


export default class ConfigureHierarchyReports extends React.Component {

  prettyColumnName = {
    "node_text": "Node text",
    "node_label": "Node class",
    "parent_text": "Parent text",
    "parent_label": "Parent label",
    "file_name": "File name",
    "page_number": "Page number",
    "link": "Link",
    "empty": "Empty",
    "attributes": "Attributes"
  };

  defaultColumns = [
    {name: "Node text", value: "node_text", value_type: "custom"},
    {name: "Node class", value: "node_label", value_type: "custom"},
    {name: "Parent text", value: "parent_text", value_type: "custom"},
    {name: "Parent class", value: "parent_label", value_type: "custom"}
  ];

  state = {
    reports: [],
    mode: "list",
    isModalShown: false,
    currentColumns: [],
    currentColumnIndex: null,
    currentReport: null,
    postProcessingFunctions: [],
  };

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.newReportFormRef = React.createRef();
  }

  componentDidMount() {
    this.loadReportsConfigs();
    this.loadPostProcessingFunctions();
  }

  loadReportsConfigs = () => {
    WorkspacesService.fetchHierarchyReportsConfigs(this.props.workspaceId).then(res => {
      this.setState({reports: res.data});
    }).catch(() => message.error("Failed to load reports configs"));
  };

  loadPostProcessingFunctions = () => {
    WorkspacesService.fetchPostProcessingLambdaFunctions(this.props.workspaceId).then(res => {
      this.setState({postProcessingFunctions: res.data});
    }).catch(() => message.error("Failed to load post processing functions"));
  };

  hideModal = () => {
    this.setState({isModalShown: false});
  };

  handleNewColumn = () => {
    this.setState({isModalShown: true, currentColumnIndex: null}, () => this.formRef.current.setFieldsValue({
      column_type: "custom_field",
      custom_field_name: "",
      page_field_name: "",
      attribute_name: "",
      name: "",
    }));
  };

  handleEditColumn = (colIndex) => {
    this.setState({isModalShown: true, currentColumnIndex: colIndex},
        () => this.formRef.current.setFieldsValue(ColumnData.fromObject(this.state.currentColumns[colIndex]).toFormValues())
    );
  };

  handleSaveColumnData = (values) => {
    if (this.state.currentColumnIndex === null) {
      // add new column
      this.setState({currentColumns: [...this.state.currentColumns, ColumnData.fromFormValues(values)]});
    } else {
      // edit existing column
      const updatedColumns = this.state.currentColumns.slice();
      updatedColumns[this.state.currentColumnIndex] = ColumnData.fromFormValues(values);
      this.setState({currentColumns: updatedColumns});
    }
    this.hideModal();
  };

  getPrettyValue = (columnData) => {
    const curFormValues = ColumnData.fromObject(columnData).toFormValues();
    if (curFormValues.column_type === "custom_field") return <Tag color="blue">{this.prettyColumnName[curFormValues.custom_field_name]}</Tag>;
    else if (curFormValues.column_type === "page_field") return <Tag color="green">{curFormValues.page_field_name}</Tag>;
    else return <Tag color="orange">{curFormValues.attribute_name}</Tag>;
  };

  handleDeleteColumn = (colIndex) => {
    this.setState({currentColumns: this.state.currentColumns.filter((_, i) => i !== colIndex)});
  };

  handleNewReport = () => {
    this.setState({mode: "new", currentColumns: this.defaultColumns, currentReport: null},
        () => this.newReportFormRef.current.setFieldsValue({name: "", post_processing_lambda_id: null})
    );
  };

  handleEditReport = (report) => {
    this.setState({mode: "edit", currentColumns: report.columns, currentReport: report},
        () => {
          this.newReportFormRef.current.setFieldsValue({
            name: report.name,
            post_processing_lambda_id: report.post_processing_lambda_id,
          });
        }
    );
  };

  handleSaveReport = (values) => {
    if (this.state.mode === "new") {
      WorkspacesService.addHierarchyReportConfig(this.props.workspaceId, {
        name: values.name,
        columns: this.state.currentColumns,
        post_processing_lambda_id: values.post_processing_lambda_id,
      }).then(() => {
        message.success("Report config saved");
        this.loadReportsConfigs();
        this.setState({mode: "list"});
      }).catch(() => {
        message.error("Failed to save report config");
      });
    } else if (this.state.mode === "edit") {
      WorkspacesService.updateHierarchyReportConfig(this.props.workspaceId, {
        id: this.state.currentReport.id,
        name: values.name,
        columns: this.state.currentColumns,
        post_processing_lambda_id: values.post_processing_lambda_id,
      }).then(() => {
        message.success("Report config updated");
        this.loadReportsConfigs();
        this.setState({mode: "list"});
      }).catch(() => {
        message.error("Failed to update report config");
      });
    }
  };

  handleDeleteReport = (reportId) => {
    WorkspacesService.deleteHierarchyReportConfig(this.props.workspaceId, reportId).then(() => {
      message.success("Report config removed");
      this.loadReportsConfigs();
    }).catch(() => {
      message.error("Failed to remove report config");
    });
  };

  render() {
    const reportsListColumns = [
      {
        title: "Report name",
        dataIndex: "name",
      },
      {
        title: "Actions",
        dataIndex: "id",
        render: (id, record) => {
          return (
              <>
                <Space size="large">
                  <a onClick={() => this.handleEditReport(record)}>Edit</a>
                  <DeleteWorkspaceReportAction onDelete={() => this.handleDeleteReport(id)}/>
                </Space>
              </>
          );
        }
      }
    ];
    return (
        <>
          {this.state.mode === "list" &&
          <Card title="Configure hierarchy reports" extra={<Button type="primary" onClick={this.handleNewReport}>New hierarchy report</Button>}>
            <Row>
              <Col span={24}>
                <Table columns={reportsListColumns} dataSource={this.state.reports}/>
              </Col>
            </Row>
          </Card>
          }
          {["new", "edit"].includes(this.state.mode)  &&
          <div style={{display: "flex",  justifyContent:"center"}}>
            <Card title={this.state.mode === "new" ? "New report" : "Edit report"} style={{width: "50%"}} >
              <Form ref={this.newReportFormRef} onFinish={this.handleSaveReport} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                <Form.Item name="name" label="Report name" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="post_processing_lambda_id" label="Post processing function" rules={[{ required: false }]}>
                  <Select>
                    {[{id: null, name: "No post processing"}, ...this.state.postProcessingFunctions].map(lambdaFunction => {
                      return (
                          <Select.Option value={lambdaFunction.id}>{lambdaFunction.name}</Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Card title="Columns" extra={<Button type="primary" onClick={this.handleNewColumn}>New column</Button>} type="inner" size="small">
                  <DragSortingTable
                      dataSource={this.state.currentColumns}
                      onRowsMoved={newRows => this.setState({currentColumns: newRows})}
                      size="small"
                      scroll={{y: "40vh"}}
                      pagination={{pageSize: 1000000, hideOnSinglePage: true}}
                  >
                    <Table.Column
                        title='Column name'
                        dataIndex='name'
                    />
                    <Table.Column
                        title='Value'
                        // dataIndex='value'
                        render={(value, record) => {
                          return this.getPrettyValue(record);
                        }}

                        // dataIndex=''
                    />
                    <Table.Column
                        title='Actions'
                        dataIndex='id'
                        render={(id, record, index) => (
                            <>
                              <a onClick={() => this.handleEditColumn(index)}><EditOutlined style={{fontSize: "16px", marginRight: "16px"}} /></a>
                              <Popconfirm title="Are you sure to delete the column？"
                                          onConfirm={() => this.handleDeleteColumn(index)}
                              >
                                <a><DeleteOutlined style={{fontSize: "16px"}} /></a>
                              </Popconfirm>
                            </>
                        )}
                    />
                  </DragSortingTable>
                </Card>
                <div style={{marginTop: "16px"}}>
                  <Button onClick={() => this.setState({mode: "list"})}>Cancel</Button>
                  <Button type="primary" htmlType="submit" style={{float: "right"}}>Save</Button>
                </div>
              </Form>
            </Card>

          </div>
          }
          <Modal
              title={this.state.currentColumnIndex === null ? "New column" : "Edit column"}
              visible={this.state.isModalShown}
              footer={null}
              onCancel={this.hideModal}
          >
            <Form name="new-edit-column-form" ref={this.formRef} onFinish={this.handleSaveColumnData} initialValues={{ column_type: "custom_field" }}>
              <Form.Item
                  label="Column name"
                  name="name"
                  rules={[{ required: true }]}
              >
                <Input/>
              </Form.Item>
              <Divider orientation="left">Column value</Divider>
              <Form.Item name="column_type">
                <Radio.Group style={{width: "100%"}}>
                  <Row gutter={[8, 8]}>
                    <Col span={8}>
                      <Radio value="custom_field">Standard field</Radio>
                    </Col>
                    <Col span={16}>
                      <Form.Item name="custom_field_name">
                        <Select>
                          {
                            customColumnNames.map(val => (
                                <Select.Option value={val}>{this.prettyColumnName[val]}</Select.Option>
                            ))
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col span={8}>
                      <Radio value="page_field">Page field</Radio>
                    </Col>
                    <Col span={16}>
                      <Form.Item name="page_field_name">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col span={8}>
                      <Radio value="attribute">Attribute</Radio>
                    </Col>
                    <Col span={16}>
                      <Form.Item name="attribute_name">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                <Row>
                  <Button onClick={this.hideModal}>Cancel</Button>
                  <Button type="primary" htmlType="submit" style={{marginLeft: "auto"}}>
                    {this.state.currentColumnIndex === null ? "Add" : "Update"}
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Modal>
        </>
    );
  }
}
