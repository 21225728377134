import React, {useState} from "react";
import {EditCommentContainer} from "../Containers/EditCommentContainer";
import * as Editable from "./Editable/EditableComment";


export function AddNewComment(
    {initial, onEditStart, onEditCancel, onEditApprove, onEditUpdate, newMarkers, markerCreationForbidden,
        children, assignTargets}
) {
    const [editing, setEditing] = useState(false);

    const handleEditStart = () => {
        setEditing(true);
        onEditStart();
    };

    const handleEditCancel = (_) => {
        setEditing(false);
        onEditCancel();
    };

    const handleEditApprove = (initial, edited) => {
        setEditing(false);
        onEditApprove(edited);
    };

    const editableComment = (
        <EditCommentContainer
            comment={initial}
            onEditUpdate={onEditUpdate}
            onEditCancel={handleEditCancel}
            onEditApprove={handleEditApprove}
        >
            {props => (
                <Editable.Comment
                    id={"new-comment-box"}
                    {...props}
                    markerCreationForbidden={markerCreationForbidden}
                    newMarkers={newMarkers}
                    assignTargets={assignTargets}
                />
            )}
        </EditCommentContainer>
    );

    return (
        <>
            <div style={{display: editing ? "none" : "block"}}>
                {children({onEditStart: handleEditStart})}
            </div>
            {editing && editableComment}
        </>
    );
}