import React from "react";
import {Button, Card, Form, Input, Row, Select} from "antd";
import {Link} from "react-router-dom";
import axios from "axios";
import {API_URL} from "../constants";
import {authHeader} from "../Utilities";


export class NewProject extends React.Component {
  state = {
    selectedWorkspace: null,
    workspacesList: [],
    projectName: "",
    projectDescription: ""
  };

  loadWorkspaces = () => {
    axios.get(
        API_URL + "/workspaces",
        {headers: authHeader()}
    ).then(result => {
      const workspacesList = result.data;
      this.setState({
        workspacesList: workspacesList,
      });
    });
  };

  componentDidMount() {
    this.loadWorkspaces();
  }

  render() {
    return (
      <>
        <Row style={{justifyContent: "center"}}>
          <Card title="Create new project" style={{width: "50%"}}>
            <Form
              labelCol={{span: 8}}
              wrapperCol={{span: 16}}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please input the project name" }]}
              >
                <Input
                    value={this.state.projectName}
                    onChange={e => this.setState({projectName: e.target.value})}
                />
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                rules={[{ required: true, message: "Please input the project description" }]}
              >
                <Input
                    value={this.state.projectDescription}
                    onChange={e => this.setState({projectDescription: e.target.value})}
                />
              </Form.Item>

              <Form.Item label="Workspace" name="workspace" rules={[{required: true}]}>
                <Select
                    id="workspaceSelect"
                    value={this.state.selectedWorkspace}
                    onChange={(value) => this.setState({selectedWorkspace: value})}
                >
                  {this.state.workspacesList.map((workspace) => {
                    return (
                        <Select.Option value={workspace.id}>{workspace.name}</Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Row style={{justifyContent: "space-between"}}>
                <Button><Link to="/projects">Cancel</Link></Button>
                <Button
                  type="primary"
                  onClick={(e) => 
                    this.props.createProject({
                      name: this.state.projectName,
                      description: this.state.projectDescription,
                      workspace_id: this.state.selectedWorkspace
                    })
                  }
                >
                  Create
                </Button>
              </Row>
            </Form>
          </Card>
        </Row>
      </>
    );
  }
}