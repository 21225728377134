import {fabric} from "fabric";

export class MiniMapHandler {
  constructor(imageViewer) {
    this.imageViewer = imageViewer;
    this.minimap = new fabric.Canvas("minimap",  { containerClass: "minimap", selection: false });

    // const ctxx = this.minimap.getContext();
    // ctxx.imageSmoothingQuality = 'high';

    this.minimap.backgroundColor = "green";

    this.sliderDragging = false;
    this.sliderXpos = 0;
    this.sliderBaseOffsetX = 0;
  }

  // copied from fabric.js
  // added setting imageSmoothingQuality high
  toCanvasElement =  (th, multiplier, cropping) =>  {
    multiplier = multiplier || 1;
    cropping = cropping || { };
    var scaledWidth = (cropping.width || th.width) * multiplier,
        scaledHeight = (cropping.height || th.height) * multiplier,
        zoom = th.getZoom(),
        originalWidth = th.width,
        originalHeight = th.height,
        newZoom = zoom * multiplier,
        vp = th.viewportTransform,
        translateX = (vp[4] - (cropping.left || 0)) * multiplier,
        translateY = (vp[5] - (cropping.top || 0)) * multiplier,
        originalInteractive = th.interactive,
        newVp = [newZoom, 0, 0, newZoom, translateX, translateY],
        originalRetina = th.enableRetinaScaling,
        canvasEl = fabric.util.createCanvasElement(),
        originalContextTop = th.contextTop;
    canvasEl.width = scaledWidth;
    canvasEl.height = scaledHeight;
    const ctx = canvasEl.getContext("2d");
    ctx.imageSmoothingQuality = "high";
    th.contextTop = null;
    th.enableRetinaScaling = false;
    th.interactive = false;
    th.viewportTransform = newVp;
    th.width = scaledWidth;
    th.height = scaledHeight;
    th.calcViewportBoundaries();
    th.renderCanvas(canvasEl.getContext("2d"), th._objects);
    th.viewportTransform = vp;
    th.width = originalWidth;
    th.height = originalHeight;
    th.calcViewportBoundaries();
    th.interactive = originalInteractive;
    th.enableRetinaScaling = originalRetina;
    th.contextTop = originalContextTop;
    return canvasEl;
  };

  createCanvasEl = () => {
    const design = this.imageViewer.canvas;
    const th = this.imageViewer;
    const minimap = this.minimap;
    // debugger;
    var designSize = { width: th.img.width, height: th.img.height };
    var originalVPT = design.viewportTransform;
    // zoom to fit the design in the display canvas
    // var designRatio = fabric.util.findScaleToFit(designSize, design);


    // zoom to fit the display the design in the minimap.
    // var minimapRatio = 0.16;//fabric.util.findScaleToFit(design, minimap);

    var scaling = Math.min(design.width / th.img.width, design.height / th.img.height);//minimap.getRetinaScaling();


    // var finalWidth =  designSize.width * designRatio;
    // var finalHeight =  designSize.height * designRatio;

    design.viewportTransform = [
      scaling, 0, 0, scaling,0,0];
    // (design.getWidth() - finalWidth) / 2,
    // (design.getHeight() - finalHeight) / 2
    // ];

    const finScaling = Math.min(minimap.width / (th.img.width * scaling), minimap.height / (th.img.height * scaling));
    // var canvas = design.toCanvasElement(finScaling); //, {left:0, top: 0, width: 5000, height: 3500});
    var canvas = this.toCanvasElement(design, finScaling); //, {left:0, top: 0, width: 5000, height: 3500});
    design.viewportTransform = originalVPT;
    return canvas;
  };

  initMinimap = () => {
    const th = this.imageViewer;
    const minimap = this.minimap;
    const design = this.imageViewer.canvas;
    var canvas = this.createCanvasEl();
    // canvas.imageSmoothingEnabled = false;
    // var backgroundImage = fabric.util.object.clone(img);//new fabric.Image(canvas);
    var backgroundImage = new fabric.Image(canvas);
    // backgroundImage.resizeFilter = new fabric.Image.filters.Resize({
    //   resizeType: 'lanczoc'
    // });
    // backgroundImage.applyResizeFilters();
    const scale = 1.0;//Math.min(minimap.width / img.width, minimap.height / img.height)
    // backgroundImage.scaleX = scale; // 1 / design.getRetinaScaling();
    // backgroundImage.scaleY = scale; // 1 / design.getRetinaScaling();
    // minimap.centerObject(backgroundImage);


    // const ctx = minimap.getContext();
    // ctx.imageSmoothingQuality = 'high';

    minimap.backgroundColor = "white";
    minimap.setBackgroundImage(backgroundImage);
    // minimap.backgroundImage = backgroundImage;



    minimap.requestRenderAll();
    var minimapView = new fabric.Rect({
      top: backgroundImage.top,
      left: backgroundImage.left,
      width: backgroundImage.width / design.getRetinaScaling(),
      height: backgroundImage.height / design.getRetinaScaling(),
      // fill: 'rgba(0, 0, 255, 0.3)',
      fill: "rgba(0, 0, 255, 0)",
      stroke: "red",//'rgba(0, 0, 0, 0.5)',//'red',
      strokeWidth: 1,
      hasBorders: false,
      hasControls: false,
      noScaleCache: false,
      strokeUniform: true,
      objectCaching: false,
    });

    const self = this;

    minimap.on("object:moving", () => {
      self.updateMiniMapShadow();
      if (!th.img) {
        return;
      }
      var designSize = { width: th.img.width, height: th.img.height };
      var rect = minimap.getObjects()[1];
      if (!rect)
        return;

      self.clipMiniMapRect();

      const totalRatio = Math.min(minimap.backgroundImage.width / th.img.width, minimap.backgroundImage.height / th.img.height);



      // rect.left = Math.min(rect.left, minimap.backgroundImage.width - rect.width * rect.scaleX);// - rect.width);
      // rect.top = Math.min(rect.top, minimap.backgroundImage.height)// - rect.height);

      // th.setState({
      //   // tmpx: rect.width * rect.scaleX,
      //   tmpx: minimap.backgroundImage.width,
      //   tmpy: rect.left
      // });


      var designRatio = fabric.util.findScaleToFit(designSize, design);
      // var totalRatio = fabric.util.findScaleToFit(designSize, minimap);
      // var finalRatio = designRatio / design.getZoom();
      // rect.scaleX = finalRatio;
      // rect.scaleY = finalRatio;
      // rect.top = minimap.backgroundImage.top - design.viewportTransform[5] * totalRatio / design.getZoom();
      // rect.left = minimap.backgroundImage.left - design.viewportTransform[4] * totalRatio / design.getZoom();
      // minimap.requestRenderAll();

      var vpt = th.canvas.viewportTransform;

      vpt[4] = -(rect.left - minimap.backgroundImage.left) / (totalRatio / design.getZoom());
      vpt[5] = -(rect.top - minimap.backgroundImage.top) / (totalRatio / design.getZoom());
      // vpt[5] = e.clientY - this.lastPosY;
      th.zoomHandler.clipViewPort();
      th.canvas.requestRenderAll();
      th.canvas.renderAll();
    });

    var minimapShadow = new fabric.Polygon([
          {x: 0, y: 0},
          {x: 0, y: 0},
          {x: 0, y: 0},
        ], {
          fill: "rgba(0, 0, 0, 0.3)"
        }
    );

    minimapView.setControlsVisibility({
      bl: false,
      br: false,
      tl: false,
      tr: false,
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      mtr: false,
    });
    minimap.add(minimapShadow);

    minimap.add(minimapView);
  };

  updateMiniMapShadow = () => {
    this.clipMiniMapRect();

    const minimap = this.minimap;

    var rect = minimap.getObjects()[1];
    if (!rect)
      return;
    var shadow = minimap.getObjects()[0];
    if (shadow) {
      minimap.remove(shadow);
    }
    var newShadow = new fabric.Polygon([
          {x: 0, y: 0},
          {x: minimap.backgroundImage.width, y: 0},
          {x: minimap.backgroundImage.width, y: rect.top + 1},
          {x: rect.left+1, y: rect.top + 1},
          {x: rect.left+1, y: rect.top + rect.height + 1},
          {x: rect.left + rect.width + 1, y: rect.top + rect.height + 1},
          {x: rect.left + rect.width + 1, y: rect.top + 1},
          {x: minimap.backgroundImage.width, y: rect.top + 1},
          {x: minimap.backgroundImage.width, y: minimap.backgroundImage.height},
          {x: 0, y: minimap.backgroundImage.height},
        ],
        {
          fill: "rgba(0,0,0,0.5)",
          selectable: false,
          evented: false,
          hasBorders: false,
          hasControls: false,
        }
    );
    minimap.add(newShadow);
    rect.moveTo(1);
    newShadow.moveTo(0);

    minimap.requestRenderAll();
  };

  clipMiniMapRect = () => {
    const th = this.imageViewer;
    var rect = this.minimap.getObjects()[1];
    if (!rect)
      return;

    const totalRatio = Math.min(this.minimap.backgroundImage.width / th.img.width, this.minimap.backgroundImage.height / th.img.height);
    rect.set({
      left: Math.min(Math.max(rect.left, 0), th.img.width * totalRatio - rect.width),
      top: Math.min(Math.max(rect.top, 0), th.img.height * totalRatio - rect.height),
    });
    rect.setCoords();
  };

  getCurSliderXPos = () => {
    const minPos = 0;
    const maxPos = 200 - 20;
    const curZoomRatio = this.imageViewer.zoomHandler.getZoomRatio();
    const curXPos = Math.floor(curZoomRatio * maxPos);

    return curXPos;
  };

  updateMiniMapVP = () => {
    const th = this.imageViewer;
    const minimap = this.minimap;
    const design = this.imageViewer.canvas;

    if (!th.img) {
      return;
    }
    // return;
    var designSize = { width: th.img.width, height: th.img.height };
    var rect = minimap.getObjects()[1];
    if (!rect)
      return;
    var designRatio = fabric.util.findScaleToFit(designSize, design);
    var totalRatio = fabric.util.findScaleToFit(designSize, minimap);
    var finalRatio = designRatio / design.getZoom();

    var scaling = Math.min(design.width / th.img.width, design.height / th.img.height);//minimap.getRetinaScaling();
    const finScaling = Math.min(minimap.width / (th.img.width * scaling), minimap.height / (th.img.height * scaling));

    rect.set({
      top: minimap.backgroundImage.top - design.viewportTransform[5] * totalRatio / design.getZoom(),
      left: minimap.backgroundImage.left - design.viewportTransform[4] * totalRatio / design.getZoom(),
      width: minimap.backgroundImage.width * finalRatio,
      height: minimap.backgroundImage.height * finalRatio,
    });
    rect.setCoords();
    minimap.requestRenderAll();
    this.updateMiniMapShadow();
  };

  updateMiniMap = () => {
    if (!this.minimap.backgroundImage) return;
    var canvas = this.createCanvasEl();
    this.minimap.backgroundImage._element = canvas;
    // minimap.backgroundImage.resizeFilter = new fabric.Image.filters.Resize({
    //   resizeType: 'lanczoc'
    // });
    // minimap.backgroundImage.applyResizeFilters();
    this.minimap.requestRenderAll();
  };

  handleSliderMouseMove = (e) => {
    if (this.sliderDragging) {
      const th = this.imageViewer;
      const minPos = 0;
      const maxPos = 200 - 20;

      const delta = e.nativeEvent.clientX - this.sliderBaseOffsetX;
      let newPos = this.sliderXpos + delta;
      newPos = Math.max(Math.min(newPos, maxPos), minPos);
      const zoomHandler = this.imageViewer.zoomHandler;
      const newZoom = zoomHandler.zoomFunc(zoomHandler.zoomFuncInv(zoomHandler.minZoomLevel) + newPos / maxPos * (zoomHandler.zoomFuncInv(zoomHandler.maxZoomLevel) - zoomHandler.zoomFuncInv(zoomHandler.minZoomLevel)));
      th.zoomHandler.setZoomForce(newZoom, th.canvas.width / 2.0, th.canvas.height / 2.0);
    }
  };

  handleSliderMouseDown = (e) => {
    this.sliderDragging = true;
    this.sliderXpos = this.getCurSliderXPos();
    this.sliderBaseOffsetX = e.nativeEvent.clientX;
  };

  handleSliderMouseUp = (e) => {
    this.sliderDragging = false;
  };

  registerEvents = () => {
    this.imageViewer.subscriptions.push(this.imageViewer.viewPortChanged$.subscribe(() => {
      this.updateMiniMapVP();
      const sliderObj = document.getElementById("minimap-slider");
      sliderObj.style.left = `${this.getCurSliderXPos()}px`;
    }));

    this.imageViewer.subscriptions.push(this.imageViewer.objectsChanged$.subscribe(() => {
      this.updateMiniMap();
    }));

    this.imageViewer.subscriptions.push(this.imageViewer.visualChanged$.subscribe(() => this.updateMiniMap()));
  };
}