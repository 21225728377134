import * as queryString from "query-string";
import {ObjectAttributeFilter, PageFieldFilter, SearchFilter} from "./SearchFilters";

class BaseFiltersFromUrl {
    constructor(url) {
        this.url = url;
    }

    getArray() {
        return [];
    }
}

export class FiltersFromURL extends BaseFiltersFromUrl {
    constructor(url) {
        super(url);

        this.possibleFilters = [
            new SearchFiltersFromUrl(url),
            new ObjectAttributesFiltersFromUrl(url),
            new PageFieldsFiltersFromUrl(url),
        ];
    }

    getArray() {
        return this.possibleFilters.flatMap(item => item.getArray());
    }
}

class ObjectAttributesFiltersFromUrl extends BaseFiltersFromUrl {
    getArray() {
        const query = queryString.parse(decodeURI(this.url));
        const queryKeys = Object.keys(query);

        return queryKeys.filter(key => key.startsWith("object_attr")).map(key => {
            const filterParts = key.match("^object_attr\\[(.*)\\]\\[(.*)\\]$");

            const filterKey = filterParts[1];
            const filterOperation = filterParts[2];
            const filterValue = query[key];

            return new ObjectAttributeFilter(filterKey, filterValue, filterKey, filterOperation);
        });
    }
}

class PageFieldsFiltersFromUrl extends BaseFiltersFromUrl {
    getArray() {
        const query = queryString.parse(decodeURI(this.url));
        const queryKeys = Object.keys(query);

        return queryKeys.filter(key => key.startsWith("page_field")).map(key => {
            const filterParts = key.match("^page_field\\[(.*)\\]\\[(.*)\\]$");

            const filterKey = filterParts[1];
            const filterOperation = filterParts[2];
            const filterValue = query[key];

            return new PageFieldFilter(filterKey, filterValue, filterKey, filterOperation);
        });
    }
}

export class SearchFiltersFromUrl extends BaseFiltersFromUrl {
    getArray() {
        const query = queryString.parse(decodeURI(this.url));
        const queryKeys = Object.keys(query);

        if (queryKeys.includes("q")) {
            const q = query["q"];
            const useWildcards = query["useWildcards"] === "true";

            return [new SearchFilter("", q, "Search", useWildcards)];
        }

        return [];
    }
}