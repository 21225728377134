export const readonlyPageFieldKeys = ["File name"];

export class PageFieldsOperationsHandler {
    constructor(imageViewer) {
        this.imageViewer = imageViewer;

        imageViewer.subscriptions.push(imageViewer.objectsChanged$.subscribe(e => {
            const fields = imageViewer.state.baseAnnotation?.fields;

            if (fields == null) return;

            imageViewer.canvas.getObjects().forEach(obj =>  {
                const objId = obj.objectMetadata?.id;

                if (objId == null) return;

                const fieldByObjId = fields.find(field => field.drawing_object_id === objId);

                if (fieldByObjId == null) return;

                fieldByObjId.value = obj.objectMetadata?.text || "";
            });

            imageViewer.setState({baseAnnotation: {...imageViewer.baseAnnotation, fields: fields}});
        }));
    }

    getSelectedObjectAssignDataOrNull = () => {
        const selectedObjectMetadata = this.imageViewer.state.selectedObject?.objectMetadata;
        if (selectedObjectMetadata == null) return null;

        return {
            id: selectedObjectMetadata.id,
            text: selectedObjectMetadata.text
        };
    };

    getPageFields = () => {
        return this.imageViewer.state.baseAnnotation?.fields || [];
    };

    updateFields = (fields) => {
        fields
            .filter(field => field.drawing_object_id != null)
            .forEach(field => {
                const assignedObject = this.imageViewer.canvas.getObjects()
                    .filter(obj => obj.objectMetadata)
                    .find(obj => obj.objectMetadata.id === field.drawing_object_id);

                assignedObject.objectMetadata.text = field.value;
            });

        this.imageViewer.objectsChanged$.next(1);

        this.imageViewer.setState({baseAnnotation: {...this.imageViewer.state.baseAnnotation, fields: fields}});
    };

    findAssignedFieldForObjectById = (id) => {
        if (id == null) throw Error("Id is undefined");

        const assignedField = this.getPageFields().filter(field => field.drawing_object_id != null)
            .find(field => field.drawing_object_id === id);

        return assignedField;
    };
}