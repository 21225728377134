

export class NoneCommentsSelection {
    constructor(needScroll) {
        this._needScroll = needScroll;
    }

    markerSelected(comment, marker) {
        return false;
    }

    commentSelected(comment) {
        return false;
    }

    needScroll() {
        return this._needScroll;
    }
}


export class CommentMarkerSelection extends NoneCommentsSelection {
    constructor(commentId, markerId, needScroll) {
        super(needScroll);
        this._commentId = commentId;
        this._markerId = markerId;
    }

    markerSelected(comment, marker) {
        return this.commentSelected(comment) && marker.id === this._markerId;
    }

    commentSelected(comment) {
        return this._commentId === comment.id;
    }
}


export class OnlyCommentSelection extends NoneCommentsSelection {
    constructor(commentId, needScroll) {
        super(needScroll);
        this._commentId = commentId;
    }

    markerSelected(comment, marker) {
        return false;
    }

    commentSelected(comment) {
        return this._commentId === comment.id;
    }
}


export class FromMarkerTargetCommentSelection extends NoneCommentsSelection {
    constructor(markerTarget, needScroll) {
        super(needScroll);
        this._markerTarget = markerTarget;
    }

    markerSelected(comment, marker) {
        return this._containsMarker(marker);
    }

    commentSelected(comment) {
        return comment.markers.some(this._containsMarker);
    }

    _containsMarker = (marker) => {
        return this._markerTarget.containsMarker(marker);
    };
}

