import {useCallback, useRef, useState} from "react";
import {ObjectModifier, ObjectModifiers, Undo} from "./ObjectModifiers";

type Spinning = {
    stop(): void;
}

type SpinningState = {
    value: boolean
}

export function useSafeSpin(): [boolean, () => Spinning] {
    const initial = false;
    const [spinning, setSpinning] = useState(initial);
    const modifiers = useRef(new ObjectModifiers({value: initial}));

    const spinStart = useCallback(() => {
        const mod = new class implements ObjectModifier<SpinningState> {
            apply(target: SpinningState): Undo {
                const prev = target.value;
                target.value = true;
                setSpinning(target.value);
                return () => {
                    target.value = prev;
                    setSpinning(target.value);
                };
            }
        };

        modifiers.current.add(mod);

        return new class implements Spinning {
            stop(): void {
                modifiers.current.remove(mod);
            }
        };
    }, []);

    return [spinning, spinStart];
}
