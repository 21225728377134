import {useEffect, useState} from "react";


export class CommentsFilter {
    test(comment) {
        return true;
    }
}

export class AndFilter extends CommentsFilter {
    constructor(left, right) {
        super();
        this._left = left;
        this._right = right;
    }

    test(comment) {
        return this._left.test(comment) && this._right.test(comment);
    }
}


export class AllCommentsFilter extends CommentsFilter {

}

export class ByStatusCommentsFilter extends CommentsFilter {
    constructor(targetStatus) {
        super();
        this._targetStatus = targetStatus;
    }

    test(comment) {
        return comment.status === this._targetStatus;
    }
}

export class OpenedCommentsFilter extends ByStatusCommentsFilter {
    constructor() {
        super("open");
    }
}


export class ClosedCommentsFilter extends ByStatusCommentsFilter {
    constructor() {
        super("closed");
    }
}


export function FilterContainer({data, children}) {
    const [filter, setFilter] = useState(new CommentsFilter());
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        setFilteredData(data.filter(el => filter.test(el)));
    }, [data, filter]);

    return children({
        data: filteredData,
        onFilterChange: setFilter
    });
}