import {useEffect, useState} from "react";

export function SelectFromLinkContainer({imageViewer, onSelect, children}) {
    const [data, setData] = useState([]);
    const [commentIdToSelect, setCommentIdToSelect] = useState(null);

    useEffect(() => {
        const sub = imageViewer.commentPassedToLink$.subscribe(target => {
            setCommentIdToSelect(target.commentId);
        });
        return () => {
            sub.unsubscribe();
        };
    }, [imageViewer]);


    useEffect(() => {
        const commentToSelect = data.find(comment => comment.id === commentIdToSelect);

        if (commentToSelect) {
            onSelect(commentToSelect);
        }
    }, [data, commentIdToSelect, onSelect]);

    return children({
        onDataLoad: setData
    });
}