import React from "react";


export function HighlightedMatch({matchText, occurrences}) {
    const occs = [{position: 0, substring: ""}, ...occurrences];
    const highlightedMatch = occs.flatMap((occ, ind) => {
        const res = [];
        if (occ.substring.length) res.push(
            <span id="highlighted" style={{ backgroundColor: "var(--color-yellow)" }}>
                {occ.substring}
            </span>
        );

        const nextStart = occ.position + occ.substring.length;
        const nextEnd = ind + 1 < occs.length ? occs[ind + 1].position : matchText.length;
        res.push(
            <span>{matchText.substring(nextStart, nextEnd)}</span>
        );

        return res;
    });
    return (
        <>{highlightedMatch}</>
    );
}