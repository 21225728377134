import {DocumentRegistrySet} from "../../../../services/DocumentRegistry/DocumentRegistry";

export function RegistryColumnIdFileName() {
    return JSON.stringify({type: "aux", key: "file_name"});
}

export function RegistryColumnIdField(field: string) {
    return JSON.stringify({type: "field", key: field});
}

export function RegistryColumnIdSet(set: DocumentRegistrySet) {
    return JSON.stringify({type: "set", key: set.id()});
}
