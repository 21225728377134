export const nodeToAddTargets = {
  NEW_NODE: "new-node",
  OBJECT: "object",
};

// used in the list for adding multiple nodes
export default class NodeToAdd {
  constructor(target, targetType) {
    this.target = target;
    this.targetType = targetType; // possible values: 'new-node', 'object'
  }

  getLabel() {
    if (this.targetType === nodeToAddTargets.NEW_NODE) return this.target.label;
    else if (this.targetType === nodeToAddTargets.OBJECT) return this.target.label;
  }

  getText() {
    if (this.targetType === nodeToAddTargets.NEW_NODE) return this.target.text;
    else if (this.targetType === nodeToAddTargets.OBJECT) return this.target.text;
  }

  duplicated(sample) {
    return !!sample
        .filter(el => el !== this)
        .filter(el => typeof el.getText === "function" && typeof el.getLabel === "function")
        .some(el => el.getText() === this.getText() && el.getLabel() === this.getLabel());
  }
}
