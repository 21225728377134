import React, {useEffect, useState} from "react";
import {Button, Col, Progress, Result, Row, Select, Space, Spin, Table, Tooltip} from "antd";
import {ColoredColumns} from "../../../../../Utilities";
import {CommonFilterEditor} from "../../../../Misc/CommonFilterEditor/CommonFilterEditor";
import {CnStatusFilterEditor} from "../../../DrawingComparison/Component/Summary/CnResultsTableFilters/CnStatusFilterEditor";
import {
    DataItemFilter,
    DataSourceFiltersEditor, ExactMatchItemFilter, isObjectMatchFilters
} from "../../../../Misc/DataSourceFilter";
import {CnStatusEditedFilter} from "../../../DrawingComparison/Component/Summary/CnResultsTableFilters/Model/CnStatusEditedFilter";
import {MgConflicts} from "../../Model/MgConflicts";
import {AnimatedCnDetails} from "../../../DrawingComparison/Model/AnimatedCnDetails/AnimatedCnDetails";
import {SafeAnimatedCnDetails} from "../../../DrawingComparison/Model/Safe/SafeAnimatedCnDetails";
import {SimpleFilterEditor} from "../../../../Misc/CommonFilterEditor/SimpleFilterEditor";
import {CachedMgConflicts} from "../../Model/Cached/CachedMgConflicts";
import {AcceptButton, RejectButton} from "../../../DrawingComparison/Component/MgResolveButtons";


export class UnresolvedMgConflictFilter extends ExactMatchItemFilter {
    constructor() {
        super("UNRESOLVED", el => el.toFilterData().mgStatus(), "mgStatus");
    }

    getName() {
        return "MUST NOT BE SHOWED";
    }
}

export class CnStatusFilterFromConflict extends DataItemFilter {
    constructor(values) {
        super(values.join(" or "), el => el.toFilterData().cnStatus(), "cnStatus");

        this._values = values;
    }

    _matchLogic(itemValue) {
        return this._values.find(val => itemValue === val);
    }

    getName() {
        return "Status";
    }
}


export class NotSameCnFilterFromConflict extends DataItemFilter {
    constructor() {
        super("", el => el.toFilterData().cnStatus(), "cnStatus");
    }

    _matchLogic(itemValue) {
        return itemValue !== "SAME";
    }

    getName() {
        return "MUST NOT BE SHOWED";
    }
}


class CnStatusEditedFilterFromConflict extends CnStatusEditedFilter {
    toConcreteFilter() {
        return new CnStatusFilterFromConflict(this._values);
    }
}

// TODO to separate file
class FilteredMgConflicts extends MgConflicts {
    constructor(origin, filters) {
        super();

        this._origin = origin;
        this._filters = filters;
    }

    values() {
        return this._origin.values().filter(el => isObjectMatchFilters(el, this._filters));
    }

    loaded() {
        return this._origin.loaded();
    }
}

export class MgDetailsMatchFilter extends DataItemFilter {
    constructor(value, key, alias) {
        super(value, el => el.toFilterData().details(), alias);

        this._key = key;
    }

    _matchLogic(itemValue) {
        const details = new AnimatedCnDetails(itemValue);

        if (!details.keys().includes(this._key)) return false;

        return details.hasValueByKey(this._key, this.value);
    }
}

function MgResult({visible, completionPercent, onUpdateVisibility, onReturn}) {
    useEffect(() => {
        if (completionPercent === 100) {
            onUpdateVisibility(true);
        }

    }, [onUpdateVisibility, completionPercent]);

    return (
        visible &&
        <Result
            title="Merge finished."
            status="success"
            extra={[
                <Button type="primary" key="back" onClick={onReturn}>
                    Back to normal mode
                </Button>,
                <Button key="continue" onClick={e => onUpdateVisibility(false)}>Inspect results</Button>
            ]}
        />
    );
}

export function MgConflictsTable({mgConflicts, selectedMgConflict, onFiltersChanged, onClose}) {
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [editorFilters, setEditorFilters] = useState([]);

    const [filterSchemas, setFilterSchemas] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);

    const [showMode, setShowMode] = useState("unresolved");

    const [mgResultVisible, setMgResultVisible] = useState(false);

    const withoutSameConflicts = new FilteredMgConflicts(
        mgConflicts,
        [new NotSameCnFilterFromConflict()]
    );

    const allConflictsLen = withoutSameConflicts.values().length;

    const unresolvedConflictsLen = new FilteredMgConflicts(
        withoutSameConflicts,
        [new UnresolvedMgConflictFilter()]
    ).values().length;

    const currentPercent = Math.round((1 - unresolvedConflictsLen / allConflictsLen) * 100);

    const allFilters = () => {
        const hiddenFilters = [];

        if (showMode === "unresolved") {
            hiddenFilters.push(new UnresolvedMgConflictFilter());
        }

        hiddenFilters.push(new NotSameCnFilterFromConflict());

        return editorFilters.concat(hiddenFilters);
    };

    const filteredConflicts = new CachedMgConflicts(new FilteredMgConflicts(mgConflicts, allFilters()));

    const getColumnsSchema = () => {
        const columnsData = [
            {
                title: "Object",
                render: (_, record) => record.toMgTableRow().object(),
                onCell: record => ({
                    onClick: e => record.selectOnCanvas()
                })
            },
            {
                title: "Status",
                render: (_, record) => record.toMgTableRow().status(),
                onCell: record => ({
                    onClick: e => record.selectOnCanvas()
                })
            },
        ];

        if (showMode === "all") {
            columnsData.push(
                {
                    title: "Merge status",
                    render: (_, record) => record.toMgTableRow().mgStatus(),
                    onCell: record => ({
                        onClick: e => record.selectOnCanvas()
                    })
                },
            );
        }

        columnsData.push({
            title: "Actions",
            render: (_, record) => record.toMgTableRow().actions()
        });

        const columns = new ColoredColumns(
            columnsData,
            (text, record, idx) => record.id() === selectedMgConflict.id() ? "#eee" : ""
        );

        return columns;
    };

    useEffect(() => {
        onFiltersChanged(allFilters());
    }, [showMode, editorFilters]);

    useEffect(() => {
        const allAttrKeys = [...new Set(mgConflicts.values().flatMap(r => new AnimatedCnDetails(r.toFilterData().details()).keys()))];

        const schemas = allAttrKeys.map(key => ({
            type: "attribute",
            attribute: key,
            allowedValues: mgConflicts.values().flatMap(r => new SafeAnimatedCnDetails(new AnimatedCnDetails(r.toFilterData().details())).values(key)),
            constructor(value) {
                return new MgDetailsMatchFilter(value, key, key);
            }
        }));

        setFilterSchemas(schemas);

        const statusNames = [...new Set(mgConflicts.values().map(el => el.toFilterData().cnStatus()))];
        setStatusOptions(statusNames.filter(name => name !== "SAME").map(name => ({value: name})));
    }, [mgConflicts]);

    useEffect(() => {
        const idx = new FilteredMgConflicts(mgConflicts, allFilters()).values()
            .findIndex(el => el.id() === selectedMgConflict.id());

        if (idx >= 0) {
            setCurrentPage(Math.floor(idx / pageSize) + 1);
        }
    }, [pageSize, showMode, editorFilters, mgConflicts, selectedMgConflict]);

    const filtersRenderer = (tagsList, editor) => (
        <>
            <Row>
                <Progress type={"line"} percent={currentPercent}
                          format={_ => {
                              return `${allConflictsLen - unresolvedConflictsLen}/${allConflictsLen}`;
                          }}
                />
            </Row>
            {
                currentPercent < 100 &&
                <Row justify={"end"} style={{marginBottom: 8}}>
                    <Space>
                        <Tooltip title={`Accept ${filteredConflicts.values().length} items`}>
                            <AcceptButton onClick={() => filteredConflicts.values().forEach(el => el.accept())}>
                                Accept all
                            </AcceptButton>
                        </Tooltip>
                        <Tooltip title={`Reject ${filteredConflicts.values().length} items`}>
                            <RejectButton onClick={() => filteredConflicts.values().forEach(el => el.reject())}>
                                Reject all
                            </RejectButton>
                        </Tooltip>
                    </Space>
                </Row>
            }
            <Row justify={"space-between"} style={{marginBottom: 8}}>
                <Col span={12}>
                    {tagsList}
                </Col>
                <Col>
                    <Space size="large">
                        {editor}
                        <Space>
                            Show
                            <Select id="mg-conflicts-show-mode-select" value={showMode}
                                    size={"small"} style={{width: 110}}
                                    onChange={setShowMode}
                            >
                                <Select.Option id="show-mode-unresolved" value="unresolved">Unresolved</Select.Option>
                                <Select.Option id="show-mode-all" value="all">All</Select.Option>
                            </Select>
                        </Space>
                    </Space>
                </Col>
            </Row>
        </>
    );

    const renderMgConflicts = () => (
        <>
            <DataSourceFiltersEditor
                itemFilters={editorFilters}
                onItemFiltersChanged={f => setEditorFilters([...f])}
                filterSchemas={filterSchemas}
                contentRenderer={filtersRenderer}
                fieldEditorRenderer={(filterSchemas, addNewFilter) => (
                    <CommonFilterEditor
                        editors={[
                            {
                                type: "cnStatus",
                                tabName: "Status",
                                render: (schemasByType, onEditedFilterChanges) => (
                                    <CnStatusFilterEditor
                                        possibleOptions={statusOptions}
                                        onEditedFilterChanged={onEditedFilterChanges}
                                        editedFilterCtor={values => new CnStatusEditedFilterFromConflict(values)}
                                    />
                                )
                            },
                            {
                                type: "attribute",
                                tabName: "Attribute",
                                render: (schemasByType, onEditedFilterChanges) => (
                                    <SimpleFilterEditor
                                        filterSchemas={schemasByType}
                                        onEditedFilterChanged={onEditedFilterChanges}
                                    />
                                )
                            }
                        ]}
                        filterSchemas={filterSchemas}
                        onNewFilter={addNewFilter}
                    />
                )}
            />
            <Table
                id="mg-conflicts-table"
                size="small"
                columns={getColumnsSchema().values()}
                dataSource={filteredConflicts.values()}
                pagination={{
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    current: currentPage,
                    pageSize: pageSize,
                    onChange: (number, size) => {
                        setCurrentPage(number);
                        setPageSize(size);
                    }
                }}
            />
        </>
    );

    return (
        <>
            <Spin spinning={!mgConflicts.loaded()}>
                <MgResult
                    visible={mgResultVisible}
                    onReturn={onClose}
                    onUpdateVisibility={setMgResultVisible}
                    completionPercent={currentPercent}
                />
                {!mgResultVisible && renderMgConflicts()}
            </Spin>
        </>
    );
}