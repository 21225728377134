import React from "react";
import {Button, Col, Dropdown, Form, Input, Menu, message, Row, Select, Spin, Table, Tooltip} from "antd";
import {Link} from "react-router-dom";
import {API_URL} from "../constants";
import axios from "axios";
import {authHeader} from "../Utilities";
import TagUrlLinkService from "../services/TagUrlLinkService";
import {Buffer} from "buffer/";

export class TagPreview extends React.Component {
  state = {
    isPreviewLoading: true,
    zoomLevel: 1,
    lastUrl: null,
    lastSrc: null,
  };

  getImgSrcUrl = () => {
    const tagBase64 = Buffer.from(this.props.tagNumber).toString("base64");

    const tagId = this.props.tagId ? this.props.tagId.toString() : "";

    const tagIdBase64 = Buffer.from(tagId).toString("base64");

    return API_URL + `/all_results/get_tag_preview.png?&tag_number=${tagBase64}&tag_id=${tagIdBase64}&zoom_level=${this.state.zoomLevel}`;
  };

  loadImage = (url) => {
    this.setState({isPreviewLoading: true, lastUrl: url});
    axios.post(url,
        {
          result_reference: {
            result_id: this.props.resultId,
            is_final: this.props.isFinalResult
          }
        },
        {
          responseType: "arraybuffer",
          headers: authHeader()
        }
        ).then((res) => {
      const b64 = Buffer.from(res.data, "binary").toString("base64");

      const curSrc = `data:application/octet-stream;base64,${b64}`;
      this.setState({
        lastSrc: curSrc,
        lastUrl: url,
        isPreviewLoading: false,
      });
    });
  };

  componentDidMount() {
    this.loadImage(this.getImgSrcUrl());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const curUrl = this.getImgSrcUrl();
    if (prevState.lastUrl !== curUrl) {
      this.loadImage(curUrl);
    }
  }


  render() {
    return (
        <Spin spinning={this.state.isPreviewLoading}>
          <img
              src={this.state.lastSrc}
              onClick={() => {
                if (this.state.zoomLevel < 10) {
                  this.setState({zoomLevel: this.state.zoomLevel + 1, isPreviewLoading: true});
                }
              }}
              style={{maxWidth: "20vw"}}
          />
        </Spin>
    );
  }
}

export class ExploreResults extends React.Component {
  constructor(props) {
    super(props);

    this.resetTotalCache();
    this.state = {
      search: "",
      limit: 20,
      page: 1,
      parsedObjects: [],
      dirty: false,
      runInfo: null,
    };
  }

  componentDidMount() {
    this.onRunChange = this.onRunChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.updateRunResults = this.updateRunResults.bind(this);

    this.props.fetchRuns(this.props.match.params.projectId);
    this.updateRunResults();

    this.loadRunInfo();
  }

  resetTotalCache() {
    this.total = 0;
    this.maxPage = 0;
  }

  updateTotal(page, resultsSize, limit) {
    if (page > this.maxPage) {
      this.maxPage = page;
      this.total = (page - 1) * limit + resultsSize;
    }
  }

  loadRunInfo = (callback) => {
    if (this.props.match.params.runId) {
      axios.get(
          API_URL + `/projects/${this.props.match.params.projectId}/processing_runs/${this.props.match.params.runId}`,
          {headers: authHeader()})
      .then(res => {
        this.setState({runInfo: res.data});
        if (callback) {
          callback();
        }
      }).catch(err => {
        message.error("Failed to load run info");
      });
    }
  };

  updateRunResults() {
    if (this.props.match.params.runId) {//} && this.state.search) {
      this.loadRunInfo();
      this.props.fetchRunResults(this.props.match.params.projectId, this.props.match.params.runId, this.state.search, this.state.page, this.state.limit + 1);
    }
  }

  updateResults() {
    const parsedObjects = [];
    this.props.runResultsList.forEach(result => {
      const tags = result.tags;
      const fields = result.fields;
      const tagsRegex = result.tagsRegex;
      const fieldsRegex = result.fieldsRegex;
      const isPage = result.isPage;

      const baseObj = {
        fileName: result.fileName,
        pageNumber: result.pageNumber,
      };

      if (isPage) {
        parsedObjects.push({
          ...baseObj,
          match: null,
          matchType: "Page",
          // key: 'field' + field,
          id: {
            documentId: result.id,
            tagNumber: null,
            tagId: null
          }
        });
      }

      for (let i = 0; i < tagsRegex.length; i++) {
        const tag = tags[i];
        parsedObjects.push({
          ...baseObj,
          match: {
            value: tag.text,
            regex: tagsRegex[i]
          },
          matchType: "Tag",
          key: "tag" + i,
          id: {
            documentId: result.id,
            tagNumber: tag.text,
            tagId: tag.id
          }
        });
      }

      for (let field in fieldsRegex) {
        parsedObjects.push({
          ...baseObj,
          match: {
            value: fields[field],
            regex: fieldsRegex[field]
          },
          matchType: "Field",
          key: "field" + field,
          id: {
            documentId: result.id,
            tagNumber: null,
            tagId: null
          }
        });
      }
    });
    parsedObjects.forEach((o, index) => o.key = index);

    this.updateTotal(this.state.page, parsedObjects.length, this.state.limit);
    this.setState({
      parsedObjects: parsedObjects.slice(0, this.state.limit),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.runResultsList !== this.props.runResultsList) {
      this.updateResults();
    }

    if (prevProps.match.params.runId !== this.props.match.params.runId
      || prevState.search !== this.state.search
      || prevState.page !== this.state.page
      || prevState.limit != this.state.limit || this.state.dirty) {
      // TODO: find a better solution for preventing multiple requests
      this.setState({
        dirty: false,
      }, this.updateRunResults());
    }
  }

  onRunChange(value) {
    this.resetTotalCache();
    this.setState({
      page: 1,
      search: "",
      parsedObjects: [],
    });

    this.props.push(`/project/${this.props.match.params.projectId}/explore_results/${value}`);
  }

  onPageChange(page, pageSize) {
    if (pageSize !== this.state.limit) {
      this.resetTotalCache();
    }

    this.setState({
      page: page,
      limit: pageSize,
    });
  }

  onSearch(value) {
    if (this.state.search !== value) {
      this.resetTotalCache();
    }

    this.setState({
      page: 1,
      search: value,
      parsedObjects: [],
      dirty: true
    });
  }

  changeRunVisibility = (isExposed) => {
    axios.post(
        API_URL + `/projects/${this.props.match.params.projectId}/processing_runs/${this.props.match.params.runId}/change_visibility`,
        { is_exposed: isExposed },
        {headers: authHeader()},
    ).then(result => {
      this.loadRunInfo(() => {message.success("Run visibility changed");});
    }).catch(err => {
      message.error("Failed to change run visibility");
    });
  };

  onExposeRun = () => {
    this.changeRunVisibility(true);
  };

  onHideRun = () => {
    this.changeRunVisibility(false);
  };

  render() {
    const opts = this.props.runsList.map(runInfo => (
      <Select.Option
        value={runInfo.id}
        key={runInfo.id}
      >
        <Row style={{ justifyContent: "space-between" }}>
          {runInfo.run}
          <small>{runInfo.status.processedPages}/{runInfo.status.totalPages}</small>
        </Row>
      </Select.Option>
    ));

    const columns = [
      {
        title: "Match",
        dataIndex: "match",
        render: (match, record) => {
          if (match === null) {
            return (<></>);
          }
          const [position, value] = match.regex;
          const originalValue = match.value;
          const start = position;
          const end = start + value.length;

          const before = originalValue.substring(0, start);
          const after = originalValue.substring(end);
          const popoverContent = (
              <TagPreview
                projectId={this.props.match.params.projectId}
                isFinalResult={false}
                resultId={record.id.documentId}
                tagId={record.id.tagId}
                tagNumber={record.id.tagNumber}
              />
          );

          const tagText = (
            <span>
              {before}
              <span style={{ backgroundColor: "var(--color-yellow)" }}>{value}</span>
              {after}
            </span>
          );

          if (record.matchType === "Field") return tagText;

          return (
            <>
              <Tooltip placement="right" title={popoverContent} color="white" overlayStyle={{maxWidth: "1800px", maxHeight: "2000px"}}
                destroyTooltipOnHide={false}
              >
                {tagText}
              </Tooltip>
            </>
          );
        }
      },
      {
        title: "Match type",
        dataIndex: "matchType",
      },
      {
        title: "File name",
        dataIndex: "fileName",
      },
      {
        title: "Page number",
        dataIndex: "pageNumber",
        width: "15%"
      },
      {
        title: "Link",
        dataIndex: "id",
        width: "15%",
        render: id => {
          const tagNumber = id.tagNumber ? id.tagNumber : "";
          const tagId = id.tagId ? id.tagId.toString() : "";

          const tagLink = TagUrlLinkService.generateRunTagLink(
              this.props.match.params.projectId,
              id.documentId,
              this.props.match.params.runId,
              tagNumber,
              tagId
          );

          return (
            <Link to={tagLink}>
              Open
            </Link>
          );
        }
      }
    ];

    const actionsMenu = (
      <Menu>
        {
          this.state.runInfo?.is_exposed ?
            null
          :
            <Menu.Item onClick={this.onExposeRun}>
              Expose run
            </Menu.Item>
        }
        {
          this.state.runInfo?.is_exposed ?
            <Menu.Item onClick={this.onHideRun}>
              Hide run
            </Menu.Item>
          :
            null
        }
      </Menu>
    );

    return (
      <>
        <Spin spinning={this.props.isRunResultsFetching || this.props.isRunsListFetching}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Run">
                <Select onChange={this.onRunChange} value={this.props.match.params.runId}>
                  {opts}
                </Select>
              </Form.Item>
            </Col>
            {
              this.state.runInfo ?
                <>
                  <Col span={8}>
                    <Input.Search id="search-within-run-box" enterButton onSearch={this.onSearch}/>
                  </Col>
                  <Col span={2}>
                    <Dropdown overlay={actionsMenu} trigger={["click"]}>
                      <Button>Actions</Button>
                    </Dropdown>
                  </Col>
                  <Col span={2}>
                    {
                      this.state.runInfo.is_exposed ?
                        <span style={{color: "rgb(16, 126, 125)"}}>exposed</span>
                      :
                        <span style={{color: "gray"}}>hidden</span>
                    }
                  </Col>
                </>
              :
              null
            }
          </Row>

          {
            this.props.match.params.runId ?
              <Table id="run-results-list" dataSource={this.state.parsedObjects} columns={columns} size="small"
                pagination={{ position: "bottomCenter", current: this.state.page, defaultPageSize: 20, total: this.total, onChange: this.onPageChange }} />
              :
              null
          }
        </Spin>
      </>
    );
  }
}
