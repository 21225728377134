import { connect } from "react-redux";
import { UploadPage } from "../components/UploadPage";
import { uploadFile, startUploadFiles } from "../actions/uploadFiles";
import promiseLimit from "promise-limit";

const mapStateToProps = state => {
  return {
    progress: state.uploadedFile.progress,
  };
};

const limitLoader = promiseLimit(2);

const mapDispatchToProps = dispatch => {
  return {
    startUploadFiles: (...args) => dispatch(startUploadFiles(...args)),
    uploadFile: (...args) => limitLoader(() => dispatch(uploadFile(...args)))
  };
};


const UploadDocumentsContainer = connect(mapStateToProps, mapDispatchToProps)(UploadPage);

export default UploadDocumentsContainer;
