import {DataItemFilter} from "../../../../../../Misc/DataSourceFilter";

export class CnStatusFilter extends DataItemFilter {
    constructor(values) {
        super(values.join(" or "), el => el.status().name(), "cnStatus");

        this._values = values;
    }

    _matchLogic(itemValue) {
        return this._values.find(val => itemValue === val);
    }

    getName() {
        return "Status";
    }
}