import React, {useEffect, useState} from "react";
import {SelectDrawingToCompareModal} from "../../DrawingComparison/Component/DrawingsComparison";
import {MgSummary} from "./Summary/MgSummary";
import {AnimatedConflictData, MgConflict} from "../Model/MgConflict";
import {MgConflicts} from "../Model/MgConflicts";
import axios from "axios";
import {API_URL} from "../../../../constants";
import {authHeader} from "../../../../Utilities";
import {RemoteMgConflicts} from "../Model/Remote/RemoteMgConflicts";
import {CachedMgConflicts} from "../Model/Cached/CachedMgConflicts";


export function DrawingsMerging({modalVisible, currentCnDrawing, onModalVisibilityChanged, createNewObject,
                                    onSummaryVisibilityChanged, deleteObject, onMgConflictsChanged, canvas,
                                    replaceObject, discardSelection,
                                    onFiltersChanged, selectedCanvasObject, anotherSelectedCanvasObject,
                                    selectCanvasObjectById, selectOtherObjectById, renderObjectData}) {
    const [conflictData, setConflictData] = useState([]);
    const [selectedConflictDataEl, setSelectedConflictDataEl] = useState({id: -1});

    const [summaryVisible, setSummaryVisible] = useState(false);

    const [mgConflicts, setMgConflicts] = useState(new MgConflicts());
    const [selectedMgConflict, setSelectedMgConflict] = useState(new MgConflict());

    useEffect(() => {
        setSelectedMgConflict(
            mgConflicts.values().find(el => el.id() === selectedConflictDataEl.id) || new MgConflict()
        );
    }, [mgConflicts, selectedConflictDataEl]);

    useEffect(() => {
        if (!selectedCanvasObject) return;

        const mgConflictToSelect = mgConflicts.values()
            .find(el => el.relatedToCanvasObject(selectedCanvasObject)) || new MgConflict();

        setSelectedMgConflict(mgConflictToSelect);
    }, [mgConflicts, selectedCanvasObject]);

    useEffect(() => {
        if (!anotherSelectedCanvasObject) return;

        const mgConflictToSelect = mgConflicts.values()
            .find(el => el.relatedToCanvasObject(anotherSelectedCanvasObject)) || new MgConflict();

        setSelectedMgConflict(mgConflictToSelect);
    }, [mgConflicts, anotherSelectedCanvasObject]);

    useEffect(() => {
        onMgConflictsChanged(mgConflicts);
    }, [mgConflicts]);

    useEffect(() => {
        discardSelection();
        setSelectedConflictDataEl({id: -1});

        setMgConflicts(
            new CachedMgConflicts(
                new RemoteMgConflicts(
                    conflictData.map(el => new AnimatedConflictData(el, (updated) => {
                        const elIdx = conflictData.indexOf(el);

                        conflictData[elIdx] = updated;

                        setConflictData([...conflictData]);
                    })),
                    (id) => {
                        setSelectedConflictDataEl(conflictData.find(el => el.id === id) || {id: -1});
                    },
                    createNewObject,
                    selectCanvasObjectById,
                    selectOtherObjectById,
                    canvas,
                    deleteObject,
                    renderObjectData,
                    replaceObject,
                    discardSelection
                )
            )
        );
    }, [conflictData]);

    useEffect(() => {
        onSummaryVisibilityChanged(summaryVisible);
    }, [summaryVisible]);

    return (
        <>
            <SelectDrawingToCompareModal
                visible={modalVisible}
                currentCnDrawing={currentCnDrawing}
                onCancel={e => onModalVisibilityChanged(false)}
                onCompareClick={async selectedCnDrawing => {
                    setMgConflicts(new MgConflicts());
                    setSummaryVisible(true);
                    onModalVisibilityChanged(false);

                    const requestBody = {
                        current_result_reference: currentCnDrawing.toResultReference(),
                        another_result_reference: selectedCnDrawing.toResultReference()
                    };

                    const response = await axios.post(API_URL + "/user_view/comparison_results",
                        requestBody,
                        {headers: authHeader()}
                    );

                    const rawConflictData = response.data
                        .map((el, idx) => {
                            el.id = idx;

                            if (el.status === "SAME") {
                                return {
                                    id: idx,
                                    cn_result: el,
                                    mg_result: {
                                        status: "ACCEPTED",
                                        merged: el.current
                                    }
                                };
                            }

                            if (el.status === "REMOVED") {
                                el.status = "ADDED";
                            } else if (el.status === "ADDED") {
                                el.status = "REMOVED";
                            }

                            return {
                                id: idx,
                                cn_result: el,
                                mg_result: {
                                    status: "UNRESOLVED",
                                    merged: {}
                                }
                            };
                        });

                    setConflictData(rawConflictData);
                }}
            />
            {
                summaryVisible &&
                <div style={{overflowY: "auto"}}>
                    <MgSummary mgConflicts={mgConflicts}
                               selectedMgConflict={selectedMgConflict}
                               onFiltersChanged={onFiltersChanged}
                               onClose={e => {
                                   setMgConflicts(new MgConflicts());
                                   setSummaryVisible(false);
                               }}
                    />
                </div>
            }
        </>
    );
}
