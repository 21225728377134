import { loadUIConfig } from "@digatex/digatex-ui-lib";

export const UI_CONFIG = loadUIConfig();

export const API_URL = process.env.REACT_APP_API_URL;
export const KH_VERSION = process.env.REACT_APP_KH_VERSION;

export const KEYCLOAK_AUTH_SERVER_URL = process.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL;
export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM;
export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
export const KEYCLOAK_ENABLED = process.env.REACT_APP_KEYCLOAK_ENABLED.toLowerCase() === "true";
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const roleColors = {
  user: "green",
  analyst: "orange",
  admin: "purple",
  manager: "blue",
};


export const viewerModes = {
  NORMAL: "normal",
  CREATING_NEW_OBJECT: "creating-new-object",
  CREATING_NEW_MARKER: "creating-new-marker",

  HIERARCHY_ADD_FROM_RECTANGLE: "hierarchy-add-from-rectangle",

  GRAPH_OBJECT_MODE: "graph-object-mode",
};


export const lambdaTypesMapping = {
  page_processing_step: "Page processing step",
  report_custom_post_processing: "Report custom post processing",
};

export const viewerConsts = {
  DEFAULT_OBJECT_ARGS: {
    lockScalingFlip: true,
    fill: "rgba(0,0,0,0)",
    // fill: 'orange',
    cornerColor: "lime",
    cornerStrokeColor: "gray",
    cornerStyle: "circle",
    transparentCorners: false,
    // cornerSize: 7,
    cornerSize: 10,
    noScaleCache: false,
    strokeUniform: true,
    objectCaching: false,
  },
  STROKE_WIDTH: 5,
  COLOR_BLUE: "rgba(0,0,255, 0.6)",
};


export const COLOR_PICKER_PRESET_COLORS = [
  "#00FF0099", // green
  "#FF8E0099", // orange
  "#FFFF0099", // yellow
  "#00FFFF99", // cyan
  "#0000FF99", // blue
  "#8000FF99", // violet
  "#FF00FF99", // magenta
  "#FF000099", // red
];
