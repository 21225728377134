import React from "react";
import {CnStatus} from "./CnStatus";
import {CnCanvasObject} from "./CnCanvasObject";

export class CnResult {
    id() {
        return -1;
    }

    status() {
        return new CnStatus();
    }

    details() {
        return [];
    }

    toTag() {
        return <></>;
    }

    toTagHash() {
        return "";
    }

    selectOnCanvas() {

    }

    toCnCanvasObject() {
        return new CnCanvasObject();
    }

    relatedToCanvasObject(canvasObj) {
        return false;
    }
}