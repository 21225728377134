import {useEffect} from "react";

const initBeforeUnLoad = (showExitPrompt) => {
    window.onbeforeunload = (event) => {
        if (showExitPrompt()) {
            const e = event || window.event;
            e.preventDefault();
            if (e) {
                e.returnValue = "";
            }
            return "";
        }
    };
};

export function BeforeUnloadPrompt({when}) {
    window.onload = function() {
        initBeforeUnLoad(when);
    };

    useEffect(() => {
        initBeforeUnLoad(when);

        console.log("!!! effect");

        return () => {
            window.onload = null;
            window.onbeforeunload = null;
        };
    }, [when]);

    return null;
}