import React, {useState} from "react";
import HierarchiesService from "../../../services/HierarchiesService";
import {Modal, Spin, Table, Tooltip} from "antd";
import {TagPreview} from "../../ExploreResults";

export function NodeSubtreeTitle({hierarchy, node, nodeColor, onClick}) {
    const [switchDrawingModalVisible, setSwitchDrawingModalVisible] = useState(false);
    const [nodeRefsDescription, setNodeRefsDescription] = useState([]);
    const [showSpin, setShowSpin] = useState(false);

    const loadNodeRefsExtraDescription = () => {
        return HierarchiesService.fetchNodeReferencesExtraData(hierarchy, node)
            .then(response => {
                const refIds = node.references.filter(ref => !ref.isOnPageReference()).map(ref => ref.id);

                setNodeRefsDescription(response.data.filter(desc => refIds.includes(desc.id)).map(desc => {
                    const reference = node.references.find(ref => ref.id === desc.id);

                    return {
                        ...desc,
                        reference: reference
                    };
                }));
            });
    };

    const handleDoubleClick = (e) => {
        if (node.isVirtual() || node.hasOnPageReference()) return;

        setShowSpin(true);

        if (node.references.length === 1) {
            node.references[0].open();
        } else if (node.references.length > 1) {
            loadNodeRefsExtraDescription().then(_ => {
                setShowSpin(false);
                setSwitchDrawingModalVisible(true);
            });
        }
    };

    return (
        <>
            <SelectReferenceToGoModal
                key={node.id}
                dataSource={nodeRefsDescription}
                visible={switchDrawingModalVisible}
                onVisibleChange={val => setSwitchDrawingModalVisible(val)}
                hierarchy={hierarchy}
                node={node}
            />
            <div
                onClick={onClick} onDoubleClick={handleDoubleClick}
            >
                <span style={{backgroundColor: nodeColor}} className="medium-circle" />
                {node.text}
                {showSpin && <Spin id="double-click-processing-spin" spinning={true}/>}
            </div>
        </>
    );
}

function SelectReferenceToGoModal({dataSource, visible, onVisibleChange}) {
    const columns = [
        {
            title: "Tag",
            dataIndex: ["tag_reference"],
            render: tagReference => {
                const tooltipContent = (
                    <TagPreview
                        resultId={tagReference.result_reference.result_id}
                        isFinalResult={true}
                        tagNumber={tagReference.tag_number}
                        tagId={tagReference.tag_id}
                    />
                );

                return (
                    <Tooltip placement="topRight" title={tooltipContent} color="white"
                             overlayStyle={{maxWidth: "1800px", maxHeight: "2000px"}}
                             destroyTooltipOnHide={true}
                    >
                        <a>
                            <span style={{backgroundColor: "#3968d0"}} className="medium-circle"/>
                            {tagReference.tag_number}
                        </a>
                    </Tooltip>
                );
            }
        },
        {
            title: "File name",
            dataIndex: ["file_name"]
        },
        {
            title: "Page number",
            dataIndex: ["page_number"],
            render: pageNumber => pageNumber + 1
        },
        {
            title: "Link",
            dataIndex: "id",
            width: "15%",
            render: (id, record) => (
                <a onClick={e => {
                    record.reference.open();
                    onVisibleChange(false);
                }}>Open</a>
            )
        }
    ];

    return (
        <Modal footer={null} title="References" visible={visible}
               id="references-modal"
               onCancel={e => onVisibleChange(false)}
               onOk={e => onVisibleChange(false)}>
            <Table size="small" columns={columns} dataSource={dataSource} pagination={false}/>
        </Modal>
    );
}