import React from "react";
import {Table, Input, Button, Space, Tooltip} from "antd";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import {TagPreview} from "./ExploreResults";


export class ExploreTags extends React.Component {
  defaultPageSize = 20;

  constructor(props) {
    super(props);

    this.state = {
      search: {},
      total: 0,
    };

    this.pagination = {
      current: 1,
      pageSize: this.defaultPageSize,
    };

    this.maxPage = 1;
    this.pageSize = this.defaultPageSize;
    this.nextSearch = {};
  }

  componentDidMount() {
    this.props.loadTags(this.pagination.current, this.pagination.pageSize, {}, []);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      if (this.pageSize !== this.pagination.pageSize || this.pagination.current >= this.maxPage) {
        this.maxPage = this.pagination.current;
        this.pageSize = this.pagination.pageSize;
        const newTotal = Math.max(
          this.state.total,
          (this.pagination.current - 1) * this.pagination.pageSize + (this.props.data?.length ?? 0) + (this.props.hasMore ? 1 : 0)
        );
        this.setState({
          total: newTotal,
        });
      }

      this.setState({
        search: this.nextSearch,
      });
    }
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button onClick={() => this.handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? "var(--color-turquoise)" : undefined }} />,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text => this.renderSearchHighlighter(text, dataIndex),
  });

  renderSearchHighlighter = (text, dataIndex) => {
    return (
        (dataIndex in this.state.search) ? (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[this.state.search[dataIndex]]}
                autoEscape
                textToHighlight={text?.toString() ?? ""}
            />
        ) : (
            text
        )
    );
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    const search = { ...this.state.search };
    search[dataIndex] = selectedKeys[0];
    this.nextSearch = search;

    this.maxPage = 1;
    this.setState({
      total: 0,
    });
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();

    const search = { ...this.state.search };
    delete search[dataIndex];
    this.nextSearch = search;

    this.maxPage = 1;
    this.setState({
      total: 0,
    });
  };

  tableChange = (pagination, filters, sorter, extra) => {
    this.pagination = pagination;

    const search = {};
    Object.entries(filters).map(entry => {
      const [key, value] = entry;
      if (value != null) {
        search[key] = value;
      }
    });

    if (!Array.isArray(sorter)) {
      if (Object.keys(sorter).length === 0) {
        sorter = [];
      }
      else {
        sorter = [sorter];
      }
    }

    const sort = sorter.map(entry => {
      const { field, order } = entry;
      return [field, order];
    }).filter(entry => entry[1] != null);

    this.props.loadTags(pagination.current, pagination.pageSize, search, sort);
  };

  render() {
    const columns = [
      {
        title: "Tag number",
        dataIndex: "tag_number",
        width: "30%",
        sorter: {
          multiple: 1,
        },
        ...this.getColumnSearchProps("tag_number"),
        render: (text, record) => {
          const popoverContent = (
              <TagPreview
                  projectId={this.props.projectId}
                  resultId={record.file_id}
                  isFinalResult={false}
                  tagNumber={text}
                  tagId={record.tag_id}
              />
          );

          return (
              <>
                <Tooltip placement="right" title={popoverContent} color="white" overlayStyle={{maxWidth: "1800px", maxHeight: "2000px"}}
                         destroyTooltipOnHide={false}
                >
                  {this.renderSearchHighlighter(text, "tag_number")}
                </Tooltip>
              </>
          );
        }
      },
      {
        title: "Tag type",
        dataIndex: "tag_type",
        width: "20%",
        sorter: {
          multiple: 1,
        },
        ...this.getColumnSearchProps("tag_type"),
      },
      {
        title: "Tag description",
        dataIndex: "tag_description",
        sorter: {
          multiple: 1,
        },
        ...this.getColumnSearchProps("tag_description"),
      },
      {
        title: "File name",
        dataIndex: "file_name",
        sorter: {
          multiple: 1,
        },
        ...this.getColumnSearchProps("file_name"),
      },
      {
        title: "Page number",
        dataIndex: "page_number",
        sorter: {
          multiple: 1,
        },
        ...this.getColumnSearchProps("page_number"),
      },
      {
        title: "Link",
        dataIndex: "id",
        render: (text, record, index) => {
          const link = this.props.createTagLink(record.run_id, record.file_id, record.tag_number, record.tag_id);
          return (
            <Link to={link}>Open</Link>
          );
        }
      }
    ];

    const results = this.props.data ?? [];
    const data = results.map((r, index) => {
      return {
        tag_number: r.tag.text,
        tag_id: r.tag.id,
        tag_type: r.tag.label,
        tag_description: r.tag.metadata.description,
        file_name: r.file_name,
        page_number: r.page_number,
        file_id: r.file_id,
        run_id: r.run_id,
        key: index,
      };
    });

    return <Table
      pagination={{ defaultPageSize: this.defaultPageSize, total: this.state.total, showSizeChanger: true }}
      columns={columns}
      dataSource={data}
      loading={this.props.loading}
      onChange={this.tableChange}
    />;
  }
}