export interface Plugin<T> {
    name(): string,

    build(target: T): Promise<void>,

    finish(target: T): Promise<void>,

    cleanup(target: T): Promise<void>,
}

export interface PluginTarget<T> {
    add(plugin: Plugin<T>): Promise<void>;
}

export interface PluginSource<T> {
    addTo(target: PluginTarget<T>): Promise<void>;
}

export class InstalledPluginsFor<T> implements PluginTarget<T> {
    private readonly target: T;
    private readonly plugins: Array<Plugin<T>> = [];

    constructor(target: T) {
        this.target = target;
    }

    async add(plugin: Plugin<T>): Promise<void> {
        this.plugins.push(plugin);
        await plugin.build(this.target);
    }

    async finish(): Promise<void> {
        await Promise.all(this.plugins.map(x => x.finish(this.target)));
        await Promise.all(this.plugins.map(x => x.cleanup(this.target)));
    }
}

export abstract class PluginBase<T> implements Plugin<T>, PluginSource<T> {
    private readonly _name: string = "";

    protected constructor(name: string) {
        this._name = name;
    }

    build(target: T): Promise<void> {
        return Promise.resolve(undefined);
    }

    cleanup(target: T): Promise<void> {
        return Promise.resolve(undefined);
    }

    finish(target: T): Promise<void> {
        return Promise.resolve(undefined);
    }

    name(): string {
        return this._name;
    }

    addTo(target: PluginTarget<T>): Promise<void> {
        return target.add(this);
    }
}


export class PluginSourceEmpty<T> implements PluginSource<T> {
    async addTo(_: PluginTarget<T>): Promise<void> {

    }
}

export class PluginSourceParallel<T> implements PluginSource<T> {
    private readonly sources: Array<PluginSource<T>>;

    constructor(sources: Array<PluginSource<T>> = []) {
        this.sources = sources;
    }

    async addTo(t: PluginTarget<T>): Promise<void> {
        await Promise.all(this.sources.map(src => src.addTo(t)));
    }
}

export class PluginSourceSequence<T> implements PluginSource<T> {
    private readonly sources: Array<PluginSource<T>>;

    constructor(sources: Array<PluginSource<T>> = []) {
        this.sources = sources;
    }

    async addTo(t: PluginTarget<T>): Promise<void> {
        for (let source of this.sources) {
            await source.addTo(t);
        }
    }
}

export class PluginAnonymous<T> extends PluginBase<T> {
    private readonly delegate: Partial<Plugin<T>>;

    constructor(name: string, delegate: Partial<Plugin<T>>) {
        super(name);
        this.delegate = delegate;
    }

    build(target: T): Promise<void> {
        return this.delegate.build?.(target) ?? Promise.resolve();
    }

    finish(target: T): Promise<void> {
        return this.delegate.finish?.(target) ?? Promise.resolve();
    }

    cleanup(target: T): Promise<void> {
        return this.delegate.cleanup?.(target) ?? Promise.resolve();
    }
}
