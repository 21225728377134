import React, {useEffect, useState} from "react";
import UserViewService from "../../../services/UserViewService";
import {AutoComplete, Divider, Form, message, Modal, Spin} from "antd";
import {RemoteFiltersEditor} from "../SearchFilters/RemoteFiltersEditor";
import {RequestBodyFromFilters} from "../SearchFilters/RequestBodyFromFilters";
import axios from "axios";
import {API_URL} from "../../../constants";
import {authHeader, empty} from "../../../Utilities";
import {EndlessScrollRemoteAutocomplete} from "../../Misc/EndlessScrollRemoteAutocomplete";

export function BulkUpdateAttributesModal({fetchOptionsUrl, visible, onVisibilityChange}) {
    const [form] = Form.useForm();
    const [filterSchemas, setFilterSchemas] = useState([]);
    const [filters, setFilters] = useState([]);
    const [spinning, setSpinning] = useState(false);

    useEffect(() => {
        UserViewService.fetchSearchFiltersSchemas(setFilterSchemas);
    }, []);

    const closeModal = () => {
        onVisibilityChange(false);
        setFilters([]);
        form.resetFields();
    };

    const submit = () => {
        setSpinning(true);
        form.validateFields().then(_ => {
            const dataToSubmit = {
                ...form.getFieldsValue(),
                filters: new RequestBodyFromFilters(filters).getBody()
            };
            axios.post(
                API_URL + "/user_view/bulk_update_attributes",
                dataToSubmit,
                {headers: authHeader()}
            ).then(({data}) => {
                const taskId = data.task_id;
                const getProgress = () => {
                    axios.get(
                        `${API_URL}/user_view/bulk_update_attributes/progress/${taskId}`,
                        {headers: authHeader()}
                    ).then(response => {
                        const progress = response.data;
                        if (progress == null || (!progress.error && progress.completed !== true)) {
                            setTimeout(() => getProgress(), 2000);
                        } else {
                            if (progress?.error) {
                                message.error(progress.error);
                            } else {
                                message.success("Updated");
                                closeModal();
                            }
                            setSpinning(false);
                        }
                    });
                };
                getProgress();
            }).catch(_ => {
                message.error("Error");
                setSpinning(false);
            });
        }).catch(empty);
    };

    const rules = [{required: true, message: "Value can not be empty"}];

    return (
        <Modal
            visible={visible}
            onCancel={closeModal}
            onOk={submit}
            okText={"Run Update"}
            okButtonProps={{
                id: "run-bulk-update"
            }}
            closable={false}
            destroyOnClose
            modalRender={node => (
                <Spin spinning={spinning}>
                    {node}
                </Spin>
            )}
        >
            <RemoteFiltersEditor
                fetchOptionsUrl={fetchOptionsUrl}
                filterSchemas={filterSchemas}
                filters={filters}
                onFiltersChanged={x => setFilters([...x])}
            />
            <Divider/>
            <Form form={form} initialValues={{attr_name: "", attr_value: ""}}>
                <Form.Item label={"Attribute Name"} name={"attr_name"} required rules={rules}>
                    <AttributeNameInput id="attr-name-input"/>
                </Form.Item>
                <Form.Item dependencies={["attr_name"]}>
                    {() => (
                        <Form.Item label={"Attribute Value"} name={"attr_value"} required rules={rules}>
                            <AttributeValueInput id="attr-value-input" form={form} fetchOptionsUrl={fetchOptionsUrl}/>
                        </Form.Item>
                    )}
                </Form.Item>
            </Form>
        </Modal>
    );
}


function AttributeNameInput({id, value, onChange}) {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        axios.get(
            API_URL + "/user_view/search_filters_configs",
            {headers: authHeader()}
        ).then(({data}) => {
            const searchFilters = data.flatMap(x => x.search_filters).filter(x => x.target === "object_attr");
            setOptions(searchFilters.map(x => ({
                value: x.key, label: x.key
            })));
        });
    }, []);

    return (
        <AutoComplete
            id={id}
            size={"small"}
            value={value}
            onChange={onChange}
            options={options}
            filterOption={(inputValue, option) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
        />
    );
}

function AttributeValueInput({id, onChange, form, fetchOptionsUrl}) {
    const attrName = form.getFieldValue("attr_name");
    return (
        <EndlessScrollRemoteAutocomplete
            id={id}
            key={attrName}
            fetchOptions={async (value, maxResults) => {
                const params = {
                    option_key: attrName,
                    option_value_part: value,
                    max_results: maxResults,
                    option_target: "object_attr"
                };
                return axios.get(
                    fetchOptionsUrl,
                    {
                        params,
                        timeout: 30 * 1000,
                        headers: authHeader()
                    }
                );
            }}
            onSelect={value => onChange(value || "")}
            onChange={value => onChange(value || "")}
        />
    );
}
