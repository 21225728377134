import axios from "axios";
import {message} from "antd";
import {API_URL} from "../constants";
import {authHeader, download} from "../Utilities";

class HierarchiesService {
  fetchHierarchies(workspaceId) {
    return axios.get(API_URL + `/hierarchies?workspace_id=${workspaceId}`, {headers: authHeader()});
  }

  addHierarchy(hierarchy) {
    return axios.post(API_URL + "/hierarchies", hierarchy, {headers: authHeader()});
  }

  fetchHierarchyById(hierarchyId) {
    return axios.get(API_URL + `/hierarchies/${hierarchyId}`, {headers: authHeader()});
  }

  deleteHierarchy(hierarchyId) {
    return axios.delete(API_URL + `/hierarchies/${hierarchyId}`, {headers: authHeader()});
  }

  addHierarchyNode(hierarchy, node) {
    if (!node.isValid()) return null;
    return axios.post(API_URL + `/hierarchies/${hierarchy.id}/nodes`, node.getDict(), {headers: authHeader()});
  }

  updateHierarchyNode(hierarchy, node) {
    if (!node.isValid()) return Promise.reject();
    return axios.put(API_URL + `/hierarchies/${hierarchy.id}/nodes/${node.id}`, node.getDict(), {headers: authHeader()});
  }

  deleteHierarchyNode(hierarchy, node) {
    return axios.delete(API_URL + `/hierarchies/${hierarchy.id}/nodes/${node.id}`, {headers: authHeader()});
  }

  exportHierarchyToExcel(hierarchy) {
    axios.post(`${API_URL}/hierarchies/${hierarchy.id}/export_to_xlsx`,
        {},{
          headers: authHeader(),
          responseType: "blob"
        }).then((response) => {
      const blob = new window.Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      download(blob, "hierarchy_exported.xlsx");
    }).catch(() => {
      message.error("Failed to export hierarchy");
    });
  }

  getHierarchyNodeReferenceTagReference(hierarchy, node, reference) {
    return axios.get(`${API_URL}/hierarchies/${hierarchy.id}/nodes/${node.id}/references/${reference.id}/tag_reference`,
        {headers: authHeader()});
  }

  searchInHierarchy(hierarchy, query, page, limit) {
    return axios.get(`${API_URL}/hierarchies/${hierarchy.id}/nodes?query=${query}&page=${page}&limit=${limit}`,
        {headers: authHeader()});
  }

  fetchPartialHierarchyById(hierarchyId, tagsList) {
    return axios.post(
        API_URL + `/hierarchies/${hierarchyId}/partial_loading/load_nodes`,
        {tags_list: tagsList},{headers: authHeader()});
  }

  fetchNodeReferencesExtraData(hierarchy, node) {
    return axios.get(API_URL + `/hierarchies/${hierarchy.id}/nodes/${node.id}/references_extra_data`, {headers: authHeader()});
  }
}

export default new HierarchiesService();
