import React from "react";

export class CnStatus {
    name() {
        return "unknown";
    }

    toTag() {
        return <></>;
    }
}