import React from "react";
import {Table} from "antd";
import Highlighter from "react-highlight-words";
import WildcardService from "../../services/WildcardService";
import {paginationConfigForImageViewerTables, PostprocessedHighlightWords} from "../../Utilities";

export class TagsTableView extends React.Component {
  render() {
    let wildcardPattern;

    if (this.props.searchQuery && this.props.highlightAsWildcards) {
      wildcardPattern = WildcardService.getPattern(this.props.searchQuery);
    }

    const postprocessedSearchWords = new PostprocessedHighlightWords(this.props.searchQuery);

    const columns = [
      {
        title: "Tag number",
        dataIndex: "tag_number",
        sorter: {
          compare: (a, b) => a.tag_number.localeCompare(b.tag_number),
          multiple: 1,
        },
        showSorterTooltip: false,
        onCell: (record, rowIndex) => {
          return {
            onClick: event => {
              this.props.clickOnTag(record.originalTag);
            }
          };
        },
        render: (text) => {
          const textToHighlight = text?.toString() ?? "";
          let searchWords;

          if (wildcardPattern != null) {
            searchWords = wildcardPattern.matchAll(textToHighlight);
          } else {
            searchWords = postprocessedSearchWords.values(textToHighlight);
          }

          return this.props.searchQuery ?
              (
                <a>
                  <Highlighter
                      highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
                      searchWords={searchWords}
                      autoEscape
                      textToHighlight={textToHighlight}
                  />
                </a>
              )
              : (<a>{text}</a>);
        }
      },
      {
        title: "Class",
        dataIndex: "tag_type",
        sorter: {
          compare: (a, b) => a.tag_type.localeCompare(b.tag_type),
          multiple: 1,
        },
        showSorterTooltip: false,
      },
      {
        title: "Description",
        dataIndex: "tag_description",
        sorter: {
          compare: (a, b) => a.tag_description.localeCompare(b.tag_description),
          multiple: 1,
        },
        showSorterTooltip: false,
      }
    ];

    const tags = this.props.tags ?? [];
    const data = tags.map((tag, index) => {
      return {
        id: tag.id,
        tag_number: tag.text,
        tag_type: tag.label,
        tag_description: tag.metadata.description,
        key: index,
        originalTag: tag,
      };
    });

    return <Table
        id="tags-table-view"
        columns={columns}
        dataSource={data}
        size="small"
        loading={this.props.isLoading}
        pagination={paginationConfigForImageViewerTables(data)}
    />;
  }
}