import {Button, Divider, Tag, Tooltip} from "antd";
import React, {ReactNode} from "react";
import {Subject} from "rxjs";
import {emptyAlias} from "../../Misc/DataSourceFilter";
import {changed$, Dep, DepSource} from "../../../services/useDep";

export class Filters<T extends Filter> implements DepSource {
    public readonly [changed$]: Subject<void> = new Subject<void>();

    private list: T[] = [];

    asList(): readonly T[] {
        return [...this.list];
    }

    count() {
        return this.list.length;
    }

    clear(): void {
        this.list = [];
        this.notifyChanged();
    }

    remove(f: T): void {
        this.list = this.list.filter(x => x !== f);
        this.notifyChanged();
    }

    add(f: T): void {
        const list = new FiltersList<T>(this.list);
        f.addTo(list);
        this.list = list.unwrap();
        this.notifyChanged();
    }

    addMany(arr: T[]): void {
        const list = new FiltersList<T>(this.list);
        arr.forEach(f => {
            f.addTo(list);
        });
        this.list = list.unwrap();
        this.notifyChanged();
    }

    replace(fs: T[]): void {
        this.list = [];
        this.addMany(fs);
    }

    refresh(): void {
        this.list = [...this.list];
        this.notifyChanged();
    }

    private notifyChanged() {
        this[changed$].next();
    }
}

export class FiltersList<T extends Filter> {
    private list: T[];

    constructor(initial: T[]) {
        this.list = initial;
    }

    replaceByKeyOrInsert(f: T) {
        const findIndex = this.list.findIndex((el: any) => el.key === f.key);
        if (findIndex > -1) {
            this.list[findIndex] = f;
        } else {
            this.list.push(f);
        }
    }

    replaceAll(arr: T[]): void {
        this.list = [...arr];
    }

    unwrap(): T[] {
        return this.list;
    }
}

export interface Filter {
    readonly key: string;

    addTo(filters: FiltersList<Filter>): void;

    print<T extends FilterMedia>(media: T): T;
}

export interface FilterMedia {
    setIcon(icon: ReactNode): this;

    setColor(color: string): this;

    setDisplayName(name: string): this;

    setDisplayValue(value: string): this;
}

class FilterMediaTag implements FilterMedia {
    private icon?: ReactNode = undefined;
    private color: string = "blue";
    private name: string = emptyAlias;
    private value: string = emptyAlias;

    setIcon(icon: ReactNode): this {
        this.icon = icon;
        return this;
    }

    setColor(color: string): this {
        this.color = color;
        return this;
    }

    setDisplayName(name: string): this {
        this.name = name;
        return this;
    }

    setDisplayValue(value: string): this {
        this.value = value;
        return this;
    }

    toTag(idx: number, key: string, onClose: () => void) {
        let isTrimmed = false;

        function detectTrim() {
            isTrimmed = true;
        }

        function trim(text: string, trimCallback: () => void) {
            const maxLen = 15;
            if (text.length > maxLen) {
                text = `${text.slice(0, maxLen)}...`;

                if (trimCallback) {
                    trimCallback();
                }
            }

            return text;
        }

        const attrText = trim(this.name, detectTrim) || emptyAlias;
        const valText = trim(this.value, detectTrim) || emptyAlias;
        const color = this.color;

        const tagElement = (
            <Tag
                id={`filter-tag_${idx}`}
                key={key}
                color={color}
                onClose={onClose}
                closable
                icon={this.icon}
            >
                <b>
                    <span style={{color: "black"}}>
                        {attrText}:&nbsp;
                    </span>
                </b>
                <span style={{color: "black"}}>
                    {valText}
                </span>
            </Tag>
        );

        return (
            isTrimmed ? (
                <Tooltip title={`${this.name}: ${this.value}`} key={key}>
                    {tagElement}
                </Tooltip>
            ) : (tagElement)
        );
    }
}

type FiltersTagListProps<T extends Filter> = {
    readonly filters: Dep<Filters<T>>;
}

export function FiltersTagList<T extends Filter>({filters}: FiltersTagListProps<T>) {
    const removeFilter = (f: T) => {
        filters.remove(f);
    };

    const tags = filters.asList().map((x, idx) => {
        const media = x.print(new FilterMediaTag());
        return media.toTag(idx, x.key, () => removeFilter(x));
    });

    const clearFiltersButton = (
        <>
            <Divider type={"vertical"}/>
            <Button
                id="clear-all-filters-button"
                size="small"
                onClick={() => {
                    filters.clear();
                }}
            >
                Clear filters
            </Button>
        </>
    );

    return (
        <>
            {tags}
            {filters.count() > 0 && clearFiltersButton}
        </>
    );
}
