export class Attributes {
    keys() {
        return [];
    }

    value(key) {
        throw new Error("Key does not exist");

        return "";
    }
}