import React, {useEffect, useState} from "react";
import HierarchiesService from "../../../services/HierarchiesService";
import {Button, Dropdown, Menu, message, Popconfirm, Row, Tooltip} from "antd";
import {TagPreview} from "../../ExploreResults";
import {useHistory} from "react-router";
import TagUrlLinkService from "../../../services/TagUrlLinkService";
import { EllipsisOutlined } from "@ant-design/icons";


function ReferencePreviewComponent({reference}) {
  const [tagReference, setTagReference] = useState(null);

  useEffect(() => {
    HierarchiesService.getHierarchyNodeReferenceTagReference(reference.node.hierarchy, reference.node, reference).then(res => {
      if (!res.data.tag_number) {
        message.warning("Invalid tag reference");
      } else {
        setTagReference(res.data);
      }
    }).catch(() => {
      message.error("Failed to get tag reference");
    });
  }, [reference]);

  return (
      tagReference && tagReference.tag_number && <TagPreview
          resultId={tagReference.result_reference.result_id}
          isFinalResult={true}
          tagNumber={tagReference.tag_number}
          tagId={tagReference.tag_id}
      />
  );
}


export default function ObjectReferenceComponent({reference, node, hierarchyView}) {
  const [isHovered, setIsHovered] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const isOnPageReference = reference.page_id === hierarchyView.props.imageViewer.state.pageId;
  const referenceColor = isOnPageReference ? "#57ce46" : "#3968d0";

  const menu = (
      <Menu>
        {!isOnPageReference && <Menu.Item>
          <a hrem="#" onClick={() => {
              reference.open();
          }}>
            Go to object
          </a>
        </Menu.Item>}
        <Menu.Item>
          <Popconfirm title="Are you sure to remove the reference？"
                      placement="right"
                      onConfirm={() => {
                        const clonedNode = node.clone();
                        clonedNode.references = node.references.filter(ref => ref !== reference);
                        HierarchiesService.updateHierarchyNode(hierarchyView.state.hierarchy, clonedNode).then(() => {
                          message.success("Reference removed");
                          hierarchyView.loadHierarchy();
                        }).catch(() => {
                          message.error("Failed to remove reference");
                        });
                        setIsDropdownVisible(false);
                      }}
                      onCancel={() => {
                        setIsDropdownVisible(false);
                      }}
          >
            <a href="#">
              Remove reference
            </a>
          </Popconfirm>
        </Menu.Item>
      </Menu>
  );

  const getTagPreview = () => {
    return (<ReferencePreviewComponent reference={reference}/>);
  };

  const popoverContent = !isOnPageReference && getTagPreview();
  return (
      <React.Fragment>
        <Row
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{minHeight: "24px"}}
        >
          <Tooltip placement="topRight" title={popoverContent} color="white" overlayStyle={{maxWidth: "1800px", maxHeight: "2000px"}}
                   destroyTooltipOnHide={true}
          >
            <a onClick={() => {
              const referencedObject = reference.findReferencedObject();
              if (referencedObject) {
                const imageViewer = hierarchyView.props.imageViewer;
                imageViewer.zoomHandler.zoomToObject(referencedObject.canvasObject);
              }
            }}
            ><span style={{backgroundColor: referenceColor}} className="medium-circle" /> {node.text}</a>
          </Tooltip>

          <div style={{marginLeft: "auto", visibility: (isHovered || isDropdownVisible) ? "initial" : "hidden"}}>
            <Dropdown overlay={menu}
                      trigger="click">
              <Button size="small">
                <EllipsisOutlined style={{fontSize: "16px"}} />
              </Button>
            </Dropdown>
          </div>
        </Row>
      </React.Fragment>
  );
}
