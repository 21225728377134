import React, {useRef} from "react";
import {AutoSizer} from "react-virtualized";

export function FitParentSize({children}) {
    const divRef = useRef();
    return (
        <div style={{height: "100%", width: "100%"}}>
            <AutoSizer children={size => {
                if (divRef.current == null) return;
                divRef.current.style.width = `${size.width}px`;
                divRef.current.style.height = `${size.height}px`;
            }}/>
            <div style={{overflow: "visible", height: "0px", width: "0px"}}>
                <div ref={divRef}>
                    <div style={{
                        overflow: "auto",
                        maxHeight: "100%",
                        maxWidth: "100%",
                        height: "100%",
                        width: "100%"
                    }}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}
