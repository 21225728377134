export class Scalar<T> {
    protected _value: T;

    constructor(value: T) {
        this._value = value;
    }

    value() {
        return this._value;
    }
}

export class ScalarMut<T> extends Scalar<T> {
    setValue(value: T) {
        this._value = value;
    }
}
