import React, {Dispatch, SetStateAction, useState} from "react";
import {Button, Form, Input, message} from "antd";
import {InsertRowRightOutlined} from "@ant-design/icons";
import {Dep} from "../../../../../services/useDep";
import {DocumentRegistryColumns} from "../../../../../services/DocumentRegistry/DocumentRegistry";
import {FormModal} from "../../../../../services/FormModal";

type NewColumnProps = {
    registryColumns: Dep<DocumentRegistryColumns>
}

export function NewColumn({registryColumns}: NewColumnProps) {
    const [modalOpen, setModalOpen] = useState(false);
    return (
        <>
            <NewColumnModal open={modalOpen} setModalOpen={setModalOpen} registryColumns={registryColumns}/>
            <Button
                icon={<InsertRowRightOutlined/>}
                type={"link"}
                onClick={() => setModalOpen(true)}
            >
                New column
            </Button>
        </>
    );
}

type NewColumnModalProps = {
    registryColumns: Dep<DocumentRegistryColumns>
    open: boolean,
    setModalOpen: Dispatch<SetStateAction<boolean>>
}

function NewColumnModal({open, setModalOpen, registryColumns}: NewColumnModalProps) {
    const submit = (values: any) => {
        return registryColumns.add(values.name).catch(() => {
            message.error("Failed to create column");
        });
    };

    return (
        <FormModal
            formId={"new-column-form-modal"}
            open={open}
            setModalOpen={setModalOpen}
            onSubmit={submit}
            modalProps={{
                title: "New Column",
                destroyOnClose: true,
                okText: "Create",
            }}
        >
            <Form.Item label={"Name"} name={"name"}>
                <Input/>
            </Form.Item>
        </FormModal>
    );
}
