import JsonForm, {getDefaultRegistry} from "@rjsf/core";
import jsonFormValidator from "@rjsf/validator-ajv8";
import React from "react";
import "./ScopeFiltersForm.less";
import {Form, Select} from "antd";

export type OptionsSource = {
    sets(): Option[],
    columns(): Option[]
}

export type Option = {
    label: React.ReactNode;
    value?: string | number | null;
}

type ValueSelectProps = {
    props: any,
    options: Option[]
};

function ValueSelect({props, options}: ValueSelectProps) {
    return (
        <Form.Item label={props.schema.title ?? props.name} labelCol={{span: 24}}>
            <Select
                options={options}
                value={props.formData}
                onChange={v => props.onChange(v)}
            />
        </Form.Item>
    );
}

type CustomSchemaFieldProps = {
    props: any,
    optionsSource: OptionsSource
}

const CustomSchemaField = function ({props, optionsSource}: CustomSchemaFieldProps) {
    const defaultRet = () => {
        const SchemaField = getDefaultRegistry().fields.SchemaField;
        return (
            <SchemaField {...props}/>
        );
    };

    if (props.schema.hasOwnProperty("#select-type")) {
        if (props.schema["#select-type"] === "column") {
            return (
                <ValueSelect props={props} options={optionsSource.columns()}/>
            );
        } else if (props.schema["#select-type"] === "set_id") {
            return (
                <ValueSelect props={props} options={optionsSource.sets()}/>
            );
        } else {
            return defaultRet();
        }
    } else {
        return defaultRet();
    }
};

type ScopeFiltersFormProps = {
    id: string,
    schema: any,
    value?: any,
    onChange?: (value: any) => void,
    optionsSource: OptionsSource
};

export function ScopeFiltersForm({id, value, onChange, schema, optionsSource}: ScopeFiltersFormProps) {
    return (
        <JsonForm
            id={id}
            className={"scope-filters-form"}
            fields={{
                SchemaField: props => CustomSchemaField({optionsSource, props})
            }}
            schema={schema}
            validator={jsonFormValidator}
            formData={value}
            onChange={v => {
                onChange?.(v.formData);
            }}
            uiSchema={{
                "ui:submitButtonOptions": { norender: true },
            }}
        />
    );
}
