import {DocumentRegistry} from "../../../services/DocumentRegistry/DocumentRegistry";
import {Scalar} from "../../../services/Scalar";
import {ReadonlyResource} from "../../../services/Resources";
import {useResource} from "../../../services/useResource";

export class CurrentRegistry extends Scalar<DocumentRegistry> {
}

export function useCurrentRegistry(currentRegistry: ReadonlyResource<CurrentRegistry>) {
    const registry = useResource(currentRegistry);
    return registry.value();
}
