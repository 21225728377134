import {fabric} from "fabric";
import {openInNewTab} from "../../Utilities";
import {tap} from "rxjs/operators";

export class CaptionDrawingHandler {
  constructor(imageViewer) {
    this.imageViewer = imageViewer;
    this.captionObject = null;
    this.captionBackground = null;

    this.externalLink = null;
  }

  updateExternalLinkFor(obj) {
    const canvas = this.imageViewer.canvas;
    let linksToShow = obj.objectMetadata.metadata.links;
    if (linksToShow && linksToShow.length > 0) {
      const updFunc = () => {
        this.externalLink.set({
          left: obj.left + obj.width * obj.scaleX + 10,
          top: obj.top + obj.height * obj.scaleY - this.externalLink.height * this.externalLink.scaleY,
          selectable: false,
          visible: true,
        });
        this.externalLink.setCoords();
        this.externalLink.off("mouseup");
        this.externalLink.on("mouseup", e => {
          openInNewTab(linksToShow[0].target);
        });
        canvas.add(this.externalLink);
        this.imageViewer.renderAll$.next(1);
        this.imageViewer.visualChanged$.next(1);
      };

      if (this.externalLink) updFunc();
      else {
        fabric.loadSVGFromURL(process.env.PUBLIC_URL + "/images/external-link-icon.svg", (objects, options) => {
          this.externalLink = fabric.util.groupSVGElements(objects, options);
          updFunc();
        });
      }
    }
  }

  clearCaption = () => {
    const canvas = this.imageViewer.canvas;
    if (this.captionObject) {
      canvas.remove(this.captionObject);
      canvas.remove(this.captionBackground);
      if (this.externalLink) {
        this.externalLink.set({visible: false});
      }
      this.captionObject = null;
      this.captionBackground = null;
    }
  };

  refreshCaption = () => {
    this.createCaptionFor(this.imageViewer.canvas.getActiveObject());
  };

  createCaptionFor = (obj) => {
    const canvas = this.imageViewer.canvas;
    this.clearCaption();
    const selectedObject = obj;
    if (!selectedObject) {
      return;
    }
    const textToShow = selectedObject.objectMetadata.text;
    if (textToShow.trim() === "") {
      return;
    }
    const fontSize = 50;
    const paddingWidth = 10;

    const textColor = "rgba(227, 181, 5, 1)";
    const backgroundColor = "rgba(0,0,0,0.7)";

    this.captionObject = new fabric.Text(textToShow, {
      left: selectedObject.left,
      top: selectedObject.top - fontSize - 10,
      fontSize: fontSize,
      selectable: false,
      evented: false,
      fill: textColor,
    });

    this.captionBackground = new fabric.Rect({
      left: this.captionObject.left - paddingWidth,
      top: this.captionObject.top,
      width: this.captionObject.width + 2 * paddingWidth,
      height: this.captionObject.height,
      rx: 10,
      ry: 10,
      fill: backgroundColor,
      selectable: false,
      hoverCursor: "default",
      // evented: false,
    });


    this.captionBackground.on("mouseover", (e) => {
      // TODO: figure out why it fails to work without refreshCaption here
      this.refreshCaption();
      this.captionBackground.fill = "rgba(0,0,0,0.2)";
      this.captionObject.fill = "rgba(227, 181, 5, 0.2)";
      this.imageViewer.renderAll$.next(1);
    });

    this.captionBackground.on("mouseout", (e) => {
      this.refreshCaption();
    });

    canvas.add(this.captionBackground);
    canvas.add(this.captionObject);

    this.updateExternalLinkFor(selectedObject);
  };

  registerEvents = () => {
    const canvas = this.imageViewer.canvas;

    this.imageViewer.subscriptions.push(this.imageViewer.objectSelected$.pipe(
        tap(opt => {
          this.createCaptionFor(opt.target);
          this.imageViewer.visualChanged$.next(1);
        })
    ).subscribe());

    canvas.on("object:modified", opt => {
      this.refreshCaption();
      this.imageViewer.visualChanged$.next(1);
    });

    this.imageViewer.subscriptions.push(this.imageViewer.objectsChanged$.subscribe(opt => {
      this.refreshCaption();
      this.imageViewer.visualChanged$.next(1);
    }));
  };
}