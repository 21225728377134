import {DependencyList, useEffect, useMemo, useRef} from "react";

export function useAbortControllers(deps?: DependencyList) {
    const abortControllers = useRef<AbortController[]>([]);

    useEffect(() => {
        return () => {
            abortControllers.current.forEach(x => x.abort());
            abortControllers.current = [];
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    return useMemo(() => ({
        use: function () {
            const controller = new AbortController();
            abortControllers.current.push(controller);
            return controller;
        },
        useSignal: function () {
            return this.use().signal;
        }
    }), []);
}
