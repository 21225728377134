import {Canvas} from "./Canvas";

export class CanvasGroup {
    private readonly registry: Map<string, Canvas> = new Map<string, Canvas>();

    public add(id: string, canvas: Canvas) {
        this.registry.set(id, canvas);
    }

    public getById(id: string) {
        return this.registry.get(id)!;
    }
}