import React, {useEffect, useState} from "react";
import {Row, Select} from "antd";
import WorkspacesService from "../../services/WorkspacesService";
import WorkspaceViewModeService from "../../services/WorkspaceViewModeService";

export function ViewWorkspaceSwitch({onChange}) {
    const [curWorkspaceId, setCurWorkspaceId] = useState(WorkspaceViewModeService.getCurrentWorkspaceId());

    useEffect(() => {
        const listener = WorkspaceViewModeService.addChangeListener(value => {
            setCurWorkspaceId(value);
        });

        return () => listener.release();
    });

    return (
        <RawViewWorkspaceSwitch
            key={curWorkspaceId}
            value={curWorkspaceId}
            onChange={newId => {
                WorkspaceViewModeService.enterWorkspace(newId);
                onChange(newId);
            }}
        />
    );
}

export function RawViewWorkspaceSwitch({value, onChange}) {
    const [workspaces, setWorkspaces] = useState([]);

    useEffect(() => {
        WorkspacesService.fetchWorkspaces().then(response => {
            setWorkspaces(response.data);
        });
    }, []);

    const isValuePresentInWorkspaces = () => {
        return !!workspaces.find(el => el.id === value);
    };

    return (
        <>
            <Row justify={"space-around"}>
                Current workspace
            </Row>
            <Row justify={"space-around"}>
                <Select onSelect={onChange}
                        id={"user-view-workspaces-select"}
                        options={workspaces.map(workspace => ({label: workspace.name, value: workspace.id}))}
                        value={isValuePresentInWorkspaces() ? value : null}
                        showSearch={true}
                        filterOption={(input, option) => {
                            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                        style={{width: "80%"}}
                        size={"small"}/>
            </Row>
        </>
    );
}