import HierarchiesService from "../../../services/HierarchiesService";
import {
  Button,
  Card,
  Col, Collapse,
  Descriptions, Dropdown,
  Form,
  Input, Menu,
  message, Modal,
  Popconfirm,
  Popover,
  Row, Select,
  Space, Spin,
  Table,
  Tooltip,
  Tree
} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import { ArrowLeftOutlined, CloseOutlined, EyeTwoTone } from "@ant-design/icons";
import {BehaviorSubject, Subject, combineLatest, timer, merge} from "rxjs";
import {
  filter,
  finalize,
  first,
  map,
  skip,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from "rxjs/operators";
import {
  getFabricjsArrow,
  isGraphObject,
  isIntersecting,
  setDifference,
  setIntersection,
  symmetricDifference
} from "../../../Utilities";
import ParentChildSelector from "./ParentChildSelector";
import {ObjectData} from "../ObjectData";
import { DownloadOutlined, EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import {EditableTextField} from "../../EditableFields/EditableTextField";
import {EditableSelectField} from "../../EditableFields/EditableSelectField";
import Hierarchy from "../../ImageViewerHandlers/Hierarchy/Hierarchy";
import HierarchyNode from "../../ImageViewerHandlers/Hierarchy/HierarchyNode";
import ObjectReference from "../../ImageViewerHandlers/Hierarchy/ObjectReference";
import NodeToAdd, {nodeToAddTargets} from "../../ImageViewerHandlers/Hierarchy/NodeToAdd";
import {viewerModes} from "../../../constants";
import {fabric} from "fabric";
import MarkupManager, {markupLayers} from "../../ImageViewerHandlers/Hierarchy/Markup/MarkupManager";
import ParentChildLayer from "../../ImageViewerHandlers/Hierarchy/Markup/Layers/ParentChildLayer";
import HighlightingLayer from "../../ImageViewerHandlers/Hierarchy/Markup/Layers/HighlightingLayer";
import {ColorHighlighter, HighlightedObject} from "../../ImageViewerHandlers/Hierarchy/Markup/Highlighters";
import {TagPreview} from "../../ExploreResults";
import TagUrlLinkService from "../../../services/TagUrlLinkService";
import {useHistory} from "react-router";
import SearchInHierarchy from "./SearchInHierarchy";
import ParentChildIconsHandler from "../../ImageViewerHandlers/Hierarchy/ParentChildIconsHandler";
import { Typography } from "antd";
import NodeData from "./NodeData";
import ExportHierarchy from "./ExportHierarchy";
import {AddMultipleNodesTable} from "./AddMultipleNodesTable";
import {NodeSubtreeTitle} from "./NodeSubstreeTitle";



// TODO: move to utils
const getObjectRect = obj => {
  const result = {
    "x1": obj.left,
    "y1": obj.top,
    "x2": obj.left + obj.width * obj.scaleX,
    "y2": obj.top + obj.height * obj.scaleY,
  };
  if (result.x1 > result.x2) [result.x1, result.x2] = [result.x2, result.x1];
  if (result.y1 > result.y2) [result.y1, result.y2] = [result.y2, result.y1];
  return result;
};

const containsObj = (outerObject, innerObject) => {
  const or = getObjectRect(outerObject);
  const ir = getObjectRect(innerObject);
  return or.x1 <= ir.x1 && ir.x2 <= or.x2 && or.y1 <= ir.y1 && ir.y2 <= or.y2;
};

const getObjectCenter = (obj) => {
  const objRect = getObjectRect(obj);
  return {
    x: (objRect.x1 + objRect.x2) / 2.0,
    y: (objRect.y1 + objRect.y2) / 2.0,
  };
};

const containsPoint = (outerObject, p) => {
  const or = getObjectRect(outerObject);
  return or.x1 <= p.x && p.x <= or.x2 && or.y1 <= p.y  && p.y <= or.y2;
};



export class ViewHierarchy extends React.Component {
  state = {
    hierarchy: null,
    isHierarchyLoading: false,
    currentNode: null,
    selectedNodes: [],
    expandedNodes: [],
    parentChildSelectorState: {
      parent: null,
      child: null,
    },
    newVirtualNodePopoverVisible: false,
    newAttributePopoverVisible: false,
    addMultipleNodesWindowShown: false,
    addMultipleNodesFromAttributeModalShown: false,
    addMultipleNodesList: [],
    currentNodeObjectAttributes: null,
    currentNodeParentAttributes: null,

    newAttribute: {
      name: "",
      value: "",
    },
  };

  componentDidMount() {
    console.log("!!!!!MNT: VH");
    this.treeRef = React.createRef();
    this.hierarchyInitialized$ = new Subject();
    this.hierarchyUpdated$ = new Subject();
    this.parentChildSelectorStateChanged$ = new BehaviorSubject(1);
    this.loadHierarchy();
    this.subscriptions = [];

    this.handlers = {
      parentChildIconsHandler: new ParentChildIconsHandler(this.props.imageViewer, this),
    };
    Object.values(this.handlers).forEach(handler => handler.registerEvents());

    this.subscriptions.push(
        merge(this.hierarchyUpdated$, this.props.imageViewer.objectsVisibilityChanged$).subscribe(this.updateHierarchyObjects)
    );

    // selecting node upon object selecting or at the beginning when user opens the hierarchy
    this.subscriptions.push(
        combineLatest([
          this.hierarchyInitialized$.pipe(first()),
          this.props.imageViewer.objectSelected$
        ]).subscribe(([_, e]) => {
        let nodeToSelect = null;
        if (this.state.hierarchy && e.target) {
          const obj = this.props.imageViewer.canvasObjectToObject(e.target);

          nodeToSelect = this.state.hierarchy.findNodeByObject(obj);
          this.selectNode(nodeToSelect, true);
        }
      })
    );

    this.markupManager = null;
  }

  updateHierarchyObjects = () => {
    if (this.markupManager) {
      this.markupManager.updateMarkup();
      this.props.imageViewer.handlers.captionDrawingHandler.refreshCaption();
    }
  };

  loadHierarchy = (extraNodesToLoad=[], callback=null) => {
    this.setState({isHierarchyLoading: true});
    const selectedNodeId = this.state.currentNode?.id ?? null;

    const expandedNodesKeys = new Set(this.state.expandedNodes.map(
        nodeId => this.state.hierarchy.getNodeById(nodeId)
    ).map(node => `${node.text}_###_${node.label}`));

    const tagsList = this.props.imageViewer.state.allObjects.map(obj => ({
      text: obj.text, label: obj.label,
      full_load: expandedNodesKeys.has(`${obj.text}_###_${obj.label}`),
    }));

    const tagsListKeys = new Set(this.props.imageViewer.state.allObjects.map(
        obj => `${obj.text}_###_${obj.label}`
    ));

    [...this.state.expandedNodes.map(
        nodeId => this.state.hierarchy.getNodeById(nodeId)
    ), ...extraNodesToLoad].forEach(node => {
      const nodeKey = `${node.text}_###_${node.label}`;
      if (!tagsListKeys.has(nodeKey)) {
        tagsList.push({
          text: node.text,
          label: node.label,
          full_load: true,
        });
        tagsListKeys.add(nodeKey);
      }
    });


    // HierarchiesService.fetchHierarchyById(this.props.hierarchyId).then(res => {
    HierarchiesService.fetchPartialHierarchyById(
        this.props.hierarchyId,
        tagsList,
    ).then(res => {
      const newHierarchy = new Hierarchy({
        id: res.data.hierarchy.id,
        name: res.data.hierarchy.name,
        nodes: [],
        imageViewer: this.props.imageViewer,
        hierarchyView: this,
      });
      newHierarchy.nodes = res.data.nodes_list.map(node => {
        const newNode = new HierarchyNode({
          id: node.id,
          text: node.text,
          label: node.label,
          parent_node_id: node.parent_node_id,
          references: [],
          attributes: node.attributes,
          hierarchy: newHierarchy,
          loading_info: node.loading_info
        });

        newNode.references = node.references.map(ref => new ObjectReference({
          id: ref.id,
          page_id: ref.page_id,
          x_rel: ref.x_rel,
          y_rel: ref.y_rel,
          node: newNode,
        }));

        return newNode;
      });

      const updatedExpandedNodes = setIntersection(
          this.state.expandedNodes,
          res.data.nodes_list.map(node => node.id)
      );

      this.setState({hierarchy: newHierarchy, expandedNodes: updatedExpandedNodes}, () => {
        if (selectedNodeId) {
          const previouslySelectedNode = this.state.hierarchy.getNodeById(selectedNodeId);
          if (previouslySelectedNode) {
            console.log("!!! PNODE:", previouslySelectedNode, selectedNodeId);
            this.selectNode(previouslySelectedNode, true, true);
          }
        }


        // update node objects in parentChildSelectorState
        if (this.state.parentChildSelectorState.parent || this.state.parentChildSelectorState.child?.targetType === "node") {
          const newSelectorState = {...this.state.parentChildSelectorState};
          if (this.state.parentChildSelectorState.parent) {
            newSelectorState.parent = newHierarchy.getNodeById(this.state.parentChildSelectorState.parent.id);
          }
          if (this.state.parentChildSelectorState.child?.targetType === "node") {
            newSelectorState.child = {
              ...this.state.parentChildSelectorState.child,
              target: newHierarchy.getNodeById(this.state.parentChildSelectorState.child.target.id),
            };
          }
          this.handleUpdateParentChildSelectorState(newSelectorState);
        }

        if (this.markupManager) this.markupManager.removeMarkup();
        this.markupManager = new MarkupManager([
            new ParentChildLayer(markupLayers.PARENT_CHILD_LAYER, this.state.hierarchy),
            new HighlightingLayer(markupLayers.ADD_NODES_LIST_HIGHLIGHTING_LAYER, []),
            new HighlightingLayer(markupLayers.RECTANGLE_SELECT_HIGHLIGHTING_LAYER, []),
        ], this.props.imageViewer);
        this.hierarchyInitialized$.next(1);
        this.hierarchyUpdated$.next(1);

        const treeData = this.getTreeData();

        this.setState({isHierarchyLoading: false, treeData: treeData});
        if (callback) callback();
      });
    }).catch(() => {
      message.error(() => message.error("Failed to load hierarchy"));
    });
  };


  // returns updated selector state
  handleCreateEdge = (selectorState) => {
    if (selectorState.parent === null || selectorState.child === null) return selectorState;
    const parentId = selectorState.parent.id;
    const hierarchy = this.state.hierarchy;

    let existingNode = null;
    if (selectorState.child.targetType === "node") {
      existingNode = selectorState.child.target;
    } else if (selectorState.child.targetType === "object") {
      existingNode = this.state.hierarchy.findNode(
          selectorState.child.target.objectMetadata.text,
          selectorState.child.target.objectMetadata.label
      );
    }

    if (existingNode) {
      const parentNode = this.state.hierarchy.getNodeById(parentId);
      const pathToRoot = parentNode.getPathToRoot();
      if (parentNode === existingNode || pathToRoot.indexOf(existingNode) !== -1) {
        message.error("Loops are not allowed");
      } else {
        const clonedNode = existingNode.clone();
        clonedNode.parent_node_id = parentId;
        HierarchiesService.updateHierarchyNode(hierarchy, clonedNode).then(() => {
          message.success("Node updated");
          this.loadHierarchy();
        }).catch(() => {
          message.error("Failed to update node");
        });
      }
    } else {
      const objectCenter = getObjectCenter(selectorState.child.target);
      const newNode = new HierarchyNode({
        text: selectorState.child.target.objectMetadata.text,
        label: selectorState.child.target.objectMetadata.label,
        parent_node_id: parentId,
        attributes: [],
        hierarchy: this.state.hierarchy,
      });
      newNode.references = [
        new ObjectReference({
          page_id: this.props.imageViewer.state.pageId,
          x_rel: objectCenter.x / this.props.imageViewer.imageWidth,
          y_rel: objectCenter.y / this.props.imageViewer.imageHeight,
          node: newNode,
        })
      ];
      HierarchiesService.addHierarchyNode(hierarchy, newNode).then(() => {
        message.success("Node added");
        this.loadHierarchy();
      }).catch(() => {
        message.error("Failed to add node");
      });
    }
    return {...selectorState, child: null};
  };

  handleSetParent = node => {
    let newState = {...this.state.parentChildSelectorState, parent: node};
    newState = this.handleCreateEdge(newState);
    this.handleUpdateParentChildSelectorState(newState);
  };

  handleSetChild = child => {
    if (child && child.targetType === "object"
        && !this.props.imageViewer.canvasObjectToObject(child.target).isValidForHierarchy()) {
      // user should never see this message, i.e. we should prevent user from setting untagged object as child
      message.error("Cannot add untagged object to hierarchy");
      return;
    }
    let newState = {...this.state.parentChildSelectorState, child: child};
    newState = this.handleCreateEdge(newState);
    this.handleUpdateParentChildSelectorState(newState);
  };

  handleUpdateParentChildSelectorState(newState) {
    this.setState({
      parentChildSelectorState: newState,
    }, () => this.parentChildSelectorStateChanged$.next(1));
  }

  selectNode = (node, skipZoom, skipScroll) => {
    if (node === null) {
      this.setState({
        selectedNodes: [],
        currentNode: null,
        currentNodeParentAttributes: null,
        currentNodeObjectAttributes: null,
      });
      return;
    }
    const curNode = node;
    const curPathToRoot = curNode.getPathToRoot();


    if (!skipZoom) {
      const targetObj = curNode.findReferencedObject() || curNode.findPotentialObject();
      if (targetObj) {
        this.props.imageViewer.zoomHandler.zoomToObject(targetObj.canvasObject, true, false);
      } else {
        // this.props.imageViewer.objectSelected$.next({target: null, source: 'zoom'});
        this.props.imageViewer.objectSelected$.next({target: null, source: "canvas"});
      }
    }
    let newStateToSet = {
      selectedNodes: [node.id],
      currentNode: curNode,
    };

    if (!skipScroll) {
      const newExpandedKeys = [...new Set([...this.state.expandedNodes, ...curPathToRoot.map(node => node.id)])];
      newStateToSet.expandedNodes = newExpandedKeys;
    }

    // to prevent flickering, set attributes to null only if the previous node was different
    if (!this.state.currentNode || curNode.id !== this.state.currentNode.id) {
      newStateToSet = {
        ...newStateToSet,
        currentNodeParentAttributes: null,
        currentNodeObjectAttributes: null,
      };
    }

    this.setState(newStateToSet, () => {
      const curNodeParent = curNode.getParent();
      if (curNodeParent) {
        curNodeParent.getObjectAttributes().then(attrs => {
          this.setState({currentNodeParentAttributes: attrs});
        });
      }
      curNode.getObjectAttributes().then(attrs => {
        this.setState({currentNodeObjectAttributes: attrs});
      });
      if (!skipScroll) {
        const attempts$ = timer(100, 300).pipe(take(8));

        attempts$.pipe(
            takeUntil(
                attempts$.pipe(
                    filter(() => {
                      const lng = document.getElementsByClassName("ant-tree-treenode-selected").length;
                      return lng > 0;
                    }),
                    skip(2),
                )
            ),
            startWith(-1),
        ).subscribe(val => {
          // this line is crucial for some reason
          this.setState({selectedNodes: [node.id]});
          if (this.treeRef.current) this.treeRef.current.scrollTo({key: node.id});
        });
      }
    });
  };

  handleExpand = (expandedKeys, expanded) => {
    this.setState({expandedNodes: expandedKeys}, () => {
      if (expanded.expanded) {
        const curNode = this.state.hierarchy.getNodeById(expanded.node.key);
        if (!curNode.loading_info.full) this.loadHierarchy();
      }
    });
  };

  getNodeColor = node => {
    if (node.isVirtual()) {
      return "#e6c43f";
    }
    if (node.hasOnPageReference()) {
      return "#57ce46";
    } else {
      return "#3968d0";
    }
  };

  buildSubtree(rootNode) {
    const nodeColor = this.getNodeColor(rootNode);
    const result = {
      title: (
          <NodeSubtreeTitle node={rootNode}
                            hierarchy={this.state.hierarchy}
                            nodeColor={nodeColor}
                            onClick={e => {
                              if (!this.state.selectedNodes.includes(rootNode.id)) {
                                this.selectNode(rootNode);
                              }
                            }}
          />
      ),
      key: rootNode.id,
      children: [],
      isLeaf: rootNode.isLeaf(),
    };

    const childNodes = rootNode.findChildren();
    childNodes.sort((a, b) => a.text.localeCompare(b.text));
    result.children = childNodes.map(node => this.buildSubtree(node));

    return result;
  }

  getTreeData() {
    if (this.state.hierarchy === null) return [];
    // TODO: write finding root node method
    const rootNode = this.state.hierarchy.getRootNode();
    return [this.buildSubtree(rootNode)];
  }

  handleCreateReference = (node, obj) => {
    const clonedNode = node.clone();
    const newReference = clonedNode.getObjectReference(obj);
    clonedNode.references.push(newReference);
    HierarchiesService.updateHierarchyNode(this.state.hierarchy, clonedNode).then(() => {
      message.success("Reference created");
      this.loadHierarchy();
    }).catch(() => {
      message.error("Failed to create reference");
    });
  };


  renderBottomPanel = () => {
    let isReferenceMissing = false;
    let selectedObject = null;
    if (this.state.currentNode && this.props.imageViewer.state.selectedObject) {
      selectedObject = this.props.imageViewer.getCurrentObject();
      const nodePotentiallyMatchesObject = selectedObject.text === this.state.currentNode.text && selectedObject.label === this.state.currentNode.label;
      isReferenceMissing = !this.state.currentNode.matchesObject(selectedObject) && nodePotentiallyMatchesObject;
    }

    const selectedCanvasObject = this.props.imageViewer.state.selectedObject;
    const isSetAsChildButtonVisible = selectedCanvasObject &&
        this.props.imageViewer.canvasObjectToObject(selectedCanvasObject).isValidForHierarchy();

    const header = (<React.Fragment>
      {isReferenceMissing && (
          <div>
            <span style={{color: "var(--color-yellow)"}}>The object is not referenced by the node</span><br />
            <a onClick={() => this.handleCreateReference(this.state.currentNode, selectedObject)}>Create reference</a>
          </div>
      )}
      {selectedCanvasObject && !isSetAsChildButtonVisible && (
          <div>
            <span style={{color: "var(--color-yellow)"}}>The object is not tagged</span><br />
          </div>
      )
      }
    </React.Fragment>);

    return (
      <React.Fragment>
        <Row>
          <Col span={13}>
            <ObjectData
                header={header}
                imageViewer={this.props.imageViewer}
                commentsAllowed={this.props.imageViewer.props.commentsAllowed}
                selectedObject={this.props.imageViewer.state.selectedObject}
                readOnlyAttributes={this.state.currentNodeObjectAttributes}
                labelsList={this.props.imageViewer.state.labelsList}
                systemAttributes={this.props.imageViewer.state.systemAttributes}
                extra={
                    isSetAsChildButtonVisible && <Button size="small" style={{marginRight: "8px"}}
                       onClick={() => this.handleSetChild({
                         target: this.props.imageViewer.state.selectedObject,
                         targetType: "object",
                       })}>Set as child</Button>
                }
                assignedPageField={
                  this.props.imageViewer.pageFieldsOperations.findAssignedFieldForObjectById(
                      this.props.imageViewer.state.selectedObject?.objectMetadata?.id || -1
                  )
                }
            />
          </Col>
          <Col span={11}>
            <NodeData
                currentNode={this.state.currentNode}
                currentNodeParentAttributes={this.state.currentNodeParentAttributes}
                hierarchy={this.state.hierarchy}
                hierarchyView={this}
                imageViewer={this.props.imageViewer}
            />
          </Col>
        </Row>
        <Row>

        </Row>
      </React.Fragment>
    );
  };

  updateAddMultipleNodesList = (newList) => {
    this.setState({
      addMultipleNodesList: newList,
    }, () => {
      const greenHighlighter = new ColorHighlighter("rgba(0, 255, 0, 0.6)");
      this.markupManager.updateLayer(
          new HighlightingLayer(markupLayers.ADD_NODES_LIST_HIGHLIGHTING_LAYER,
              newList.filter(
                  nodeToAdd => nodeToAdd.targetType === nodeToAddTargets.OBJECT
              ).map(nodeToAdd => new HighlightedObject(nodeToAdd.target, greenHighlighter))
          )
      );
    });
  };

  renderAddMultipleNodesWindow = () => {
    const formLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };


    const handleAddFromAttribute = (values) => {
      const selectedObject = this.props.imageViewer.getCurrentObject();
      const nodesToAdd = selectedObject.getAttributeValue(values.attribute_name).split(",").map(nodeInfo => {
        let nodeText, nodeLabel;
        [nodeText, nodeLabel] = nodeInfo.split("|");
        const newNode = new HierarchyNode({
          text: nodeText, label: nodeLabel, references: [], attributes: [], hierarchy: this.state.hierarchy,
        });

        return new NodeToAdd(newNode, nodeToAddTargets.NEW_NODE);
      });
      this.updateAddMultipleNodesList([...this.state.addMultipleNodesList, ...nodesToAdd]);
      this.setState({addMultipleNodesFromAttributeModalShown: false});
    };

    const handleAddFromGraphObject = () => {
      const helper = this.props.imageViewer.state.selectedObject.wrappingData.helper;
      const edgesToCheck = helper.getEdgesRaw().map(edge => {
        const v1 = helper.getWrappedObjects().get(edge.start_vertex);
        const v2 = helper.getWrappedObjects().get(edge.end_vertex);
        return {p1: {x: v1.left, y: v1.top}, p2: {x: v2.left, y: v2.top}};
      });
      const canvasObjectsToAssign = this.props.imageViewer.canvas.getObjects().filter(obj => {
        if (!obj.isProperObject || !obj.visible || isGraphObject(obj)) return false;
        const curObjRect = getObjectRect(obj);
        const P1 = {x: curObjRect.x1, y: curObjRect.y1};
        const P2 = {x: curObjRect.x2, y: curObjRect.y1};
        const P3 = {x: curObjRect.x2, y: curObjRect.y2};
        const P4 = {x: curObjRect.x1, y: curObjRect.y2};
        const segmentsToCheck = [
          {p1: P1, p2: P2},
          {p1: P2, p2: P3},
          {p1: P3, p2: P4},
          {p1: P4, p2: P1},
        ];
        let hasIntersection = false;
        edgesToCheck.forEach(edge => segmentsToCheck.forEach(segment => {
          if (isIntersecting(edge.p1, edge.p2, segment.p1, segment.p2)) {
            hasIntersection = true;
          }
        }));

        if (hasIntersection) return true;
        return false;
      });
      const nodesToAdd = canvasObjectsToAssign.map(
          canvasObject => new NodeToAdd(this.props.imageViewer.canvasObjectToObject(canvasObject), "object")
      );
      this.updateAddMultipleNodesList([...this.state.addMultipleNodesList, ...nodesToAdd]);
    };

    const handleAddFromRectangle = () => {
      const defaultArgs = {
        lockScalingFlip: true,
        fill: "rgba(0,0,0,0)",
        cornerColor: "lime",
        cornerStrokeColor: "gray",
        cornerStyle: "circle",
        transparentCorners: false,
        cornerSize: 10,
        noScaleCache: false,
        strokeUniform: true,
        objectCaching: false,
      };

      const imageViewer = this.props.imageViewer;
      let newObjectCorner;
      let curObj;
      let curPoint;

      const cleanHighlightedObjects = () => {
        this.markupManager.updateLayer(
            new HighlightingLayer(markupLayers.RECTANGLE_SELECT_HIGHLIGHTING_LAYER, [])
        );
      };

      const isObjectWithinRectangleSelection = (canvasObject, rectangleObject) => {
        if (!canvasObject.isProperObject) return false;

        const obj = imageViewer.canvasObjectToObject(canvasObject);
        if (!(canvasObject.visible && containsPoint(rectangleObject, getObjectCenter(canvasObject))
            && obj.isValidForHierarchy())) return false;

        // skip parent to avoid self-loops
        const objectNode = this.state.hierarchy.findNodeByObject(obj);
        const parentId = this.state.parentChildSelectorState?.parent?.id;
        if (objectNode && parentId && objectNode.id === parentId) return false;

        return true;
      };
      // toggling objects by drawing a rectangle
      this.subscriptions.push(this.props.imageViewer.mouseDown$.pipe(
          withLatestFrom(imageViewer.mode$),
          filter(([_, mode]) => mode === viewerModes.HIERARCHY_ADD_FROM_RECTANGLE), map(([opt, _]) => opt),
          first(),

          tap(opt => {
            newObjectCorner = imageViewer.canvas.getPointer(opt.e);
            imageViewer.canvas.selection = false;
            curObj = new fabric.Rect({
              ...defaultArgs,
              width: 1,
              height: 1,
              stroke: "gray",
              strokeDashArray: [10.0 / imageViewer.zoomHandler.zoomLevel], //, 20.0 / imageViewer.zoomHandler.zoomLevel],
              strokeDashOffset: 0,
              fill: "rgba(0, 255, 0, 0.2)",
              top: newObjectCorner.y,
              left: newObjectCorner.x,
              strokeWidth: 5.0 / imageViewer.zoomHandler.zoomLevel,
              evented: false,
              selectable: false,
              // rx: 0.5,
              // ry: 0.5,
            });
            imageViewer.canvas.add(curObj);
            imageViewer.renderAll$.next(1);
          }),
          switchMap(opt => imageViewer.mouseMove$.pipe(
              tap(x => {
                curPoint = imageViewer.canvas.getPointer(opt.e);
                curObj.set("width", curPoint.x - newObjectCorner.x);
                curObj.set("height", curPoint.y - newObjectCorner.y);
                curObj.setCoords();

                const objectsToSelect = imageViewer.canvas.getObjects().filter(
                    (obj) => isObjectWithinRectangleSelection(obj, curObj)
                ).map(canvasObject => imageViewer.canvasObjectToObject(canvasObject));

                const greenHighlighter = new ColorHighlighter("rgba(0, 255, 0, 0.6)");
                const redHighlighter = new ColorHighlighter("rgba(255, 0, 0, 0.7)");

                const alreadySelectedObjects = new Set(
                    this.state.addMultipleNodesList.filter(
                        nodeToAdd => nodeToAdd.targetType === nodeToAddTargets.OBJECT
                    ).map(nodeToAdd => nodeToAdd.target)
                );

                this.markupManager.updateLayer(
                    new HighlightingLayer(markupLayers.RECTANGLE_SELECT_HIGHLIGHTING_LAYER,
                      objectsToSelect.map(
                          obj => new HighlightedObject(
                              obj,
                              alreadySelectedObjects.has(obj) ? redHighlighter : greenHighlighter
                          )
                      )
                    )
                );
                imageViewer.renderAll$.next(1);
              }),
              takeUntil(imageViewer.mouseUp$),
              finalize(() => {
                imageViewer.canvas.selection = true;
                const selectedObjs = imageViewer.canvas.getObjects().filter(
                    (obj) => isObjectWithinRectangleSelection(obj, curObj)
                ).map(canvasObject => this.props.imageViewer.canvasObjectToObject(canvasObject));

                const alreadySelectedObjects = new Set(
                    this.state.addMultipleNodesList.filter(
                        nodeToAdd => nodeToAdd.targetType === nodeToAddTargets.OBJECT
                    ).map(nodeToAdd => nodeToAdd.target)
                );

                const nodesToAdd = setDifference(selectedObjs, alreadySelectedObjects).map(
                    obj => new NodeToAdd(obj, "object")
                );

                const objectsToRemove = new Set(setIntersection(selectedObjs, alreadySelectedObjects));
                this.updateAddMultipleNodesList([
                  ...this.state.addMultipleNodesList.filter(
                      nodeToAdd => nodeToAdd.targetType === nodeToAddTargets.OBJECT && !objectsToRemove.has(nodeToAdd.target)
                  ),
                  ...nodesToAdd],
                );
                imageViewer.canvas.remove(curObj);
                cleanHighlightedObjects();
                imageViewer.mode$.next(viewerModes.NORMAL);
              }),
              )
          )
      ).subscribe());

      imageViewer.mode$.next(viewerModes.HIERARCHY_ADD_FROM_RECTANGLE);
    };

    const handleApplyChanges = () => {
      const parentNodeId = this.state.parentChildSelectorState.parent.id;
      const promises = [];
      for (const listItem of this.state.addMultipleNodesList) {
        if (listItem.targetType === nodeToAddTargets.NEW_NODE) {
          const newNode = listItem.target;
          newNode.parent_node_id = parentNodeId;
          promises.push(HierarchiesService.addHierarchyNode(this.state.hierarchy, newNode));
        } else if (listItem.targetType === nodeToAddTargets.OBJECT) {
          // TODO: DRY

          const existingNode = this.state.hierarchy.findNode(
              listItem.target.text,
              listItem.target.label,
          );
          if (existingNode) {
            const parentNode = this.state.hierarchy.getNodeById(parentNodeId);
            const pathToRoot = parentNode.getPathToRoot();
            if (parentNode === existingNode || pathToRoot.indexOf(existingNode) !== -1) {
              message.error("Loops are not allowed");
            } else {
              const clonedNode = existingNode.clone();
              clonedNode.parent_node_id = parentNodeId;
              promises.push(HierarchiesService.updateHierarchyNode(this.state.hierarchy, clonedNode));
            }
          } else {
            const objectCenter = listItem.target.getRect().getCenter();
            const newNode = new HierarchyNode({
              text: listItem.target.text,
              label: listItem.target.label,
              parent_node_id: parentNodeId,
              attributes: [],
              hierarchy: this.state.hierarchy,
            });
            newNode.references = [
              new ObjectReference({
                page_id: this.props.imageViewer.state.pageId,
                x_rel: objectCenter.x / this.props.imageViewer.imageWidth,
                y_rel: objectCenter.y / this.props.imageViewer.imageHeight,
                node: newNode,
              })
            ];
            promises.push(HierarchiesService.addHierarchyNode(this.state.hierarchy, newNode));
          }
        }
      }

      Promise.all(promises).then(() => {
        message.success("Changes applied");
        this.loadHierarchy();
        this.updateAddMultipleNodesList([]);
      }).catch(() => {
        message.error("Failed to apply changes");
      });
    };
    const selectedObject = this.props.imageViewer.getCurrentObject();
    const subnodeAttributes = selectedObject && this.state.hierarchy?.getSubnodesAttributesKeys(selectedObject);
    const formInitialValues = {
      attribute_name: subnodeAttributes && subnodeAttributes[0],
    };

    const isApplyChangesEnabled = this.state.addMultipleNodesList.length > 0 && this.state.parentChildSelectorState.parent
        && !this.state.addMultipleNodesList.some(el => el.duplicated(this.state.addMultipleNodesList));

    return (this.state.addMultipleNodesWindowShown &&
      <div style={{position: "absolute", background:"white", overflowY: "scroll", width: "400px", right: 0, bottom: 0, height: "60%", border: "3px solid #009F98", zIndex: 1000}}>
        <div style={{minHeight: "100%", background: "white", width: "100%"}}>
          <Card title="Add multiple nodes" extra={<CloseOutlined onClick={() => this.setState({addMultipleNodesWindowShown: false})}/>}
                size="small" style={{height: "100%"}}>
            <Row >
              {this.props.imageViewer.state.selectedObject &&
                  <React.Fragment>
                      {this.state.hierarchy.hasSubnodesAttribute(selectedObject) &&
                      <Button size="small"
                          style={{marginRight: "8px", marginTop: "8px"}}
                              onClick={() => this.setState({addMultipleNodesFromAttributeModalShown: true})}>
                        From attribute</Button>
                      }
                      {selectedObject.isValidForHierarchy() && <Button
                          size="small"
                          style={{marginRight: "8px", marginTop: "8px"}}
                          onClick={() => {
                        const newItem = new NodeToAdd(selectedObject, nodeToAddTargets.OBJECT);
                        this.updateAddMultipleNodesList([...this.state.addMultipleNodesList, newItem]);
                        }}>Add current</Button>
                      }
                      {isGraphObject(this.props.imageViewer.state.selectedObject) && <Button
                          size="small"
                          style={{marginRight: "8px", marginTop: "8px"}}
                          onClick={() => {
                        handleAddFromGraphObject();
                      }}>Add from piping object</Button>}
                  </React.Fragment>
              }
                <Button
                    size="small"
                    style={{marginRight: "8px", marginTop: "8px"}}
                    onClick={() => handleAddFromRectangle()}
                    disabled={this.props.imageViewer.state.isCreatingMarker}
                >
                  Add from rectangle
                </Button>
              {this.state.addMultipleNodesList.length > 0 &&
                <Button size="small" style={{marginRight: "8px", marginTop: "8px"}}
                      onClick={() => this.updateAddMultipleNodesList([])}>Clear</Button>
              }
            </Row>
            <AddMultipleNodesTable dataSource={this.state.addMultipleNodesList}
                                   onUpdateDataSource={this.updateAddMultipleNodesList}/>
            <Button type="primary" disabled={!isApplyChangesEnabled}
                    style={{marginTop: "8px"}}
                    onClick={handleApplyChanges}>Apply changes</Button>
          </Card>
        </div>
        <Modal
            title="Add multiple nodes from attribute"
            visible={this.state.addMultipleNodesFromAttributeModalShown}
            destroyOnClose={true}
            onCancel={() => this.setState({addMultipleNodesFromAttributeModalShown: false})}
            footer={null}
            bodyStyle={{height: "auto"}}
        >
          <Form {...formLayout} name="add-from-attribute"
                onFinish={handleAddFromAttribute}
                initialValues={formInitialValues}>
            <Form.Item name={"attribute_name"} label="Attribute" rules={[{ required: true }]}>
              <Select>
                {
                  selectedObject &&
                  (subnodeAttributes.map(attributeKey => {
                    return (<Select.Option value={attributeKey}>{attributeKey}</Select.Option>);
                  }))
                }
              </Select>
            </Form.Item>
            <Row>
              <Button type="primary" htmlType="submit" style={{marginLeft: "auto"}}>
                Add
              </Button>
            </Row>
          </Form>
        </Modal>
      </div>);
  };

  handleOpenModalSearchInHierarchy = () => {
    this.setState({searchInHierarchyModalShown: true});
  };

  handleCloseSearchInHierarchy = () => {
    this.setState({searchInHierarchyModalShown: false});
  };



  renderSearchInHierarchyModal() {

    return (
        <Modal
            title="Search in hierarchy"
            visible={this.state.searchInHierarchyModalShown}
            destroyOnClose={true}
            onCancel={this.handleCloseSearchInHierarchy}
            footer={null}
            bodyStyle={{height: "auto"}}
        >
          <SearchInHierarchy hierarchy={this.state.hierarchy} parent={this} closeModal={this.handleCloseSearchInHierarchy} />
        </Modal>
    );
  }


  componentWillUnmount() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    if (this.markupManager) this.markupManager.removeMarkup();
    this.handlers.parentChildIconsHandler.clearIcons();
  }

  render() {
    const treeData = this.state.treeData;

    return (
      <React.Fragment>
        <Row>
          <Button id="exit-hierarchy-button"
                  shape="circle" size="medium" style={{marginRight: "16px", marginBottom: "16px"}}
                  onClick={this.props.onBack} icon={<ArrowLeftOutlined />}/>
          Hierarchy:&nbsp;<span id="current-hierarchy-name">{this.state.hierarchy?.name}</span>
          <div style={{marginLeft: "auto"}}>
            <a id="search-in-hierarchy" style={{marginRight: "32px"}} onClick={this.handleOpenModalSearchInHierarchy}>
              <Tooltip title="Search in hierarchy" placement="bottom">
                <SearchOutlined style={{fontSize: "24px"}} />
              </Tooltip>
            </a>
            <a id="download-hierarchy-spreadsheet-icon" style={{marginRight: "16px"}}>
              <Tooltip title="Export hierarchy to excel" placement="bottom">
                <ExportHierarchy hierarchyId={this.state.hierarchy?.id}/>
              </Tooltip>
            </a>
          </div>
        </Row>
        <Spin spinning={this.state.isHierarchyLoading} delay={500}>
          <div id="hierarchy-tree-view">
            <Tree
                ref={this.treeRef}
                style={{height: "350px"}}
                showLine={{showLeafIcon: false}}
                showIcon={false}
                treeData={treeData}
                height={350}
                onExpand={this.handleExpand}
                selectedKeys={this.state.selectedNodes}
                expandedKeys={this.state.expandedNodes}
            />
          </div>
        </Spin>
        <Row style={{margin: "8px"}}>
          <Col span={16}>
            <ParentChildSelector
                selectorState={this.state.parentChildSelectorState}
                onChange={newState => this.handleUpdateParentChildSelectorState(newState)}
            />
          </Col>
          <Col span={8}>
            <Row>
              <Button size="small" style={{marginLeft: "auto"}}
                    onClick={() => this.setState({addMultipleNodesWindowShown: true})}>Add multiple nodes</Button>
            </Row>
          </Col>
        </Row>

        {this.renderBottomPanel()}

        {ReactDOM.createPortal(this.renderAddMultipleNodesWindow(), document.getElementById("workspace-col"))}

        {this.renderSearchInHierarchyModal()}
      </React.Fragment>
    );
  }
}
