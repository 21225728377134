import React, {useState} from "react";
import {Input, Tag, Tooltip} from "antd";
import {CloseOutlined, PlusOutlined} from "@ant-design/icons";

export default function EditableTagGroup({dataSource, onTagsChanged, newTagText, tagColor, withClearAllButton, withEditTagAbility}) {
    withEditTagAbility = withEditTagAbility?? true;
    withClearAllButton = withClearAllButton?? true;

    const emptyTag = {name: "", idx: -1};
    const maxVisibleTagLength = 20;
    const tagInputStyle = {width: "78px", marginRight: "8px", marginTop: "8px", verticalAlign: "top"};
    const editTagStyle = {userSelect: "none", marginTop: "8px"};
    const siteTagPlus = {background: "#fff", borderStyle: "dashed"};

    const [newTagInputVisible, setNewTagInputVisible] = useState(false);
    const [editedTag, setEditedTag] = useState(emptyTag);

    function emitTagsEdited() {
        setEditedTag(emptyTag);
        onTagsChanged(dataSource);
    }

    function handleClearAll() {
        dataSource = [];
        emitTagsEdited();
    }

    function handleEditInputConfirm() {
        dataSource = dataSource.map((tag, idx) => editedTag.idx === idx ? editedTag.name : tag);

        emitTagsEdited();
    }

    function handleNewTag() {
        if (!dataSource.includes(editedTag.name)) {
            dataSource = [...dataSource, editedTag.name];
        }

        emitTagsEdited();
    }

    function handleTagDeletion(targetIdx) {
        dataSource = dataSource.filter((tag, idx) => idx !== targetIdx);

        emitTagsEdited();
    }

    const tags = dataSource.map((tag, idx) => {
        if (editedTag.idx === idx) {
            return (
                <Input
                    value={editedTag.name}
                    key={tag}
                    size={"small"}
                    style={tagInputStyle}
                    onChange={e => setEditedTag({...editedTag, name: e.target.value})}
                    onBlur={handleEditInputConfirm}
                    onPressEnter={(e)=>{
                        handleEditInputConfirm();
                        e.preventDefault();
                    }}
                    autoFocus={true}
                />
            );
        }

        const isLongTag = tag.length >= maxVisibleTagLength;

        const tagElement = (
            <Tag
                id={`editable-tag_${idx}`}
                style={editTagStyle}
                color={tagColor}
                key={tag}
                closable={true}
                onClose={()=>handleTagDeletion(idx)}
            >
                <span onDoubleClick={e => {
                    e.preventDefault();

                    if (!withEditTagAbility) {
                        return;
                    }

                    setEditedTag({name: tag, idx: idx});
                }}>
                  {isLongTag ? `${tag.slice(0, maxVisibleTagLength)}...` : tag}
                </span>
            </Tag>
        );

        return isLongTag ? (
            <Tooltip title={tag} key={tag}>
                {tagElement}
            </Tooltip>
        ) : (tagElement);
    });

    function renderNewTagElement() {
        if (newTagInputVisible) {
            return (
                <Input
                    id="new-tag-name-input"
                    value={editedTag.name}
                    size={"small"}
                    style={tagInputStyle}
                    onChange={e => setEditedTag({...editedTag, name: e.target.value})}
                    onBlur={(e) => {
                        if (editedTag.name !== "") {
                            handleNewTag();
                        }
                        setNewTagInputVisible(false);
                    }}
                    onPressEnter={(e)=>{
                        handleNewTag();
                        setNewTagInputVisible(false);
                        e.preventDefault();
                    }}
                    autoFocus={true}
                />
            );
        }

        return (
            <Tag id="add-new-tag-btn" style = {{...siteTagPlus, marginTop: "8px"}} onClick={() => {
                setEditedTag(emptyTag);
                setNewTagInputVisible(true);
            }}>
                <PlusOutlined/> {newTagText}
            </Tag>
        );
    }

    function renderClearAllElement() {
        if (dataSource.length <= 1) {
            return null;
        }

        return (
            <Tag style = {{...siteTagPlus, marginTop: "8px"}} onClick={handleClearAll}>
                <CloseOutlined/> Clear all
            </Tag>
        );
    }

    return (
        <>
            {tags}
            {renderNewTagElement()}
            {withClearAllButton && renderClearAllElement()}
        </>
    );
}