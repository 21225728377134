import {PluginAnonymous, PluginSourceSequence} from "../../../services/Plugins";
import {DocumentRegistryViewRef} from "../DocumentRegistryView";
import {CurrentRegistry} from "../Resources/CurrentRegistry";
import {ImmortalAutoSizer} from "../../Misc/ImmortalAutoSizer";
import {Tree} from "antd";
import {Key, useEffect, useState} from "react";
import {DocumentRegistry, FileSystemResult} from "../../../services/DocumentRegistry/DocumentRegistry";

export class PluginFileSystem extends PluginSourceSequence<DocumentRegistryViewRef> {
    constructor() {
        super([
            new PluginFileSystemTree(),
        ]);
    }
}

class PluginFileSystemTree extends PluginAnonymous<DocumentRegistryViewRef> {
    constructor() {
        super("file-system-tree", {
            async build(target: DocumentRegistryViewRef): Promise<void> {
                const registry = target.res(CurrentRegistry).get().value();
                target.addDock("left-ur", {
                    children: (
                        <FileSystemTree
                            registry={registry}
                        />
                    ),
                    key: "fs",
                    name: "File System",
                });
            }
        });
    }
}

interface FileSystemTreeProps {
    registry: DocumentRegistry,
}

interface DataNode {
    title: string;
    key: string;
    path: Path,
    isLeaf?: boolean;
    children?: DataNode[];
}

class Path {
    private readonly path: string[];

    constructor(path: string[] = []) {
        this.path = path;
    }

    with(el: string) {
        return new Path(
            [...this.path, el]
        );
    }

    toString() {
        return this.path.join("/");
    }
}

const updateTreeData = (list: DataNode[], key: Key, children: DataNode[]): DataNode[] => (
    list.map(node => {
        if (node.key === key) {
            return {
                ...node,
                children,
            };
        }
        if (node.children) {
            return {
                ...node,
                children: updateTreeData(node.children, key, children),
            };
        }
        return node;
    })
);

function toNode(path: Path, data: FileSystemResult): DataNode[] {
    return [
        ...data.dirs.map(dir => ({
            title: dir.name,
            key: path.with(dir.name).toString(),
            path: path.with(dir.name)
        })),
        ...data.files.map(file => ({
            title: file.name,
            key: file.item.id(),
            path: path.with(file.name),
            isLeaf: true
        })),
    ];
}

function FileSystemTree({registry}: FileSystemTreeProps) {
    const [treeData, setTreeData] = useState<DataNode[]>([]);

    useEffect(() => {
        const path = new Path();
        registry.fileSystem(path.toString()).then(data => {
            setTreeData(toNode(path, data));
        });
    }, [registry]);

    const loadData = ({key, children, path}: DataNode) => (
        new Promise<void>((resolve, reject) => {
            if (children) {
                resolve();
                return;
            }
            registry.fileSystem(path.toString()).then(data => {
                setTreeData(origin => (
                    updateTreeData(origin, key, toNode(path, data))
                ));
                resolve();
            }).catch(reject);
        })
    );

    return (
        <ImmortalAutoSizer>
            {({height, width}) => (
                <div style={{height, width}}>
                    <Tree.DirectoryTree
                        height={height}
                        loadData={loadData}
                        treeData={treeData}
                    />
                </div>
            )}
        </ImmortalAutoSizer>
    );
}