import {fabric} from "fabric";
import {filter, map, pairwise, switchMap, takeUntil, tap, withLatestFrom} from "rxjs/operators";
import {merge} from "rxjs";
import {viewerConsts, viewerModes} from "../../constants";
import {Subject} from "rxjs";

const COLOR_BLUE = "rgba(0,0,255, 0.6)";

export class NewObjectHandler {
  constructor(imageViewer) {
    this.imageViewer = imageViewer;
    this.crossLines = [];
    this.newObject = null;
    this.drawingNewObjectAborted$ = new Subject();
    this.lastMousePoint = {x: 0, y: 0};
  }

  deactivateObjects = () => {
    this.imageViewer.canvas.getObjects().filter((obj) => obj.isProperObject).forEach(obj => {
      obj.evented = false;
    });
  };

  activateObjects = () => {
    this.imageViewer.canvas.getObjects().filter((obj) => obj.isProperObject).forEach(obj => {
      obj.evented = true;
    });
  };

  handleStart = () => {
    const th = this.imageViewer;

    th.objectSelected$.next({target: null, source: "canvas"});
    th.mode$.next(viewerModes.CREATING_NEW_OBJECT);

    this.deactivateObjects();

    th.renderAll$.next(1);

    th.canvas.defaultCursor = "crosshair";
    th.canvas.setCursor("crosshair");

    let vertLine = new fabric.Line([
          this.lastMousePoint.x,
          0,
          this.lastMousePoint.x,
          th.imageHeight
        ],
        {stroke: "black", strokeUniform: true, selectable: false, evented: false, strokeWidth: 1 / th.zoomHandler.zoomLevel});

    let horLine = new fabric.Line([
          0,
          this.lastMousePoint.y,
          th.imageWidth,
          this.lastMousePoint.y
        ],
        {stroke: "black", strokeUniform: true, selectable: false, evented: false, strokeWidth: 1 / th.zoomHandler.zoomLevel});
    th.canvas.add(vertLine);
    th.canvas.add(horLine);
    this.crossLines = [vertLine, horLine];
    th.renderAll$.next(1);
  };

  handleAbort = () => {
    this.drawingNewObjectAborted$.next(1);
    const th = this.imageViewer;
    th.canvas.defaultCursor = "default";
    th.canvas.setCursor("default");
    th.mode$.next(viewerModes.NORMAL);
    this.crossLines.forEach((line) => th.canvas.remove(line));
    this.crossLines = [];
    this.activateObjects();
    th.renderAll$.next(1);
  };

  handleFinish = () => {
    const th = this.imageViewer;
    th.mode$.next(viewerModes.NORMAL);
    th.canvas.defaultCursor = "default";
    if (this.newObject.width < 0) {
      this.newObject.left = this.newObject.left + this.newObject.width;
      this.newObject.width = -this.newObject.width;
    }
    if (this.newObject.height < 0) {
      this.newObject.top = this.newObject.top + this.newObject.height;
      this.newObject.height = -this.newObject.height;
    }
    this.newObject.rx = 0.5;
    this.newObject.ry = 0.5;
    this.newObject.setCoords();
    this.imageViewer.renderAll$.next(1);

    this.imageViewer.objectsChanged$.next(1);
    th.objectSelected$.next({target: this.newObject, source: "canvas"});

    this.activateObjects();
    th.renderAll$.next(1);
  };

  registerEvents = () => {
    // synchronize imageViewer.state.isCreatingObject value
    this.imageViewer.subscriptions.push(
        this.imageViewer.mode$.subscribe(mode => this.imageViewer.setState({isCreatingObject: mode === viewerModes.CREATING_NEW_OBJECT}))
    );

    const canvas = this.imageViewer.canvas;

    // handle drawing new object rectange with mouse
    this.imageViewer.subscriptions.push(this.imageViewer.mouseDown$.pipe(
        withLatestFrom(this.imageViewer.mode$),
        filter(([_, mode]) => mode === viewerModes.CREATING_NEW_OBJECT), map(([opt, _]) => opt),

        map(opt => ({corner: canvas.getPointer(opt.e)})),
        tap((opt) => {
          canvas.remove(this.crossLines[0]);
          canvas.remove(this.crossLines[1]);
          this.crossLines = [];

          const newObjectLabel = this.imageViewer.state.currentLabel;

          const strokeColor = COLOR_BLUE;

          const newObjectCorner = opt.corner;

          //canvas.getPointer(opt.e);//{x: opt.e.offsetX / th.zoomLevel, y: opt.e.offsetY / th.zoomLevel};
          this.newObject = new fabric.Rect({
            ...viewerConsts.DEFAULT_OBJECT_ARGS,
            width: 1,
            height: 1,
            stroke: strokeColor,
            top: newObjectCorner.y,
            left: newObjectCorner.x,
            strokeWidth: viewerConsts.STROKE_WIDTH / this.imageViewer.zoomHandler.zoomLevel,
            evented: false,
            // rx: 0.5,
            // ry: 0.5,
          });

          this.newObject.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            mtr: false,
          });
          this.imageViewer.lastObjectIndex += 1;
          this.newObject.objectMetadata = {
            id: this.imageViewer.lastObjectIndex,
            label: newObjectLabel,
            text: "",
            metadata: {
              description: "",
              attributes: []
            }
          };
          this.newObject.isProperObject = true;
          canvas.add(this.newObject);
          canvas.selection = false;
          this.imageViewer.renderAll$.next(1);
        }),
        switchMap(opt => this.imageViewer.mouseMove$.pipe(
            map(opt2 => ({otherCorner: canvas.getPointer(opt2.e)})),
            tap(opt2 => {
              const newObjectCorner = opt.corner;
              const curPoint = opt2.otherCorner;

              this.newObject.set("width", curPoint.x - newObjectCorner.x);
              this.newObject.set("height", curPoint.y - newObjectCorner.y);
              this.newObject.setCoords();
              this.imageViewer.renderAll$.next(1);
            }),
            takeUntil(
                merge(
                    this.imageViewer.mouseUp$,
                    this.drawingNewObjectAborted$,
                ).pipe(tap(() => this.handleFinish()))
            ),
        )),
    ).subscribe());

    // handle drawing cross lines on mouse move
    this.imageViewer.subscriptions.push(this.imageViewer.mode$.pipe(
        filter(mode => mode === viewerModes.CREATING_NEW_OBJECT),
        switchMap(opt => this.imageViewer.mouseMove$.pipe(
            map(opt => ({pointer: canvas.getPointer(opt.e)})),
            tap(opt => {
              if (this.crossLines.length !== 2) return;
              const curPoint = opt.pointer;
              this.crossLines[0].set("x1", curPoint.x);
              this.crossLines[0].set("x2", curPoint.x);
              this.crossLines[0].setCoords();
              this.crossLines[1].set("y1", curPoint.y);
              this.crossLines[1].set("y2", curPoint.y);
              this.crossLines[1].setCoords();
              this.imageViewer.renderAll$.next(1);
            }),
            takeUntil(this.imageViewer.mouseDown$),
          )
        ),
    ).subscribe());

    this.imageViewer.subscriptions.push(this.imageViewer.mouseMove$.subscribe(opt => {
      this.lastMousePoint = canvas.getPointer(opt.e);
    }));
  };
}
