import {PluginAnonymous} from "../../../services/Plugins";
import {ResourcesSource} from "../../../services/Resources";
import {CanvasGroup} from "../Resources/CanvasGroup";

export class PluginCanvasGroup extends PluginAnonymous<ResourcesSource> {
    constructor() {
        super("canvas-group", {
            async build(target: ResourcesSource) {
                target.res(CanvasGroup).set(new CanvasGroup());
            }
        });
    }
}