import React, {useState} from "react";
import axios from "axios";
import {Button, Card, Col, Form, Input, message, Popover, Row, Space, Table, Tabs, Tag, Typography} from "antd";
import {API_URL} from "../../constants";
import {Link} from "react-router-dom";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {authHeader} from "../../Utilities";
import PermissionsService from "../../services/PermissionsService";
import {AuthContext} from "../../contexts/AuthContext";
import ConfigureExcelReports from "./ConfigureExcelReports";
import {ConfigureSystemAttributes} from "./ConfigureSystemAttributes";
import {ConfigureStatusReport} from "./ConfigureStatusReport";
import {ConfigureSearchFilters} from "./ConfigureSearchFilters";
import ConfigureHierarchyReports from "./ConfigureHierarchyReports";
import {ImportDocumentRegistry} from "./ImportDocumentRegistry";

const {Title} = Typography;


export class Workspace extends React.Component {
    static contextType = AuthContext;

    state = {
        workspace: null,
        projectsList: [],
        usersList: [],
        addUserModalVisible: false,
        usersToAdd: [],
    };

    loadProjects = (workspaceId) => {
        axios.get(API_URL + `/workspaces/${workspaceId}/projects`, {headers: authHeader()}).then(res => {
            this.setState({projectsList: res.data});
        }).catch(err => {
            message.error("Failed to load projects list");
        });
    };

    loadWorkspace = (workspaceId) => {
        axios.get(API_URL + `/workspaces/${workspaceId}`, {headers: authHeader()}).then(res => {
            this.setState({workspace: res.data});
        }).catch(err => {
            message.error("Failed to load workspace");
        });
    };

    loadUsers = (workspaceId) => {
        axios.get(API_URL + `/workspaces/${workspaceId}/users`, {headers: authHeader()}).then(res => {
            this.setState({usersList: res.data});
        }).catch(err => {
            message.error("Failed to load users list");
        });
    };

    handleProjectRemoval = (projectId, projectName) => {
        axios.delete(API_URL + `/projects/${projectId}`, {
            headers: authHeader(),
            params: {name: projectName}
        }).then(result => {
            message.success("Project deleted");
            this.loadProjects(this.props.match.params.workspaceId);
        }).catch(err => {
            message.error("Failed to delete project");
        });
    };

    componentDidMount() {
        this.loadWorkspace(this.props.match.params.workspaceId);
        this.loadProjects(this.props.match.params.workspaceId);
        this.loadUsers(this.props.match.params.workspaceId);
    }

    render() {
        const projectsColumns = [
            {
                title: "Name",
                dataIndex: "name",
            },
            {
                title: "Description",
                dataIndex: "description",
            },
            {
                title: "Actions",
                dataIndex: "id",
                render: (id, record) => {
                    return (
                        <Space size="large">
                            <Link to={`/project/${id}`}>Open</Link>
                            <ProjectNameValidationPopover
                                key={id}
                                projectId={id}
                                desiredName={record.name}
                                onOk={values => this.handleProjectRemoval(id, values.name)}
                            >
                                <a>Delete</a>
                            </ProjectNameValidationPopover>
                        </Space>
                    );
                }
            },
        ];

        const usersColumns = [
            {
                title: "Email",
                dataIndex: "email",
                render: (email, record) => {
                    return (
                        <Link to={new PermissionsService(this.context.user).getLinkToUser(record.id)}>{email}</Link>
                    );
                }
            },
            {
                title: "Permissions",
                dataIndex: "id",
                render: (userId, record) => {
                    const allPermissions = [...record.permissions, ...record.organization.permissions];
                    const workspacePermissions = [...new Set(allPermissions.filter(
                        p => p.target === this.props.match.params.workspaceId
                    ).map(
                        p => p.permission_type
                    ))].sort();
                    return (
                        <React.Fragment>
                            {
                                workspacePermissions.map(permissionType => (<Tag>{permissionType}</Tag>))
                            }
                        </React.Fragment>
                    );
                }
            }
        ];

        return (
            <>
                <Row>
                    <Link to={"/workspaces"}>
                        <Button shape="circle" size="large" icon={<ArrowLeftOutlined/>}
                                style={{marginRight: "16px", marginBottom: "16px"}}/>
                    </Link>
                    <Title level={4}>Workspace: {this.state.workspace?.name}</Title>
                </Row>
                <Tabs>
                    <Tabs.TabPane tab="Details" key="details">
                        <Row gutter={16}>
                            <Col span={14}>
                                <Card title="Projects">
                                    <Table id="workspace-projects-table"
                                           dataSource={this.state.projectsList} columns={projectsColumns} size="small"/>
                                </Card>
                            </Col>
                            <Col span={10}>
                                <Card title="Users">
                                    <Table id="workspace-users-table"
                                           dataSource={this.state.usersList} columns={usersColumns} size="small"/>
                                </Card>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Excel reports" key="excel_reports">
                        <Tabs>
                            <Tabs.TabPane tab="Tags export" key="tags_export">
                                <ConfigureExcelReports workspaceId={this.props.match.params.workspaceId}/>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Hierarchy export" key="hierarchy_export">
                                <ConfigureHierarchyReports workspaceId={this.props.match.params.workspaceId}/>
                            </Tabs.TabPane>
                        </Tabs>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Status report" key="status_report">
                        <ConfigureStatusReport workspaceId={this.props.match.params.workspaceId}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="System Attributes Configuration" key="system_attributes_configuration">
                        <ConfigureSystemAttributes workspaceId={this.props.match.params.workspaceId}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Search Filters Configuration" key="search_filters_configuration">
                        <ConfigureSearchFilters workspaceId={this.props.match.params.workspaceId}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Import Document Registry" key="import_document_registry">
                        <ImportDocumentRegistry workspaceId={this.props.match.params.workspaceId}/>
                    </Tabs.TabPane>
                </Tabs>
            </>
        );
    }
}


function ProjectNameValidationPopover({desiredName, onOk, children}) {
    const [visible, setVisible] = useState(false);

    const formId = "project-name-validation-form";
    const formLayout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const popoverContent = (
        <div style={{width: "288px"}}>
            <Row style={{marginBottom: "8px"}}>
                <span>To confirm project deletion, type the project name in the field below</span>
            </Row>
            <ProjectNameValidationForm id={formId}
                                       onFinish={values => {
                                           setVisible(false);
                                           onOk(values);
                                       }}
                                       desiredName={desiredName}
                                       initialValue={{name: ""}}/>
            <Row
                style={{justifyContent: "space-between"}}
            >
                <Button size="small" onClick={_ => setVisible(false)} style={{width: 90}}>Cancel</Button>
                <Button
                    danger
                    form={formId}
                    key="submit" htmlType="submit"
                    type="primary" size="small" style={{width: 90}}
                >Delete</Button>
            </Row>
        </div>
    );

    return (
        <Popover
            title="Confirm delete project"
            visible={visible}
            onVisibleChange={value => setVisible(value)}
            destroyTooltipOnHide={true}
            trigger={"click"}
            content={popoverContent}
        >
            {children}
        </Popover>
    );
}

function ProjectNameValidationForm({id, initialValue, desiredName, onFinish}) {
    const formLayout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const nameRules = [
        {
            validator: async (_, target) => {
                if (target === desiredName) return Promise.resolve();

                return Promise.reject(new Error("Name doesn't match"));
            }
        }
    ];

    return (
        <Form id={id}
              hideRequiredMark={true}
              onFinish={(values) => onFinish(values)}
              initialValues={initialValue}
              {...formLayout}
        >
            <Form.Item label="name"
                       name="name"
                       rules={nameRules}>
                <Input id="project-name-input" size="small"/>
            </Form.Item>
        </Form>
    );
}