import axios from "axios";
import {API_URL} from "../constants";
import {authHeader} from "../Utilities";
import {ObjectAttributeFilter, PageFieldFilter} from "../components/UserView/SearchFilters/SearchFilters";

class UserViewService {

    fetchSearchFiltersConfigs() {
        return axios.get(
            API_URL + "/user_view/search_filters_configs",
            {headers: authHeader()}
        );
    }

    fetchSearchFiltersSchemas(onLoad) {
        this.fetchSearchFiltersConfigs().then(response => {
            const searchFilters = response.data.flatMap(item => item.search_filters);

            const schemas = searchFilters
                .filter(searchFilter => ["page_field", "object_attr"].includes(searchFilter.target))
                .map(searchFilter => (
                    {
                        attribute: searchFilter.key,
                        allowedValues: [],
                        target: searchFilter.target,
                        constructor: (value, operation) => {
                            const key = searchFilter.key;

                            if (searchFilter.target === "page_field") {
                                return new PageFieldFilter(key, value, key, operation);
                            } else if (searchFilter.target === "object_attr") {
                                return new ObjectAttributeFilter(key, value, key, operation);
                            } else {
                                throw new Error(`Unknown target type ${searchFilter.target}`);
                            }
                        }
                    }
                ));

            onLoad(schemas);
        });
    }
}

export default new UserViewService();