import {DependencyList, useEffect} from "react";

type Subscription = {
    unsubscribe(): void;
}

export function useSubscription(subscriptionFactory: () => Subscription, deps: DependencyList) {
    useEffect(() => {
        const sub = subscriptionFactory();
        return () => sub.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}