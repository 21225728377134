import React from "react";
import AuthService from "./services/AuthService";
import {Route, Redirect} from "react-router-dom";
import { Table, Input, Button, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {fabric} from "fabric";
import WorkspaceViewModeService from "./services/WorkspaceViewModeService";
import ImageViewerObject from "./components/ImageViewerHandlers/ImageViewerObject";
import Rectangle from "./components/ImageViewerHandlers/Geometry/Rectangle";
import axios from "axios";



export function authHeader() {
  return AuthService.headers();
  // const user = AuthService.getCurrentUser();
  //
  // if (user && user.access_token) {
  //   return { Authorization: 'Bearer ' + user.access_token };
  // } else {
  //   return {};
  // }
}

export function sec2time(timeInSeconds) {
  const pad = (num, size) => {
    return ("0".repeat(size) + num).slice(size * -1);
  };

  if (timeInSeconds === Infinity)
    return "∞";

  const time = parseFloat(timeInSeconds).toFixed(3);
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor(time / 60) % 60;
  const seconds = Math.floor(time - minutes * 60);

  return pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);
}

export function download(blob, fileName) {
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

export function downloadFromUrl(url, fileName) {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = url;
  a.download = fileName;
  a.target = "_blank";
  a.click();
  window.URL.revokeObjectURL(url);
}

export const groupBy = (arr, key) => {
  return arr.reduce((dict, item) => {
    (dict[item[key]] = dict[item[key]] || []).push(item);
    return dict;
  }, {});
};

export function addTableTextFilters(comp) {
  if (!comp.state)
    comp.state = {};
  comp.setState({
    searchText: "",
    searchedColumn: "",
  });

  comp.getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
              ref={node => {
                comp.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => comp.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button onClick={() => comp.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
            <Button
                type="primary"
                onClick={() => comp.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
            >
              Search
            </Button>
          </Space>
        </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? "var(--color-turquoise)" : undefined }} />,
    onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : "",
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => comp.searchInput.select(), 100);
      }
    },
    render: text =>
        comp.state.searchedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: "var(--color-yellow)", padding: 0 }}
                searchWords={[comp.state.searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ""}
            />
        ) : (
            text
        ),
  });

  comp.handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    comp.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  comp.handleReset = clearFilters => {
    clearFilters();
    comp.setState({ searchText: "" });
  };
}

export function openInNewTab(url) {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
}


export function getFabricjsArrow(points, params={}) {
  const headLength = 20;
  function _createArrowHead(points) {
    var
        x1 = points[0],
        y1 = points[1],
        x2 = points[2],
        y2 = points[3],

        dx = x2 - x1,
        dy = y2 - y1,

        raw_angle = Math.atan2(dy, dx);

    let angle = raw_angle;
    angle *= 180 / Math.PI;
    angle += 90;

    var triangle = new fabric.Triangle({
      angle: angle,
      // fill: '#207cca',
      fill: "#f35151",
      top: y2,
      left: x2,
      height: headLength,
      width: headLength * 0.75,
      originX: "center",
      originY: "center",
      evented: false,
    });

    return [triangle];
  }

  const
    x1 = points[0],
    y1 = points[1],
    x2 = points[2],
    y2 = points[3],

    dx = x2 - x1,
    dy = y2 - y1;

  const raw_angle = Math.atan2(dy, dx);

  const targetX = x2 - headLength * 0.5 * Math.cos(raw_angle);
  const targetY = y2 - headLength * 0.5 * Math.sin(raw_angle);

  const adjustedPoints = [x1, y1, targetX, targetY];

  const lineParams = {
    strokeWidth: 5,
    stroke: "#7db9e8",
    originX: "center",
    originY: "center",
    hasControls: false,
    hasBorders: false,
    hasRotatingPoint: false,
    hoverCursor: "default",
    evented: false,
  };

  if (params.isDashed) {
    lineParams.strokeDashArray = [10, 10];
  }

  var line = new fabric.Line(adjustedPoints, lineParams);

  return [line, ..._createArrowHead(adjustedPoints)];
}

export function paginationConfigForImageViewerTables(data) {
  return { defaultPageSize: 10, total: data.length, showSizeChanger: data.length > 10 };
}
export function isGraphObject(obj) {
  return obj.objectMetadata?.shape?.shape_type === "graph";
}

export function isIntersecting(p1, p2, p3, p4) {
  function CCW(p1, p2, p3) {
    return (p3.y - p1.y) * (p2.x - p1.x) > (p2.y - p1.y) * (p3.x - p1.x);
  }
  return (CCW(p1, p3, p4) != CCW(p2, p3, p4)) && (CCW(p1, p2, p3) != CCW(p1, p2, p4));
}

export function symmetricDifference(arrA, arrB) {
  const setA = new Set(arrA);
  const setB = new Set(arrB);
  const _difference = new Set(setA);
  setB.forEach(elem => {
    if (_difference.has(elem)) {
      _difference.delete(elem);
    } else {
      _difference.add(elem);
    }
  });
  return [..._difference];
}

export function setDifference(arrA, arrB) {
  const setA = new Set(arrA);
  const setB = new Set(arrB);
  const _difference = new Set(setA);
  setB.forEach(elem => {
    if (_difference.has(elem)) {
      _difference.delete(elem);
    }
  });
  return [..._difference];
}

export function setIntersection(arrA, arrB) {
  const setA = new Set(arrA);
  const setB = new Set(arrB);
  const _intersection = new Set();
  setA.forEach(elem => {
    if (setB.has(elem)) {
      _intersection.add(elem);
    }
  });
  return [..._intersection];
}

export class AnimatedAuthUser {
  constructor(rawUser) {
    this._rawUser = rawUser ?? {
      roles: [],
      permissions: [],
      organization: {
        permissions: []
      }
    };
  }

  isAdmin() {
    return this._rawUser.roles.includes("admin");
  }

  canModifyCurrentWorkspaceReports() {
    if (!WorkspaceViewModeService.isInViewingMode()) return false;

    const currentWsId = WorkspaceViewModeService.getCurrentWorkspaceId();

    return this.canModifyWorkspaceReports(currentWsId);
  }

  canSearchForComments() {
    const allPermissions = [...this._rawUser.permissions, ...this._rawUser.organization.permissions];

    const commentsPermissions = allPermissions
        .filter(permission => permission.target_type === "workspace")
        .filter(permission => permission.permission_type === "comments");
    const inViewingMode = WorkspaceViewModeService.isInViewingMode();

    let permitted;
    if (this.isAdmin() && !inViewingMode) {
      permitted = commentsPermissions.length > 0;
    } else {
      const currentWsId = WorkspaceViewModeService.getCurrentWorkspaceId();
      const hasPermissionToCurrentWs = commentsPermissions.filter(
          permission => permission.target === currentWsId
      ).length > 0;

      permitted = inViewingMode && hasPermissionToCurrentWs;
    }

    return permitted;
  }

  canModifyWorkspaceReports(wsId) {
    if (this.isAdmin()) return true;

    const allPermissions = [...this._rawUser.permissions, ...this._rawUser.organization.permissions];

    const finalizePermissions = allPermissions
        .filter(permission => permission.target === wsId && permission.target_type === "workspace")
        .filter(permission => permission.permission_type === "finalize_results");

    return finalizePermissions.length > 0;
  }

  canSearchForHierarchies() {
    const allPermissions = [...this._rawUser.permissions, ...this._rawUser.organization.permissions];

    const hierarchiesPermissions = allPermissions
        .filter(permission => permission.target_type === "workspace")
        .filter(permission => permission.permission_type === "hierarchies");
    const inViewingMode = WorkspaceViewModeService.isInViewingMode();

    let permitted;
    if (this.isAdmin() && !inViewingMode) {
      permitted = hierarchiesPermissions.length > 0;
    } else {
      const currentWsId = WorkspaceViewModeService.getCurrentWorkspaceId();
      const hasPermissionToCurrentWs = hierarchiesPermissions.filter(
          permission => permission.target === currentWsId
      ).length > 0;

      permitted = inViewingMode && hasPermissionToCurrentWs;
    }

    return permitted;
  }
}

export class ColoredColumns {
  constructor(columns, color) {
    this._columns = columns;

    if (typeof color === "function") {
      this._color = color;
    } else {
      this._color = () => color;
    }
  }

  values() {
    return this._columns.map(col => {
      return {
        ...col,
        render: (text, record, idx) => {
          const render = col.render ?? function(){return text;};

          return {
            props: {
              style: { background: this._color(text, record, idx) },
            },
            children: render(text, record, idx)
          };
        }
      };
    });
  }
}


export function cache(fn){
  const NO_RESULT = {};
  let res = NO_RESULT;
  return async function () {
    if(res === NO_RESULT) return (res = fn.apply(this, arguments));
    return res;
  };
}


export function postprocessed(text) {
  return text
      .toLowerCase().replace(/[ -]/g, "");
}


export class PostprocessedHighlightWords {
  constructor(searchQuery) {
    searchQuery = postprocessed(searchQuery);

    const pattern = [];

    const escaped = (s) => {
      return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
    };

    for (let i = 0; i < searchQuery.length; i++) {
      const char = searchQuery.charAt(i);

      pattern.push(escaped(char));
    }

    this._regex = pattern.join("[- ]*");
  }

  values(text) {
    return Array.from(text.toLowerCase().matchAll(this._regex), x => x[0]);
  }
}

export function empty() {

}


export class RectangleFromBbox extends Rectangle {
  constructor(bbox) {
    super(bbox.x1, bbox.y1, bbox.x2, bbox.y2);
  }
}


export function bboxOf(canvasObj) {
  return {
    "x1": canvasObj.left,
    "y1": canvasObj.top,
    "x2": canvasObj.left + canvasObj.width * canvasObj.scaleX,
    "y2": canvasObj.top + canvasObj.height * canvasObj.scaleY,
  };
}


export class ImageViewerObjectFromCanvas extends ImageViewerObject {
  constructor(canvasObj) {
    let curObject = {
      "id": canvasObj.objectMetadata.id,
      "label": canvasObj.objectMetadata.label,
      "text": canvasObj.objectMetadata.text,
      "bbox": bboxOf(canvasObj),
      "metadata": canvasObj.objectMetadata.metadata,
    };

    if (canvasObj.objectMetadata.shape) {
      curObject.shape = canvasObj.objectMetadata.shape;
    }

    curObject.canvasObject = canvasObj;

    super(curObject);
  }
}


// export const ProtectedRoute = ({ component: Component, ...rest }) => (
//     <Route {...rest} render={(props) => (
//         AuthService.isAuthenticated() ?
//             <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location }}} />
//     )} />
// );


export class UpdateJobProgress {
  constructor(updateProgressTimeoutMSec=1000) {
    this._onUpdateProgress = empty;
    this._onError = taskResult => {
      throw new Error(taskResult.error);
    };
    this._checkIsCompleted = taskResult => taskResult.completed === true;
    this._checkIsFailed = taskResult => taskResult.error != null;
    this._updateProgressTimeoutMSec = updateProgressTimeoutMSec;
  }

  async do(url) {
    return new Promise((resolve, reject) => {
      const updateProgress = () => {
        axios.get(
            url, {headers: authHeader()}
        ).then(result => {
          const taskResult = result.data ?? {};
          if (this._checkIsCompleted(taskResult)) {
            resolve(taskResult);
          } else if (this._checkIsFailed(taskResult)) {
            this._onError(taskResult);
          } else {
            this._onUpdateProgress(taskResult);
            setTimeout(updateProgress, this._updateProgressTimeoutMSec);
          }
        }).catch(error => {
          reject(error);
        });
      };
      updateProgress();
    });
  }

  withUpdateProgress(onUpdateProgress) {
    this._onUpdateProgress = onUpdateProgress;
    return this;
  }

  withDefaultUpdateProgress(setProgressCallback) {
    return this.withUpdateProgress(taskResult => {
          const current = taskResult.progress?.current ?? 0;
          const total = taskResult.progress?.total ?? 0;
          setProgressCallback({
            status: taskResult.progress?.status ?? "",
            percent: Math.round(((current / total) ?? 0) * 100),
          });
        }
    );
  }
}
