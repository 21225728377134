import React from "react";
import {Input, Row} from "antd";
import { EditOutlined } from "@ant-design/icons";


export class EditableTextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newValue: "",
      isEditing: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.isEditing && prevProps !== this.props) {
      this.setState({newValue: this.props.value});
    }
  }

  handleAbortEditing = () => {
    this.setState({isEditing: false, newValue: this.props.value});
  };

  handleFinishEditing = () => {
    this.props.onUpdate(this.state.newValue);
    this.setState({isEditing: false});
  };

  handleKeyDown = (e) => {
    if (e.nativeEvent.key === "Escape") {
      this.handleAbortEditing();
    } else if (e.nativeEvent.key === "Enter") {
      this.handleFinishEditing();
    }
  };

  render() {
    return (
        <>
          {
            !this.state.isEditing &&
            <Row style={{justifyContent: "space-between"}}>
              <span>{this.props.value}</span>
              <a style={{visibility: this.props.editIconShown ? "initial" : "hidden"}}
                 onClick={() => this.setState({isEditing: true})}>
                <EditOutlined/>
              </a>

            </Row>
          }
          {
            this.state.isEditing &&
            <Row style={{flexFlow: "initial", margin: "-2px"}} className="editable-field">
              <Input
                  id="attribute-edit-text-input"
                  autoFocus={true}
                  value={this.state.newValue}
                  onKeyDown={this.handleKeyDown}
                  onBlur={this.handleFinishEditing}
                  onChange={(e) => this.setState({newValue: e.target.value})}
                  style={{flexGrow: 1, width: null, height: "100%"}}
              />
            </Row>
          }
        </>
    );
  }
}