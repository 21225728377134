class WildcardPattern {
    constructor(patternString) {
        this.patternString = patternString;
    }

    match(target) {
        return target.toString().match(this.patternString);
    }

    matchAll(target) {
        return [...target.toString().matchAll(this.patternString)].flat();
    }
}

class WildcardService {
    getPattern(s) {
        return new WildcardPattern(this._generatePatternString(s));
    }

    _generatePatternString(s) {
        const escapedSearchValue = this._escape(s.toString());
        const pattern = escapedSearchValue.replace(/\\\?/g, ".").replace(/\\\*/g, ".*");

        return pattern;
    }

    _escape(s) {
        return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
    }
}

export default new WildcardService();