import {useEffect, useState} from "react";
import {CommentMarkerSelection, NoneCommentsSelection, OnlyCommentSelection} from "../Selection/CommentsSelection";

export function SelectionContainer({selection, scroll, data, children}) {
    const [currentSelection, setCurrentSelection] = useState(new NoneCommentsSelection());
    const [selectTargets, setSelectTargets] = useState([]);
    const [lastScrolledIdx, setLastScrolledIdx] = useState();

    useEffect(() => {
        const selectedComments = data.filter(comment => selection.commentSelected(comment));

        const targets = selectedComments.flatMap(el => {
            if (!el.markers.some(marker => selection.markerSelected(el, marker))) {
                return [new OnlyCommentSelection(el.id, selection.needScroll())];
            }

            return el.markers
                .filter(marker => selection.markerSelected(el, marker))
                .map(marker => new CommentMarkerSelection(el.id, marker.id, selection.needScroll()));
        });

        setSelectTargets(targets);
        setCurrentSelection(targets[0] ?? new NoneCommentsSelection());
    }, [selection, data]);

    useEffect(() => {
        if (currentSelection.needScroll()) {
            setLastScrolledIdx(last => {
                const idx = data.findIndex(el => currentSelection.commentSelected(el));

                if (idx > -1 && idx !== last) {
                    setTimeout(() => {scroll.do(idx);}, 300);
                }

                return idx;
            });
        }
    }, [scroll, currentSelection, data]);

    return children({
        commentsSelection: currentSelection,
        currentSelectionIndex: selectTargets.indexOf(currentSelection),
        totalSelectionTargets: selectTargets.length,
        onSelectTargetIndexChange: (idx) => setCurrentSelection(selectTargets[idx] ?? new NoneCommentsSelection())
    });
}
