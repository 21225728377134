import React, {useCallback, useContext, useEffect, useState} from "react";
import {Spin, Tabs, message} from "antd";
import {API_URL} from "../../constants";
import {SearchContextConsumer} from "../../contexts/SearchContext";
import {useLocation, withRouter} from "react-router";
import SearchComponent from "./SearchComponent";
import {QueryFromFilters} from "./SearchFilters/QueryFromFilters";
import {QueryWithTab, TabFromQuery} from "../Misc/Query";
import {SearchFiltersFromUrl} from "./SearchFilters/SearchFiltersFromUrl";
import {Conditional} from "../Misc/Conditional";
import {HierarchiesSearch} from "./HierarchiesGlobalSearch";
import {AuthContext} from "../../contexts/AuthContext";
import {AnimatedAuthUser, authHeader} from "../../Utilities";
import * as queryString from "query-string";
import axios from "axios";
import WorkspaceViewModeService from "../../services/WorkspaceViewModeService";


function SearchTabs({location, history, drawings, hierarchies}) {
    const [activeKey, setActiveKey] = useState("unknown");

    useEffect(() => {
        setActiveKey(new TabFromQuery(location.search).getValue());
    }, [location]);

    const onChangeTab = useCallback((key) => {
        const filters = new SearchFiltersFromUrl(location.search).getArray();

        history.push({
            pathname: location.pathname,
            search: new QueryWithTab(new QueryFromFilters(filters).toString(), key).toString()
        });
    }, [history, location]);

    return (
        <Tabs activeKey={activeKey} onChange={onChangeTab}>
            <Tabs.TabPane tab="Drawings" key="drawings">
                <Conditional
                    cond={() => activeKey === "drawings"}
                    render={() => drawings}
                />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Hierarchies" key="hierarchies">
                <Conditional
                    cond={() => activeKey === "hierarchies"}
                    render={() => hierarchies}
                />
            </Tabs.TabPane>
        </Tabs>
    );
}


function SyncWorkspace({children}) {
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        const url = location.search;
        const query = queryString.parse(decodeURI(url));
        const wsName = query["workspace"];
        if (wsName == null) {
            setLoading(false);
        } else {
            axios.get(
                API_URL + "/workspaces/by_name",
                {headers: authHeader(), params: {name: wsName}}
            ).then(response => {
                WorkspaceViewModeService.enterWorkspace(response.data.id);
            }).catch(_ => {
                message.error("Failed to load workspace");
            }).finally(_ => {
                setLoading(false);
            });
        }
    }, []);

    if (loading) {
        return (
            <Spin
                spinning={true}
                size="large"
                style={{opacity: 1, position: "absolute", zIndex: 100, left: "50vw", top: "30vh"}}
            />
        );
    } else {
        return children;
    }
}


function UserViewSearchInner({context, location, history}) {
    const authContext = useContext(AuthContext);
    const user = new AnimatedAuthUser(authContext.user);

    const onSearch = useCallback((value, page) => context.setQuery(value), [context]);

    const drawings = (
        <SearchComponent
            fetchOptionsUrl={API_URL + "/user_view/search_results/possible_search_option_values"}
            url={API_URL + "/user_view/search_results"}
            onSearch={onSearch}
        />
    );

    if (user.canSearchForHierarchies()) {
        const hierarchies = (
            <HierarchiesSearch
                context={context}
                location={location}
                history={history}
                onSearch={onSearch}
            />
        );

        return (
            <SearchTabs
                location={location}
                history={history}
                drawings={drawings}
                hierarchies={hierarchies}
            />
        );
    } else {
        return drawings;
    }
}

const UserViewSearchInnerWithRouter = withRouter(UserViewSearchInner);

function UserViewSearch() {
  return (
      <SearchContextConsumer>
          {
            context => (
                <SyncWorkspace>
                    <UserViewSearchInnerWithRouter context={context}/>
                </SyncWorkspace>
            )
          }
      </SearchContextConsumer>
  );
}

export default UserViewSearch;
