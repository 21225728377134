import {AutoSizer} from "react-virtualized";
import React, {useEffect, useRef} from "react";
import {fabric} from "fabric";

export const ResponsiveCanvas = ({id, onReady}) => {
    const canvasEl = useRef(null);
    const canvas = useRef(null);

    useEffect(() => {
        if (canvas.current == null) {
            canvas.current = new fabric.Canvas(canvasEl.current);
        } else {
            canvas.current.clear();
        }
        onReady?.(canvas.current);
    }, [onReady]);

    return (
        <>
            <AutoSizer children={({width, height}) => {
                canvas.current?.setWidth(width);
                canvas.current?.setHeight(height);
                canvas.current?.renderAll();
            }}/>
            <div style={{overflow: "visible", height: "0px", width: "0px"}}>
                <canvas id={id} ref={canvasEl}/>
            </div>
        </>
    );
};
