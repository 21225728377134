import {fabric} from "fabric";

export class ColorHighlighter {
  constructor(color) {
    this.color = color;
  }

  getCanvasObjects(obj) {
    const defaultArgs = {
      lockScalingFlip: true,
      fill: "rgba(0,0,0,0)",
      cornerColor: "lime",
      cornerStrokeColor: "gray",
      cornerStyle: "circle",
      transparentCorners: false,
      cornerSize: 10,
      noScaleCache: false,
      strokeUniform: true,
      objectCaching: false,
    };

    const canvasObjects = [
      new fabric.Rect({
        ...defaultArgs,
        fill: this.color,
        top: obj.bbox.y1,
        left: obj.bbox.x1,
        width: obj.getRect().getWidth(),
        height: obj.getRect().getHeight(),
        evented: false,
      })
    ];

    return canvasObjects;
  }
}

export class HighlightedObject {
  constructor(obj, highlighter) {
    this.obj = obj;
    this.highlighter = highlighter;
  }

  getHighlightedObjects() {
    return this.highlighter.getCanvasObjects(this.obj);
  }
}
