import {DocumentRegistryItem} from "../../../services/DocumentRegistry/DocumentRegistry";

export interface DocumentRegistryItemInspector {
    inspect(item: DocumentRegistryItem): void;
}

export class DocumentRegistryItemInspectors {
    private readonly inspectors: DocumentRegistryItemInspector[] = [];

    inspect(item: DocumentRegistryItem) {
        this.inspectors.forEach(x => x.inspect(item));
    }

    add(inspector: DocumentRegistryItemInspector) {
        this.inspectors.push(inspector);
    }

    remove(inspector: DocumentRegistryItemInspector) {
        const index = this.inspectors.indexOf(inspector);
        if (index > -1) {
            this.inspectors.splice(index, 1);
        }
    }
}
