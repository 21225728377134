import {Attributes} from "../Attributes";

export class AnnotationObjectAttributes extends Attributes {
    constructor(annotationObj) {
        super();

        this._data = annotationObj;
    }

    keys() {
        const keys = ["Class", "Text", "Description"];
        return keys.concat(this._data.metadata.attributes.map(attr => attr.key));
    }

    value(key) {
        let value;

        if (key === "Class") {
            value = this._data.label;
        } else if (key === "Text") {
            value = this._data.text;
        } else if (key === "Description") {
            value = this._data.metadata.description;
        } else {
            value = this._data.metadata.attributes.find(attr => attr.key === key).value;
        }

        if (value == null) {
            throw new Error("Key not found");
        }

        return value;
    }
}