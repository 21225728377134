import {PluginAnonymous, PluginSourceSequence} from "../../../services/Plugins";
import {DocumentRegistryViewRef} from "../DocumentRegistryView";
import {
    DocumentRegistryItemInspector,
    DocumentRegistryItemInspectors
} from "../Resources/DocumentRegistryItemInspectors";
import {DocumentRegistryItem} from "../../../services/DocumentRegistry/DocumentRegistry";
import {Resource} from "../../../services/Resources";
import {useEffect, useState} from "react";

class DocAssociationsContent {
    private readonly id: string;

    constructor(id: string) {
        this.id = id;
    }

    toString() {
        return `DOC to DOC SPECIFIC ASSOCIATIONS of ${this.id}`;
    }
}

export class DocAssociations implements DocumentRegistryItemInspector {
    private readonly content: Resource<DocAssociationsContent>;

    constructor(content: Resource<DocAssociationsContent>) {
        this.content = content;
    }

    inspect(item: DocumentRegistryItem): void {
        this.content.set(new DocAssociationsContent(item.id()));
    }
}

export class PluginDocAssociations extends PluginSourceSequence<DocumentRegistryViewRef> {
    constructor() {
        super([
            new PluginAnonymous("doc-associations-ref", {
                async build(target: DocumentRegistryViewRef): Promise<void> {
                    const associations = new DocAssociations(target.res(DocAssociationsContent));
                    const inspectors = target.res(DocumentRegistryItemInspectors).get();
                    inspectors.add(associations);
                }
            }),
            new PluginDocAssociationsView(),
        ]);
    }
}

class PluginDocAssociationsView extends PluginAnonymous<DocumentRegistryViewRef> {
    constructor() {
        super("doc-associations-view", {
            async build(target: DocumentRegistryViewRef): Promise<void> {
                target.addDock("right-bl", {
                    children: (
                        <DocAssociationsView
                            content={target.res(DocAssociationsContent)}
                        />
                    ),
                    key: "DOC to DOC SPECIFIC ASSOCIATIONS",
                    name: "Associations",
                });
            }
        });
    }
}

type DocAssociationsViewProps = {
    content: Resource<DocAssociationsContent>,
}

function DocAssociationsView({content}: DocAssociationsViewProps) {
    const [data, setData] = useState("Nothing to show");

    useEffect(() => {
        const sub = content.effect(c => setData(c.toString()));
        return () => sub.unsubscribe();
    }, [content]);

    return (
        <>
            {data}
        </>
    );
}
