import React from "react";
import {fetchProjects} from "../actions/actions";
import {Button, message, Popconfirm, Progress, Row, Col, Table, Tabs, Typography, Modal, Input, Form} from "antd";
import {Link} from "react-router-dom";
import axios from "axios";
import {API_URL} from "../constants";
import {authHeader} from "../Utilities";

export class Samples extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      samplesList: [],
      objectDetectionSamplesList: [],
      startTrainingModalVisible: false,
      numberOfEpochs: 10,
    };
  }

  loadSamples = (projectId) => {
    axios.get(
        API_URL + `/projects/${projectId}/samples`,
        {headers: authHeader()}
    ).then(result => {
      const samplesList = result.data.samples;
      this.setState({
        samplesList: samplesList,
      });
    });
  };

  loadProject = (projectId) => {
    axios.get(
        API_URL + `/projects/${projectId}`,
        {headers: authHeader()}
    ).then(result => {
      const project = result.data.project;
      this.setState({
        project: project,
      });
    });
  };


  loadObjectDetectionSamples = (projectId) => {
    axios.get(
        API_URL + `/projects/${projectId}/object_detection_samples`,
        {headers: authHeader()}
    ).then(result => {
      const objectDetectionSamplesList = result.data.object_detection_samples;
      this.setState({
        objectDetectionSamplesList: objectDetectionSamplesList,
      });
    });
  };

  componentDidMount() {
    this.loadSamples(this.props.match.params.projectId);
    this.loadProject(this.props.match.params.projectId);
    this.loadObjectDetectionSamples(this.props.match.params.projectId);
  }

  handleSampleRemoval = (projectId, sampleId) => {
    axios.delete(API_URL + `/projects/${projectId}/samples/${sampleId}`, {headers: authHeader()}).then(result => {
      this.loadSamples(projectId);
    }).catch(err => {
      message.error("Error removing sample");
    });
  };

  handleObjectDetectionSampleRemoval = (projectId, sampleId) => {
    axios.delete(API_URL + `/projects/${projectId}/object_detection_samples/${sampleId}`, {headers: authHeader()}).then(result => {
      this.loadSamples(projectId);
    }).catch(err => {
      message.error("Error removing object detection sample");
    });
  };

  handleStartTraining = (projectId, epochs) => {
    axios.post(API_URL + `/projects/${projectId}/train_object_detection_model`, {
      epochs: epochs,
    }, {headers: authHeader()}).then(result => {
      message.success("Training scheduled");
    }).catch(err => {
      if (err.response.status === 422 && err.response.data.message) {
        message.error(err.response.data.message);
      } else {
        message.error("Error starting training");
      }
    });
  };


  handleStartTrainingOk = () => {
    this.handleStartTraining(this.props.match.params.projectId, parseInt(this.state.numberOfEpochs));
    this.setState({startTrainingModalVisible: false});
  };

  handleStartTrainingCancel = () => {
    this.setState({startTrainingModalVisible: false});
  };

  showModal = () => {
    this.setState({startTrainingModalVisible: true});
  };

  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        // key: 'name',
      },
      {
        title: "Actions",
        dataIndex: "id",
        render: (sampleId) => {
          return (
              <>
                <Row style={{justifyContent: "space-between"}}>
                  {/*<a href="#">Edit</a>*/}
                  <Link to={`/project/${this.props.match.params.projectId}/samples/view_sample/${sampleId}`}>
                    Edit
                  </Link>

                  <Popconfirm title="Are you sure to delete the sample？" onConfirm={() => this.handleSampleRemoval(this.props.match.params.projectId, sampleId)}>
                    <a href="#">Delete</a>
                  </Popconfirm>
                </Row>

              </>
          );
        }
      }
    ];

    const objectDetectionSamplesColumns = [
      {
        title: "Name",
        dataIndex: "name",
        // key: 'name',
      },
      {
        title: "Actions",
        dataIndex: "id",
        render: (sampleId) => {
          return (
              <>
                <Row style={{justifyContent: "space-between"}}>
                  {/*<a href="#">Edit</a>*/}
                  <Link to={`/project/${this.props.match.params.projectId}/object_detection_samples/view_sample/${sampleId}`}>
                    Edit
                  </Link>

                  <Popconfirm title="Are you sure to delete the object detection sample？" onConfirm={() => this.handleObjectDetectionSampleRemoval(this.props.match.params.projectId, sampleId)}>
                    <a href="#">Delete</a>
                  </Popconfirm>
                </Row>

              </>
          );
        }
      }
    ];

    const training_progress = this.state?.project?.project_data?.object_detection_training_progress;

    return (
        <>
          <Row style={{justifyContent: "space-between", padding: "1em"}}>
            <Typography.Title level={4}>Samples</Typography.Title>
          </Row>
          <Tabs defaultActiveKey="object_detection">
            <Tabs.TabPane tab="Fields" key="fields_extraction">
              <Table dataSource={this.state.samplesList} columns={columns}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Symbols" key="object_detection">
              <Modal
                  title="Start training"
                  visible={this.state.startTrainingModalVisible}
                  onOk={() => this.handleStartTrainingOk()}
                  onCancel={this.handleStartTrainingCancel}
                  width="30vw"
                  bodyStyle={{height: "65vh"}}
              >
                <Form.Item label="Epochs">
                  <Input value={this.state.numberOfEpochs} onChange={(e) => this.setState({numberOfEpochs: e.target.value})}/>
                </Form.Item>
              </Modal>
              <Row style={{margin: "16px"}}>
                <Col span={4}>
                  <Button onClick={() => this.showModal()}>Train</Button>
                </Col>
                <Col span={8}>
                  {
                    training_progress ? <Progress percent={training_progress} /> : null
                  }
                </Col>
              </Row>
              <Table dataSource={this.state.objectDetectionSamplesList} columns={objectDetectionSamplesColumns}/>
            </Tabs.TabPane>
          </Tabs>
        </>
    );
  }

}