import {Button, Card, Divider, Input, message, Row, Select, Space, Spin, Tag} from "antd";
import React, {useCallback, useEffect, useState} from "react";

import TextArea from "antd/lib/input/TextArea";
import * as Static from "../Static/StaticComment";
import {EditMarkerContainer} from "../../Containers/EditMarkerContainer";
import {TrimmedTag} from "../../../../Misc/TrimmedTag";


function AddNewMarker({newMarkers, onCreate, creationForbidden}) {
    const [newMarker, setNewMarker] = useState(null);

    const resetNewMarker = () => {
        setNewMarker(old => {
            if (old != null) {
                old.unsubscribe();
            }

            return null;
        });
    };

    useEffect(() => {
        return () => {
            setNewMarker(old => {
                if (old != null) {
                    old.unsubscribe();
                    old.abort();
                }

                return null;
            });
        };
    }, []);

    const handleCreate = useCallback(
        created => {
            onCreate({
                ...created,
            });
            resetNewMarker();
        }, [onCreate]
    );

    const handleAbort = _ => {
        newMarker.abort();
        resetNewMarker();
    };

    const trigger = (
        <Button
            disabled={creationForbidden}
            size="small"
            onClick={e => {
                setNewMarker(
                    newMarkers.create(handleCreate, () => setNewMarker(null))
                );
            }}
        >
            Add reference
        </Button>
    );

    const abort = (
        <Button
            size="small"
            onClick={handleAbort}
        >
            Cancel
        </Button>
    );

    return newMarker != null ? abort : trigger;
}


export function CommentMessage({value, onChange}) {
    const [currentValue, setValue] = useState(value);

    const editApprove = () => {
        onChange(currentValue);
    };

    return (
        <TextArea
            rows={1}
            value={currentValue}
            onChange={e => setValue(e.target.value)}
            onBlur={editApprove}
            onPressEnter={editApprove}
        />
    );
}


function CommentMarker({marker, onDelete, onMarkerTextUpdate}) {
    const [editing, setEditing] = useState(false);
    const [editedText, setEditedText] = useState(marker.text.toString());

    const editApprove = () => {
        setEditing(false);
        onMarkerTextUpdate(editedText);
    };

    const input = (
        <Input
            style={{
                width: "78px",
                marginRight: "8px",
                verticalAlign: "top"
            }}
            autoFocus={true}
            type={"text"}
            size={"small"}
            value={editedText}
            onChange={e => setEditedText(e.target.value)}
            onBlur={editApprove}
            onPressEnter={editApprove}
        />
    );

    const staticText = (
        <TrimmedTag closable onClose={e => onDelete(marker)} text={editedText || "(point)"}>
            {text => (
                <span onDoubleClick={e => setEditing(true)}>
                    {text}
                </span>
            )}
        </TrimmedTag>
    );

    return (
        editing ? input : staticText
    );
}


function AssignTo({comment, assignTargets, onSelect}) {
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(assignTargets.defaultCommentOptionValue(comment));
    const [debounceId, setDebounceId] = useState();

    useEffect(() => {
        assignTargets.values("")
            .then(targets => {
                setOptions(targets.map(t => t.asCommentSelectOption()));
            })
            .catch(e => message.error("Failed to load options"));
    }, [assignTargets]);

    const clearSelected = () => {
        onSelect([]);
        setSelected({
            key: null,
            label: null,
            value: null
        });
    };

    const handleSelect = async (opt) => {
        onSelect([JSON.parse(opt.value)]);
        setSelected(opt);
    };

    const handleSearch = (text) => {
        if (debounceId) {
            clearTimeout(debounceId);
        }
        const nextDebounceID = setTimeout(() => {
            assignTargets.values(text)
                .then(targets => {setOptions(targets.map(t => t.asCommentSelectOption()));})
                .catch(e => message.error("Failed to load options"));
        }, 200);
        setDebounceId(nextDebounceID);
    };

    return (
        <Row justify={"space-between"}>
            <Select
                labelInValue
                id={"assign-targets-select"}
                size={"small"}
                placeholder={"Assign to"}
                value={selected}
                options={options}
                notFoundContent={"no options"}
                style={{ width: "80%" }}
                onSelect={handleSelect}
                onSearch={handleSearch}
                filterOption={false}
                showSearch
            />
            <a onClick={e => clearSelected()}>Clear</a>
        </Row>
    );
}


export function Comment(
    {comment, newMarkers, onApply, onCancel, onAddNewMarker, onDeleteMarker, onEditMessage, onUpdateMarker,
        markerCreationForbidden, assignTargets, onAssignTarget, id}
) {
    return (
        <Card id={id}>
            <Static.CommentHeader
                date={comment.date}
                username={comment.author.name}
            />
            <CommentMessage value={comment.message} onChange={onEditMessage}/>
            <Divider style={{marginBottom: "8px", marginTop: "8px"}}/>
            {comment.markers.map(marker => (
                <EditMarkerContainer
                    key={marker.id}
                    marker={marker}
                    onUpdate={onUpdateMarker}
                >
                    {props => (
                        <CommentMarker
                            key={marker.id}
                            marker={props.marker}
                            onDelete={onDeleteMarker}
                            onMarkerTextUpdate={props.onMarkerTextUpdate}
                        />
                    )}
                </EditMarkerContainer>
            ))}
            <AddNewMarker
                newMarkers={newMarkers}
                onCreate={onAddNewMarker}
                creationForbidden={markerCreationForbidden}
            />
            <Divider style={{marginBottom: "8px", marginTop: "8px"}}/>
            <AssignTo comment={comment} assignTargets={assignTargets} onSelect={onAssignTarget}/>
            <Divider style={{marginBottom: "8px", marginTop: "8px"}}/>
            <Row justify={"start"}>
                <Space>
                    <Button onClick={onApply} size={"small"} type={"primary"}>
                        Apply
                    </Button>
                    <Button onClick={onCancel} size={"small"}>
                        Cancel
                    </Button>
                </Space>
            </Row>
        </Card>
    );
}