import {useCallback, useEffect, useState} from "react";
import {message} from "antd";


function createdAtComparer(a, b) {
    return Date.parse(b.date) - Date.parse(a.date);
}


export function InMemoryCommentsContainer(
    {dataSource, children, onDeleteComment, onEditComment, onAddComment}
) {
    const [data, setData] = useState(dataSource);

    useEffect(() => {
        setData(dataSource.sort(createdAtComparer));
    }, [dataSource]);

    const updateCommentInternal = useCallback(comment => {
        setData(old => old.map(el => el.id === comment.id ? comment : el));
    }, []);

    const approveEdit = useCallback((initial, edited) => {
        onEditComment(
            initial,
            edited,
            (approved) => {
                updateCommentInternal(approved);
                message.success("Comment updated");
            },
            () => {
                updateCommentInternal(initial);
                message.error("Failed to update comment");
            }
        );
    }, [updateCommentInternal, onEditComment]);

    const deleteComment = useCallback(comment => {
        onDeleteComment(
            comment,
            () => {
                setData(old => old.filter(el => el.id !== comment.id));
                message.success("Comment deleted");
            },
            () => {
                message.error("Failed to delete comment");
            }
        );
    }, [onDeleteComment]);

    const addComment = useCallback(comment => {
        onAddComment(
            comment,
            (added) => {
                setData(old => [added, ...old].sort(createdAtComparer));
                message.success("Comment added");
            },
            () => {
                message.error("Failed to add comment");
            }
        );
    }, [onAddComment]);

    return children({
        data: data,
        onAddComment: addComment,
        onDeleteComment: deleteComment,
        onEditCommentApprove: approveEdit,
        onEditCommentCancel: updateCommentInternal,
        onEditCommentUpdate: updateCommentInternal
    });
}