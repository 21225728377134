import { combineReducers } from "redux";
import {
  REQUEST_PROJECTS,
  RECEIVE_PROJECTS,
  REQUEST_DOCUMENTS_LIST,
  RECEIVE_DOCUMENTS_LIST,
  REQUEST_STATISTICS,
  RECEIVE_STATISTICS,
  SCHEDULE_DOCUMENTS_PROCESSING,
  DOCUMENTS_PROCESSING_SCHEDULED,
  REQUEST_RUNS, RECEIVE_RUNS,
  REQUEST_RUN_RESULTS,
  RECEIVE_RUN_RESULTS,
  REQUEST_DOCUMENT_ANNOTATION,
  RECEIVE_DOCUMENT_ANNOTATION,
} from "../actions/actions";
import { connectRouter } from "connected-react-router";
import { uploadedFile } from "./uploadFiles";
import { folderContent } from "./fileExplorer";

function projects(
  state = {
    isFetching: false,
    items: []
  },
  action
) {
  switch (action.type) {
    case REQUEST_PROJECTS:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_PROJECTS:
      return {
        ...state,
        isFetching: false,
        items: action.projects,
      };
    default:
      return state;
  }
}

function documentsList(
  state = {
    isFetching: false,
    items: []
  },
  action
) {
  switch (action.type) {
    case REQUEST_DOCUMENTS_LIST:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_DOCUMENTS_LIST:
      return {
        ...state,
        isFetching: false,
        items: action.documents,
      };
    default:
      return state;
  }
}

function statistics(
  state = {
    isFetching: false,
    summary: {},
    fileTypesStatistics: []
  },
  action
) {
  switch (action.type) {
    case REQUEST_STATISTICS:
      return {
        ...state,
        isFetching: true,
        summary: {},
        fileTypesStatistics: []
      };
    case RECEIVE_STATISTICS:
      return {
        ...state,
        isFetching: false,
        summary: action.summary,
        fileTypesStatistics: action.fileTypesStatistics
      };
    default:
      return state;
  }
}

function documentsProcessing(state = { isScheduling: false, payload: null }, action) {
  switch (action.type) {
    case SCHEDULE_DOCUMENTS_PROCESSING:
      return { ...state, isScheduling: true, payload: null };
    case DOCUMENTS_PROCESSING_SCHEDULED:
      return { ...state, isScheduling: false, payload: action.payload };
    default:
      return state;
  }
}

function runsList(
  state = {
    isFetching: false,
    payload: {}
  },
  action
) {
  switch (action.type) {
    case REQUEST_RUNS:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_RUNS:
      return {
        ...state,
        isFetching: false,
        payload: action.payload.data,
      };
    default:
      return state;
  }
}

function runResultsList(
  state = {
    isFetching: false,
    payload: {}
  },
  action
) {
  switch (action.type) {
    case REQUEST_RUN_RESULTS:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_RUN_RESULTS:
      return {
        ...state,
        isFetching: false,
        payload: action.payload.data,
      };
    default:
      return state;
  }
}

function documentAnnotation(
  state = {
    isFetching: false,
    payload: {}
  },
  action
) {
  switch (action.type) {
    case REQUEST_DOCUMENT_ANNOTATION:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_DOCUMENT_ANNOTATION:
      return {
        ...state,
        isFetching: false,
        payload: action.payload.data,
      };
    default:
      return state;
  }
}

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  projects,
  documents: documentsList,
  statistics,
  documentsProcessing,
  runsList,
  runResultsList,
  documentAnnotation,
  uploadedFile,
  folderContent,
});

export default createRootReducer;
