import {PluginAnonymous, PluginSourceSequence} from "../../../services/Plugins";
import {DocumentRegistryViewRef} from "../DocumentRegistryView";
import {Button, Row, Select, Spin, Tree} from "antd";
import React, {Dispatch, Key, ReactNode, SetStateAction, useEffect, useState} from "react";
import {ImmortalAutoSizer} from "../../Misc/ImmortalAutoSizer";
import {CurrentRegistry, useCurrentRegistry} from "../Resources/CurrentRegistry";
import {
    DocumentRegistry,
    ReadonlyDocumentRegistryColumns,
    useRegistryColumns
} from "../../../services/DocumentRegistry/DocumentRegistry";
import {ColumnFilter, GridFilter, RegistryFilters, useRegistryFilters} from "../Resources/RegistryFilters";
import {ReadonlyResource} from "../../../services/Resources";
import {DragSortingTable} from "../../Misc/DragSortingTable";
import {FitParentSize} from "../../Misc/FitParentSize";
import SplitPane from "react-split-pane";
import {DeleteOutlined} from "@ant-design/icons";
import {useAbortControllers} from "../../../services/useAbortControllers";
import {Dep} from "../../../services/useDep";

export class PluginPivotTable extends PluginSourceSequence<DocumentRegistryViewRef> {
    constructor() {
        super([
            new PluginPivotTableTree(),
        ]);
    }
}

class PluginPivotTableTree extends PluginAnonymous<DocumentRegistryViewRef> {
    constructor() {
        super("pivot-table-tree", {
            async build(target: DocumentRegistryViewRef): Promise<void> {
                const registry = target.res(CurrentRegistry);
                const filters = target.res(RegistryFilters);
                target.addDock("left-ur", {
                    children: (
                        <PivotTableDock
                            filters={filters}
                            registry={registry}
                        />
                    ),
                    key: "DOCUMENT CLASSIFICATIONS",
                    name: "Classifications",
                });
            }
        });
    }
}

type PivotTableDockProps = {
    filters: ReadonlyResource<RegistryFilters>
    registry: ReadonlyResource<CurrentRegistry>
}

function PivotTableDock({filters, registry: registryRes}: PivotTableDockProps) {
    const registry = useCurrentRegistry(registryRes);
    const registryColumns = useRegistryColumns(registry);
    const [pivotColumns, setPivotColumns] = useState<string[]>([]);

    useEffect(() => {
        setPivotColumns(pivotColumns => pivotColumns.filter(x => registryColumns.includes(x)));
    }, [registryColumns]);

    return (
        <PivotTableDockLayout
            top={(
                <PivotColumns
                    pivotColumns={pivotColumns}
                    registryColumns={registryColumns}
                    setPivotColumns={setPivotColumns}
                />
            )}
            bottom={(
                <PivotTableTree
                    filters={filters}
                    pivotColumns={pivotColumns}
                    registry={registry}
                />
            )}
        />
    );
}

type PivotTableDockLayoutProps = {
    top: ReactNode,
    bottom: ReactNode,
}

function PivotTableDockLayout({top, bottom}: PivotTableDockLayoutProps) {
    return (
        <ImmortalAutoSizer>
            {({width, height}) => (
                <Row style={{width: width + "px", height: height + "px"}}>
                    <SplitPane
                        style={{position: "relative"}}
                        split="horizontal"
                        primary={"first"}

                        maxSize={height * 0.75}
                        minSize={height * 0.25}
                        defaultSize={height * 0.25}

                        resizerStyle={{
                            height: "8px",
                            width: "100%",
                            transform: "translate(0%, 0%)"
                        }}
                    >
                        <FitParentSize>
                            {top}
                        </FitParentSize>
                        <FitParentSize>
                            <div style={{position: "absolute", width: "100%", background: "#f0f2f5", height: "8px"}}/>
                            <div style={{height: "100%", width: "100%", paddingTop: "8px"}}>
                                {bottom}
                            </div>
                        </FitParentSize>
                    </SplitPane>
                </Row>
            )}
        </ImmortalAutoSizer>
    );
}

type PivotColumnsProps = {
    pivotColumns: string[],
    setPivotColumns: Dispatch<SetStateAction<string[]>>,
    registryColumns: Dep<ReadonlyDocumentRegistryColumns>,
}

function PivotColumns({pivotColumns, registryColumns, setPivotColumns}: PivotColumnsProps) {
    const [value, setValue] = useState<string | undefined>();
    return (
        <FitParentSize>
            <Select
                placeholder={"Add Column"}
                value={value}
                onChange={setValue}
                onSelect={v => {
                    setPivotColumns(prev => [...prev, v]);
                    setValue("");
                }}
                showSearch={true}
                size={"small"}
                style={{width: "100%"}}
                options={registryColumns.asList().filter(x => !pivotColumns.includes(x)).map(x => ({
                    label: x,
                    value: x,
                }))}
            />
            <PivotColumnsTable pivotColumns={pivotColumns} setPivotColumns={setPivotColumns}/>
        </FitParentSize>
    );
}

type PivotColumnsTableProps = {
    pivotColumns: string[],
    setPivotColumns: Dispatch<SetStateAction<string[]>>,
}

function PivotColumnsTable({pivotColumns, setPivotColumns}: PivotColumnsTableProps) {
    const [hoveredRowIndex, setHoveredRowIndex] = useState<number | undefined>();
    const deleteColumn = (column: string) => {
        setPivotColumns(prev => prev.filter(x => x !== column));
    };
    return (
        <DragSortingTable
            style={{width: "100%"}}
            size={"small"}
            pagination={false}
            columns={[
                {
                    title: "Column",
                    render: (_: any, value: any, index: number) => {
                        const deleteThisColumn = () => deleteColumn(value);
                        return (
                            <Row justify={"space-between"} style={{minHeight: "24px"}}>
                                {value}
                                {hoveredRowIndex === index && (
                                    <Button
                                        size={"small"}
                                        type={"link"}
                                        icon={<DeleteOutlined/>}
                                        onClick={deleteThisColumn}
                                    />
                                )}
                            </Row>
                        );
                    }
                }
            ]}
            dataSource={pivotColumns}
            onRowsMoved={(sorted: string[]) => setPivotColumns(sorted)}
            showHeader={false}
            onRowMouseEnter$={setHoveredRowIndex}
            onRowMouseLeave$={() => setHoveredRowIndex(undefined)}
        />
    );
}

interface DataNode {
    title: string;
    key: string;
    value: string;
    path: string[],
    isLeaf?: boolean;
    children?: DataNode[];
    addFiltersTo: (filters: GridFilter[]) => void,
}

const updateTreeData = (list: DataNode[], key: Key, children: DataNode[]): DataNode[] => (
    list.map(node => {
        if (node.key === key) {
            return {
                ...node,
                children,
            };
        }
        if (node.children) {
            return {
                ...node,
                children: updateTreeData(node.children, key, children),
            };
        }
        return node;
    })
);

type PivotTableTreeProps = {
    filters: ReadonlyResource<RegistryFilters>
    registry: DocumentRegistry,
    pivotColumns: string[]
}

function PivotTableTree({filters: filtersRes, registry, pivotColumns}: PivotTableTreeProps) {
    const abortControllers = useAbortControllers([pivotColumns]);
    const filters = useRegistryFilters(filtersRes);
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
    const [initializing, setInitializing] = useState(false);
    const [treeData, setTreeData] = useState<DataNode[]>([]);

    useEffect(() => {
        setExpandedKeys([]);
        setInitializing(true);
        registry.pivotTable(
            pivotColumns,
            [],
            undefined,
            undefined,
            abortControllers.useSignal()
        ).then(data => {
            if (!data.isCompleted()) return;
            setInitializing(false);
            setTreeData(data.val().map(x => ({
                key: x.value,
                title: x.value || "<empty>",
                value: x.value,
                path: [],
                isLeaf: pivotColumns.length <= 1,
                addFiltersTo: filters => filters.push(new ColumnFilter(pivotColumns[0], x.value, pivotColumns[0])),
            })));
        });
    }, [abortControllers, registry, pivotColumns]);

    const loadData = ({key, children, path, value, addFiltersTo: parentAddFiltersTo}: DataNode) => (
        new Promise<void>((resolve, reject) => {
            if (children) {
                resolve();
                return;
            }
            const childrenPath = [...path, value];
            registry.pivotTable(
                pivotColumns,
                childrenPath,
                undefined,
                undefined,
                abortControllers.useSignal()
            ).then(data => {
                if (!data.isCompleted()) return;
                setTreeData(origin => (
                    updateTreeData(origin, key, data.val().map(x => ({
                        key: `${key}-${x.value}`,
                        title: x.value || "<empty>",
                        value: x.value,
                        path: childrenPath,
                        isLeaf: childrenPath.length + 1 >= pivotColumns.length,
                        addFiltersTo: (filters) => {
                            parentAddFiltersTo(filters);
                            filters.push(
                                new ColumnFilter(
                                    pivotColumns[childrenPath.length],
                                    x.value,
                                    pivotColumns[childrenPath.length]
                                )
                            );
                        },
                    })))
                ));
                resolve();
            }).catch(reject);
        })
    );

    return (
        <ImmortalAutoSizer>
            {({height, width}) => (
                <div style={{height, width}}>
                    <Spin spinning={initializing}>
                        <Tree
                            height={height}
                            loadedKeys={[]}
                            expandedKeys={expandedKeys}
                            onExpand={setExpandedKeys}
                            loadData={loadData}
                            treeData={treeData}
                            onSelect={(_, e) => {
                                if (e.selected) {
                                    const newFilters: GridFilter[] = [];
                                    e.node.addFiltersTo(newFilters);
                                    filters.replace(newFilters);
                                } else {
                                    filters.clear();
                                }
                            }}
                        />
                    </Spin>
                </div>
            )}
        </ImmortalAutoSizer>
    );
}