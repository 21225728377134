import {PluginAnonymous, PluginSourceSequence} from "../../../../services/Plugins";
import {DocumentRegistryViewRef} from "../../DocumentRegistryView";
import {Button, Form, Input, message, Popconfirm, Row, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {Dep} from "../../../../services/useDep";
import {
    DocumentRegistryScope,
    DocumentRegistryScopes,
    useRegistryScopes
} from "../../../../services/DocumentRegistry/DocumentRegistry";
import {ReadonlyResource} from "../../../../services/Resources";
import {CurrentRegistry, useCurrentRegistry} from "../../Resources/CurrentRegistry";
import {GridFilter, RegistryFilters, ScopeFilter, useRegistryFilters} from "../../Resources/RegistryFilters";
import {Filters} from "../Filters";
import {FormModal} from "../../../../services/FormModal";
import React, {Dispatch, SetStateAction, useCallback, useMemo, useState} from "react";
import {Option, OptionsSource, ScopeFiltersForm} from "./ScopeFiltersForm";

export class PluginScopes extends PluginSourceSequence<DocumentRegistryViewRef> {
    constructor() {
        super([
            new PluginScopesList(),
        ]);
    }
}

class PluginScopesList extends PluginAnonymous<DocumentRegistryViewRef> {
    constructor() {
        super("scopes-list", {
            async build(target: DocumentRegistryViewRef): Promise<void> {
                target.addDock("left-ur", {
                    children: (
                        <ScopesListContainer
                            registry={target.res(CurrentRegistry)}
                            filters={target.res(RegistryFilters)}
                        />
                    ),
                    key: "scopes",
                    name: "Scopes",
                });
            }
        });
    }
}

type ScopesListContainerProps = {
    registry: ReadonlyResource<CurrentRegistry>,
    filters: ReadonlyResource<RegistryFilters>,
}

function ScopesListContainer({registry: registryRes, filters: filtersRes}: ScopesListContainerProps) {
    const registry = useCurrentRegistry(registryRes);
    const registryScopes = useRegistryScopes(registry);
    const filters = useRegistryFilters(filtersRes);
    const optionsSource: OptionsSource = useMemo(() => ({
        columns(): Option[] {
            return registry.columns().asList().map(x => ({
                label: x,
                value: x
            }));
        },
        sets(): Option[] {
            return registry.sets().asList().map(x => ({
                label: x.name(),
                value: x.id()
            }));
        }
    }), [registry]);
    return (
        <ScopesList
            registryScopes={registryScopes}
            filters={filters}
            optionsSource={optionsSource}
        />
    );
}

interface ScopesListProps {
    registryScopes: Dep<DocumentRegistryScopes>;
    filters: Dep<Filters<GridFilter>>;
    optionsSource: OptionsSource;
}

function ScopesList({registryScopes, filters, optionsSource}: ScopesListProps) {
    const [newModalOpen, setNewModalOpen] = useState(false);

    const columns: ColumnsType<DocumentRegistryScope> = [
        {
            title: "Name",
            dataIndex: "name"
        },
        {
            title: "Actions",
            render: (_, item) => (
                <Space>
                    <Button
                        size={"small"}
                        type={"link"}
                        onClick={_ => {
                            filters.add(new ScopeFilter(item.id, item.name));
                        }}
                    >
                        Show
                    </Button>
                    <EditScopeAction
                        optionsSource={optionsSource}
                        item={item}
                        registryScopes={registryScopes}
                        onEdit={() => filters.refresh()}
                    />
                    <Popconfirm
                        title="Are you sure to delete the scope？"
                        onConfirm={() => {
                            registryScopes.delete(item.id).catch(_ => {
                                message.error("Failed to delete scope!");
                            });
                        }}>
                        <Button type={"link"} size={"small"}>Delete</Button>
                    </Popconfirm>
                </Space>
            )
        }
    ];

    const createNewScope = useCallback((values: any) => {
        return registryScopes.create(values.name, values.filters).catch(() => {
            message.error("Failed to create scope");
        });
    }, [registryScopes]);

    return (
        <>
            <EditScopeModal
                okText={"Create"}
                title={"New Scope"}
                registryScopes={registryScopes}
                open={newModalOpen}
                setModalOpen={setNewModalOpen}
                submit={createNewScope}
                optionsSource={optionsSource}
            />
            <Row justify={"end"} style={{marginBottom: "8px"}}>
                <Button
                    type={"primary"}
                    onClick={_ => setNewModalOpen(true)}
                >
                    Create
                </Button>
            </Row>
            <Table
                rowKey={r => r.id}
                dataSource={registryScopes.asList()}
                columns={columns}
                size={"small"}
            />
        </>
    );
}

type EditScopeActionProps = {
    item: DocumentRegistryScope,
    registryScopes: Dep<DocumentRegistryScopes>,
    onEdit: () => void,
    optionsSource: OptionsSource
};

function EditScopeAction({item, registryScopes, onEdit, optionsSource}: EditScopeActionProps) {
    const [modalOpen, setModalOpen] = useState(false);

    const editScope = useCallback((values: any) => {
        return registryScopes.edit(
            item.id, values.name, values.filters
        ).then(
            onEdit
        ).catch(() => {
            message.error("Failed to edit the scope");
        });
    }, [onEdit, item, registryScopes]);

    return (
        <>
            <EditScopeModal
                optionsSource={optionsSource}
                title={"Edit Scope"}
                okText={"Save"}
                registryScopes={registryScopes}
                open={modalOpen}
                setModalOpen={setModalOpen}
                submit={editScope}
                initialValues={item}
            />
            <Button
                size={"small"}
                type={"link"}
                onClick={_ => setModalOpen(true)}
            >
                Edit
            </Button>
        </>
    );
}

type EditScopeModalProps = {
    submit: (values: any) => Promise<any>;
    title: string
    okText: string
    registryScopes: Dep<DocumentRegistryScopes>,
    open: boolean,
    setModalOpen: Dispatch<SetStateAction<boolean>>,
    initialValues?: any,
    optionsSource: OptionsSource
};

function EditScopeModal({
                            submit,
                            okText,
                            title,
                            initialValues,
                            open,
                            setModalOpen,
                            registryScopes,
                            optionsSource
                        }: EditScopeModalProps) {
    return (
        <FormModal
            formId={"new-row-form-modal"}
            onSubmit={submit}
            open={open}
            setModalOpen={setModalOpen}
            modalProps={{
                title: title,
                destroyOnClose: true,
                okText: okText,
                width: 1200
            }}
            formProps={{
                initialValues
            }}
        >
            <Form.Item label={"Name"} name={"name"}>
                <Input/>
            </Form.Item>
            <Form.Item name={"filters"}>
                <ScopeFiltersForm
                    optionsSource={optionsSource}
                    id={"scope-filters-form"}
                    schema={registryScopes.scopeFilterSchema()}
                />
            </Form.Item>
        </FormModal>
    );
}
