import React from "react";
import axios from "axios";
import {API_URL} from "../constants";
import {Button, Col, Form, Input, Modal, Popconfirm, Row, Select, Table, Card, Radio, Tabs} from "antd";
import {authHeader} from "../Utilities";

export class PostProcessingRules extends React.Component {
  state = {
    rulesList: [],
    newRuleVisible: false,
    activeTabKey: "filter",
    newRuleName: "",
    filterPositiveSample: "",
    filterNegativeSample: "",
    transformationSource: "",
    transformationTarget: "",
    filterPreviewRows: [],
    transformPreviewRows: [],

    dataSource: "text",
    dataTarget: "text",
    dataMode: "overwrite",
  };

  onPaneChange = (activeKey) => {
    this.setState({activeTabKey: activeKey});
    if (activeKey === "preview") {
      this.handleResultsPreview(this.props.match.params.projectId);
    }
  };

  handleResultsPreview = (projectId) => {

  };

  loadRules = (projectId) => {
    axios.get(
        API_URL + `/projects/${projectId}/post_processing_rules`,
        {headers: authHeader()}
    ).then(result => {
      const rulesList = result.data.rules_list;
      this.setState({
        rulesList: rulesList,
      });
    });
  };

  handleRuleRemoval = (projectId, ruleId) => {
    axios.delete(
        API_URL + `/projects/${projectId}/post_processing_rules/${ruleId}`,
        {
          headers: authHeader()
      }
    ).then(result => {
      this.loadRules(projectId);
    });
  };

  showModal = () => {
    this.setState({newRuleVisible: true});
  };

  handleNewRuleCancel = () => {
    this.setState({newRuleVisible: false});
  };

  handleNewRuleOk = (projectId) => {
    axios.post(
      API_URL + `/projects/${projectId}/post_processing_rules/get_filter_regexp`,
      {
        positive_example: this.state.filterPositiveSample,
        negative_example: this.state.filterNegativeSample,
      },
      {
        headers: authHeader()
      }
    ).then(result => {
      const filterRegexp = result.data.filter_regexp;
      axios.post(
          API_URL + `/projects/${projectId}/post_processing_rules/get_transformation_regexp`,
          {
            source: this.state.transformationSource,
            target: this.state.transformationTarget,
          },
          {
            headers: authHeader(),
          }
      ).then(result => {
        const transformationRegexp = result.data.transformation_regexp;
        axios.post(
            API_URL + `/projects/${projectId}/post_processing_rules`,
            {
              name: this.state.newRuleName,
              rule_data: {
                filter_regexp: filterRegexp,
                transformation_regexp: transformationRegexp,
                data_source: this.state.dataSource,
                data_target: this.state.dataTarget,
                data_mode: this.state.dataMode,
              }
            },
            {
              headers: authHeader()
            }
        ).then(result => {
          this.loadRules(projectId);
          this.setState({newRuleVisible: false});
        });
      });
    });
  };

  handleNewRule = () => {
    this.setState({
          newRuleName: "",
          filterPositiveSample: "",
          filterNegativeSample: "",
          filterPreviewRows: [],
          transformPreviewRows: [],
        },() => this.showModal()
    );
  };

  handleFilterPreview = (projectId) => {
    axios.post(
        API_URL + `/projects/${projectId}/post_processing_rules/get_filter_regexp`,
        {
          positive_example: this.state.filterPositiveSample,
          negative_example: this.state.filterNegativeSample,
        },
        {
          headers: authHeader()
        }
    ).then(result => {
      const filterRegexp = result.data.filter_regexp;
      axios.post(
        API_URL + `/projects/${projectId}/post_processing_rules/preview_filtered_tags`,
        {
          filter_regexp: filterRegexp,
          data_source: this.state.dataSource,
        },
        {
          headers: authHeader()
        }
      ).then(result => {
          this.setState({filterPreviewRows: result.data.tags.map((tag) => {return {tag};})});
      });
    });
  };

  handleTransformPreview = (projectId) => {
    axios.post(
        API_URL + `/projects/${projectId}/post_processing_rules/get_filter_regexp`,
        {
          positive_example: this.state.filterPositiveSample,
          negative_example: this.state.filterNegativeSample,
        },
        {
          headers: authHeader()
        }
    ).then(result => {
      const filterRegexp = result.data.filter_regexp;
      axios.post(
          API_URL + `/projects/${projectId}/post_processing_rules/get_transformation_regexp`,
          {
            source: this.state.transformationSource,
            target: this.state.transformationTarget,
          },
          {
            headers: authHeader(),
          }
      ).then(result => {
        const transformationRegexp = result.data.transformation_regexp;
        axios.post(
          API_URL + `/projects/${projectId}/post_processing_rules/preview_transformed_tags`,
          {
            filter_regexp: filterRegexp,
            transformation_regexp: transformationRegexp,
            data_source: this.state.dataSource,
          },
          {
            headers: authHeader(),
          }
        ).then(result => {
          this.setState({transformPreviewRows: result.data.tags});
        });
      });
    });
  };

  componentDidMount() {
    this.loadRules(this.props.match.params.projectId);
  }

  render() {
    const rulesColumns = [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Description",
        dataIndex: "description"
      },
      {
        title: "Actions",
        dataIndex: "id",
        render: (ruleId) => {
          return (
              <Popconfirm title="Are you sure to delete the rule？" onConfirm={() => this.handleRuleRemoval(this.props.match.params.projectId, ruleId)}>
                <a href="#">Delete</a>
              </Popconfirm>
          );
        }
      }
    ];

    const filterPreviewColumns = [
      {
        title: "Tag",
        dataIndex: "tag",
      }
    ];

    const transformPreviewColumns = [
      {
        title: "Source",
        dataIndex: "source",
      },
      {
        title: "Target",
        dataIndex: "target",
      },
    ];

    return (
      <>
        <Row style={{justifyContent: "flex-end"}}>
          <Button type="primary" onClick={this.handleNewRule}>New rule</Button>
        </Row>
        <Table id="postprocessing-rules-list-table" dataSource={this.state.rulesList} columns={rulesColumns} size="small" style={{paddingTop: "16px"}} />

        <Modal
            title="New rule"
            visible={this.state.newRuleVisible}
            onOk={() => this.handleNewRuleOk(this.props.match.params.projectId)}
            onCancel={this.handleNewRuleCancel}
            width="80vw"
            bodyStyle={{height: "65vh"}}
        >
          <Row>
            <Col span={5}>
              <Form.Item label="Rule name">
                <Input value={this.state.newRuleName} onChange={(e) => this.setState({newRuleName: e.target.value})}/>
              </Form.Item>
            </Col>
            <Col offset={1} span={18}>
              <Tabs activeKey={this.state.activeTabKey} onChange={this.onPaneChange}>
                <Tabs.TabPane tab="Filter" key="filter">
                  <Row>
                    <Col span={5}>
                      <Form.Item label="Positive sample">
                        <Input value={this.state.filterPositiveSample} onChange={(e) => this.setState({filterPositiveSample: e.target.value})}/>
                      </Form.Item>
                      <Form.Item label="Negative sample">
                        <Input value={this.state.filterNegativeSample} onChange={(e) => this.setState({filterNegativeSample: e.target.value})}/>
                      </Form.Item>
                      <Form.Item>
                        <Button id="preview-filter-button" type="primary" onClick={() => this.handleFilterPreview(this.props.match.params.projectId)}>Preview</Button>
                      </Form.Item>
                    </Col>
                    <Col offset={1} span={18}>
                      <Table id="postprocessing-tags-filter-preview-table"
                             dataSource={this.state.filterPreviewRows}
                             columns={filterPreviewColumns} size="small" style={{paddingTop: "16px"}}
                      />
                    </Col>
                  </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Transform" key="transform">
                  <Row>
                    <Col span={5}>
                      <Form.Item label="Source sample">
                        <Input value={this.state.transformationSource} onChange={(e) => this.setState({transformationSource: e.target.value})}/>
                      </Form.Item>
                      <Form.Item label="Target sample">
                        <Input value={this.state.transformationTarget} onChange={(e) => this.setState({transformationTarget: e.target.value})}/>
                      </Form.Item>
                      <Form.Item>
                        <Button id="preview-transform-button" type="primary" onClick={() => this.handleTransformPreview(this.props.match.params.projectId)}>Preview</Button>
                      </Form.Item>
                    </Col>
                    <Col offset={1} span={18}>
                      <Table id="postprocessing-tags-transform-preview-table"
                             dataSource={this.state.transformPreviewRows}
                             columns={transformPreviewColumns} size="small" style={{paddingTop: "16px"}}
                      />
                    </Col>
                  </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Advanced" key="advanced">
                  <Row>
                    <Col span={5}>
                      <Form.Item label="Data source">
                        <Input value={this.state.dataSource} onChange={(e) => this.setState({dataSource: e.target.value})}/>
                      </Form.Item>
                      <Form.Item label="Data target">
                        <Input value={this.state.dataTarget} onChange={(e) => this.setState({dataTarget: e.target.value})}/>
                      </Form.Item>
                      <Form.Item label="Mode">
                        <Select id="postprocessing-data-mode"
                                value={this.state.dataMode}
                                onChange={(value) => this.setState({dataMode: value})}>
                          <option value="overwrite">Overwrite</option>
                          <option value="append">Append</option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}