import React, { Component } from "react";
import axios from "axios";
import {API_URL} from "../constants";
import {authHeader} from "../Utilities";

export const AuthContext = React.createContext();

const { Provider, Consumer } = AuthContext;

class AuthContextProvider extends Component {
  state = {
    user: null
  };

  componentDidMount() {
    if (!["/login", "/auth/reset_password", "/auth/create_password"].includes(window.location.pathname))
      this.loadUser();
  }

  loadUser = () => {
    axios.get(API_URL + "/get_current_user", {headers: authHeader()}).then(res => {
      this.setState({user: res.data});
    });
  };

  render() {
    return <Provider value={{user: this.state.user}}>
      {this.props.children}
    </Provider>;
  }
}

export { AuthContextProvider, Consumer as AuthContextConsumer };
