import {CnDrawing} from "../CnDrawing";

export class RemoteCnDrawing extends CnDrawing {
    constructor(data, history) {
        super();

        this._data = data;
        this._history = history;
    }

    id() {
        return this._data.id;
    }

    openSwitched(cnDrawingToCompare, selectedCnResult) {
        const isFinal = this._data.is_final_result === 1;

        const switchState = {
            cnSwitch: {
                selectedDrawing: cnDrawingToCompare.toResultReference()
            }
        };

        if (isFinal) {
            this._history.push({
                pathname: `/project/${this._data.run.project_id}/final_results/view_result/${this._data.id}`,
                hash: selectedCnResult.toTagHash(),
                state: switchState
            });
        }
        else {
            this._history.push({
                pathname: `/project/${this._data.run.project_id}/explore_results/${this._data.run.id}/view_document/${this._data.id}`,
                hash: selectedCnResult.toTagHash(),
                state: switchState
            });
        }
    }

    open() {
        const isFinal = this._data.is_final_result === 1;

        if (isFinal) {
            this._history.push({
                pathname: `/project/${this._data.run.project_id}/final_results/view_result/${this._data.id}`,
            });
        }
        else {
            this._history.push({
                pathname: `/project/${this._data.run.project_id}/explore_results/${this._data.run.id}/view_document/${this._data.id}`,
            });
        }
    }

    toColumnData() {
        let runName = "";

        if (this._data.is_final_result === 0) {
            runName = this._data.run.name;
        } else {
            runName = `Finalized version (${this._data.run.name})`;
        }

        return {
            file_name: this._data.page_info.file_path,
            title: this._data.title,
            page_number: this._data.page_info.number + 1,
            same_page_version: this._data.page_info.same_page_version,
            run_name: runName
        };
    }

    toResultReference() {
        return {
            result_id: this._data.id,
            is_final: this._data.is_final_result === 1
        };
    }
}