import React, {useState} from "react";
import {AutoComplete, Input, Row} from "antd";
import {EditOutlined} from "@ant-design/icons";

export function EditableAutoCompleteField({value, editIconShown, onUpdate, optionsList}) {
    const [isEditing, setEditing] = useState(false);
    const [inputValue, setInputValue] = useState(value);
    const [displayedOptions, setDisplayedOptions] = useState(optionsList);

    const renderAsTextField = () => {
        return (
            <Row style={{justifyContent: "space-between"}}>
                <span>{value}</span>
                {editIconShown &&
                <a onClick={() => setEditing(true)}>
                    <EditOutlined/>
                </a>
                }
            </Row>
        );
    };

    const renderAsAutoCompleteField = () => {
        return (
            <Row style={{flexFlow: "initial", width: "auto", margin: "-15px"}} className="editable-select-field-cell">
                <AutoComplete
                    id="attribute-edit-text-input"
                    autoFocus={true}
                    defaultOpen={isEditing}
                    autoClearSearchValue={false}
                    defaultValue={value}
                    className="editable-select-field"
                    onChange={newValue => {
                        setInputValue(newValue);
                    }}
                    onSelect={selected => {
                        onUpdate(selected);
                        setEditing(false);
                    }}
                    onSearch={text => setDisplayedOptions([...optionsList].sort((a, b) => {
                      return b.toLowerCase().includes(text.toLowerCase()) - a.toLowerCase().includes(text.toLowerCase());
                    }))}
                    options={displayedOptions.map(option => ({value: option}))}
                    onKeyDown={e => {
                        if (e.nativeEvent.key === "Escape") {
                            setEditing(false);
                        }
                    }}
                    onBlur={_ => setEditing(false)}
                >
                    <Input
                        onPressEnter={_ => {
                            onUpdate(inputValue);
                            setEditing(false);
                        }}
                    />
                </AutoComplete>
            </Row>
        );
    };

    return(
        <>
            {isEditing? renderAsAutoCompleteField() : renderAsTextField()}
        </>
    );
}
