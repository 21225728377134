import React, {useState} from "react";
import {
    Button,
    Col,
    Dropdown,
    Form,
    Input,
    Menu,
    message,
    Modal,
    Popconfirm,
    Popover,
    Row,
    Table
} from "antd";
import {DeleteOutlined, LinkOutlined, EllipsisOutlined} from "@ant-design/icons";
import {EditableTextField} from "../EditableFields/EditableTextField";

function FieldEditorModal({visible, fields, onClose, onFieldsChange, editable, selectedObjectAssignData,
                              zoomToObjectById, readonlyKeys}) {
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
    const [hoveredColIndex, setHoveredColIndex] = useState(null);
    const [newFieldPopoverVisible, setNewFieldPopoverVisible] = useState(false);

    function isRecordReadonly(record) {
        return readonlyKeys.includes(record.key);
    }

    function notifyFieldsChange(updatedFields) {
        onFieldsChange(updatedFields);
    }

    function removeField(targetField) {
        const updatedFields = fields.filter(field => field !== targetField);

        notifyFieldsChange(updatedFields);
    }

    function updateFieldValue(targetField, newValue) {
        const updatedFields = fields.map(field => {
            if (field !== targetField) return field;

            field.value = newValue;

            return field;
        });

        notifyFieldsChange(updatedFields);
    }

    function clearAssign(targetField) {
        const updatedFields = fields.map(field => {
            if (field !== targetField) return field;

            delete field["drawing_object_id"];

            return field;
        });

        notifyFieldsChange(updatedFields);
    }

    function assignSelectedObject(targetField) {
        const updatedFields = fields.map(field => {
            if (field !== targetField) return field;

            field.drawing_object_id = selectedObjectAssignData.id;
            field.value = selectedObjectAssignData.text;

            return field;
        });

        notifyFieldsChange(updatedFields);
    }

    function addNewField(newField) {
        const updatedFields = [...fields, newField];

        notifyFieldsChange(updatedFields);
    }

    const tableCols = [
        {
            title: "key",
            dataIndex: "key",
            width: "30%",
            render: (text, record, index) => {
                const deleteIconShown = !isRecordReadonly(record) && editable && index === hoveredColIndex;

                return {
                    props: {
                        style: { background: "WhiteSmoke" }
                    },
                    children: (
                        <div style={{width: "auto", height: "100%", margin: "-8px", padding: "8px"}}
                             onMouseEnter={() => setHoveredColIndex(index)}
                             onMouseLeave={() => setHoveredColIndex(-1)}>
                            {text}
                            <Popconfirm
                                placement="topLeft"
                                title={`Are you sure to delete page field '${text}'?`}
                                onConfirm={() => {
                                    if (record.drawing_object_id == null) {
                                        removeField(record);
                                    } else {
                                        message.error("The field cannot be deleted: it refers to an object");
                                    }
                                }}
                                okText="Yes" cancelText="No"
                            >
                                <a style={{visibility: deleteIconShown ? "initial" : "hidden"}}><DeleteOutlined/></a>
                            </Popconfirm>
                        </div>
                    )
                };
            }
        },
        {
            title: "value",
            dataIndex: "value",
            render: (value, record, index) => {
                if (!editable || isRecordReadonly(record)) return value;

                const isDrawingObjectIdPresent = record.drawing_object_id != null;

                return <Row justify={"space-between"}>
                    <Col span={20}>
                        <EditableTextField
                            value={value}
                            editIconShown={index === hoveredRowIndex}
                            onUpdate={(newText) => {
                                updateFieldValue(record, newText);
                            }}
                        />
                    </Col>
                    <Col span={2} offset={2}>
                        {isDrawingObjectIdPresent ?
                            <AssignedObjectMenu
                                visible={true}
                                onShowAssigned={() => zoomToObjectById(record.drawing_object_id)}
                                onClearAssigned={() => clearAssign(record)}
                            />
                            :
                            <AssignSelectedObjectMenu
                                visible={true}
                                onAssignClick={e => {
                                    if (selectedObjectAssignData == null) {
                                        message.error("Error to assign not selected object");
                                        return;
                                    }

                                    const alreadyAssignedField = fields
                                        .find(field => field.drawing_object_id === selectedObjectAssignData.id);

                                    if (alreadyAssignedField == null) {
                                        assignSelectedObject(record);
                                    } else {
                                        message.error(
                                            `This object is already assigned to '${alreadyAssignedField.key}'`
                                        );
                                    }
                                }}
                            />
                        }
                    </Col>
                </Row>;
            }
        }
    ];

    const tableData = fields;

    const addNewFieldButton = (
        <NewFieldPopover
            visible={newFieldPopoverVisible}
            onVisibleChange={value => setNewFieldPopoverVisible(value)}
            initialValue={{key: "", value: ""}}
            onOk={newField => {
                addNewField(newField);
                setNewFieldPopoverVisible(false);
            }}
            onCancel={() => setNewFieldPopoverVisible(false)}
            forbiddenKeys={fields.map(field => field.key)}
        >
            <Button id={"add-new-field-button"}
                onClick={() => setNewFieldPopoverVisible(true)} type={"primary"} size={"small"}>Add new</Button>
        </NewFieldPopover>
    );

    return (
        <Modal title={"Page fields"}
               width={600}
               visible={visible}
               onCancel={onClose}
               footer={editable ? [addNewFieldButton] : null}
               destroyOnClose
        >
            <Table
                id="page-fields-table"
                dataSource={tableData}
                columns={tableCols}
                size="small"
                pagination={false}
                bordered={true}
                showHeader={false}
                style={{marginBottom: "8px"}}
                onRow={(record, rowIndex) => {
                    return {
                        onMouseEnter: event => setHoveredRowIndex(rowIndex),
                        onMouseLeave: event => setHoveredRowIndex(null),
                    };
                }}
            />
        </Modal>
    );
}

function AssignSelectedObjectMenu({visible, onAssignClick}) {
    const overlay = (
        <Menu>
            <Menu.Item id="assign-selected-object" onClick={onAssignClick}>
                Assign selected object
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={overlay} trigger={"click"}>
            <a style={{visibility: visible ? "initial" : "hidden"}}><LinkOutlined style={{color: "grey"}}/></a>
        </Dropdown>
    );
}

function AssignedObjectMenu({visible, onShowAssigned, onClearAssigned}) {
    const overlay = (
        <Menu>
            <Menu.Item id="show-assigned-object" onClick={onShowAssigned}>
                Show
            </Menu.Item>
            <Menu.Item id="remove-assigned-object" onClick={onClearAssigned}>
                Remove assign
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={overlay} trigger={"click"}>
            <a style={{visibility: visible ? "initial" : "hidden"}}><LinkOutlined/></a>
        </Dropdown>
    );
}

function NewFieldPopover({visible, onVisibleChange, children, onOk, onCancel, initialValue, forbiddenKeys}) {
    const formId = "new-field-form";

    const content = (
        <div style={{width: "376px"}}>
            <NewFieldForm id={formId} key="alive" initialValue={initialValue}
                            onFinish={onOk} forbiddenKeys={forbiddenKeys}/>
            <Row
                style={{justifyContent: "space-between"}}
            >
                <Button size="small" onClick={onCancel} style={{width: 90}}>Cancel</Button>
                <Button
                    form={formId}
                    key="submit" htmlType="submit"
                    type="primary" size="small" style={{width: 90}}
                >OK</Button>
            </Row>
        </div>
    );

    return (
        <Popover
            destroyTooltipOnHide={true}
            visible={visible}
            trigger={"click"}
            title={"New field"}
            onVisibleChange={onVisibleChange}
            content={content}
        >
            {children}
        </Popover>
    );

}

function NewFieldForm({id, initialValue, onFinish, forbiddenKeys}) {
    const formLayout = {
        labelCol: {span: 3},
        wrapperCol: {span: 21},
    };

    const nameRules = [
        {
            required: true
        },
        {
            validator: async (_, target) => {
                if (!forbiddenKeys.includes(target)) return Promise.resolve();

                return Promise.reject(new Error("Field names must be unique!"));
            }
        }
    ];

    return (
        <Form id={id}
              hideRequiredMark={true}
              onFinish={(value) => onFinish(value)}
              initialValues={initialValue}
              {...formLayout}
        >
            <Form.Item label="name"
                       name="key"
                       style={{marginBottom: "4px"}}
                       rules={nameRules}
            >
                <Input id="field-key-input" size="small"/>
            </Form.Item>
            <Form.Item label="value" name="value" style={{marginBottom: "12px"}}>
                <Input defaultValue="" id="field-value-input" size="small"/>
            </Form.Item>
        </Form>
    );
}

export function FieldsEditor({fields, editable, onFieldsChange, readonlyKeys, zoomToObjectById, selectedObjectAssignData}) {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Button size={"small"}
                    id={"page-fields-editor"}
                    onClick={() => setVisible(true)}
                    icon={<EllipsisOutlined/>}
            />
            <FieldEditorModal
                zoomToObjectById={(id) => {
                    setVisible(false);
                    zoomToObjectById(id);
                }}
                selectedObjectAssignData={selectedObjectAssignData}
                readonlyKeys={readonlyKeys}
                onFieldsChange={onFieldsChange}
                editable={editable}
                visible={visible}
                fields={fields}
                onClose={() => setVisible(false)}
            />
        </>
    );
}