import React, {useEffect} from "react";
import {message, Spin} from "antd";
import axios from "axios";
import {API_URL} from "../constants";
import {authHeader} from "../Utilities";
import {useHistory} from "react-router";


export function GeneralLink(props) {
    const history = useHistory();

    useEffect(() => {
        axios.post(
            API_URL + "/general_links/concrete_link",
            {
                "general_url": window.location.href
            },
            {headers: authHeader()}
        ).then(response => {
            const concreteUrl = new URL(response.data["concrete_link"]["url"]);

            history.replace(concreteUrl.pathname + concreteUrl.search + concreteUrl.hash);
        }).catch(response => {
            message.error("Failed to parse link");
            history.replace("/");
        });
    }, [history]);

    return (
        <Spin spinning={true} size="large"
              style={{opacity: 1, position: "absolute", zIndex: 100, left: "50vw", top: "30vh"}}
        />
    );
}