import {API_URL} from "../constants";
import axios from "axios";
import {authHeader} from "../Utilities";
import {Buffer} from "buffer/";

class ImageDownloadService {
  constructor(imageId) {
    this.imageId = imageId;
  }

  downloadImage = (isCompressed, onProgressCallback) => {
    let imageUrl = API_URL + `/images/${this.imageId}.png`;
    if (isCompressed) {
      imageUrl = API_URL + `/images_compressed/${this.imageId}.png`;
    }

    return axios.get(imageUrl, {
      responseType: "arraybuffer",
      headers: authHeader(),
      onDownloadProgress: onProgressCallback,
    }).then(res => {
      const b64 = Buffer.from(res.data, "binary").toString("base64");
      const imgUrlBinary = `data:application/octet-stream;base64,${b64}`;
      return imgUrlBinary;
    });
  };
}

export default ImageDownloadService;