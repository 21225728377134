import React from "react";
import {CnObject} from "../CnObject";
import {SafeAttributes} from "./SafeAttributes";
import {CnCanvasObject} from "../CnCanvasObject";
import {SafeCnCanvasObject} from "./SafeCnCanvasObject";

export class SafeCnObject extends CnObject {
    constructor(origin) {
        super();
        this._origin = origin;
    }

    toCnCanvasObject() {
        try {
            return new SafeCnCanvasObject(
                this._origin.toCnCanvasObject()
            );
        } catch (e) {
            return new CnCanvasObject();
        }
    }

    toTag() {
        try {
            return this._origin.toTag();
        } catch (e) {
            return <></>;
        }
    }

    toTagHash() {
        try {
            return this._origin.toTagHash();
        } catch (e) {
            return "";
        }
    }

    selectOnCanvas() {
        try {
            this._origin.selectOnCanvas();
        } catch (e) {
            console.log(e);
        }
    }

    relatedToCanvasObject(canvasObj) {
        try {
            return this._origin.relatedToCanvasObject(canvasObj);
        } catch (e) {
            return false;
        }
    }

    attrs() {
        return new SafeAttributes(
            this._origin.attrs()
        );
    }
}