import React, {useEffect, useState} from "react";
import {Button, Card, Form, message} from "antd";
import axios from "axios";
import {API_URL} from "../../constants";
import {authHeader} from "../../Utilities";
import EditableTagGroup from "../Misc/EditableTagGroup";

function loadSystemAttributes(workspaceId) {
    return axios.get(
        API_URL + `/workspaces/${workspaceId}/search_filters_config`,
        {headers: authHeader()}
    );
}

export function ConfigureSearchFilters({workspaceId}) {
    const [pageFilters, setPageFilters] = useState([]);
    const [objectAttributesFilters, setObjectAttributesFilters] = useState([]);

    function saveChanges() {
        axios.post(
            API_URL + `/workspaces/${workspaceId}/search_filters_config`,
            {
                search_filters: [...pageFilters, ...objectAttributesFilters]
            },
            {headers: authHeader()}
        ).then(response => {
            message.success("Changes saved");
        }).catch(e => {
            message.error("Error saving configuration");
        });
    }

    useEffect(() => {
        loadSystemAttributes(workspaceId).then(response => {
            setObjectAttributesFilters(response.data.search_filters.filter(el => el.target === "object_attr"));
            setPageFilters(response.data.search_filters.filter(el => el.target === "page_field"));
        });
    }, [workspaceId]);

    return (
        <>
            <Card title="Search Filters Configuration">
                <Button type="primary" onClick={saveChanges}>Save changes</Button>
                <Form.Item label="Allowed page fields">
                    <ConfigTagsEditor
                        searchFiltersKeys={pageFilters.map(item => item.key)}
                        onTagsChanged={tags => {
                            const updatedConfig = tags.map(el => (
                                {
                                    key: el,
                                    target: "page_field"
                                }
                            ));

                            if (!tags.find(tag => tag === "File name")) {
                                updatedConfig.push(
                                    {
                                        key: "File name",
                                        target: "page_field"
                                    }
                                );
                            }

                            setPageFilters(updatedConfig);
                        }}
                    />
                </Form.Item>
                <Form.Item label="Allowed object attributes">
                    <ConfigTagsEditor
                        searchFiltersKeys={objectAttributesFilters.map(item => item.key)}
                        onTagsChanged={tags => {
                            const updatedConfig = tags.map(el => (
                                {
                                    key: el,
                                    target: "object_attr"
                                }
                            ));

                            setObjectAttributesFilters(updatedConfig);
                        }}
                    />
                </Form.Item>
            </Card>
        </>
    );
}


function ConfigTagsEditor({searchFiltersKeys, onTagsChanged}) {
    const [tagGroupVersion, setTagGroupVersion] = useState(0);

    return (
        <EditableTagGroup
            key={tagGroupVersion}
            dataSource={searchFiltersKeys}
            onTagsChanged={(labels) => {
                onTagsChanged(labels);
                setTagGroupVersion(tagGroupVersion + 1);
            }}
            newTagText={"New Attribute"}
            tagColor={"cyan"}
            withClearAllButton={false}
            withEditTagAbility={false}
        />
    );
}