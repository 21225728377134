import React from "react";
import OrganizationsService from "../../services/OrganizationsService";
import {Button, message, Row, Table, Typography} from "antd";
import {addTableTextFilters} from "../../Utilities";
import {Link} from "react-router-dom";

export class OrganizationsList extends React.Component {
  state = {
    organizationsList: [],
  };


  constructor(props) {
    super(props);

    addTableTextFilters(this);
  }

  componentDidMount() {
    this.loadOrganizations();
  }

  loadOrganizations = () => {
    OrganizationsService.fetchOrganizations().then(orgsList => {
      this.setState({organizationsList: orgsList.data});
    }).catch(() => message.error("Failed to load organizations list"));
  };

  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        ...this.getColumnSearchProps("name"),
        render: (match, record) => {
          return (
              <Link to={`${this.props.root}organizations/${record.id}`}>{match}</Link>
          );
        },
      },
      {
        title: "Number of users",
        dataIndex: "users_count",
      }
    ];
    return (
      <>
        <Row style={{justifyContent: "space-between", padding: "1em"}}>
          <Typography.Title level={4}>Organizations</Typography.Title>
          <Link to={`${this.props.root}organizations/new`}>
            <Button type="primary">New organization</Button>
          </Link>
        </Row>
        <Table
            dataSource={this.state.organizationsList}
            columns={columns}
        />
      </>
    );
  }
}