import React from "react";
import {Row, Select} from "antd";
import { EditOutlined } from "@ant-design/icons";

export class EditableSelectField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }

  handleAbortEditing = () => {
    this.setState({isEditing: false});
  };

  handleKeyDown = (e) => {
    if (e.nativeEvent.key === "Escape") {
      this.handleAbortEditing();
    }
  };

  render() {
    return (
        <>
          {
            !this.state.isEditing &&
            <Row style={{justifyContent: "space-between"}}>
              <span>{this.props.value}</span>
              <a style={{visibility: this.props.editIconShown ? "initial" : "hidden"}}
                  onClick={() => this.setState({isEditing: true})}>
                <EditOutlined/>
              </a>
            </Row>
          }

          {
            this.state.isEditing &&
            <Row style={{flexFlow: "initial", width: "auto", margin: "-15px"}} className="editable-select-field-cell">
              <Select
                  className="editable-select-field"
                  style={{}}
                  autoFocus={true}
                  open={true}
                  onChange={(newValue) => {
                    this.props.onUpdate(newValue);
                    this.setState({isEditing: false});
                  }}
                  value={this.props.value}
                  onKeyDown={this.handleKeyDown}
                  onBlur={this.handleAbortEditing}

              >
                {
                  this.props.optionsList.map((option) => {
                    return <Select.Option value={option}>{option}</Select.Option>;
                  })
                }
              </Select>
              {/*<Select*/}
              {/*    autoFocus={true}*/}
              {/*    value={this.state.newValue}*/}
              {/*    onKeyDown={this.handleKeyDown}*/}
              {/*    onBlur={this.handleFinishEditing}*/}
              {/*    onChange={(e) => this.setState({newValue: e.target.value})}*/}
              {/*    style={{flexGrow: 1, width: null, height: '100%'}}*/}
              {/*/>*/}
            </Row>
          }
        </>
    );
  }
}