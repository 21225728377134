import React from "react";
import {MgTableRow} from "./MgTableRow";
import {DetailsFromAttributes} from "../../DrawingComparison/Model/DetailsFromAttributes";
import {AnnotationObjectAttributes} from "../../DrawingComparison/Model/Annotation/AnnotationObjectAttributes";
import {DefaultMgTableRow} from "./DefaultMgTableRow";
import {TagCnStatus} from "../../DrawingComparison/Model/Tag/TagCnStatus";
import {Space} from "antd";
import {CnCanvasObject} from "../../DrawingComparison/Model/CnCanvasObject";
import {fabric} from "fabric";
import {viewerConsts} from "../../../../constants";
import Rectangle from "../../../ImageViewerHandlers/Geometry/Rectangle";
import {getFabricjsArrow} from "../../../../Utilities";


export class MgFilterData {
    cnStatus() {
        return "";
    }

    mgStatus() {
        return "";
    }

    details() {
        return [];
    }
}

export class DefaultMgFilterData extends MgFilterData {
    constructor(conflictData) {
        super();

        this._conflictData = conflictData;
    }

    cnStatus() {
        return this._conflictData.cnStatus();
    }

    mgStatus() {
        return this._conflictData.mgStatus();
    }

    details() {
        return this._conflictData.cnDetails();
    }
}

export class MgConflict {
    id() {
        return -1;
    }

    accept() {

    }

    reject() {

    }

    toFilterData() {
        return new MgFilterData();
    }

    toMgTableRow() {
        return new MgTableRow();
    }

    detailsTag() {
        return (
            <></>
        );
    }

    toCnCanvasObject() {
        return new CnCanvasObject();
    }

    selectOnCanvas() {

    }

    relatedToCanvasObject(obj) {
        return false;
    }
}

export class AnimatedConflictData {
    constructor(conflictData, updateConflictData) {
        this._conflictData = () => conflictData;
        this._updateConflictData = updateConflictData;
    }

    accept(merged) {
        const conflictData = this._conflictData();

        conflictData.mg_result.status = "ACCEPTED";
        conflictData.mg_result.merged = merged;

        this._updateConflictData(conflictData);
    }

    reject() {
        const conflictData = this._conflictData();

        conflictData.mg_result.status = "REJECTED";
        conflictData.mg_result.merged = this._conflictData().cn_result.current;

        this._updateConflictData(conflictData);
    }

    manual(merged) {
        const conflictData = this._conflictData();

        conflictData.mg_result.status = "MANUAL";
        conflictData.mg_result.merged = merged;

        this._updateConflictData(conflictData);
    }

    id() {
        return this._conflictData().id;
    }

    current() {
        return this._conflictData().cn_result.current;
    }

    another() {
        return this._conflictData().cn_result.another;
    }

    merged() {
        return this._conflictData().mg_result.merged;
    }

    cnStatus() {
        return this._conflictData().cn_result.status;
    }

    cnDetails() {
        return new DetailsFromAttributes(
            new AnnotationObjectAttributes(this.current()),
            new AnnotationObjectAttributes(this.another()),
        ).value();
    }

    mgStatus() {
        return this._conflictData().mg_result.status;
    }

    relatedToCanvasObject(canvasObj) {
        if (canvasObj == null) return;

        let result;

        if (canvasObj.fromAnotherDrawing) {
            result = canvasObj.objectMetadata.id === this.another().id;
        } else {
            result = canvasObj.objectMetadata.id === this.current().id;
        }

        return result;
    }

    identity() {
        let objectInfoSource;

        if (this.cnStatus() === "ADDED") {
            objectInfoSource = this.another();
        } else {
            objectInfoSource = this.current();
        }

        return {
            text: objectInfoSource.text,
            label: objectInfoSource.label
        };
    }
}

export class SameMgConflict extends MgConflict {
    constructor(conflictData, canvas) {
        super();

        this._conflictData = conflictData;
        this._canvas = canvas;
    }

    id() {
        return this._conflictData.id();
    }

    accept() {
    }

    reject() {
    }

    toFilterData() {
        return new DefaultMgFilterData(this._conflictData);
    }

    toMgTableRow() {
        throw new Error("Unsupported");

        return super.toMgTableRow();
    }

    detailsTag() {
        throw new Error("Unsupported");

        return super.detailsTag();
    }

    toCnCanvasObject() {
        return new CurrentCnCanvasObject(this._conflictData.current(), this._canvas, "rgba(0, 0, 0, 0)");
    }

    selectOnCanvas() {
        throw new Error("Unsupported");
    }

    relatedToCanvasObject(obj) {
        return this._conflictData.relatedToCanvasObject(obj);
    }
}


export class ModifiedMgConflict extends MgConflict {
    constructor(conflictData, replaceObject, mgTableRow, detailsTag, canvas, selectCurrentOnCanvasById) {
        super();

        this._conflictData = conflictData;
        this._replaceObject = replaceObject;
        this._mgTableRow = mgTableRow;
        this._detailsTag = detailsTag;
        this._canvas = canvas;
        this._selectCurrentOnCanvasById = selectCurrentOnCanvasById;
    }

    id() {
        return this._conflictData.id();
    }

    selectOnCanvas() {
        this._selectCurrentOnCanvasById(this._conflictData.current().id);
    }

    accept() {
        const merged = this._replaceObject(this._conflictData.current(), this._conflictData.another());

        this._conflictData.accept(merged);
    }

    reject() {
        this._conflictData.reject();
    }

    toMgTableRow() {
        return this._mgTableRow;
    }

    detailsTag() {
        return this._detailsTag;
    }

    relatedToCanvasObject(canvasObj) {
       return this._conflictData.relatedToCanvasObject(canvasObj);
    }

    toCnCanvasObject() {
        return new ModifiedMgCanvasObject(
            this._conflictData.current(),
            this._canvas
        );
    }

    toFilterData() {
        return new DefaultMgFilterData(this._conflictData);
    }
}

export class MovedMgConflict extends MgConflict {
    constructor(conflictData, replaceObject, mgTableRow, detailsTag, canvas, selectCurrentOnCanvasById) {
        super();

        this._conflictData = conflictData;
        this._replaceObject = replaceObject;
        this._mgTableRow = mgTableRow;
        this._detailsTag = detailsTag;
        this._canvas = canvas;
        this._selectCurrentOnCanvasById = selectCurrentOnCanvasById;
    }

    id() {
        return this._conflictData.id();
    }

    selectOnCanvas() {
        this._selectCurrentOnCanvasById(this._conflictData.current().id);
    }

    accept() {
        const merged = this._replaceObject(this._conflictData.current(), this._conflictData.another());

        this._conflictData.accept(merged);
    }

    reject() {
        this._conflictData.reject();
    }

    toMgTableRow() {
        return this._mgTableRow;
    }

    detailsTag() {
        return this._detailsTag;
    }

    relatedToCanvasObject(canvasObj) {
        return this._conflictData.relatedToCanvasObject(canvasObj);
    }

    toCnCanvasObject() {
        return new MovedMgCanvasObject(
            this._conflictData.current(),
            this._conflictData.another(),
            this._canvas
        );
    }

    toFilterData() {
        return new DefaultMgFilterData(this._conflictData);
    }
}

export class RemovedMgConflict extends MgConflict {
    constructor(conflictData, deleteObject, mgTableRow, detailsTag, canvas, selectCurrentOnCanvasById, selectAnotherOnCanvasById) {
        super();

        this._conflictData = conflictData;
        this._deleteObject = deleteObject;
        this._mgTableRow = mgTableRow;
        this._detailsTag = detailsTag;
        this._canvas = canvas;
        this._selectCurrentOnCanvasById = selectCurrentOnCanvasById;
        this._selectAnotherOnCanvasById = selectAnotherOnCanvasById;
    }

    id() {
        return this._conflictData.id();
    }

    selectOnCanvas() {
        this._selectCurrentOnCanvasById(this._conflictData.current().id);
    }

    accept() {
        this._deleteObject(this._conflictData.current().id);
        this._conflictData.accept({});
    }

    reject() {
        this._conflictData.reject();
    }

    toMgTableRow() {
        return this._mgTableRow;
    }

    detailsTag() {
        return this._detailsTag;
    }

    relatedToCanvasObject(canvasObj) {
        return this._conflictData.relatedToCanvasObject(canvasObj);
    }

    toCnCanvasObject() {
        return new RemovedMgCanvasObject(
            this._conflictData.current(),
            this._canvas
        );
    }

    toFilterData() {
        return new DefaultMgFilterData(this._conflictData);
    }
}

export class AddedMgConflict extends MgConflict {
    constructor(conflictData, createNewObject, mgTableRow, detailsTag, canvas, selectAnotherOnCanvasById, selectCurrentOnCanvasById) {
        super();

        this._conflictData = conflictData;
        this._createNewObject = createNewObject;
        this._mgTableRow = mgTableRow;
        this._detailsTag = detailsTag;
        this._canvas = canvas;
        this._selectAnotherOnCanvasById = selectAnotherOnCanvasById;
        this._selectCurrentOnCanvasById = selectCurrentOnCanvasById;
    }

    id() {
        return this._conflictData.id();
    }

    selectOnCanvas() {
        this._selectAnotherOnCanvasById(this._conflictData.another().id);
    }

    accept() {
        const merged = this._createNewObject(this._conflictData.another());
        this._conflictData.accept(merged);
    }

    reject() {
        this._conflictData.reject();
    }

    toMgTableRow() {
        return this._mgTableRow;
    }

    detailsTag() {
        return this._detailsTag;
    }

    relatedToCanvasObject(canvasObj) {
        return this._conflictData.relatedToCanvasObject(canvasObj);
    }

    toCnCanvasObject() {
        return new AddedMgCanvasObject(
            this._conflictData.current(),
            this._conflictData.another(),
            this._canvas
        );
    }

    toFilterData() {
        return new DefaultMgFilterData(this._conflictData);
    }
}

export class ResolvedMgConflict extends MgConflict {
    constructor(origin, conflictData, onMgConflictSelect, selectOnCanvasById, canvas, renderObjectData) {
        super();

        this._origin = origin;
        this._conflictData = conflictData;
        this._onMgConflictSelect = onMgConflictSelect;
        this._selectOnCanvasById = selectOnCanvasById;
        this._canvas = canvas;
        this._renderObjectData = renderObjectData;
    }

    id() {
        return this._conflictData.id();
    }

    accept() {

    }

    reject() {

    }

    toMgTableRow() {
        return new DefaultMgTableRow(
            this._conflictData.identity().text,
            this._conflictData.identity().label,
            new TagCnStatus(this._conflictData.cnStatus()),
            this._conflictData.mgStatus(),
            (
                <></>
            )
        );
    }

    detailsTag() {
        return this._renderObjectData();
    }

    toCnCanvasObject() {
        return new MultipleCnCanvasDecorator(
            [
                new HideOnlyCnCanvasDecorator(
                    this._origin.toCnCanvasObject()
                ),
                new CurrentCnCanvasObject(this._conflictData.merged(), this._canvas, "rgba(0,0,0,0)")
            ]
        );
    }

    selectOnCanvas() {
        const mergedId = this._conflictData.merged().id;

        if (mergedId != null) {
            this._selectOnCanvasById(mergedId);
        }
    }

    relatedToCanvasObject(canvasObj) {
        if (canvasObj == null) {
            return false;
        }

        let result;

        const mergedId = this._conflictData.merged().id;

        if (mergedId == null) {
            result = false;
        } else {
            result = mergedId === canvasObj.objectMetadata.id;
        }

        return result;
    }

    toFilterData() {
        return new DefaultMgFilterData(this._conflictData);
    }
}

export class Cached {
    constructor(target) {
        this._target = target;
        this._cache = null;
    }

    value = () => {
        if (this._cache == null) {
            this._cache = this._target();
        }

        return this._cache;
    };
}

export class DynamicMgConflictDecorator extends MgConflict {
    constructor(origin) {
        super();

        this._origin = new Cached(origin).value;
    }

    selectOnCanvas() {
        this._origin().selectOnCanvas();
    }

    id() {
        return this._origin().id();
    }

    accept() {
        this._origin().accept();
    }

    reject() {
        this._origin().reject();
    }

    toMgTableRow() {
        return this._origin().toMgTableRow();
    }

    detailsTag() {
        return this._origin().detailsTag();
    }

    toCnCanvasObject() {
        return this._origin().toCnCanvasObject();
    }

    relatedToCanvasObject(canvasObj) {
        return this._origin().relatedToCanvasObject(canvasObj);
    }

    toFilterData() {
        return this._origin().toFilterData();
    }
}

export class HideOnlyCnCanvasDecorator extends CnCanvasObject {
    constructor(origin) {
        super();
        this._origin = origin;
    }

    select() {
    }

    deselect() {
        this._origin.deselect();
    }

    addToCanvas() {
    }

    removeFromCanvas() {
        this._origin.removeFromCanvas();
    }

    addHighlight() {
    }

    removeHighlight() {
        this._origin.removeHighlight();
    }

    show() {
    }

    hide() {
        this._origin.hide();
    }
}

export class CnCanvasDecorator extends CnCanvasObject {
    constructor(origin) {
        super();

        this._origin = origin;
    }

    select() {
        this._origin.select();
    }

    deselect() {
        this._origin.deselect();
    }

    addToCanvas() {
        this._origin.addToCanvas();
    }

    removeFromCanvas() {
        this._origin.removeFromCanvas();
    }

    addHighlight() {
        this._origin.addHighlight();
    }

    removeHighlight() {
        this._origin.removeHighlight();
    }

    show() {
        this._origin.show();
    }

    hide() {
        this._origin.hide();
    }
}

export class AddedMgCanvasObject extends CnCanvasDecorator {
    constructor(currentAnnotationObj, anotherAnnotationObj, canvas) {
        super(new AnotherCnCanvasObject(currentAnnotationObj, anotherAnnotationObj, canvas, "rgba(0, 255, 0, 0.6)"));
    }
}

export class ModifiedMgCanvasObject extends CnCanvasDecorator {
    constructor(currentAnnotationObj, canvas) {
        super(new CurrentCnCanvasObject(currentAnnotationObj, canvas, "rgba(0,0,255,0.6)"));
    }
}

export class RemovedMgCanvasObject extends CnCanvasDecorator {
    constructor(currentAnnotationObj, canvas) {
        super(new CurrentCnCanvasObject(currentAnnotationObj, canvas, "rgba(255,0,0,0.6)"));
    }
}

export class ArrowCnCanvasObject extends CnCanvasObject {
    constructor(fromObj, toObj, id, canvas) {
        super();

        this._fromObj = fromObj;
        this._toObj = toObj;
        this._id = id;
        this._canvas = canvas;
    }

    select() {

    }

    deselect() {

    }

    addToCanvas() {
        const from = new Rectangle(this._fromObj.bbox.x1, this._fromObj.bbox.y1, this._fromObj.bbox.x2, this._fromObj.bbox.y2);
        const to = new Rectangle(this._toObj.bbox.x1, this._toObj.bbox.y1, this._toObj.bbox.x2, this._toObj.bbox.y2);

        getFabricjsArrow([
            from.getCenter().x,
            from.getCenter().y,
            to.getCenter().x,
            to.getCenter().y
        ]).forEach(el => {
            el.arrowId = this._id;
            el.selectable = false;
            el.visible = false;

            this._canvas.add(el);
        });
    }

    removeFromCanvas() {
        this._canvas.getObjects()
            .filter(el => el.arrowId === this._id)
            .forEach(el => this._canvas.remove(el));
    }

    addHighlight() {
        this.show();
    }

    removeHighlight() {
        this.hide();
    }

    show() {
        this._canvas.getObjects()
            .filter(el => el.arrowId === this._id)
            .forEach(el => {
                el.visible = true;
            });
    }

    hide() {
        this._canvas.getObjects()
            .filter(el => el.arrowId === this._id)
            .forEach(el => {
                el.visible = false;
            });
    }
}


class CanvasObjects {
    values() {
        return [];
    }
}


class CurrentCanvasObjects extends CanvasObjects {
    constructor(canvas, currentId) {
        super();

        this._canvas = canvas;
        this._currentId = currentId;
    }

    values() {
        const found = this._canvas.getObjects()
            .find(el => el.isProperObject && el.objectMetadata.id === this._currentId);

        if (!found) return [];

        return [found];
    }
}

class AnotherCanvasObjects extends CanvasObjects {
    constructor(canvas, anotherId) {
        super();

        this._canvas = canvas;
        this._anotherId = anotherId;
    }

    values() {
        const found = this._canvas.getObjects()
            .find(el => el.fromAnotherDrawing && el.objectMetadata?.id === this._anotherId);

        if (!found) return [];

        return [found];
    }
}

export class CachedCanvasObjects extends CanvasObjects {
    constructor(origin) {
        super();

        this._origin = origin;
        this._cache = null;
    }

    values() {
        if (this._cache == null) {
            this._cache = this._origin.values();
        }

        return this._cache;
    }
}

export class AnotherCnCanvasObject extends CnCanvasObject {
    constructor(currentAnnotationObject, anotherAnnotationObject, canvas, color) {
        super();

        this._currentAnnotationObject = currentAnnotationObject;
        this._anotherAnnotationObject = anotherAnnotationObject;
        this._canvas = canvas;
        this._color = color;

        this._relatedCanvasObjects = new CachedCanvasObjects(new AnotherCanvasObjects(canvas, anotherAnnotationObject.id));
    }

    select() {
        super.select();
    }

    deselect() {
        super.deselect();
    }

    addToCanvas() {
        const obj = this._anotherAnnotationObject;

        const rect = new fabric.Rect({
            ...viewerConsts.DEFAULT_OBJECT_ARGS,
            width: Math.round(obj.bbox.x2 - obj.bbox.x1),
            height: Math.round(obj.bbox.y2 - obj.bbox.y1),
            stroke: "rgba(141,141,139,0.6)",
            top: Math.round(obj.bbox.y1),
            left: Math.round(obj.bbox.x1),
            strokeWidth: viewerConsts.STROKE_WIDTH,
            strokeDashArray: [15, 15],
            rx: 0.5,
            ry: 0.5
        });

        rect.hasControls = false;
        rect.lockMovementX = true;
        rect.lockMovementY = true;

        rect.visible = false;

        rect.isProperObject = false;
        rect.fromAnotherDrawing = true;

        rect.objectMetadata = {...obj};
        rect.origin = {...this._currentAnnotationObject};

        this._canvas.add(rect);
    }

    removeFromCanvas() {
        this._relatedCanvasObjects.values()
            .forEach(el => this._canvas.remove(el));
    }

    addHighlight() {
        this._relatedCanvasObjects.values()
            .forEach(el => {
                el.fill = this._color;
                el.baseFillColor = this._color;
            });

        this.show();
    }

    removeHighlight() {
        this._relatedCanvasObjects.values()
            .forEach(el => {
                el.fill = "rgba(0,0,0,0)";
                el.baseFillColor = "rgba(0,0,0,0)";
            });

        this.hide();
    }

    show() {
        this._relatedCanvasObjects.values()
            .forEach(el => {
                el.visible = true;
            });
    }

    hide() {
        this._relatedCanvasObjects.values()
            .forEach(el => {
                el.visible = false;
            });
    }
}


export class CurrentCnCanvasObject extends CnCanvasObject {
    constructor(currentAnnotationObj, canvas, color) {
        super();

        this._currentAnnotationObject = currentAnnotationObj;
        this._canvas = canvas;
        this._color = color;

        this._relatedCanvasObjects = new CachedCanvasObjects(new CurrentCanvasObjects(canvas, currentAnnotationObj.id));
    }

    select() {
        super.select();
    }

    deselect() {
        super.deselect();
    }

    addToCanvas() {
        super.addToCanvas();
    }

    removeFromCanvas() {
        this.removeHighlight();
    }

    addHighlight() {
        this._relatedCanvasObjects.values()
            .forEach(el => {
                el.fill = this._color;
                el.baseFillColor = this._color;
            });
    }

    removeHighlight() {
        this._relatedCanvasObjects.values()
            .forEach(el => {
                el.fill = "rgba(0,0,0,0)";
                el.baseFillColor = "rgba(0,0,0,0)";
            });
    }

    show() {
        this._relatedCanvasObjects.values()
            .forEach(el => {
                el.visible = true;
            });
    }

    hide() {
        this._relatedCanvasObjects.values()
            .forEach(el => {
                el.visible = false;
            });
    }
}


export class MultipleCnCanvasDecorator extends CnCanvasObject {
    constructor(origins) {
        super();

        this._origins = origins;
    }

    select() {
        this._origins.forEach(el => el.select());
    }

    deselect() {
        this._origins.forEach(el => el.deselect());
    }

    addToCanvas() {
        this._origins.forEach(el => el.addToCanvas());
    }

    removeFromCanvas() {
        this._origins.forEach(el => el.removeFromCanvas());
    }

    addHighlight() {
        this._origins.forEach(el => el.addHighlight());
    }

    removeHighlight() {
        this._origins.forEach(el => el.removeHighlight());
    }

    show() {
        this._origins.forEach(el => el.show());
    }

    hide() {
        this._origins.forEach(el => el.hide());
    }
}


export class MovedMgCanvasObject extends MultipleCnCanvasDecorator {
    constructor(currentAnnotationObject, anotherAnnotationObj, canvas) {
        super(
            [
                new CurrentCnCanvasObject(currentAnnotationObject, canvas, "rgba(128,0,255,0.6)"),
                new ArrowCnCanvasObject(currentAnnotationObject, anotherAnnotationObj, currentAnnotationObject.id, canvas),
                new AnotherCnCanvasObject(currentAnnotationObject, anotherAnnotationObj, canvas, "rgba(128,0,255,0.6)")
            ]
        );
    }
}