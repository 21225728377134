import {PluginAnonymous} from "../../../services/Plugins";
import {ResourcesSource} from "../../../services/Resources";
import {CanvasGroup} from "../Resources/CanvasGroup";

export class PluginCanvasWheelZoom extends PluginAnonymous<ResourcesSource> {
    constructor(canvasId: string) {
        super(`${canvasId}-canvas-wheel-zoom`, {
            async build(target: ResourcesSource) {
                const canvasGroup = target.res(CanvasGroup).get();
                const canvas = canvasGroup.getById(canvasId);
                const zoom = canvas.zoom;
                canvas.events.mouseWheel$.subscribe(opt => {
                    const delta = opt.e.deltaY;

                    let nextZoom = zoom.value();
                    nextZoom *= 0.999 ** delta;
                    zoom.toPoint({x: opt.e.offsetX, y: opt.e.offsetY}, nextZoom);

                    opt.e.preventDefault();
                    opt.e.stopPropagation();
                });
            }
        });
    }
}