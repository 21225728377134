import React, {Dispatch, SetStateAction, useState} from "react";
import {Form, FormProps, Modal, ModalProps, Spin} from "antd";

type FormModalProps = {
    formId: string,
    onSubmit: (values: any) => Promise<any>,
    open: boolean,
    setModalOpen: Dispatch<SetStateAction<boolean>>,
    modalProps?: Partial<ModalProps>,
    formProps?: Omit<Partial<FormProps>, "onFinish">,
    children?: React.ReactNode;
}

export function FormModal({formId, onSubmit, open, setModalOpen, modalProps, formProps, children}: FormModalProps) {
    const [spinning, setSpinning] = useState(false);

    const submit = (values: any) => {
        setSpinning(true);
        onSubmit(values)
            .then(() => setModalOpen(false))
            .finally(() => setSpinning(false));
    };

    return (
        <Modal
            okText={"Create"}
            {...modalProps}
            open={open}
            onCancel={() => setModalOpen(false)}
            destroyOnClose={true}
            okButtonProps={{
                type: "primary",
                ...modalProps?.okButtonProps,
                htmlType: "submit",
                form: formId,
            }}
            modalRender={node => (
                <Spin spinning={spinning}>
                    {modalProps?.modalRender?.(node) ?? node}
                </Spin>
            )}
        >
            <Form
                {...formProps}
                id={formId}
                onFinish={submit}
            >
                {children}
            </Form>
        </Modal>
    );
}
