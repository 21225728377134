import {Button, Form, Input, Modal, Row, Table, message} from "antd";
import React, {useEffect, useState} from "react";
import HierarchiesService from "../../../services/HierarchiesService";
import Highlighter from "react-highlight-words";

export default function SearchInHierarchy({hierarchy, parent, closeModal}) {
  const [foundNodes, setFoundNodes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pagination, setPagination] = useState({current: 1, pageSize: 10});
  const [inputText, setInputText] = useState("");

  const formLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const loadData = (curQuery, curPagination) => {
    HierarchiesService.searchInHierarchy(hierarchy, curQuery, curPagination.current, curPagination.pageSize).then(res => {
      setFoundNodes(res.data.nodes);
      setPagination({
        current: res.data.pagination.page,
        pageSize: res.data.pagination.limit,
        total: res.data.pagination.total,
      });
    }).catch(() => {
      message.error("Failed to search in hierarchy");
    });
  };

  const handleTableChange = (newPagination) => {
    loadData(searchQuery, newPagination);
  };

  useEffect(() => {
    loadData(searchQuery, pagination);
  }, []);

  const columns = [
    {
      title: "Text",
      dataIndex: "text",
      render: (value) => {
        return (<Highlighter
            highlightStyle={{ backgroundColor: "var(--color-yellow)", padding: 0 }}
            searchWords={[searchQuery]}
            autoEscape
            textToHighlight={value}
        />);
      }
    },
    {
      title: "Class",
      dataIndex: "label",
    },
    {
      title: "Actions",
      dataIndex: "id",
      render: (id, record) => {
        return (
            <a onClick={() => {
              parent.loadHierarchy([{text: record.text, label: record.label}], () => {

                parent.selectNode(parent.state.hierarchy.getNodeById(id), false);
                closeModal();
              });
            }}>Open</a>
        );
      }
    }
  ];

  const handleSearch = () => {
    setSearchQuery(inputText);
    loadData(inputText, {current: 1, pageSize: 10});
  };

  return (
      <div>
        <Input.Search id="hierarchy-search-box" onChange={e => setInputText(e.target.value)} value={inputText} enterButton
                      onSearch={handleSearch} style={{marginBottom: "16px"}}/>
        <Table id="hierarchy-search-results-table"
               dataSource={foundNodes} columns={columns} onChange={handleTableChange} pagination={pagination}/>
      </div>
    //   <Form {...formLayout} name="add-from-attribute"
    //                       onFinish={(values) => {
    //
    //                       }
    //                       }>
    // <Form.Item name={'search_query'} label="" rules={[{ required: true }]}>
    //
    // </Form.Item>
    //
    //
    //
    // <Row>
    //   <Button type="primary" htmlType="submit" style={{marginLeft: 'auto'}}>
    //     Search
    //   </Button>
    // </Row>
  // </Form>
  );
}