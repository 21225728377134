import axios from "axios";
import {API_URL} from "../constants";
import {authHeader, download} from "../Utilities";
import {message} from "antd";
import contentDisposition from "content-disposition";

class GuideService {

    _downloadGuide(url) {
        axios.get(
            url,
            {headers: authHeader(), responseType: "blob"}
        ).then(response => {
            const blob = new window.Blob([response.data], { type: "application/pdf" });
            const contentDispositionHeader = response.headers["content-disposition"];
            const fileName = contentDisposition.parse(contentDispositionHeader).parameters.filename;
            download(blob, fileName);
        }).catch(err => {
            message.error("Failed to download");
        });
    }

    downloadGuideByType(type) {
        this._downloadGuide(API_URL + `/global_settings/guides/${type}`);
    }

    downloadUserGuide() {
        this.downloadGuideByType("user");
    }

    downloadAdminGuide() {
        this.downloadGuideByType("admin");
    }
}

export default new GuideService();