import React from "react";
import {HierarchiesList} from "./Hierarchies/HierarchiesList";
import {ViewHierarchy} from "./Hierarchies/ViewHierarchy";

export class HierarchiesView extends React.Component {
  state = {
    mode: "list",
    currentHierarchyId: null,
  };

  handleView = hierarchyId => {
    this.setState({
      mode: "view",
      currentHierarchyId: hierarchyId,
    });
  };

  render() {
    return (
      <React.Fragment>
        { this.state.mode === "list" && <HierarchiesList onView={this.handleView} /> }
        { this.state.mode === "view" &&
          <ViewHierarchy
            imageViewer={this.props.imageViewer}
              hierarchyId={this.state.currentHierarchyId} onBack={() => this.setState({mode: "list"})}/>
        }
      </React.Fragment>
    );
  }
}