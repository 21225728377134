import {filter, map, pairwise, startWith, switchMap, takeUntil, tap, withLatestFrom} from "rxjs/operators";
import {viewerModes} from "../../../constants";
import {EMPTY, of} from "rxjs";
import {message} from "antd";
import {WrapperObjectHelper} from "./WrapperObjectHelper";

// shape format example
// const testGraphDetails = {
//   id: 3333,
//   shape: {
//     shape_type: 'graph',
//     shape_data: {
//       vertices: [
//         {id: 1, x: 300, y: 300},
//         {id: 2, x: 500, y: 300},
//         {id: 3, x: 600, y: 100},
//         {id: 4, x: 700, y: 300},
//         {id: 5, x: 600, y: 500},
//         {id: 6, x: 400, y: 500},
//       ],
//       edges: [
//         {id: 7, start_vertex: 1, end_vertex: 2},
//         {id: 8, start_vertex: 2, end_vertex: 3},
//         {id: 9, start_vertex: 2, end_vertex: 4},
//         {id: 10, start_vertex: 4, end_vertex: 5},
//         {id: 11, start_vertex: 5, end_vertex: 6},
//       ]
//     }
//   }
// }


export class GraphObjectHandler {
  constructor(imageViewer) {
    this.imageViewer = imageViewer;
    this.curWrapperObject = null;
  }

  handleCreateNew = () => {
    this.curWrapperObject = WrapperObjectHelper.buildEmpty(this.imageViewer);
    this.curWrapperObject.wrappingData.helper.addToCanvas();
    this.imageViewer.objectSelected$.next({target: this.curWrapperObject, source: "canvas"});
    this.imageViewer.mode$.next(viewerModes.GRAPH_OBJECT_MODE);
    this.imageViewer.setState({isCreatingNewPiping: true});
  };

  handleCancelCreatingNew = () => {
    this.curWrapperObject.wrappingData.helper.removeItself();
    this.imageViewer.setState({isCreatingNewPiping: false});
    this.imageViewer.mode$.next(viewerModes.NORMAL);
    this.imageViewer.objectSelected$.next({target: null, source: "canvas"});
    this.imageViewer.objectsChanged$.next(1);
  };

  handleStartEditing = wrapperObject => {
    this.curWrapperObject = wrapperObject;
    this.imageViewer.mode$.next(viewerModes.GRAPH_OBJECT_MODE);
    this.imageViewer.setState({isEditingPiping: true});
  };

  handleFinishEditing = () => {
    if (this.curWrapperObject.wrappingData.helper.getVerticesRaw().length === 0) message.error("The piping is empty");
    else {
      this.curWrapperObject.wrappingData.helper.discardActiveVertex();
      this.imageViewer.mode$.next(viewerModes.NORMAL);
      this.imageViewer.setState({isEditingPiping: false, isCreatingNewPiping: false});
      this.curWrapperObject = null;
    }
  };

  handleSetColor = (newColor) => {
    this.curWrapperObject.wrappingData.helper.setColor(newColor);
  };

  registerEvents = () => {
    const canvas = this.imageViewer.canvas;

    // select piping object by clicking on vertex
    this.imageViewer.subscriptions.push(this.imageViewer.mouseUp$.pipe(
        withLatestFrom(this.imageViewer.mode$),
        filter(([_, mode]) => mode === viewerModes.NORMAL), map(([opt, _]) => opt),

        tap(opt => {
          const curPoint = this.imageViewer.canvas.getPointer(opt.e);
          const matchingObjects = canvas.getObjects().filter(
              obj => obj.wrappingData?.objectType === "vertex" && obj.containsPoint(curPoint, null, true)
          );

          if (matchingObjects.length === 0) return;

          const clickedVertex = matchingObjects[0];
          this.imageViewer.objectSelected$.next({target: clickedVertex.wrappingData.wrapperObject, source: "canvas"});
        }),
    ).subscribe());

    this.imageViewer.subscriptions.push(this.imageViewer.mouseMove$.pipe(
      withLatestFrom(this.imageViewer.mode$),
      filter(([_, mode]) => mode === viewerModes.GRAPH_OBJECT_MODE), map(([opt, _]) => opt),
      tap(opt => {
        const curPoint = this.imageViewer.canvas.getPointer(opt.e);
        const tracingLineObject = this.curWrapperObject.wrappingData.helper.activeVertexTracingLineObject;
        if (tracingLineObject) {
          tracingLineObject.set({x2: curPoint.x, y2: curPoint.y});
          this.imageViewer.canvas.renderAll();
        }
      })
    ).subscribe());

    // create vertex on singe click
    // or select active vertex
    this.imageViewer.subscriptions.push(this.imageViewer.mouseDown$.pipe(
      withLatestFrom(this.imageViewer.mode$),
      filter(([_, mode]) => mode === viewerModes.GRAPH_OBJECT_MODE), map(([opt, _]) => opt),

      switchMap(opt => this.imageViewer.mouseUp$.pipe(
        takeUntil(this.imageViewer.mouseMove$),
        tap(() => {
          const curPoint = this.imageViewer.canvas.getPointer(opt.e);

          const curHelper = this.curWrapperObject.wrappingData.helper;
          const verticesList = curHelper.getVerticesRaw().map(v => curHelper.getWrappedObjects().get(v.id));

          const matchingObjects = verticesList.filter(
              (obj) => obj.containsPoint(curPoint, null, true)
          );

          if (matchingObjects.length === 0) {
            // adding new vertex
            const newVertexId = curHelper.addNewVertex(curPoint.x, curPoint.y);
            const newVertex = curHelper.getWrappedObjects().get(newVertexId);

            if (curHelper.activeVertex !== null) {
              // toggling edge between the vertices
              curHelper.toggleEdge(curHelper.activeVertex, newVertex);
            }
            curHelper.setActiveVertex(newVertex);

          } else {
            // setting active vertex
            const activeVertexToSet = matchingObjects[0];
            if (activeVertexToSet === curHelper.activeVertex) {
              curHelper.discardActiveVertex();
            } else {
              if (curHelper.activeVertex !== null) {
                // toggling edge between the vertices
                curHelper.toggleEdge(curHelper.activeVertex, activeVertexToSet);
              }
              curHelper.setActiveVertex(activeVertexToSet);
            }
          }
        }),
      ))
    ).subscribe());

    // handle panning
    this.imageViewer.subscriptions.push(this.imageViewer.mouseDown$.pipe(
        withLatestFrom(this.imageViewer.mode$),
        filter(([_, mode]) => mode === viewerModes.GRAPH_OBJECT_MODE), map(([opt, _]) => opt),

        filter(opt => {
          const curPoint = this.imageViewer.canvas.getPointer(opt.e);

          const curHelper = this.curWrapperObject.wrappingData.helper;
          const verticesList = curHelper.getVerticesRaw().map(v => curHelper.getWrappedObjects().get(v.id));

          const matchingObjects = verticesList.filter(
              (obj) => obj.containsPoint(curPoint, null, true)
          );

          return matchingObjects.length === 0;
        }),
        tap(opt => {
          this.imageViewer.canvas.selection = false;
        }),
        map(opt => ({x: opt.e.clientX, y: opt.e.clientY})),
        switchMap(opt => this.imageViewer.mouseMove$.pipe(
            map(opt2 => ({x: opt2.e.clientX, y: opt2.e.clientY})),
            startWith(opt),
            pairwise(),
            tap(pair => {
              const curPoint = pair[1];
              const prevPoint = pair[0];
              var vpt = this.imageViewer.canvas.viewportTransform;
              vpt[4] += curPoint.x - prevPoint.x;
              vpt[5] += curPoint.y - prevPoint.y;
              this.imageViewer.zoomHandler.clipViewPort();
              this.imageViewer.renderAll$.next(1);
              this.imageViewer.viewPortChanged$.next(1);
            }),
            takeUntil(this.imageViewer.mouseUp$.pipe(tap(() => {
              this.imageViewer.canvas.selection = false;
            })))
        ))).subscribe());

    // handle vertex moving
    this.imageViewer.subscriptions.push(this.imageViewer.mouseDown$.pipe(
        withLatestFrom(this.imageViewer.mode$),
        filter(([_, mode]) => mode === viewerModes.GRAPH_OBJECT_MODE), map(([opt, _]) => opt),

        switchMap(opt => {
          const curPoint = this.imageViewer.canvas.getPointer(opt.e);

          const curHelper = this.curWrapperObject.wrappingData.helper;
          const verticesList = curHelper.getVerticesRaw().map(v => curHelper.getWrappedObjects().get(v.id));

          const matchingObjects = verticesList.filter(
              (obj) => obj.containsPoint(curPoint, null, true)
          );
          if (matchingObjects.length === 0) return EMPTY;
          const selectedVertex = matchingObjects[0];
          return of({vertex: selectedVertex, dx: curPoint.x - selectedVertex.left, dy: curPoint.y - selectedVertex.top});
        }),
        switchMap(vertexData => this.imageViewer.mouseMove$.pipe(
          tap(opt => {
            const curPoint = this.imageViewer.canvas.getPointer(opt.e);
            vertexData.vertex.set({left: curPoint.x - vertexData.dx, top: curPoint.y - vertexData.dy});
            vertexData.vertex.setCoords();

            const curHelper = this.curWrapperObject.wrappingData.helper;
            const verts = curHelper.getVerticesRaw();
            const vertInd = verts.findIndex(vert => vert.id === vertexData.vertex.wrappingData.selfId);
            verts[vertInd] = {...verts[vertInd], x: vertexData.vertex.left, y: vertexData.vertex.top};


            if (vertexData.vertex === this.curWrapperObject.wrappingData.helper.activeVertex) {
              this.curWrapperObject.wrappingData.helper.activeVertexExtraObject.set({
                left: vertexData.vertex.left,
                top: vertexData.vertex.top
              });
              this.curWrapperObject.wrappingData.helper.activeVertexExtraObject.setCoords();
              this.curWrapperObject.wrappingData.helper.activeVertexTracingLineObject.set({
                x1: vertexData.vertex.left,
                y1: vertexData.vertex.top
              });
            }
            curHelper.updateEdges();
            this.imageViewer.canvas.requestRenderAll();
          }),
          takeUntil(this.imageViewer.mouseUp$.pipe(tap(() => {
            const curHelper = this.curWrapperObject.wrappingData.helper;
            curHelper.updateBBox();
          }))),
        )),
    ).subscribe());

    this.imageViewer.subscriptions.push( this.imageViewer.escapeKeyPressed$.pipe(
        withLatestFrom(this.imageViewer.mode$),
        filter(([_, mode]) => mode === viewerModes.GRAPH_OBJECT_MODE), map(([opt, _]) => opt),
    ).subscribe(() => this.curWrapperObject.wrappingData.helper.discardActiveVertex()));

    this.imageViewer.subscriptions.push(this.imageViewer.deleteKeyPressed$.pipe(
        withLatestFrom(this.imageViewer.mode$),
        filter(([_, mode]) => mode === viewerModes.GRAPH_OBJECT_MODE), map(([opt, _]) => opt),
    ).subscribe(() => {
      const helper = this.curWrapperObject.wrappingData.helper;
      if (helper.activeVertex) {
        if (helper.getVerticesRaw().length === 1) message.error("Can not remove the last vertex from the piping.");
        else helper.removeVertex(helper.activeVertex.wrappingData.selfId);
      }
    }));
  };
}
