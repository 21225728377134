import { connect } from "react-redux";
import { createSelector } from "reselect";
import { ExploreResults } from "../components/ExploreResults";
import { fetchRuns, fetchRunResults } from "../actions/actions";
import { push } from "connected-react-router";

const processingResults = state => state.runResultsList.payload.processing_results;
const processingRuns = state => state.runsList.payload.processing_runs;

export const processingResultMapper = createSelector(
  [processingResults],
  (processing_results) => (processing_results || []).map((runResultInfo) => ({
    fileName: runResultInfo.file_name,
    pageNumber: runResultInfo.page_number,
    id: runResultInfo.id,
    key: runResultInfo.id,
    tags: runResultInfo.tags,
    fields: runResultInfo.fields,
    tagsRegex: runResultInfo.regex_tags,
    fieldsRegex: runResultInfo.regex_fields,
    isPage: runResultInfo.is_page,
  }))
);

export const runListMapper = createSelector(
  [processingRuns],
  (processing_runs) => (processing_runs || []).map((runInfo) => ({
    run: runInfo.name,
    id: runInfo.id,
    status: {
      totalPages: runInfo.total_pages,
      processedPages: runInfo.processed_pages
    }
  }))
);

const mapStateToProps = state => {
  return {
    runsList: runListMapper(state),
    runResultsList: processingResultMapper(state),
    isRunResultsFetching: state.runResultsList.isFetching,
    isRunsListFetching: state.runsList.isFetching
  };
};

const actionCreators = {
  fetchRuns,
  fetchRunResults,
  push
};


const ExploreResultsContainer = connect(mapStateToProps, actionCreators)(ExploreResults);

export default ExploreResultsContainer;
