import React, {useState} from "react";
import {Button, Col, Form, Input, Popover, Radio, Row, Select, Space, Tabs} from "antd";
import {DataSourceFiltersEditor, emptyAlias} from "../../Misc/DataSourceFilter";
import {Option} from "antd/es/mentions";
import axios from "axios";
import {authHeader} from "../../../Utilities";
import {EndlessScrollRemoteAutocomplete} from "../../Misc/EndlessScrollRemoteAutocomplete";
import {filterOperationsMap} from "./SearchFilters";

export function RemoteFiltersEditor({fetchOptionsUrl, filters, onFiltersChanged, filterSchemas, contentRenderer}) {
    const defaultRenderer = (tagsList, editor) => (
        <Row justify={"space-between"} style={{marginBottom: 8}}>
            <Col span={15}>
                {tagsList}
            </Col>
            <Col>
                {editor}
            </Col>
        </Row>
    );

    return (
        <DataSourceFiltersEditor
            itemFilters={filters}
            onItemFiltersChanged={onFiltersChanged}
            filterSchemas={filterSchemas}
            fieldEditorRenderer={(filterSchemas, addNewFilter) => (
                <RemoteFilterEditor fetchOptionsUrl={fetchOptionsUrl}
                                             filterSchemas={filterSchemas}
                                             onNewFilter={addNewFilter}/>
            )}
            contentRenderer={contentRenderer || defaultRenderer}
        />
    );
}

function RemoteFilterEditor({fetchOptionsUrl, onNewFilter, filterSchemas}) {
    const [visible, setVisible] = useState(false);

    const fieldEditor = (
        <RemoteFilterEditorContent
            attributeLabel={"Field name"}
            fetchOptionsSchema={{url: fetchOptionsUrl, option_target: "page_field"}}
            filterSchemas={filterSchemas.filter(schema => schema.target === "page_field")}
            onOk={filter => {
                onNewFilter(filter);
                setVisible(false);
            }}
            onCancel={_ => setVisible(false)}
        />
    );

    const attributeEditor = (
        <RemoteFilterEditorContent
            attributeLabel={"Attribute name"}
            fetchOptionsSchema={{url: fetchOptionsUrl, option_target: "object_attr"}}
            filterSchemas={filterSchemas.filter(schema => schema.target === "object_attr")}
            onOk={filter => {
                onNewFilter(filter);
                setVisible(false);
            }}
            onCancel={_ => setVisible(false)}
        />
    );

    const editorWithTabs = (
        <Tabs defaultActiveKey={"field"}>
            <Tabs.TabPane tab={"Field"} key={"field"}>
                {fieldEditor}
            </Tabs.TabPane>
            <Tabs.TabPane tab={"Attribute"} key={"attribute"}>
                {attributeEditor}
            </Tabs.TabPane>
        </Tabs>
    );

    const hasObjectAttrSchemas = filterSchemas.filter(schema => schema.target === "object_attr").length > 0;

    const editorContent = (
        <div style={{width: "400px"}}>
            {hasObjectAttrSchemas ? editorWithTabs : fieldEditor}
        </div>
    );

    return (
        <Popover
            visible={visible}
            destroyTooltipOnHide={true}
            trigger="click"
            title="Add filter"
            placement="bottom"
            content={editorContent}
            onVisibleChange={visible => {
                setVisible(visible);
            }}
        >
            <Button
                id="add-filter-button"
                icon={<img src={process.env.PUBLIC_URL + "/images/filter_list-24px.svg"}/>}
                size="small"
            >
                Add filter
            </Button>
        </Popover>
    );
}

function RemoteFilterEditorContent({fetchOptionsSchema, filterSchemas, onOk, onCancel, attributeLabel}) {
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [selectedValue, setSelectedValue] = useState("");
    const [filterOperation, setFilterOperation] = useState(filterOperationsMap.equal);

    const allowedAttributes = [...new Set(filterSchemas.map((schema) => schema.attribute))];

    const searchableSelectProps = {
        size: "small",
        showSearch: true,
        filterOption: filterOption,
    };

    function filterOption(input, option) {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }

    return (
        <Form labelCol={{span: 6}} wrapperCol={{span: 18}}>
            <Form.Item label={attributeLabel}>
                <Select
                    id="attribute-select"
                    {...searchableSelectProps}
                    value={selectedAttribute}
                    onSelect={(attr) => {
                        setSelectedAttribute(attr);
                        setSelectedValue(null);
                    }}
                >
                    {
                        allowedAttributes.map((attr, idx) => {
                            const option = attr === "" ? emptyAlias : attr;
                            return (<Option id={`attribute-select-option_${attr}`} value={attr}>{option}</Option>);
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item label="Value">
                {filterOperation === filterOperationsMap.contains ? <Input id="value-input"
                                                    size="small"
                                                    value={selectedValue}
                                                    onChange={e => setSelectedValue(e.target.value)}
                    />
                    :
                    <EndlessScrollRemoteAutocomplete
                        key={selectedAttribute}
                        fetchOptions={(value, maxResults) => {
                            const params = {
                                option_key: selectedAttribute,
                                option_value_part: value,
                                max_results: maxResults,
                                option_target: fetchOptionsSchema.option_target
                            };

                            return axios.get(
                                fetchOptionsSchema.url,
                                {
                                    params,
                                    timeout: 30 * 1000,
                                    headers: authHeader()
                                }
                            );
                        }}
                        onSelect={value => setSelectedValue(value || "")}
                        onChange={value => setSelectedValue(value || "")}
                    />}
            </Form.Item>
            <Radio.Group onChange={e => {setFilterOperation(e.target.value);}} defaultValue={filterOperationsMap.equal}>
                <Space direction="vertical">
                    <Radio value={filterOperationsMap.equal}>Full match</Radio>
                    <Radio value={filterOperationsMap.contains}>Partial match</Radio>
                </Space>
            </Radio.Group>
            <Row>
                <Space style={{marginLeft: "auto"}}>
                    <Button
                        id="cancel-filter-button"
                        size="small"
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        id="apply-filter-button"
                        type="primary" size="small"
                        onClick={() => {
                            if (selectedAttribute == null) {
                                return;
                            }

                            const schema = filterSchemas.find((schema) => schema.attribute === selectedAttribute);
                            const newFilter = schema.constructor(selectedValue || "", filterOperation);

                            onOk(newFilter);
                        }}
                    >
                        Add
                    </Button>
                </Space>
            </Row>
        </Form>
    );
}
