import React, {useCallback, useState} from "react";
import {DataEditorProps, Item, Rectangle} from "@glideapps/glide-data-grid";
import {Dropdown} from "antd";
import {ItemType} from "antd/lib/menu/hooks/useItems";
import {DeleteOutlined} from "@ant-design/icons";

type CellContextMenuProps = {
    itemsOf(cell: Item): ItemType[];
}

export function useCellContextMenu({itemsOf}: CellContextMenuProps) {
    const [bounds, setBounds] = useState<Rectangle>();
    const [isOpen, setIsOpen] = useState(false);
    const [cell, setCell] = useState<Item | undefined>(undefined);

    const onCellContextMenu = useCallback<NonNullable<DataEditorProps["onCellContextMenu"]>>((cell, event) => {
        event.preventDefault();
        setBounds(event.bounds);
        setCell(cell);
        setIsOpen(false);
        setTimeout(() => {
            setIsOpen(true);
        });
    }, []);

    const menu = (
        <Dropdown
            menu={{
                onClick: console.log,
                items: cell != null ? itemsOf(cell) : []
            }}
            // content={(
            //     <Menu
            //         items={cell != null ? itemsOf(cell) : []}
            //     />
            // )}
            trigger={["hover", "click"]}
            open={isOpen}
            onOpenChange={open => {
                if (!isOpen) return;
                setIsOpen(open);
            }}
        >
            <div
                style={{
                    position: "fixed",
                    bottom: (bounds?.y ?? 0) + (bounds?.height ?? 0),
                    height: bounds?.height ?? 0,
                    left: bounds?.x ?? 0,
                    right: (bounds?.x ?? 0) + (bounds?.width ?? 0),
                    top: bounds?.y ?? 0,
                    width: bounds?.width ?? 0,
                }}
            />
        </Dropdown>
    );

    return {
        onCellContextMenu,
        cellContextMenu: menu
    };
}

type OnDeleteSource = {
    onDelete(): void
}

export function CellContextItemDeleteRow(target: OnDeleteSource) {
    return {
        label: "Delete row",
        danger: true,
        onClick: () => target.onDelete(),
        key: "delete",
        icon: <DeleteOutlined/>,
    };
}
