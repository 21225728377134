import React from "react";
import {Button, Form, Input, Col, Row, Layout, Card, message, Spin} from "antd";
import AuthService from "../../services/AuthService";
import KHHeader from "../KHHeader";
import * as queryString from "query-string";

export class PasswordReset extends React.Component {
  state = {
    isLoading: true
  };

  componentDidMount() {
    const urlParams = queryString.parse(this.props.location.search);
    AuthService.validateResetPasswordToken(
        urlParams.email,
        urlParams.token,
    ).then(() => {
      this.setState({isLoading: false});
    }).catch(() => {
      message.error("Link is not valid anymore");
      this.props.history.push("/login");
    });
  }

  onFinish = (values) => {
    const urlParams = queryString.parse(this.props.location.search);
    AuthService.changePassword(
        urlParams.email,
        urlParams.token,
        values.password
    ).then(() => {
      let message_text = "Password is changed";
      if (this.props.mode === "create") message_text = "Password created";
      message.success(message_text, 5);
      this.props.history.push("/login");
    }).catch(() => {
      let message_text = "Failed to change password";
      if (this.props.mode === "create") message_text = "Failed to create password";
      message.error(message_text);
    });
  };

  render() {
    const layout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
    };
    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };
    return (
        <Layout  style={{height:"100vh"}}>
          {/*<KHHeader skipUser={true}/>*/}
          <Layout.Content style={{width: "100%", alignSelf: "center", padding: "24px"}}>
            <Col span={8} offset={8}>
              {this.state.isLoading && <Spin size="large" style={{position: "absolute", top: "30vh", left: "50%"}}/>}
              {!this.state.isLoading &&
                <Card title="Create new password">
                  <Form
                      {...layout}
                      name="basic"
                      initialValues={{remember: true}}
                      onFinish={this.onFinish}
                  >
                    <Form.Item
                        label="New password"
                        name="password"
                        hasFeedback
                        rules={[{required: true, message: "Please input your password!"},
                          { min: 8, message: "Password must be at least 8 characters" }]}
                    >
                      <Input.Password/>
                    </Form.Item>

                    <Form.Item
                        label="Confirm new password"
                        name="password_confirmation"
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                            {required: true, message: "Please input your password confirmation!"},
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || getFieldValue("password") === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject("The two passwords that you entered do not match!");
                              },
                            }),
                        ]}
                    >
                      <Input.Password/>
                    </Form.Item>

                    <Row>
                      <Button type="primary" htmlType="submit" style={{marginLeft: "auto"}}>
                        Save
                      </Button>
                    </Row>

                  </Form>
                </Card>
              }
            </Col>
          </Layout.Content>
        </Layout>
    );
  }
}