import React from "react";
import {Link} from "react-router-dom";
import {fetchRuns} from "../actions/actions";
import {Button, Checkbox, Col, Form, Input, Modal, Popconfirm, Progress, Row, Select, Table, Tabs, Tooltip} from "antd";
import {API_URL} from "../constants";
import axios from "axios";
import {TagPreview} from "./ExploreResults";
import {LinkingRules} from "./LinkingRules";
import {authHeader} from "../Utilities";
import {PostProcessingRules} from "./PostProcessingRules";
import {Buffer} from "buffer/";

const { TabPane } = Tabs;

export class ExtractTags extends React.Component {
  state = {
    tagsStats: {
      template_stats: [],
      agg_stats: {
        total_tags: null,
        covered_tags: null
      }
    },
    rulesList: [],
    newRuleVisible: false,
    newRuleType: null,
    newRuleName: "",
    newRuleRegexp: "^$",
    newRuleTagType: "",
    previewedTags: [],
    modelName: "",
    extractObjects: false,
    activeTabKey: "processing",
  };

  componentDidMount() {
    this.props.dispatch(fetchRuns(this.props.match.params.projectId));

    if (this.props.match.params.textRule) {
      this.onPaneChange("text_rules");
      const curRegexp = new Buffer(this.props.match.params.textRule, "base64").toString("utf-8");
      this.setState({
        newRuleType: "create_tag",
        newRuleName: "",
        newRuleRegexp: curRegexp,
        newRuleTagType: "",
      },() => this.showModal());
    }
  }

  _loadStats = (projectId, runId) => {
    axios.get(
        API_URL + `/projects/${projectId}/tags_stats`,
        {headers: authHeader()}
    ).then(result => {
      const tagsStats = result.data;
      this.setState({
        tagsStats: tagsStats,
      });
    });
  };

  loadRules = (projectId) => {
    axios.get(
        API_URL + `/projects/${projectId}/rules`,
        {headers: authHeader()}
    ).then(result => {
      const rulesList = result.data.rules_list;
      this.setState({
        rulesList: rulesList,
      });
    });
  };

  onPaneChange = (activeKey) => {
    this.setState({activeTabKey: activeKey});
    if (activeKey === "text_rules") {
      this.loadRules(this.props.match.params.projectId);
    }
  };

  handleRuleRemoval = (projectId, ruleId) => {
    axios.delete(API_URL + `/projects/${projectId}/rules/${ruleId}`, {headers: authHeader()}).then(result => {
      this.loadRules(projectId);
    });
  };

  handleNewRule = () => {
    this.setState({
        newRuleType: "create_tag",
        newRuleName: "",
        newRuleRegexp: "^$",
        newRuleTagType: "",
      },() => this.showModal()
    );
  };

  handleNewRuleOk = () => {
    axios.post(API_URL + `/projects/${this.props.match.params.projectId}/rules`, {
      name: this.state.newRuleName,
      rule_data: {
        regexp: this.state.newRuleRegexp,
        rule_type: this.state.newRuleType,
        tag_type: this.state.newRuleTagType
      }
    }, {headers: authHeader()}).then(result => {
      this.setState({newRuleVisible: false},
          () => this.loadRules(this.props.match.params.projectId)
      );
    });
  };

  handleNewRuleCancel = () => {
    this.setState({newRuleVisible: false});
  };

  showModal = () => {
    this.setState({newRuleVisible: true, previewedTags: []});
  };

  handlePreview = () => {
    axios.post(API_URL + `/projects/${this.props.match.params.projectId}/preview_tags`, {
      regexp: this.state.newRuleRegexp,
    }, {headers: authHeader()}).then(result => {
      this.setState({
        previewedTags: result.data.tags
      });
    });
  };

  render() {
    const columns = [
      {
        title: "Run",
        dataIndex: "run",
        render: run => (
            <Link to={`/project/${this.props.match.params.projectId}/explore_results/${run.id}`}>
              {run.name}
            </Link>
        )
      },
      {
        title: "Status",
        dataIndex: "status",
        render: runData => {
          const percentage = (100.0 * runData.processedPages / runData.totalPages).toFixed(1);
          return (
            <React.Fragment>
              <Row>
                <Col span={5} style={{textAlign: "right"}}>
                {runData.processedPages} / {runData.totalPages}
                </Col>
                <Col span={17} offset={1}>
                  <Progress percent={percentage} status="normal"/>
                </Col>
              </Row>
            </React.Fragment>
          );
        }
      }
    ];

    const statsColumns = [
      {
        title: "Tag template",
        dataIndex: "template",
      },
      {
        title: "Count",
        dataIndex: "tags_count",
        render: (tag_count) => {
          const curPercentage = (100.0 * tag_count / this.state.tagsStats.agg_stats.total_tags).toFixed(1);
          return `${tag_count} (${curPercentage} %)`;
        }
      },
      {
        title: "Sample",
        dataIndex: "tag_sample"
      },
      {
        title: "Actions",
        dataIndex: "template_regexp",
        render: (regexp, record) => {
          return (
              <a href="#" onClick={() => {
                this.setState({
                      newRuleType: "create_tag",
                      newRuleName: `Rule for ${record.tag_sample}`,
                      newRuleRegexp: regexp,
                      newRuleTagType: "",
                    },() => this.showModal()
                );
              }}>Create rule</a>
          );
        }
      }
    ];

    const rulesColumns = [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Type",
        dataIndex: "type"
      },
      {
        title: "Regexp",
        dataIndex: "regexp"
      },
      {
        title: "Actions",
        dataIndex: "id",
        render: (ruleId) => {
          return (
              <Popconfirm title="Are you sure to delete the rule？" onConfirm={() => this.handleRuleRemoval(this.props.match.params.projectId, ruleId)}>
                <a href="#">Delete</a>
              </Popconfirm>
          );
        }
      }
    ];



    const previewedTagsColumns = [
      {
        title: "Tag",
        dataIndex: "tag",
        render: (tag, record) => {
          const popoverContent = (
              <TagPreview
                  projectId={this.props.match.params.projectId}
                  tagId={record.tag_id}
                  resultId={record.result_id}
                  isFinalResult={false}
                  tagNumber={tag}
              />
          );
          return (
              <Tooltip placement="right" title={popoverContent} color="white" overlayStyle={{maxWidth: "1800px", maxHeight: "2000px"}}
                       destroyTooltipOnHide={true}
              >
                {tag}
              </Tooltip>
          );
        }
      }
    ];



    let coverage_percentage_str = "";
    let coverage_percentage = 0.0;
    if (this.state.tagsStats.agg_stats.covered_tags !== null && this.state.tagsStats.agg_stats.covered_tags > 0 && this.state.tagsStats.agg_stats.total_tags > 0) {
      coverage_percentage_str = `(${(100.0 * this.state.tagsStats.agg_stats.covered_tags / this.state.tagsStats.agg_stats.total_tags).toFixed(1)} %)`;
      coverage_percentage = (100.0 * this.state.tagsStats.agg_stats.covered_tags / this.state.tagsStats.agg_stats.total_tags).toFixed(1);
    }
    return (
      <>
        {/*<Tabs defaultActiveKey="processing" onChange={this.onPaneChange}>*/}
        <Tabs activeKey={this.state.activeTabKey} onChange={this.onPaneChange}>
          <TabPane tab="Processing" key="processing">
            <Row style={{justifyContent: "flex-end"}}>
              <Button
                type="primary"
                onClick={() => this.props.processDocuments(this.props.match.params.projectId, this.state.extractObjects)}
              >
                Process documents
              </Button>
            </Row>
            <Table dataSource={this.props.runsList} columns={columns} size="small" style={{paddingTop: "16px"}} />
          </TabPane>
          <TabPane tab="Stats" key="stats">
            <Row>
              <Col span={5}>
                <Button onClick={() => {this._loadStats(this.props.match.params.projectId);}}>
                  Refresh
                </Button>
              </Col>
              <Col span={5}>
                <span>Total candidates: {this.state.tagsStats.agg_stats.total_tags}</span>
              </Col>
              <Col span={2}><span>Covered: {this.state.tagsStats.agg_stats.covered_tags}</span></Col>
              <Col span={11}>
                <Progress percent={coverage_percentage} />
              </Col>
            {/*<span>Covered: {this.state.tagsStats.agg_stats.covered_tags} {coverage_percentage_str}</span>*/}
            </Row>
            <Table id="text-rules-templates-stats" dataSource={this.state.tagsStats.templates_stats} columns={statsColumns} size="small" style={{paddingTop: "16px"}} />
          </TabPane>
          <TabPane tab="Text rules" key="text_rules">
            <Row style={{justifyContent: "flex-end"}}>
              <Button type="primary" onClick={this.handleNewRule}>New rule</Button>
            </Row>
            <Table id="text-rules-list" dataSource={this.state.rulesList} columns={rulesColumns} size="small" style={{paddingTop: "16px"}} />
          </TabPane>

          <TabPane tab="Objects" key="objects">
            <Row style={{padding: "16px"}}>
              <Checkbox checked={this.state.extractObjects} onChange={(e) => this.setState({extractObjects: e.target.checked})}>Extract objects</Checkbox>
            </Row>
            {/*<Form.Item label="Model name">*/}
              {/*<Input value="run_demo_test_6" onChange={(e) => this.setState({modelName: e.target.value})}/>*/}
            {/*</Form.Item>*/}
          </TabPane>

          <TabPane tab="Linking rules" key="linking_rules">
            <LinkingRules match={this.props.match}/>
          </TabPane>

          <TabPane tab="Post-processing rules" key="post_processing_rules">
            <PostProcessingRules match={this.props.match}/>
          </TabPane>
        </Tabs>



        <Modal
            title="New rule"
            visible={this.state.newRuleVisible}
            onOk={() => this.handleNewRuleOk()}
            onCancel={this.handleNewRuleCancel}
            width="80vw"
            bodyStyle={{height: "65vh"}}
        >
          <Row>
            <Col span={5}>
              <Form.Item label="Rule name">
                <Input value={this.state.newRuleName} onChange={(e) => this.setState({newRuleName: e.target.value})}/>
              </Form.Item>
              <Form.Item label="Rule type">
                <Select id="ruleTypeSelect" value={this.state.newRuleType} onChange={(value) => this.setState({newRuleType: value})}>
                  <option value="create_tag">Create tag</option>
                  <option value="ignore">Ignore</option>
                </Select>
              </Form.Item>
              <Form.Item label="Regexp">
                <Input value={this.state.newRuleRegexp} onChange={(e) => this.setState({newRuleRegexp: e.target.value})}/>
              </Form.Item>
              {(this.state.newRuleType === "create_tag") &&
                <Form.Item label="Tag type">
                  <Input value={this.state.newRuleTagType}
                         onChange={(e) => this.setState({newRuleTagType: e.target.value})}/>
                </Form.Item>
              }

              <Button type="primary" onClick={this.handlePreview}>Preview</Button>

              {/*<Button type="primary" onClick={*/}
              {/*  () => this.handlePreview({*/}
              {/*    sourceString: ((sourceString || {}).state || {}).value,*/}
              {/*    targetString: ((targetString || {}).state || {}).value*/}
              {/*  })*/}
              {/*}>Preview</Button>*/}

              <Button type="primary" />
            </Col>
            <Col span={19} style={{overflow: "auto", height: "65vh"}}>
              <Table dataSource={this.state.previewedTags} columns={previewedTagsColumns} size="small" style={{paddingTop: "16px", paddingLeft: "16px"}} />
              {/*<TransformPreview filterRegexp={this.props.filterRegexp} transformationRegexp={this.state.currentRegexp} entityType={this.props.entityType}/>*/}
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}
