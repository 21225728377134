import React, {useEffect, useState} from "react";
import {Alert, Button, Card, Divider, Form, message, Row, Space, Table, Tabs, Tag, Typography, Upload} from "antd";
import {DownloadOutlined, UploadOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {API_URL} from "../constants";
import {authHeader, download} from "../Utilities";
import axios from "axios";
import GuideService from "../services/GuideService";

class GlobalSettingsData {
    guideTypeDataKeyMap = {
        admin: "regular_admin_guide_file_path",
        user: "regular_user_guide_file_path",
    };

    constructor(data) {
        this.data = data;
    }

    setGuideByType(value, type) {
        const dataKey = this._getDataKeyByGuideType(type);

        this.data[dataKey] = value;
    }

    getGuideFileAnnotationByType(type) {
        return this._getGuideFileAnnotation(type, this._getGuidePathByType(type));
    }

    _getDataKeyByGuideType(type) {
        if (!(type in this.guideTypeDataKeyMap)) {
            throw new Error("Unknown guide type");
        }

        const dataKey = this.guideTypeDataKeyMap[type];

        return dataKey;
    }

    _getGuidePathByType(type) {
        const dataKey = this._getDataKeyByGuideType(type);

        return this.data[dataKey];
    }

    _getGuideFileAnnotation(uid, guideFilePath) {
        if (!guideFilePath) {
            return [];
        }

        const fileName = guideFilePath.split("/").slice(-1)[0];

        return [
            {
                uid: uid,
                name: fileName,
                type: "application/pdf",
                status: "done"
            }
        ];
    }
}

export class GlobalSettings extends React.Component {
    state = {
        globalSettings: null,
        saveChangesAllowed: false
    };

    componentDidMount() {
        axios.get(
            API_URL + "/global_settings",
            {headers: authHeader()}
        ).then(result => {
            this.setState({globalSettings: new GlobalSettingsData(result.data)});
        });
    }

    saveChanges = () => {
        axios.post(
            API_URL + "/global_settings",
            this.state.globalSettings.data,
            {headers: authHeader()}
        ).then(result => {
            this.setState({globalSettings: new GlobalSettingsData(result.data)});
            this.setState({saveChangesAllowed: false});
        });
    };

    updateGlobalSettings = (globalSettings) => {
        this.setState({saveChangesAllowed: true});
        this.setState({globalSettings: globalSettings});
    };

    render() {
        return (
            <>
                <Row style={{justifyContent: "space-between", padding: "1em"}}>
                    <Typography.Title level={4}>Global settings</Typography.Title>
                    {this.state.saveChangesAllowed && <Alert
                        message={"The changes are not saved yet. Press 'Save changes' for them to take effect."}
                        type={"warning"}
                    />}
                    <Button
                        id="save-changes-button"
                        type={"primary"}
                        disabled={!this.state.saveChangesAllowed}
                        onClick={this.saveChanges}
                    >
                        Save changes
                    </Button>
                </Row>
                {this.state.globalSettings &&
                    <Row style={{justifyContent: "center", padding: "1em"}}>
                        <Tabs defaultActiveKey="guides" style={{ width: "100%" }}>
                            <Tabs.TabPane tab="Guides" key="guides">
                                <Space direction="vertical" style={{ width: "100%"}}>
                                    <Card title="Configure guides">
                                        <UploadGuide guideType={"user"}
                                                     globalSettings={this.state.globalSettings}
                                                     onGlobalSettingsChanged={this.updateGlobalSettings}
                                        />
                                        <Divider/>
                                        <UploadGuide guideType={"admin"}
                                                     globalSettings={this.state.globalSettings}
                                                     onGlobalSettingsChanged={this.updateGlobalSettings}
                                        />
                                    </Card>
                                </Space>
                            </Tabs.TabPane>
                        </Tabs>
                    </Row>
                }
            </>
        );
    }
}

function UploadGuide({guideType, globalSettings, onGlobalSettingsChanged}) {
    return (
        <UploadOnePDF
            id={`upload-${guideType}-guide-input`}
            defaultFileList={globalSettings.getGuideFileAnnotationByType(guideType)}
            onDownload={()=>GuideService.downloadGuideByType(guideType)}
            onRemove={()=>{
                globalSettings.setGuideByType("", guideType);
                onGlobalSettingsChanged(globalSettings);
            }}
            actionUrl={API_URL + "/global_settings/guides/user"}
            onFileUploaded={(response) => {
                globalSettings.setGuideByType(response.file_path, guideType);
                onGlobalSettingsChanged(globalSettings);
            }}
        >
            <Button
                id={`upload-${guideType}-guide-button`}
                icon={<UploadOutlined />}
            >
                Upload {guideType} guide
            </Button>
        </UploadOnePDF>
    );
}

function UploadOnePDF({id, defaultFileList, onFileUploaded, children, actionUrl, onRemove, onDownload}) {
    const [fileList, setFileList] = useState(defaultFileList);

    const previewIcon = <span
        className={"fiv-viv fiv-icon-pdf fiv-icon-blank"}
        style={{ fontSize: "1.5em", marginRight: "0.5em" }}
    />;

    return (
        <Upload
            id={id}
            accept={"application/pdf"}
            fileList={fileList}
            listType={"picture"}
            showUploadList={{
                showDownloadIcon: true
            }}
            action={actionUrl}
            headers={authHeader()}
            iconRender={()=>previewIcon}
            onDownload={onDownload}
            onRemove={onRemove}
            beforeUpload={file => {
                const isPdf = file.type === "application/pdf";

                if (!isPdf) {
                    message.error(`${file.name} is not a pdf file`);
                }

                return isPdf;
            }}
            onChange={(info)=>{
                let fileList = info.fileList.filter(file=>!!file.status);
                fileList = fileList.slice(-1);

                fileList = fileList.map(file => {
                    if (file.status === "done" && info.file.response) {
                        onFileUploaded(info.file.response);
                        message.success("File uploaded successfully");
                    } else if (file.status === "error") {
                        message.error("Error uploading file");
                    }

                    return file;
                });
                setFileList(fileList);
            }}
        >
            {children}
        </Upload>
    );
}