import React, {useEffect, useState} from "react";
import * as queryString from "query-string";
import axios from "axios";
import {API_URL} from "../../constants";
import {authHeader} from "../../Utilities";
import {TagPreview} from "../ExploreResults";
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Form,
  Input, message,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip
} from "antd";
import {Link} from "react-router-dom";
import {withRouter} from "react-router";
import TagUrlLinkService from "../../services/TagUrlLinkService";
import {DataSourceFiltersEditor, emptyAlias} from "../Misc/DataSourceFilter";
import {Option} from "antd/es/mentions";
import {RequestBodyFromFilters} from "./SearchFilters/RequestBodyFromFilters";
import {FiltersFromURL} from "./SearchFilters/SearchFiltersFromUrl";
import {DefaultSearchQuery} from "./SearchFilters/QueryFromFilters";
import {ObjectAttributeFilter, PageFieldFilter} from "./SearchFilters/SearchFilters";
import {RemoteFiltersEditor} from "./SearchFilters/RemoteFiltersEditor";
import UserViewService from "../../services/UserViewService";
import {SearchTableSettings} from "./SearchColumns/SearchColumnsEditor";
import SearchCustomizeService from "../../services/SearchCustomizeService";
import {HighlightedMatch} from "../Misc/HighlightedMatch";

class SearchComponent extends React.Component {
  constructor(props) {
    super(props);

    this.resetTotalCache();
    this.state = {
      search: null,
      limit: 20,
      page: 1,
      total: 0,
      parsedObjects: [],
      dirty: false,
      searchResultsList: null,
      tableFilters: [],
      filterSchemas: [],
      customizeConfig: null,
      showMode: "documents",
      ignoreTextObjects: true
    };
  }

  componentDidMount() {
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearch = this.onSearch.bind(this);

    SearchCustomizeService.getConfig().then(data => {
      const advancedConfig = SearchCustomizeService.getAdvancedConfig();
      let includeTextObjects = advancedConfig.include_text_objects;
      const query = queryString.parse(decodeURI(this.props.location.search));
      if (["true", "false"].includes(query["ignoreTextObjects"])) {
        includeTextObjects = !(query["ignoreTextObjects"] === "true");
      }
      this.setState({
            customizeConfig: data,
            ignoreTextObjects: !includeTextObjects
          }, () => {
            this.handleSearch(this.props.location.search);
          }
      );
    });

    UserViewService.fetchSearchFiltersSchemas(schemas => {
      this.setState({filterSchemas: schemas});
    });
  }

  handleSearch = (url) => {
    const query = queryString.parse(decodeURI(url));
    const searchQuery = query["q"] || "";
    const pageNumber = parseInt(query["page"]) || 1;
    const useWildcards = query["useWildcards"] === "true";
    const showMode = query["showMode"];
    const ignoreTextObjects = this.state.ignoreTextObjects;

    const filters = new FiltersFromURL(url).getArray();
    this.setState({tableFilters: filters},
        () => this.onSearch(searchQuery, pageNumber, useWildcards, showMode, ignoreTextObjects));
  };

  resetTotalCache() {
    this.setState({total: 0});
    this.maxPage = 0;
  }

  updateTotal(page, resultsSize, limit) {
    if (page > this.maxPage) {
      this.maxPage = page;
      this.setState({total: (page - 1) * limit + resultsSize});
    }
  }


  updateSearchResults = () => {
    const params = {
      search: encodeURIComponent(this.state.search),
      page: encodeURIComponent(this.state.page),
      limit: encodeURIComponent(this.state.limit),
      use_wildcards: encodeURIComponent(this.state.useWildcards),
      show_mode: encodeURIComponent(this.state.showMode),
      ignore_text_objects: encodeURIComponent(this.state.ignoreTextObjects)
    };

    const defaultColumnNames = ["Match", "File name", "Page", "Link"];

    const data = {
      filters: new RequestBodyFromFilters(this.state.tableFilters).getBody(),
      custom_columns: (this.state.customizeConfig?.columns || [])
          .filter(column => !defaultColumnNames.includes(column.name))
          .filter(column => column.checked)
          .map(column => ({
            name: column.name,
            target: column.target
          }))
    };

    // axios.get(API_URL + `/projects/${this.state.runInfo.projectId}/processing_runs/${this.state.runInfo.id}/results`, {
    axios.post(this.props.url, data, {
      params,
      timeout: 30 * 1000,
      headers: authHeader()
    }).then(res => this.setState({searchResultsList: res.data.processing_results}))
        .catch(e => {
          message.error("Failed to load results");
        });
  };

  updateResults() {
    const parsedObjects = this.state.searchResultsList;
    this.updateTotal(this.state.page, parsedObjects.length, this.state.limit);
    this.setState({
      parsedObjects: parsedObjects.slice(0, this.state.limit),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.searchResultsList !== this.state.searchResultsList) {
      this.updateResults();
    }

    if (prevState.search !== this.state.search
        || prevState.page !== this.state.page
        || prevState.limit !== this.state.limit || prevState.useWildcards !== this.state.useWildcards || this.state.dirty) {
      // TODO: find a better solution for preventing multiple requests
      this.setState({
        dirty: false,
      }, this.updateSearchResults);
    }

    if (prevProps.location !== this.props.location) {
      this.handleSearch(this.props.location.search);
    }
  }

  onPageChange(page) {
    const searchQuery = new DefaultSearchQuery(
        this.state.tableFilters, page, this.state.showMode, this.state.ignoreTextObjects
    );

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: searchQuery.toString()
    });
  }

  onSearch(value, page=1, useWildcards=false, showMode="documents", ignoreTextObjects=true) {
    if (this.props.onSearch) this.props.onSearch(value, page);

    if (this.state.search !== value) {
      this.resetTotalCache();
    }

    this.setState({
      page: page,
      showMode: showMode,
      ignoreTextObjects: ignoreTextObjects,
      search: value,
      useWildcards: useWildcards,
      parsedObjects: [],
      dirty: true
    });
  }

  setTableFilters = (filters) => {
    this.resetTotalCache();
    const searchQuery = new DefaultSearchQuery(
        filters, 1, this.state.showMode, this.state.ignoreTextObjects
    );

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: filters.length > 0 ? searchQuery.toString() : ""
    });

    this.setState({tableFilters: filters});
  };

  render() {
    const defaultColumnNames = ["Match", "File name", "Page", "Link"];

    const customTableColumns = (this.state.customizeConfig?.columns || [])
        .filter(col => col.checked)
        .filter(col => !defaultColumnNames.includes(col.name))
        .map(col => ({
              title: col.name,
              render: record => record.custom_columns
                  .find(customColumn => customColumn.name === col.name)?.value || ""
            })
        );

    let columns = [
      {
        title: "Match",
        dataIndex: ["match", "text"],
        fixed: "left",
        render: (match, record) => {
          if (match === null) {
            return (<></>);
          }

          const popoverContent = (
              <TagPreview
                  projectId={record.processing_result.project_id}
                  resultId={record.processing_result.id}
                  isFinalResult={record.processing_result.is_final_result}
                  tagNumber={match}
                  tagId={record.match.payload.tag_id}
              />
          );

          const tagText = <HighlightedMatch matchText={match} occurrences={record.match.occurrences}/>;

          if (record.match.match_type === "document_field") return "";

          return (
              <>
                <Tooltip placement="right" title={popoverContent} color="white" overlayStyle={{maxWidth: "1800px", maxHeight: "2000px"}}
                         destroyTooltipOnHide={false}
                >
                  <span>
                    {tagText}
                  </span>
                </Tooltip>
              </>
          );
        },
      },
      {
        title: "File name",
        dataIndex: ["processing_result", "file_name"],
        render: (fileName, record) => {
          if (record.match.match_type === "document_field" && record.match.source === "file-name")
            return <HighlightedMatch matchText={record.match.text} occurrences={record.match.occurrences}/>;
          else
            return fileName;
        }
      },
      {
        title: "Page",
        dataIndex: ["processing_result", "page_number"],
        render: pageNumber => pageNumber === null ? "" : pageNumber + 1,
        width: "5%",
      },
      ...customTableColumns,
      {
        title: "Link",
        dataIndex: ["match", "text"],
        fixed: "right",
        width: "5%",
        render: (tagNumber, record) => {
          let tagLink;

          const tagId = record.match.payload.tag_id ? record.match.payload.tag_id.toString() : "";
          tagNumber = record.match.source === "text" ? tagNumber : "";

          tagLink = TagUrlLinkService.generateTagLinkByFinalProperty(
              record.processing_result.project_id,
              record.processing_result.id,
              record.processing_result.run_id,
              tagNumber,
              tagId,
              record.processing_result.is_final_result
          );

          return (
              <Link to={tagLink}>
                Open
              </Link>
          );
        }
      },
    ];

    const hasAttributeFilters = this.state.tableFilters.filter(el => el instanceof ObjectAttributeFilter).length === 0;

    if (this.state.search === "" && hasAttributeFilters) {
      columns = columns.filter(col => !["match", "match type"].includes(col.title.toLowerCase()));
    }

    return (
        <>
          <Spin spinning={false}>
            {
              <>
                <RemoteFiltersEditor
                    fetchOptionsUrl={this.props.fetchOptionsUrl}
                    filterSchemas={this.state.filterSchemas}
                    filters={this.state.tableFilters}
                    onFiltersChanged={this.setTableFilters}
                    contentRenderer={(tagsList, editor) => (
                        <Row justify={"space-between"} style={{marginBottom: 8}}>
                          <Col span={15}>
                            {tagsList}
                          </Col>
                          <Col>
                            <Space size={"large"}>
                              {editor}
                              <Space>
                                Show
                                <Select id="show-mode-select" value={this.state.showMode}
                                        size={"small"} style={{width: 110}}
                                        onChange={value => {
                                          this.resetTotalCache();
                                          this.setState({showMode: value}, () => {
                                            const searchQuery = new DefaultSearchQuery(
                                                this.state.tableFilters,
                                                1,
                                                this.state.showMode,
                                                this.state.ignoreTextObjects
                                            );

                                            this.props.history.push({
                                              pathname: this.props.location.pathname,
                                              search: searchQuery.toString()
                                            });
                                          });
                                        }}
                                >
                                  <Select.Option id="show-mode-documents" value="documents">Documents</Select.Option>
                                  <Select.Option id="show-mode-pages" value="pages">Pages</Select.Option>
                                </Select>
                              </Space>
                              <SearchTableSettings
                                  dataSource={{
                                    customize: {
                                      data: this.state.customizeConfig || {columns: []},
                                      onSave: data => {
                                        SearchCustomizeService.updateConfig(data);
                                        this.setState({customizeConfig: data});

                                        this.props.history.replace({
                                          pathname: this.props.location.pathname,
                                          search: this.props.location.search
                                        });
                                      }
                                    },
                                    advanced: {
                                      ignoreTextObjects: {
                                        data: this.state.ignoreTextObjects,
                                        onSave: data => {
                                          this.resetTotalCache();
                                          const newAdvancedConfig = {
                                            ...SearchCustomizeService.getAdvancedConfig(),
                                            include_text_objects: !data,
                                          };
                                          SearchCustomizeService.updateAdvancedConfig(newAdvancedConfig);
                                          this.setState({ignoreTextObjects: data}, () => {
                                            const searchQuery = new DefaultSearchQuery(
                                                this.state.tableFilters,
                                                1,
                                                this.state.showMode,
                                                this.state.ignoreTextObjects
                                            );

                                            this.props.history.push({
                                              pathname: this.props.location.pathname,
                                              search: searchQuery.toString()
                                            });
                                          });
                                        }
                                      }
                                    }
                                  }}
                              />
                            </Space>
                          </Col>
                        </Row>
                    )}
                />
                {this.state.customizeConfig &&
                <Table id="tags-search-results-table" dataSource={this.state.parsedObjects} columns={columns}
                       scroll={{x: 1500}}
                       size="small"
                       pagination={{
                         position: "bottomCenter",
                         current: this.state.page,
                         defaultPageSize: 20,
                         total: this.state.total,
                         showSizeChanger: false,
                         onChange: this.onPageChange
                       }}/>
                }
              </>
            }
          </Spin>
        </>
    );
  }
}

export default withRouter(SearchComponent);
