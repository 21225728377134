import React, {useContext, useState} from "react";
import * as Static from "./Static/StaticComment";
import * as Editable from "./Editable/EditableComment";
import {Button, Input, Menu, message, Modal, Popconfirm, Tooltip} from "antd";
import {EditCommentContainer} from "../Containers/EditCommentContainer";
import { CheckOutlined} from "@ant-design/icons";
import {AuthContext} from "../../../../contexts/AuthContext";


export function Comment(
    {
        idx,

        comment,
        onClick,
        onMarkerClick,

        onEditApprove,
        onEditUpdate,
        onEditCancel,

        onDelete,

        newMarkers,
        markerCreationForbidden,

        commentsSelection,

        commentLinks,
        assignTargets
    }
) {
    const authContext = useContext(AuthContext);
    const canBeEdited = authContext.user.id === comment.author.userId;

    const [editing, setEditing] = useState(false);

    const handleEditStart = () => {
        setEditing(true);
    };

    const handleEditCancel = (canceled) => {
        setEditing(false);
        onEditCancel(canceled);
    };

    const handleEditApprove = (initial, edited) => {
        setEditing(false);
        onEditApprove(initial, edited);
    };

    const actions = (onEditStatusAndApprove) => {
        const conditionalActions = [];

        if (comment.status === "closed" && canBeEdited) {
            conditionalActions.push(
                <Menu.Item
                    onClick={e => {
                        onEditStatusAndApprove("open");
                    }}
                    key={"re-open"}
                >
                    Re-open
                </Menu.Item>
            );
        }

        return [
            ...conditionalActions,
            canBeEdited && (
                <Menu.Item onClick={handleEditStart} key={"edit"}>
                    Edit
                </Menu.Item>
            ),
            canBeEdited && (
                <Menu.Item id={"delete-comment"} key={"delete"}>
                    <Popconfirm
                        style={{display: "block"}}
                        title="Are you sure to delete the comment?"
                        onConfirm={onDelete}
                    >
                        <span style={{display: "block"}}>
                            Delete
                        </span>
                    </Popconfirm>
                </Menu.Item>
            ),
            (
                <LinkToComment key={"link-to-comment"} comment={comment} commentLinks={commentLinks}/>
            )
        ];
    };

    const editableComment = (
        <EditCommentContainer
            key={"editable"}
            comment={comment}
            onEditUpdate={onEditUpdate}
            onEditCancel={handleEditCancel}
            onEditApprove={handleEditApprove}
        >
            {props => (
                <Editable.Comment
                    id={`edited-comment_${idx}`}
                    {...props}
                    markerCreationForbidden={markerCreationForbidden}
                    newMarkers={newMarkers}
                    assignTargets={assignTargets}
                />
            )}
        </EditCommentContainer>
    );

    const staticComment = (
        <EditCommentContainer
            key={"static"}
            comment={comment}
            onEditUpdate={onEditUpdate}
            onEditCancel={handleEditCancel}
            onEditApprove={handleEditApprove}
        >
            {props => (
                <Static.Comment
                    id={`static-comment_${idx}`}

                    commentsSelection={commentsSelection}
                    comment={comment}
                    actions={actions(props.onEditStatusAndApprove)}
                    outsideActions={[
                        canBeEdited && (comment.status === "open" && (
                            <Tooltip title="Close comment" key="closeStatus">
                                <CheckOutlined
                                    id={"close-comment-action"}
                                    style={{color: "var(--color-turquoise)"}}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        props.onEditStatusAndApprove("closed");
                                    }}
                                />
                            </Tooltip>
                        ))
                    ]}
                    onClick={onClick}
                    onMarkerClick={onMarkerClick}
                />
            )}
        </EditCommentContainer>
    );

    return (
        editing ? editableComment : staticComment
    );
}

function LinkToComment({key, commentLinks, comment, ...rest}) {
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <Menu.Item
            key={key}
            {...rest}
        >
            <a onClick={e => setModalVisible(true)}>
                Link to this comment...
            </a>
            <Modal
                title={"Link to this comment..."}
                key={comment.id}
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={
                    <>
                        <Button
                            onClick={e => setModalVisible(false)}
                            type={"primary"}
                        >
                            Ok
                        </Button>
                        <Button
                            onClick={e => {
                                navigator.clipboard.writeText(commentLinks.get(comment)).then(e => {
                                    message.success("Copied");
                                });
                            }}
                        >
                            Copy Link
                        </Button>
                    </>
                }
            >
                <Input value={commentLinks.get(comment)} onFocus={e => e.target.select()}/>
            </Modal>
        </Menu.Item>
    );
}