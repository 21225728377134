import {useEffect} from "react";


export function MarkupContainer({data, markup, layerCtor, children}) {
    useEffect(() => {
        markup.updateLayer(layerCtor(data));

        return () => {
            markup.updateLayer(layerCtor([]));
        };
    }, [data, markup, layerCtor]);

    return children();
}