import {PluginAnonymous} from "../../../services/Plugins";
import {ResourcesSource} from "../../../services/Resources";
import {CanvasGroup} from "../Resources/CanvasGroup";
import {Canvas} from "../Resources/Canvas";
import {fabric} from "fabric";

export class PluginCanvasSimplePan extends PluginAnonymous<ResourcesSource> {
    constructor(canvasId: string, abortDragStrategy?: AbortDragStartStrategy) {
        super(`${canvasId}-simple-pan`, {
            async build(target: ResourcesSource) {
                const canvasGroup = target.res(CanvasGroup).get();
                const canvas = canvasGroup.getById(canvasId);

                let abortDrag = abortDragStrategy ?? new AbortDragStartStrategyDisabled();
                let isDragging = false;
                let lastPosX: number;
                let lastPosY: number;

                canvas.raw.selection = false;
                canvas.events.mouseDown$.subscribe(opt => {
                    if (isDragging) return;
                    if (abortDrag.test(canvas, opt)) return;
                    const evt = opt.e;
                    isDragging = true;
                    lastPosX = evt.clientX;
                    lastPosY = evt.clientY;
                });
                canvas.events.mouseMove$.subscribe(opt => {
                    if (isDragging) {
                        const e = opt.e;
                        const vpt = canvas.raw.viewportTransform!;
                        vpt[4] += e.clientX - lastPosX;
                        vpt[5] += e.clientY - lastPosY;
                        canvas.raw.requestRenderAll();
                        lastPosX = e.clientX;
                        lastPosY = e.clientY;
                    }
                });
                canvas.events.mouseUp$.subscribe(() => {
                    if (isDragging) {
                        // on mouse up we want to recalculate new interaction
                        // for all objects, so we call setViewportTransform
                        canvas.raw.setViewportTransform(canvas.raw.viewportTransform!);
                        isDragging = false;
                    }
                });
            }
        });
    }
}

export interface AbortDragStartStrategy {
    test(canvas: Canvas, opt: fabric.IEvent<MouseEvent>): boolean;
}

export class AbortDragStartStrategyDisabled implements AbortDragStartStrategy {
    test(_canvas: Canvas, _opt: fabric.IEvent<MouseEvent>): boolean {
        return false;
    }
}
