export const markupLayers = {
  RECTANGLE_SELECT_HIGHLIGHTING_LAYER: "rectangle-select-highlighting-layer",
  ADD_NODES_LIST_HIGHLIGHTING_LAYER: "add-nodes-list-highlighting-layer",
  PARENT_CHILD_LAYER: "parent-child-layer",
};

export default class MarkupManager {
  constructor(layers, imageViewer) {
    this.layers = layers;
    this.imageViewer = imageViewer;

    this.extraObjectsByLayer = {};
    this.layers.forEach(layer => this.extraObjectsByLayer[layer.layerId] = []);
  }

  removeMarkup() {
    for (const layer of this.layers) {
      this.extraObjectsByLayer[layer.layerId].forEach(canvasObject => this.imageViewer.canvas.remove(canvasObject));
      this.extraObjectsByLayer[layer.layerId] = [];
    }
  }

  _redrawLayer(layer) {
    this.extraObjectsByLayer[layer.layerId].forEach(canvasObject => this.imageViewer.canvas.remove(canvasObject));
    this.extraObjectsByLayer[layer.layerId] = layer.getCanvasObjects();
    this.extraObjectsByLayer[layer.layerId].forEach(canvasObject => this.imageViewer.canvas.add(canvasObject));
  }

  updateMarkup() {
    for (const layer of this.layers) this._redrawLayer(layer);
  }

  updateLayer(newLayer) {
    this.layers = this.layers.map(layer => layer.layerId === newLayer.layerId ? newLayer : layer);
    this._redrawLayer(newLayer);
  }
}