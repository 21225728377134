import {CnIntent} from "../CnIntent";
import axios from "axios";
import {API_URL} from "../../../../../constants";
import {authHeader} from "../../../../../Utilities";
import {RemoteCnResults} from "./RemoteCnResults";

export class RemoteCnIntent extends CnIntent {
    constructor(current, another, selectCanvasObjectById, selectOtherObjectById, canvas) {
        super();

        this._current = current;
        this._another = another;
        this._selectCanvasObjectById = selectCanvasObjectById;
        this._selectOtherObjectById = selectOtherObjectById;
        this._canvas = canvas;
    }

    switch(selectedCnResult) {
        this._another.openSwitched(this._current, selectedCnResult);
    }

    async cnResults() {
        const requestBody = {
            current_result_reference: this._current.toResultReference(),
            another_result_reference: this._another.toResultReference(),
        };

        const response = await axios.post(API_URL + "/user_view/comparison_results",
            requestBody,
            {headers: authHeader()}
        );

        return new RemoteCnResults(
            response.data,
            this._selectCanvasObjectById,
            this._selectOtherObjectById,
            this._canvas
        );
    }

    prepared() {
        return true;
    }
}