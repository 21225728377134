import React from "react";
import ImageViewer from "./ImageViewer/ImageViewer";
import axios from "axios";
import {API_URL} from "../constants";
import {authHeader} from "../Utilities";

export class ObjectDetectionSampleViewer extends React.Component {
  render() {

    return (
        <ImageViewer
            editable={true}
            hierarchiesAllowed={false}
            commentsAllowed={false}
            history={this.props.history}
            location={this.props.location}
            projectId={this.props.match.params.projectId}
            loadAnnotation={() => {
              return axios.get(
                  API_URL + `/projects/${this.props.match.params.projectId}/object_detection_samples/${this.props.match.params.sampleId}`,
                  {headers: authHeader()}
              );
            }}

            saveChanges={(annotation) => {
              return axios.put(API_URL + `/projects/${this.props.match.params.projectId}/object_detection_samples/${this.props.match.params.sampleId}`,
                {
                  annotation: annotation
                },
                {headers: authHeader()}
              );
            }}
        />
    );
  }
}