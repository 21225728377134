import React, {useContext, useState} from "react";
import {Button, Popover, Row} from "antd";
import * as Editable from "./Editable/EditableComment";
import {AuthContext} from "../../../../contexts/AuthContext";
import {ImageViewerObjectFromCanvas} from "../../../../Utilities";
import {MarkerTargetAsProperObj} from "../Selection/MarkerBelonging";


export function AddNewCommentOnObject({children, annotationObject, onAddNewComment}) {
    const [visible, setVisible] = useState(false);

    const content = (
        <div style={{width: "376px"}}>
            <AddNewCommentBody
                annotationObject={annotationObject}
                onCancel={e => setVisible(false)}
                onOk={comment => {
                    setVisible(false);
                    onAddNewComment(comment);
                }}
            />
        </div>
    );

    const handleClick = e => {
        setVisible(true);
    };

    return (
        <Popover
            id={"new-comment-on-object"}
            destroyTooltipOnHide={true}
            visible={visible}
            trigger={"click"}
            title={"New comment"}
            onVisibleChange={setVisible}
            content={content}
        >
            {children({
                onClick: handleClick
            })}
        </Popover>
    );
}


function AddNewCommentBody({onCancel, onOk, annotationObject}) {
    const authContext = useContext(AuthContext);
    const [message, setMessage] = useState("");

    const handleOk = e => {
        const markerTarget = new MarkerTargetAsProperObj(
            new ImageViewerObjectFromCanvas(annotationObject)
        );

        onOk({
            message: message,
            author: {name: authContext.user.name},
            date: new Date(),
            status: "open",
            markers: [
                markerTarget.attachedMarker({id: 1, position: {x: annotationObject.left, y: annotationObject.top}})
            ],
            assignTargets: []
        });
    };

    return (
        <>
            <Editable.CommentMessage value={message} onChange={setMessage}/>
            <Row
                style={{justifyContent: "space-between", marginTop: "12px"}}
            >
                <Button size="small" onClick={onCancel} style={{width: 90}}>Cancel</Button>
                <Button
                    onClick={handleOk}
                    type="primary" size="small" style={{width: 90}}
                >
                    OK
                </Button>
            </Row>
        </>
    );
}