import BaseLayer from "./BaseLayer";
import {fabric} from "fabric";

export default class HighlightingLayer extends BaseLayer {
  constructor(layerId, objectsToHighlight) {
    super(layerId);
    this.objectsToHighlight = objectsToHighlight;
  }

  getCanvasObjects() {
    const defaultArgs = {
      lockScalingFlip: true,
      fill: "rgba(0,0,0,0)",
      cornerColor: "lime",
      cornerStrokeColor: "gray",
      cornerStyle: "circle",
      transparentCorners: false,
      cornerSize: 10,
      noScaleCache: false,
      strokeUniform: true,
      objectCaching: false,
    };

    const canvasObjects = this.objectsToHighlight.flatMap(highlightedObject => highlightedObject.getHighlightedObjects());

    return canvasObjects;
  }
}