import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../constants";
import {authHeader, ColoredColumns} from "../../Utilities";
import {Badge, Card, Col, message, Row, Space, Table, Typography} from "antd";
import {JsonEditor} from "jsoneditor-react";
import moment from "moment";
import {AsyncDataSource, ManualPagedTable} from "../Misc/Table/ManualPagedTable";

const { Title } = Typography;


class DataSource extends AsyncDataSource {
    constructor(curProjectId) {
        super();
        this._curProjectId = curProjectId;
    }

    async values(page, limit) {
        const data = await axios.get(
            API_URL + "/jobs",
            {
                params: {page: page, limit: limit, project_id: this._curProjectId},
                headers: authHeader()
            }
        ).then(res => res.data).catch(err => {
            message.error("Failed to load jobs");
            return [];
        });

        return data;
    }
}


export function ProjectJobs(props) {
    const curProjectId = props.match.params.projectId;
    return (
        <Jobs curProjectId={curProjectId}/>
    );
}

export function Jobs({curProjectId=null}) {
    const [dataSource, setDataSource] = useState(new DataSource(curProjectId));
    const [detailsSource, setDetailsSource] = useState({
        colorOf: record => "",
        details: () => (<></>)
    });

    const rowColor = (text, record, idx) => detailsSource.colorOf(record);

    return (
        <>
            <Row style={{padding: "1em"}}>
                <Title level={4}>Jobs</Title>
            </Row>
            <Row gutter={16}>
                <Col span={14}>
                    <Card title={"List"} size={"small"}>
                        <JobsTable
                            asyncDataSource={dataSource}
                            setDetailsSource={setDetailsSource}
                            rowColor={rowColor}
                            size={"small"}
                        />
                    </Card>
                </Col>
                <Col span={10}>
                    <Card title={"Details"} size={"small"}>
                        {detailsSource.details()}
                    </Card>
                </Col>
            </Row>
        </>
    );
}

function JobDetails({job}) {
    return (
        <>
            <JsonEditor key={job.id} value={{args: job.args}} mode="view"/>
            <Space direction="vertical" style={{marginTop: "16px"}}>
                <RelatedJobInfo job={job} entityIndex="workspace" entityAlias="Workspace"/>
                <RelatedJobInfo job={job} entityIndex="project" entityAlias="Project"/>
                <RelatedJobInfo job={job} entityIndex="user" entityAlias="User"/>
            </Space>
        </>
    );
}

function RelatedJobInfo({job, entityIndex, entityAlias}) {
    const entity = job[entityIndex];

    if (!entity) return null;

    return (
        <div id={`related-info_${entityIndex}`}>
            {entityAlias}: {entity.name}
        </div>
    );
}

function JobLogDetails({log}) {
    return (
        <JsonEditor key={log.id} value={{payload: log.payload}} mode="view"/>
    );
}

function Status({name, payload}) {
    let status = "default";

    if (name === "FINISHED") {
        status = "success";
    } else if (name === "FAILED") {
        status = "error";
    } else if (["CUSTOM_PROGRESS", "PROGRESS", "RUNNING"].includes(name)) {
        status = "processing";
    } else if (["SCHEDULED", "RETRY_SCHEDULED"].includes(name)) {
        status = "warning";
    }

    return (
        <span>
            <Badge status={status}/>
            {payload.data?.status ?? name}
        </span>
    );
}

function JobsTable({asyncDataSource, rowColor, setDetailsSource}) {
    const [jobsSource, setJobsSource] = useState(new AsyncDataSource());

    useEffect(() => {
        const detailsSourceOf = job => ({
            colorOf: record => {
                return record.key === job.id ? "#eee" : "";
            },
            details: () => {
                return <JobDetails job={job}/>;
            }
        });

        const createdAtOf = job => {
            const firstLog = job.logs[0];

            if (firstLog == null) {
                return "UNKNOWN";
            }

            return moment.utc(firstLog.created_at).local().format("MM/DD/YYYY, h:mm:ss:SSS a");
        };

        const durationOf = job => {
            const firstLog = job.logs[0];
            const lastLog = job.logs.slice(-1)[0];

            if (firstLog === lastLog) {
                return "UNKNOWN";
            }

            return `${new Date(lastLog.created_at).getTime() - new Date(firstLog.created_at).getTime()}ms`;
        };

        const statusOf = job => {
            const lastLog = job.logs.slice(-1)[0];
            return <Status name={lastLog?.status || "UNKNOWN"} payload={lastLog?.payload}/>;
        };

        setJobsSource(
            {
                values: async (page, limit) => {
                    const jobs = await asyncDataSource.values(page, limit);

                    const records = jobs.map(job => ({
                        key: job.id,
                        type: job.type,
                        status: statusOf(job),
                        duration: durationOf(job),
                        created_at: createdAtOf(job),
                        logs: job.logs,
                        onClick: e => setDetailsSource(detailsSourceOf(job))
                    }));

                    return records;
                }
            }
        );
    }, [asyncDataSource, setDetailsSource]);

    const expandedRowRender = (record) => {
        return (
            <JobLogsTable
                key={record.key}
                logs={record.logs}
                rowColor={rowColor}
                setDetailsSource={setDetailsSource}
            />
        );
    };

    const cellProps = record => ({
        onClick: e => record.onClick(e)
    });

    const coloredColumns = new ColoredColumns(
        [
            {
                title: "Type",
                dataIndex: "type",
                onCell: cellProps
            },
            {
                title: "Status",
                dataIndex: "status",
                onCell: cellProps
            },
            {
                title: "Created at",
                dataIndex: "created_at",
                onCell: cellProps
            },
            {
                title: "Duration",
                dataIndex: "duration",
                onCell: cellProps
            }
        ],
        rowColor
    );

    return (
        <ManualPagedTable
            id="jobs-table"
            columns={coloredColumns.values()}
            asyncDataSource={jobsSource}
            scroll={{x: 400}}
            size="small"
            expandable={{ expandedRowRender }}
        />
    );
}


function JobLogsTable({logs, rowColor, setDetailsSource}) {
    const [records, setRecords] = useState([]);

    useEffect(() => {
        const detailsSourceOf = log => ({
            colorOf: record => {
                return record.key === log.id ? "#eee" : "";
            },
            details: () => {
                return <JobLogDetails log={log}/>;
            }
        });

        const statusOf = log => {
            return <Status name={log.status} payload={log.payload}/>;
        };

        setRecords(
            logs.map(log => ({
                key: log.id,
                status: statusOf(log),
                created_at: moment.utc(log.created_at).local().format("MM/DD/YYYY, h:mm:ss:SSS a"),
                onClick: e => setDetailsSource(detailsSourceOf(log))
            }))
        );
    }, [logs]);

    const cellProps = record => ({
        onClick: e => record.onClick(e)
    });

    const coloredColumns = new ColoredColumns(
        [
            {
                title: "Status",
                dataIndex: "status",
                onCell: cellProps
            },
            {
                title: "Date time",
                dataIndex: "created_at",
                onCell: cellProps
            },
        ],
        rowColor
    );

    return (
        <Table
            id="job-logs-table"
            scroll={{x: 400}}
            size="small"
            pagination={records.length > 10}
            dataSource={records}
            columns={coloredColumns.values()}
        />
    );
}