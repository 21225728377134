import {PluginAnonymous, PluginSourceParallel, PluginSourceSequence} from "../../../services/Plugins";
import {DocumentRegistryViewRef} from "../DocumentRegistryView";
import {CanvasGroup} from "../../ResponsiveCanvas/Resources/CanvasGroup";
import {Canvas} from "../../ResponsiveCanvas/Resources/Canvas";
import {PluginCanvasWheelZoom} from "../../ResponsiveCanvas/Plugins/PluginCanvasWheelZoom";
import {PluginCanvasSimplePan} from "../../ResponsiveCanvas/Plugins/PluginCanvasSimplePan";
import {ResponsiveCanvas} from "../../ResponsiveCanvas/ResponsiveCanvas";
import React from "react";
import {DocumentRegistryItem} from "../../../services/DocumentRegistry/DocumentRegistry";
import {ReadonlyResource} from "../../../services/Resources";
import {
    DocumentRegistryItemInspector,
    DocumentRegistryItemInspectors
} from "../Resources/DocumentRegistryItemInspectors";
import {message} from "antd";

class DocQuickView implements DocumentRegistryItemInspector {
    private readonly canvasGroup: ReadonlyResource<CanvasGroup>;
    private readonly canvasId: string;

    constructor(canvasGroup: ReadonlyResource<CanvasGroup>, canvasId: string) {
        this.canvasGroup = canvasGroup;
        this.canvasId = canvasId;
    }

    inspect(item: DocumentRegistryItem): void {
        item.previewCompressed().then(preview => {
            const img = preview.image;
            this.canvas().setBackgroundImage(img);
        }).catch(() => {
            message.error("Failed to load image!");
        });
    }

    private canvas(): Canvas {
        return this.canvasGroup.get().getById(this.canvasId);
    }
}

export class PluginDocQuickView extends PluginSourceSequence<DocumentRegistryViewRef> {
    constructor() {
        const canvasId = "doc-quick-view-canvas";
        super([
            new PluginAnonymous("doc-view-ref", {
                async build(target: DocumentRegistryViewRef): Promise<void> {
                    const dockQuickView = new DocQuickView(target.res(CanvasGroup), canvasId);
                    const inspectors = target.res(DocumentRegistryItemInspectors).get();
                    inspectors.add(dockQuickView);
                }
            }),
            new PluginDockQuickViewCanvas(canvasId),
            new PluginSourceParallel([
                new PluginCanvasWheelZoom(canvasId),
                new PluginCanvasSimplePan(canvasId),
            ]),
        ]);
    }
}

class PluginDockQuickViewCanvas extends PluginAnonymous<DocumentRegistryViewRef> {
    constructor(canvasId: string) {
        super("quick-view", {
            build(target: DocumentRegistryViewRef) {
                return new Promise(resolve => {
                    target.addDock("right-ul", {
                        children: (
                            <ResponsiveCanvas id={canvasId} onReady={canvas => {
                                const canvasGroup = target.res(CanvasGroup).get();
                                canvasGroup.add(canvasId, new Canvas(canvas));
                                resolve();
                            }}/>
                        ),
                        key: "doc-quick-view",
                        name: "Quick View",
                        forceRender: true,
                    });
                });
            }
        });
    }
}
