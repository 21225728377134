import {Row, Table, Tooltip} from "antd";
import React from "react";
import {WarningOutlined} from "@ant-design/icons";

export function AddMultipleNodesTable({dataSource, onUpdateDataSource}) {
    const sortedDuplicates = dataSource
        .filter(el => el.duplicated(dataSource))
        .sort((a, b) => a.getText().localeCompare(b.getText()) || a.getLabel().localeCompare(b.getLabel()));

    const withoutDuplicates = dataSource.filter(el => !sortedDuplicates.includes(el));

    const listColumns = [
        {
            title: "Name",
            render: (_, record, idx) => {
                const duplicated = sortedDuplicates.includes(record);
                return (
                    <div>
                        <Row justify={"space-between"}>
                            {record.getText()}
                            {duplicated && <DuplicatedWarning id={`duplicate-warning_${idx}`}/>}
                        </Row>
                    </div>
                );
            }
        },
        {
            title: "Actions",
            render: (_, record, idx) => {
                return (
                    <a id={`remove-node-to-add_${idx}`}
                        onClick={() => onUpdateDataSource(dataSource.filter(item => item !== record))}
                    >
                        Remove
                    </a>
                );
            }
        }
    ];

    return (
        <Table
            id="add-multiple-nodes-table"
            dataSource={sortedDuplicates.concat(withoutDuplicates)}
            columns={listColumns}
            size="small"
            style={{marginTop: "8px"}}
        />
    );
}


function DuplicatedWarning(props) {
    return (
       <Warning {...props} message={"Node is duplicated"}/>
    );
}

function Warning({id, color="red", message}) {
    return (
        <span id={id} style={{color: color}}>
            <Tooltip title={message}>
                <WarningOutlined/>
            </Tooltip>
        </span>
    );
}