export interface ResourcesSource {
    res<T>(type: ResType<T>): Resource<T>
}

export class Resources implements ResourcesSource {
    private readonly resources: Map<ResType<any>, any> = new Map();
    private readonly readonlyResources: Set<ResType<any>> = new Set();
    private readonly delSignals: ResSignals = new ResSignals();
    private readonly setSignals: ResSignals = new ResSignals();

    res<T>(type: ResType<T>): Resource<T> {
        return new Resource<T>(type, this);
    }

    set<T>(type: ResType<T>, resource: T) {
        if (this.isReadonly(type)) {
            throw new Error(`Unable to set the readonly resource of type ${type}`);
        }
        this.resources.set(type, resource);
        this.setSignals.notify(type, resource);
    }

    get<T>(type: ResType<T>): T {
        if (!this.resources.has(type)) {
            throw new Error(`Unable to find the resource of type ${type}`);
        }
        return this.resources.get(type);
    }

    del<T>(type: ResType<T>) {
        if (this.isReadonly(type)) {
            throw new Error(`Unable to del the readonly resource of type ${type}`);
        }
        if (!this.resources.has(type)) {
            throw new Error(`Unable to find the resource of type ${type}`);
        }
        const val = this.get(type);
        this.resources.delete(type);
        this.delSignals.notify(type, val);
    }

    has<T>(type: ResType<T>): boolean {
        return this.resources.has(type);
    }

    onSet<T>(type: ResType<T>, callback: ResSignalCallback<T>): ResSignalSubscription {
        return this.setSignals.subscribe(type, callback);
    }

    onDel<T>(type: ResType<T>, callback: ResSignalCallback<T>): ResSignalSubscription {
        return this.delSignals.subscribe(type, callback);
    }

    isReadonly<T>(type: ResType<T>) {
        return this.readonlyResources.has(type);
    }

    makeReadonly<T>(type: ResType<T>) {
        this.readonlyResources.add(type);
    }
}

export type ResType<T> = new (...args: any[]) => T;

interface SubscriptionLifetime {
    add(sub: Subscription): void
}

interface Subscription {
    unsubscribe(): void
}

interface ResSignalSubscription extends Subscription {
    unsubscribeWith(lifetime: SubscriptionLifetime): void
}

type ResSignalCallback<T> = (what: T) => void;

class ResSignals {
    private readonly subscriptions: Map<ResType<any>, any> = new Map();

    notify<T>(type: ResType<T>, what: T) {
        this.bucketValues(type).forEach(x => x(what));
    }

    subscribe<T>(type: ResType<T>, callback: ResSignalCallback<T>): ResSignalSubscription {
        const uniqueCallback = (what: T) => callback(what);
        this.bucketUpdate(type, prev => [...prev, uniqueCallback]);
        return {
            unsubscribe: () => {
                this.bucketUpdate(
                    type,
                    prev => prev.filter(x => x !== uniqueCallback)
                );
            },
            unsubscribeWith(subs: SubscriptionLifetime) {
                subs.add(this);
            }
        };
    }

    private bucketValues<T>(type: ResType<T>): ReadonlyArray<ResSignalCallback<T>> {
        if (!this.subscriptions.has(type)) {
            return [];
        } else {
            return [...this.subscriptions.get(type)];
        }
    }

    private bucketUpdate<T>(type: ResType<T>, set: (prev: ReadonlyArray<ResSignalCallback<T>>) => Array<ResSignalCallback<T>>) {
        this.subscriptions.set(type, set(this.bucketValues(type)));
    }
}

type EffectCleanup = (() => void ) | void;

export interface ReadonlyResource<T> {
    get(): T;

    getSafe(): T | undefined;

    isInserted(): boolean;

    effect(callback: (what: T) => EffectCleanup): ResSignalSubscription;

    map<Y>(map: (what: T) => Y, fallback: (() => Y) | Y): Y;

    test(cond: (what: T) => boolean): boolean;
    test(cond: (what: T) => boolean, fallback: boolean): boolean;
}

export class Resource<T> implements ReadonlyResource<T> {
    private readonly resources: Resources;
    private readonly type: ResType<T>;

    constructor(type: ResType<T>, resources: Resources) {
        this.resources = resources;
        this.type = type;
    }

    get(): T {
        return this.resources.get(this.type);
    }

    set(value: T): this{
        this.resources.set(this.type, value);
        return this;
    }

    del() {
        this.resources.del(this.type);
    }

    isInserted(): boolean {
        return this.resources.has(this.type);
    }

    effect(callback: (what: T) => EffectCleanup): ResSignalSubscription {
        const subs: Array<Subscription> = [];
        let cleanup: EffectCleanup = undefined;

        subs.push(this.resources.onSet(this.type, what => {
            cleanup?.();
            cleanup = callback(what);
        }));

        subs.push(this.resources.onDel(this.type, _ => {
            cleanup?.();
            cleanup = undefined;
        }));

        if (this.isInserted()) {
            cleanup = callback(this.get());
        }

        return new class implements ResSignalSubscription {
            unsubscribe(): void {
                subs.forEach(x => x.unsubscribe());
            }

            unsubscribeWith(lifetime: SubscriptionLifetime): void {
                lifetime.add(this);
            }
        }();
    }

    map<Y>(map: (what: T) => Y, fallback: (() => Y) | Y): Y {
        if (this.isInserted()) {
            return map(this.get());
        } else {
            if (isCallable(fallback)) {
                return fallback();
            } else {
                return fallback as Y;
            }
        }
    }

    test(cond: (what: T) => boolean, fallback = false): boolean {
        return this.map(cond, fallback);
    }

    getSafe(): T | undefined {
        return this.isInserted() ? this.get() : undefined;
    }

    isSealed(): boolean {
        return this.resources.isReadonly(this.type);
    }

    seal(): void {
        this.resources.makeReadonly(this.type);
    }
}

function isCallable<T>(arg: (() => T) | T): arg is (() => T) {
    return typeof arg === "function";
}

export {ResSignals as _TestOnlyResSignals};
