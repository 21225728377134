import BaseLayer from "./BaseLayer";
import {getFabricjsArrow, isGraphObject} from "../../../../../Utilities";
import {fabric} from "fabric";
import {viewerConsts} from "../../../../../constants";

export default class ParentChildLayer extends BaseLayer {
  constructor(layerId, hierarchy) {
    super(layerId);
    this.hierarchy = hierarchy;
  }

  getCanvasObjects() {
    const parentRelations = this.hierarchy.nodes.flatMap(node => {
      if (node.parent_node_id === null) return [];
      const childObject = node.findReferencedObject();
      const parentNode = node.getParent();
      const parentObject = parentNode.findReferencedObject();
      if (!childObject || !this.hierarchy.imageViewer.isObjectVisible(childObject.canvasObject)) return [];

      if (parentObject && this.hierarchy.imageViewer.isObjectVisible(parentObject.canvasObject)) {
        if (!childObject.isGraphObject() && !parentObject.isGraphObject()) {
          const pStart = childObject.getRect().getCenter();
          const pEnd = parentObject.getRect().getCenter();
          return getFabricjsArrow([pStart.x, pStart.y, pEnd.x, pEnd.y]);
        }

        if (parentObject.isGraphObject() && !childObject.isGraphObject()) {
          const defaultArgs = {
            lockScalingFlip: true,
            fill: "rgba(0,0,0,0)",
            cornerColor: "lime",
            cornerStrokeColor: "gray",
            cornerStyle: "circle",
            transparentCorners: false,
            cornerSize: 10,
            noScaleCache: false,
            strokeUniform: true,
            objectCaching: false,
          };
          const tmpObj = new fabric.Rect({
            ...defaultArgs,
            stroke: parentObject.shape.shape_color.substr(0, 7) + "FF",
            strokeDashArray: [10, 3],
            top: childObject.bbox.y1,
            left: childObject.bbox.x1,
            width: childObject.getRect().getWidth(),
            height: childObject.getRect().getHeight(),
            strokeWidth: 5.0,
            evented: false,
          });
          return [tmpObj];
        }
      } else {
        // finding indirect parent
        const pathToRoot = parentNode.getPathToRoot();
        const indirectParentNode = pathToRoot.find(parentCandidate => {
          const obj = parentCandidate.findReferencedObject();
          return obj && this.hierarchy.imageViewer.isObjectVisible(obj.canvasObject);
        });

        if (indirectParentNode) {
          const indirectParentObject = indirectParentNode.findReferencedObject();

          if (!childObject.isGraphObject() && !indirectParentObject.isGraphObject()) {
            const pStart = childObject.getRect().getCenter();
            const pEnd = indirectParentObject.getRect().getCenter();
            return getFabricjsArrow([pStart.x, pStart.y, pEnd.x, pEnd.y], {isDashed: true});
          }
        }
      }

      return [];
    });

    return parentRelations;
  }
}