import {DataEditorProps, GridColumn} from "@glideapps/glide-data-grid";
import {changed$, DepSource, useDep} from "../../../services/useDep";
import {useCallback, useEffect, useRef, useState} from "react";
import {Subject} from "rxjs";

type CompatibleColumn = GridColumn & {
    readonly id: string
}

export type StatefulColumn<T extends CompatibleColumn> = T & {
    readonly width: number,
    setWidth(width: number): void;
};

export function useStatefulColumns<T extends CompatibleColumn>(columnsCtor: () => T[]): [
    StatefulColumn<T>[],
    Pick<DataEditorProps, "onColumnResize">,
    {
        readonly current: StatefulColumn<T>[]
    }
] {
    const state = useDep(() => new ColumnState(), []);
    const [columns, setColumns] = useState<StatefulColumn<T>[]>([]);
    const columnsRef = useRef<StatefulColumn<T>[]>(columns);

    useEffect(() => {
        const originColumns = columnsCtor();
        const columns = originColumns.map(x => ({
            ...x,
            width: state.getWidth(x.id),
            setWidth(width: number) {
                state.setWidth(x.id, width);
            }
        }));
        setColumns(columns);
        columnsRef.current = columns;
    }, [state, columnsCtor]);

    const onColumnResize = useCallback<NonNullable<DataEditorProps["onColumnResize"]>>(
        (_column, newSize, colIndex) => {
            columns[colIndex].setWidth(newSize);
        },
        [columns]
    );

    return [columns, {onColumnResize}, columnsRef];
}

type ColumnStateItem = {
    width: number;
}

class ColumnState implements DepSource {
    public readonly [changed$]: Subject<void> = new Subject<void>();
    private readonly map: Map<string, ColumnStateItem> = new Map<string, ColumnStateItem>();

    getWidth(id: string): number {
        const item = this.item(id);
        return item.width;
    }

    setWidth(id: string, width: number) {
        const item = this.item(id);
        item.width = width;
        this[changed$].next();
    }

    private item(id: string): ColumnStateItem {
        if (!this.map.has(id)) {
            const item: ColumnStateItem = {
                width: 300
            };
            this.map.set(id, item);
        }
        return this.map.get(id)!;
    }
}
