import React from "react";
import {Row, Col, Modal, Form, Input, message, Space, Switch, Popconfirm} from "antd";
import { Typography } from "antd";
import { Table, Button } from "antd";
import { showNewProjectForm, fetchProjects } from "../../actions/actions";
import { Link } from "react-router-dom";
import axios from "axios";
import {API_URL} from "../../constants";
import {addTableTextFilters, authHeader} from "../../Utilities";
import WorkspaceViewModeService from "../../services/WorkspaceViewModeService";
import WorkspacesService from "../../services/WorkspacesService";

const { Title } = Typography;

class NewWorkspaceModal extends React.Component {
  state = {
    name: "",
  };

  handleOK = () => {
    axios.post(
      API_URL + "/workspaces",
      { name: this.state.name },
      {headers: authHeader()},
    ).then(result => {
      this.props.handleRefresh();
      this.props.handleHide();
    }).catch(err => {
      message.error("Failed to create workspace");
    });
  };

  handleCancel = () => {
    this.props.handleHide();
  };

  render() {
    return (
      <>
        <Modal
            title="New workspace"
            visible={this.props.visible}
            onOk={this.handleOK}
            onCancel={this.handleCancel}
        >
          <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input the workspace name" }]}
          >
            <Input value={this.state.name} onChange={(e) => this.setState({name: e.target.value})}/>
          </Form.Item>
        </Modal>
      </>
    );
  }
}

export class WorkspacesList extends React.Component {
  state = {
    workspacesList: [],
    newWorkspaceModalVisible: false,
    showAll: false,
  };

  constructor(props) {
    super(props);
    addTableTextFilters(this);
  }

  loadWorkspaces = () => {
    axios.get(
        API_URL + `/workspaces?all=${this.state.showAll}`,
        {headers: authHeader()}
    ).then(result => {
      const workspacesList = result.data.sort(
          (w1, w2) => w2.name.toLowerCase().includes("[live]") - w1.name.toLowerCase().includes("[live]")
      );
      this.setState({
        workspacesList: workspacesList,
      });
    });
  };

  hideModal = () => {
    this.setState({newWorkspaceModalVisible: false});
  };

  showModal = () => {
    this.setState({newWorkspaceModalVisible: true});
  };

  componentDidMount() {
    this.loadWorkspaces();
  }

  handleViewWorkspace = (workspaceId) => {
    WorkspaceViewModeService.enterWorkspace(workspaceId);
    this.props.history.push("/user_view/search");
  };

  handleDeleteWorkspace = (workspaceId) => {
    WorkspacesService.deleteWorkspace(workspaceId)
        .then(response => {
          message.success("Workspace has been removed");
          this.loadWorkspaces();
        }).catch(err => {
          if (err.response && err.response.status === 400 && err.response.data.message) {
              message.error(err.response.data.message);
          } else {
              message.error("Failed to remove workspace");
          }
        }
    );
  };

  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Link",
        dataIndex: "id",
        render: (id) => {
          return (<div>
            <Space size="large">
              <Link to={`/workspace/${id}`}>Open</Link>
              <Popconfirm
                  key={id}
                  title="Are you sure to delete the workspace？"
                  onConfirm={() => this.handleDeleteWorkspace(id)}
              >
                <a>Delete</a>
              </Popconfirm>
              <Button onClick={() => this.handleViewWorkspace(id)}>View</Button>
            </Space>
          </div>);
        }
      }
    ];

    return (
      <>
        <Row style={{padding: "1em"}}>
          <Title level={4}>Workspaces</Title>
          <div style={{marginLeft: "auto"}}>
            <Space size="large">
              <div>
                <span>Show all</span>
                <Switch
                  checked={this.state.showAll} style={{marginLeft: "8px"}}
                  onChange={(checked) => {
                    this.setState({showAll: checked}, this.loadWorkspaces);
                  }}
                />
              </div>
              <Button type="primary" onClick={this.showModal}>New workspace</Button>
            </Space>
          </div>
        </Row>
        <Table dataSource={this.state.workspacesList} columns={columns} />
        <NewWorkspaceModal
          visible={this.state.newWorkspaceModalVisible}
          handleHide={this.hideModal}
          handleRefresh={this.loadWorkspaces}
        />
      </>
    );
  }
}
