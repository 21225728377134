import {ReadonlyResource} from "./Resources";
import {useEffect, useState} from "react";

export function useResourceSafe<T>(res: ReadonlyResource<T>): T | undefined {
    const [value, setValue] = useState<T | undefined>(
        () => res.getSafe()
    );
    useEffect(() => {
        const sub = res.effect(setValue);
        return () => sub.unsubscribe();
    }, [res]);
    return value;
}

export function useResource<T>(res: ReadonlyResource<T>): T {
    if (!res.isInserted()) {
        throw new Error("Resource is not initialized!!!");
    }
    return useResourceSafe(res)!;
}
