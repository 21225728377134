import {useState} from "react";

export function EditCommentContainer({comment, onEditApprove, onEditCancel, onEditUpdate, children}) {
    const [initial] = useState(comment);

    const updateMessage = newMessage => {
        onEditUpdate({...comment, message: newMessage});
    };

    const updateStatus = newStatus => {
        onEditUpdate({...comment, status: newStatus});
    };

    const updateStatusAndApprove = newStatus => {
        const withNewStatus = {...comment, status: newStatus};

        onEditUpdate(withNewStatus);
        onEditApprove(initial, withNewStatus);
    };

    const addNewMarker = newMarker => {
        const lastId = Math.max(
            Math.max(...comment.markers.map(el => el.id)),
            0
        );

        onEditUpdate({
            ...comment,
            markers: [
                ...comment.markers,
                {...newMarker, id: lastId + 1}
            ]
        });
    };

    const updateMarker = marker => {
        onEditUpdate({
            ...comment,
            markers: comment.markers.map(el => el.id === marker.id ? marker : el)
        });
    };

    const deleteMarker = marker => {
        onEditUpdate({...comment, markers: comment.markers.filter(el => el.id !== marker.id)});
    };

    const handleApply = () => {
        onEditApprove(initial, comment);
    };

    const handleCancel = () => {
        onEditCancel(initial);
    };

    const assignTarget = (targets) => {
        onEditUpdate({...comment, assignTargets: targets});
    };

    return children({
        comment: comment,
        // newMarkers: newMarkers,

        onApply: handleApply,
        onCancel: handleCancel,

        onAssignTarget: assignTarget,
        onAddNewMarker: addNewMarker,
        onUpdateMarker: updateMarker,
        onDeleteMarker: deleteMarker,
        onEditMessage: updateMessage,
        onEditStatus: updateStatus,
        onEditStatusAndApprove: updateStatusAndApprove,
    });
}