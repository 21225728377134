import {useCallback, useEffect, useState} from "react";
import {message} from "antd";
import axios from "axios";
import {API_URL} from "../../../../constants";
import {authHeader} from "../../../../Utilities";


export class DrawingInfo {
    constructor(imageViewer) {
        this._imageViewer = imageViewer;
    }

    resultReference() {
        return this._imageViewer.toResultReference();
    }

    page() {
        return {
            "id": this._imageViewer.state.pageId,
            "size": {
                "width": this._imageViewer.imageWidth,
                "height": this._imageViewer.imageHeight
            }
        };
    }
}


function mapInternalToApiRequest(comment, drawingInfo) {
    const pageInfo = drawingInfo.page();

    return {
        id: comment.id,
        message: comment.message,
        author: {
            user_id: comment.author.userId
        },
        date: comment.date,
        markers: comment.markers.map(marker => ({
            id: marker.id,
            text: marker.text,
            x_rel: marker.position.x / pageInfo.size.width,
            y_rel: marker.position.y / pageInfo.size.height,
        })),
        status: comment.status,
        assign_targets: comment.assignTargets.map(t => ({user_id: t.userId}))
    };
}


function mapApiResponseToInternal(response, drawingInfo) {
    const pageInfo = drawingInfo.page();

    return {
        id: response.id,
        message: response.message,
        author: {
            userId: response.author.user_id,
            name: response.author.name
        },
        date: response.date,
        markers: response.markers.map(marker => ({
            id: marker.id,
            text: marker.text,
            position: {
                x: marker.x_rel * pageInfo.size.width,
                y: marker.y_rel * pageInfo.size.height,
            }
        })),
        status: response.status,
        assignTargets: response.assign_targets.map(t => ({userId: t.user_id, name: t.name}))
    };
}


export function ApiSyncCommentsContainer({drawingInfo, children, onDataLoad}) {
    const [data, setData] = useState([]);

    useEffect(() => {
        const result_ref = drawingInfo.resultReference();

        const params = {
            page_id: drawingInfo.page().id,
            result_id: result_ref.result_id,
            is_final: result_ref.is_final,
        };

        axios.get(
            API_URL + "/comments",
            {headers: authHeader(), params: params}
        ).then(response => {
            const loadedData = response.data.map(el => mapApiResponseToInternal(el, drawingInfo));
            setData(loadedData);
            onDataLoad(loadedData);
        }).catch(err => {
            message.error("Failed to load comments");
        });
    }, [drawingInfo, onDataLoad]);

    const editComment = useCallback((initial, edited, onSuccess, onFailure) => {
        axios.put(
            API_URL + `/comments/${edited.id}`,
            mapInternalToApiRequest(edited, drawingInfo),
            {headers: authHeader()}
        ).then(response => {
            onSuccess(mapApiResponseToInternal(response.data, drawingInfo));
        }).catch(err => {
            onFailure();
        });
    }, [drawingInfo]);

    const addComment = useCallback((created, onSuccess, onFailure) => {
        const result_ref = drawingInfo.resultReference();

        const params = {
            page_id: drawingInfo.page().id,
            result_id: result_ref.result_id,
            is_final: result_ref.is_final,
        };

        axios.post(
            API_URL + "/comments",
            mapInternalToApiRequest(created, drawingInfo),
            {headers: authHeader(), params: params}
        ).then(response => {
            onSuccess(mapApiResponseToInternal(response.data, drawingInfo));
        }).catch(err => {
            onFailure();
        });
    }, [drawingInfo]);

    const deleteComment = useCallback((comment, onSuccess, onFailure) => {
        axios.delete(
            API_URL + `/comments/${comment.id}`,
            {headers: authHeader()}
        ).then(response => {
            onSuccess();
        }).catch(err => {
            onFailure();
        });
    }, []);

    return children({
        data: data,
        onAddComment: addComment,
        onDeleteComment: deleteComment,
        onEditComment: editComment
    });
}