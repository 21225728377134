import React from "react";
import AuthService from "../../services/AuthService";
import WorkspaceViewModeService from "../../services/WorkspaceViewModeService";
import {KEYCLOAK_ENABLED} from "../../constants";

export class Logout extends React.Component {
    componentDidMount() {
        AuthService.logout({redirectUri: window.location.origin});
        if (!KEYCLOAK_ENABLED) {
            this.props.history.push("/");
            window.location.reload();
        }
        WorkspaceViewModeService.exitWorkspace();
    }

    render() {
        return null;
    }
}