import {Buffer} from "buffer/";

class TagUrlLinkService {
    getTagHash(tagNumber, tagId) {
        const tagBase64 = Buffer.from(tagNumber).toString("base64");

        const fragmentElements = [tagBase64];

        if (tagId !== "") {
            const tagIdBase64 = Buffer.from(tagId).toString("base64");
            fragmentElements.push(tagIdBase64);
        }

        return `#${fragmentElements.join(",")}`;
    }

    generateTagLinkByFinalProperty(projectId, resultId, runId, tagNumber, tagId, isFinal, extraArgs="") {
        if (isFinal) {
            return this.generateFinalizedTagLink(projectId, resultId, tagNumber, tagId, extraArgs);
        } else {
            return this.generateRunTagLink(projectId, resultId, runId, tagNumber, tagId, extraArgs);
        }
    }

    generateRunTagLink(projectId, resultId, runId, tagNumber, tagId, extraArgs="") {
        const tagHash = this.getTagHash(tagNumber, tagId);

        return `/project/${projectId}/explore_results/${runId}/view_document/${resultId}${extraArgs}${tagHash}`;
    }

    generateFinalizedTagLink(projectId, resultId, tagNumber, tagId, extraArgs="") {
        const tagHash = this.getTagHash(tagNumber, tagId);

        return `/project/${projectId}/final_results/view_result/${resultId}${extraArgs}${tagHash}`;
    }
}

export default new TagUrlLinkService();