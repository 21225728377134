import React from "react";

import {
  Layout,
  Typography,
  Avatar,
  Menu,
  Dropdown,
  Row,
  Input,
  Space,
  Tooltip,
  Tag, Checkbox
} from "antd";
import { Link, withRouter } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  API_URL, RECAPTCHA_SITE_KEY,
} from "../constants";
import {authHeader} from "../Utilities";
import {SearchContextConsumer} from "../contexts/SearchContext";
import CacheService from "../services/CacheService";
import WorkspaceViewModeService from "../services/WorkspaceViewModeService";
import {AuthContext, AuthContextConsumer} from "../contexts/AuthContext";
import {AutoCompleteWithSearchOptions} from "./Misc/AutoCompleteWithSearchOptions";
import GuideService from "../services/GuideService";
import { HistoryOutlined } from "@ant-design/icons";
import {AnotherDrawingVersionSwitcher} from "./Misc/AnotherDrawingVersionSwitcher";
import {FiltersFromURL} from "./UserView/SearchFilters/SearchFiltersFromUrl";
import {SearchFilter} from "./UserView/SearchFilters/SearchFilters";
import {DefaultSearchQuery} from "./UserView/SearchFilters/QueryFromFilters";
import {QueryWithTab, ShowModeFromQuery, TabFromQuery} from "./Misc/Query";
import {
  About,
  AutoHideOnSmallScreen,
  ContactUsLink,
  ContactUsModal,
  HomeLink,
  OtherResources
} from "@digatex/digatex-ui-lib";
import {TrimmedText} from "./Misc/TrimmedText";

const searchTypeNames = {
  search_in_current_run: "Search in current run",
  search_in_all_drawings: "Search in all drawings",
  search_in_current_drawing: "Search in current drawing",
  search_in_all_comments: "Search in comments",
};

const searchOptionsOrder = [
  "search_in_current_drawing",
  "search_in_current_run",
  "search_in_all_comments",
  "search_in_all_drawings"
];

class KHHeaderInner extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      searchPage: 1,
      searchDropdownVisible: false,
      isLoginPage: this.isLoginPage(props.location.pathname),
      currentProjectData: null,
      currentRunData: null,
      currentViewedWorkspaceData: null,
      projectWorkspaceData: null,

      isRecaptchaVerified: false,
      recaptchaToken: "",
      showContactForm: false
    };

    this.formRef = React.createRef();
    this.recaptchaRef = React.createRef();
  }

  isLoginPage = (pathname) => {
    return ["/login", "/auth/reset_password", "/auth/create_password"].includes(pathname);
  };

  sendEmail = (formData) => {
    return axios.post(API_URL + "/feedback/contact_us", formData, {headers: authHeader()});
  };

  handleUrl = () => {
    this.setState({currentProjectData: null, isFinalized: false});
    if (this.props.location.pathname.indexOf("/final_results/view_result/") !== -1) {
      this.setState({isFinalized: true});
    }
    if (this.props.match.params.projectId) {
      const loadProjectWorkspaceData = (projectData) => {
        CacheService.getWorkspaceData(projectData.workspace_id).then(workspaceData => {
          this.setState({projectWorkspaceData: workspaceData});
        });
      };

      CacheService.getProjectData(this.props.match.params.projectId).then(projectData => {
        this.setState({currentProjectData: projectData});
        loadProjectWorkspaceData(projectData);
      });
    }

    if (this.props.match.params.runId && this.props.match.params.projectId) {
      CacheService.getRunData(this.props.match.params.projectId, this.props.match.params.runId).then(runData => {
        this.setState({currentRunData: runData});
      });
    }

    if (this.props.match.params.finalResultId && this.props.match.params.projectId) {
        CacheService.getRunDataFromFinalResult(this.props.match.params.projectId,
            this.props.match.params.finalResultId).then(runData => {
          this.setState({currentRunData: runData});
        });
    }

    if (WorkspaceViewModeService.isInViewingMode()) {
      CacheService.getWorkspaceData(WorkspaceViewModeService.getCurrentWorkspaceId()).then(projectData => {
        this.setState({currentViewedWorkspaceData: projectData});
      });
    } else {
      this.setState({currentViewedWorkspaceData: null});
    }
  };

  componentDidMount() {
    this.setState({searchQuery: this.props.searchQuery});

    const searchHandlerSchema = {
      owner: this,
      searchType: "search_in_all_drawings",
      handler: this.onSearch
    };

    this.props.addSearchHandlerSchema(searchHandlerSchema);

    if (!this.props.skipUser) {
      // axios.get(API_URL + '/get_user_name', {headers: authHeader()}).then(res => this.setState({userName: res.data.user_name}));
    }
    this.handleUrl();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchQuery !== this.props.searchQuery) {
      this.setState({searchQuery: this.props.searchQuery});
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({isLoginPage: this.isLoginPage(this.props.location.pathname)});
      this.handleUrl();
    }
  }

  componentWillUnmount() {
    this.props.removeSearchHandlerSchemasByOwner(this);
  }

  isAdmin = () => {
    return this.context.user && this.context.user.roles.includes("admin");
  };

  handleLogout = () => {
    this.props.history.push("/logout");
  };

  onSearch = (value, searchSettings) => {
    let filters = [];
    const showMode = new ShowModeFromQuery(this.props.location.search);
    const tab = new TabFromQuery(this.props.location.search);

    if (this.props.location.pathname === "/user_view/search") {
      filters = new FiltersFromURL(this.props.location.search).getArray();
    }

    const searchFilter = new SearchFilter("", value, "Search", searchSettings.useWildcards);

    searchFilter.addTo(filters);

    const filtersQuery = filters.length > 0 ?
        new DefaultSearchQuery(filters, 1, showMode.getValue()).toString()
        : "";

    this.props.history.push({
      pathname: "/user_view/search",
      search: new QueryWithTab(filtersQuery, tab.getValue()).toString()
    });
  };

  handleExitViewMode = () => {
    WorkspaceViewModeService.exitWorkspace();
    this.props.history.push("/workspaces");
  };

  renderLinkToCurrentRunData = (linkText) => {
    const runId = this.state.currentRunData.id;
    const link = runId ? `/user_view/explore_results/${runId}` : "";

    return (
        <Link id="link-to-current-run" style={{color: "white", marginLeft: "4px"}} to={link}>
          <TrimmedText text={linkText ?? ""} maxLength={57} tooltipProps={{placement: "topLeft"}}>
            {_ => linkText}
          </TrimmedText>
        </Link>
    );
  };

  renderDownloadGuide = () => {
    const isAdmin = this.context.user && this.context.user.roles.includes("admin");

    const overlay = (
        <Menu>
          <Menu.Item id="download-user-guide-item" onClick={()=>GuideService.downloadUserGuide()}>
            User guide
          </Menu.Item>
          <Menu.Item id="download-admin-guide-item" onClick={()=>GuideService.downloadAdminGuide()}>
            Admin guide
          </Menu.Item>
        </Menu>
    );

    return (
        <>
          <Dropdown
              disabled={!isAdmin}
              placement={"bottomCenter"}
              trigger={"click"}
              overlay={overlay}
              arrow
          >
            <img id="icon-download-guide" src={process.env.PUBLIC_URL + "/images/download-user-guide.png"}
                 width={24} height={24} style={{alignSelf: "center"}}
                 onClick={() => !isAdmin && GuideService.downloadUserGuide()}
            />
          </Dropdown>
        </>
    );
  };

  getSearchOptionsAndMainSearchHandler = () => {
    const sortedSearchHandlers = this.props.searchHandlersSchemas.sort((a, b)=> {
      const aOrderIndex = searchOptionsOrder.findIndex(orderEl => orderEl === a.searchType);
      const bOrderIndex = searchOptionsOrder.findIndex(orderEl => orderEl === b.searchType);

      return aOrderIndex - bOrderIndex;
    });

    const mainSearchHandler = sortedSearchHandlers[0] ?
        () => sortedSearchHandlers[0].handler(this.state.searchQuery, this.props.searchSettings) :
        () => this.onSearch(this.state.searchQuery, this.props.searchSettings);

    const searchOptions = sortedSearchHandlers.map(schema => {
      const suggestedName = searchTypeNames[schema.searchType];

      return schema.render ? schema.render(this.state.searchQuery, suggestedName) :
          (<Menu.Item>
            <a onClick={() => {
              schema.handler(this.state.searchQuery, this.props.searchSettings);
              this.setState({searchDropdownVisible: false});
            }}>
              {suggestedName}
            </a>
          </Menu.Item>);
    });

    return [searchOptions, mainSearchHandler];
  };

  getScopeName = () => {
    const isCurrentWorkspaceNameVisible = this.props.location.pathname.indexOf("/view_result/") !== -1 ||
        this.props.location.pathname.indexOf("/view_document/") !== -1;

    const projectName = `Project: ${this.state.currentProjectData?.name || ""}`;
    const workspaceName = `Workspace: ${this.state.projectWorkspaceData?.name || ""}`;
    const resultName = isCurrentWorkspaceNameVisible ? workspaceName : projectName;

    return (
        <TrimmedText text={resultName} maxLength={50} tooltipProps={{placement: "left"}}>
          {_ => resultName}
        </TrimmedText>
    );
  };

  render() {
    const [searchOptions, mainSearchHandler] = this.getSearchOptionsAndMainSearchHandler();

    const exitViewModeMenu = (
        <Menu>
          <Menu.Item onClick={this.handleExitViewMode}>
            Exit view mode
          </Menu.Item>
        </Menu>
    );

    const userGuideFileName = "1000-100-DAT-00009 04 Quick reference guide - DIGATEX Knowledge Hub.pdf";

    const isFinalResult = this.props.match.params.finalResultId != null;
    const resultId = isFinalResult ? this.props.match.params.finalResultId : this.props.match.params.documentId;

    const isDrawingVersionsSwitcherVisible = this.props.location.pathname.indexOf("/view_result/") !== -1 ||
        this.props.location.pathname.indexOf("/view_document/") !== -1;

    const anotherDrawingVersionSwitcher = (
          isDrawingVersionsSwitcherVisible &&
          <AnotherDrawingVersionSwitcher
              onVersionOptionClick={(drawingVersion, alreadyOpened) => {
                if (alreadyOpened) return;

                if (drawingVersion.is_final) {
                  this.props.history.push(`/project/${drawingVersion.project_id}/final_results/view_result/${drawingVersion.result_id}`);
                }
                else {
                  this.props.history.push(`/project/${drawingVersion.project_id}/explore_results/${drawingVersion.run_id}/view_document/${drawingVersion.result_id}`);
                }
              }}
              isFinalResult={isFinalResult}
              resultId={resultId}
              button={(<HistoryOutlined id="drawing-versions-switcher-button"/>)}
          />
    );

    return (
        <Layout.Header style={{padding: 10, display: "flex", justifyContent: "space-between", alignItems: "center"}}>

          <HomeLink/>

          {!this.state.isLoginPage && (
              <React.Fragment>
                <AutoCompleteWithSearchOptions
                      searchQuery={this.state.searchQuery}
                      autoCompleteOptions={this.props.searchSettings.useWildcards ? [] : this.props.autoCompleteOptions}
                      maxAutoCompleteOptionsNumber={5}
                      minSearchQueryLengthToActivate={2}
                      searchOptions={searchOptions}
                      searchSettings={(
                          <Menu.Item>
                            <Checkbox
                                id="use-wildcards-checkbox"
                                checked={this.props.searchSettings.useWildcards}
                                onChange={e => {
                                  const searchSettings = this.props.searchSettings;
                                  searchSettings.useWildcards = e.target.checked;

                                  this.props.setSearchSettings(searchSettings);
                                }}
                            >
                              Use wildcards
                            </Checkbox>
                          </Menu.Item>
                      )}
                      onAutoCompleteOptionClick={(text) => {
                        this.setState({searchQuery: text}, mainSearchHandler);
                        this.setState({searchDropdownVisible: false});
                      }}
                      visible={this.state.searchDropdownVisible}
                      onVisibleChange={(visible) => this.setState({searchDropdownVisible: visible})}
                >
                  <Input.Search id="global-search-box" enterButton
                                style={{width: "20%", marginLeft: "32px"}}
                                value={this.state.searchQuery}
                                autoComplete="off"
                                onClick={() => {
                                  this.setState({searchDropdownVisible: true});
                                }}
                                onSearch={() => {
                                  mainSearchHandler();
                                  this.setState({searchDropdownVisible: false});
                                }}
                                onChange={e => {
                                  this.setState({searchQuery: e.target.value});
                                  this.setState({searchDropdownVisible: true});
                                }}
                  />
                </AutoCompleteWithSearchOptions>

                {this.state.currentProjectData && (
                    <AutoHideOnSmallScreen allowedDimensions={["xl", "xxl"]}>
                      <div style={{marginLeft: "32px", flex: 1, overflowX: "hidden", marginRight: "32px"}} >
                        <div style={{padding: 0, whiteSpace: "nowrap"}} >
                          <h5 style={{margin: 0, padding: 0, color: "white", lineHeight: "1.5", fontWeight: 600, fontSize: "1vw", overflowX: "hidden", textOverflow: "ellipsis"}}>
                            {this.getScopeName()}
                          </h5>
                          {/*<Typography.Title level={5} style={{color: 'white', margin: 0, padding: 0}}>Project: {this.state.currentProjectData.name}</Typography.Title>*/}
                        </div>
                        <div>
                          {this.state.isFinalized && this.state.currentRunData && (
                              <RunInfo
                                  prefix={"Finalized version"}
                                  text={(this.state.currentRunData.name && (
                                      this.renderLinkToCurrentRunData(`(${this.state.currentRunData.name})`)
                                  ))}
                                  drawingSwitcher={anotherDrawingVersionSwitcher}
                              />
                          )}
                          {!this.state.isFinalized && this.state.currentRunData && (
                              <RunInfo
                                  prefix={"Run:"}
                                  text={this.renderLinkToCurrentRunData(this.state.currentRunData.name)}
                                  drawingSwitcher={anotherDrawingVersionSwitcher}
                              />
                          )}
                        </div>
                      </div>
                    </AutoHideOnSmallScreen>
                )}
              </React.Fragment>
          )}
          <div style={{marginLeft: "auto"}}>
            <Row>
              <Space size="middle">
                <About/>
                {
                  this.state.isLoginPage ?
                      null
                      :
                      <Row>
                        <Space size="middle">
                          <a>
                            <Tooltip title="Download user guide" placement="bottom">
                              {this.renderDownloadGuide()}
                            </Tooltip>
                          </a>
                          <ContactUsLink show={() => this.setState({showContactForm: true})}/>
                          <a>
                            <Tooltip title="Log out" placement="bottom">
                              <img id="icon-logout" src={process.env.PUBLIC_URL + "/images/logout.png"}
                                   onClick={this.handleLogout}
                                   width={24} height={24} style={{alignSelf: "center"}}/>
                            </Tooltip>
                          </a>
                        </Space>
                        <div style={{display: "flex"}}>
                          <div style={{marginLeft: "32px", display: "flex", flexDirection: "column", alignSelf: "center"}}>
                            <div style={{display: "flex"}}>
                              <div style={{alignSelf: "center", lineHeight: 0}}>
                                <Avatar style={{backgroundColor: "var(--color-gray)"}} size="default" icon={<UserOutlined/>}/>
                                <span style={{color: "white", margin: "1em"}}>
                            {this.context.user?.full_name}
                          </span>
                              </div>


                            </div>
                            {/*<span style={{color: 'white', margin: '1em'}}>User</span>*/}
                            <div style={{lineHeight: 0, alignSelf: "center"}}>
                              {
                                  this.isAdmin() && WorkspaceViewModeService.isInViewingMode() &&
                                  <Tooltip
                                      title={`Workspace: ${this.state.currentViewedWorkspaceData ? this.state.currentViewedWorkspaceData.name : "..."}`}
                                      placement="left"
                                  >
                                    <Dropdown overlay={exitViewModeMenu} trigger="click">
                                      <Tag style={{marginTop: "4px", marginRight: 0}} color='blue' size="small">View mode</Tag>
                                    </Dropdown>
                                  </Tooltip>
                              }
                            </div>
                          </div>
                        </div>
                      </Row>
                }
              </Space>
            </Row>
          </div>
          <ContactUsModal show={this.state.showContactForm}
                          close={() => this.setState({showContactForm: false})}
                          sendEmail={this.sendEmail}
                          initialValues={{name: this.context.user?.name}}
                          recaptchaSiteKey={RECAPTCHA_SITE_KEY}
                  />
        </Layout.Header>
    );
  }
}


function RunInfo({prefix, text, drawingSwitcher}) {
  return (
      <Typography.Title
          level={5}
          style={{
            color: "white",
            margin: 0,
            padding: 0,
            fontSize: "0.8vw",
            maxWidth: "100%",
            maxHeight: "100%",
            display: "flex",
          }}
      >
        <span style={{whiteSpace: "nowrap"}}>{prefix}</span>
        {text && (
            <span style={{overflowX: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
              {text}
            </span>
        )}
        <span style={{marginLeft: "4px", marginTop: "2px"}}>{drawingSwitcher}</span>
      </Typography.Title>
  );
}

const KHHeaderInnerWithRouter = withRouter(KHHeaderInner);

function KHHeader() {
  return (
      <SearchContextConsumer>{
        context => (
          <KHHeaderInnerWithRouter context={context}
                                   searchHandlersSchemas={context.searchHandlersSchemas}
                                   searchQuery={context.query}
                                   handleQuery={context.handleQuery}
                                   autoCompleteOptions={context.autoCompleteOptions}
                                   addSearchHandlerSchema={context.addSearchHandlerSchema}
                                   removeSearchHandlerSchemasByOwner={context.removeSearchHandlerSchemasByOwner}
                                   searchSettings={context.searchSettings}
                                   setSearchSettings={context.setSearchSettings}
          />
          )
        }
      </SearchContextConsumer>
  );
}
export default KHHeader;
