import {CnCanvasObject} from "./CnCanvasObject";

export class ResultCnCanvasObject extends CnCanvasObject {
    constructor(current, another, status) {
        super();

        this._current = current;
        this._another = another;
        this._status = status;
    }

    select() {
        this._current.select();
        this._another.select();
    }

    deselect() {
        this._current.deselect();
        this._another.deselect();
    }

    addToCanvas() {
        this._current.addToCanvas();
        // this._another.addToCanvas();
        if (this._status.name() === "REMOVED" || this._status.name() === "MOVED") {
            this._another.addToCanvas();
        }
    }

    removeFromCanvas() {
        this._current.removeFromCanvas();
        // this._another.addToCanvas();
        if (this._status.name() === "REMOVED" || this._status.name() === "MOVED") {
            this._another.removeFromCanvas();
        }
    }

    addHighlight() {
        this._current.addHighlight();
        this._another.addHighlight();
    }

    removeHighlight() {
        this._current.removeHighlight();
        this._another.removeHighlight();
    }

    show() {
        this._current.show();
        this._another.show();
    }

    hide() {
        this._current.hide();
        this._another.hide();
    }
}