export const filterOperationsMap = {
    equal: "eq",
    contains: "contains"
};

export class RemoteFilter {
    constructor(key, value, filterAlias="") {
        this.key = key;
        this.value = value;
        this.filterAlias = filterAlias;
    }

    addTo(filters) {
        const findIndex = filters.findIndex(el => el.filterAlias === this.filterAlias);

        if (findIndex > -1) {
            filters[findIndex] = this;
        } else {
            filters.push(this);
        }

        return filters;
    }

    getDisplayValue() {
        return this.value;
    }

    getName() {
        return this.filterAlias;
    }
}

export class SearchFilter extends RemoteFilter {
    constructor(key, value, filterAlias="", useWildcards=false) {
        super(key, value, filterAlias);
        this.useWildcards = useWildcards;
    }

    // this type of filter can't exist with empty value
    addTo(filters) {
        if (!this.value) {
            const findIndex = filters.findIndex(el => el.filterAlias === this.filterAlias);

            if (findIndex > -1) {
                filters.splice(findIndex, 1);
            }

            return filters;
        }

        return super.addTo(filters);
    }

    getName() {
        if (this.useWildcards) return `${this.filterAlias} (wildcard)`;

        return super.getName();
    }
}

export class PageFieldFilter extends RemoteFilter {
    constructor(key, value, filterAlias="", operation) {
        super(key, value, filterAlias);

        this.operation = operation;
    }

    getDisplayValue() {
        if (this.operation === filterOperationsMap.contains) return `*${this.value}*`;

        return super.getDisplayValue();
    }

    getName() {
        return super.getName();
    }
}

export class ObjectAttributeFilter extends RemoteFilter {
    constructor(key, value, filterAlias="", operation) {
        super(key, value, filterAlias);

        this.operation = operation;
    }

    getDisplayValue() {
        if (this.operation === filterOperationsMap.contains) return `*${this.value}*`;

        return super.getDisplayValue();
    }

    getName() {
        return `${this.filterAlias}`;
    }

    getColor() {
        return "green";
    }
}