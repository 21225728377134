import {Subject, Subscribable} from "rxjs";
import {DependencyList, useEffect, useMemo, useState} from "react";

export const changed$ = Symbol();

export interface DepSource {
    readonly [changed$]: Subscribable<any>;
}

const derefSymbol = Symbol();

export type Dep<T extends DepSource> = T & {
    readonly [derefSymbol]: () => T;
};

export function deref<T extends DepSource>(dep: Dep<T>): T {
    return dep[derefSymbol]();
}

export function useDep<T extends DepSource>(initial: T | (() => T), deps: DependencyList | undefined): Dep<T> {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const target = useMemo<T>(() => init(initial), deps);
    const [dep, setDep] = useState(depOf(target));
    useEffect(() => {
        const sub = target[changed$].subscribe(() => {
            setDep(depOf(target));
        });
        return () => sub.unsubscribe();
    }, [target]);
    return dep;
}

export function asDepSource<T extends object>(
    target: T, adapt: (self: T, changed$: Subject<void>) => void
): T & DepSource {
    const subject = new Subject<any>();
    adapt(target, subject);
    return new Proxy(target, {
        get(target: T, p: string | symbol, receiver: any): any {
            if (p === changed$) {
                return subject;
            }
            return Reflect.get(target, p, receiver);
        }
    }) as T & DepSource;
}

function depOf<T extends DepSource>(target: T): Dep<T> {
    return new Proxy(target, {
        get: (target, propKey, receiver) => {
            if (propKey === derefSymbol) {
                return () => target;
            }
            return Reflect.get(target, propKey, receiver);
        }
    }) as Dep<T>;
}

function init<T>(initial: T | (() => T)): T {
    if (isCallable(initial)) {
        return initial();
    } else {
        return initial;
    }
}

function isCallable<T>(arg: T | (() => T)): arg is () => T  {
    return typeof arg === "function";
}
