interface Subscription {
    unsubscribe(): void
}

export class Subscriptions {
    private readonly subs: Array<Subscription> = [];

    add(sub: Subscription): void {
        this.subs.push(sub);
    }

    unsubscribe(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }
}