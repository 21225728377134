import React, {Dispatch, SetStateAction, useState} from "react";
import {Button, Form, Input, message} from "antd";
import {InsertRowRightOutlined} from "@ant-design/icons";
import {Dep} from "../../../../../services/useDep";
import {DocumentRegistrySets} from "../../../../../services/DocumentRegistry/DocumentRegistry";
import {FormModal} from "../../../../../services/FormModal";

type NewSetProps = {
    registrySets: Dep<DocumentRegistrySets>
}

export function NewSet({registrySets}: NewSetProps) {
    const [modalOpen, setModalOpen] = useState(false);
    return (
        <>
            <NewSetModal open={modalOpen} setModalOpen={setModalOpen} registrySets={registrySets}/>
            <Button
                icon={<InsertRowRightOutlined/>}
                type={"link"}
                onClick={() => setModalOpen(true)}
            >
                New set
            </Button>
        </>
    );
}

type NewSetModalProps = {
    registrySets: Dep<DocumentRegistrySets>
    open: boolean,
    setModalOpen: Dispatch<SetStateAction<boolean>>
}

function NewSetModal({open, setModalOpen, registrySets}: NewSetModalProps) {
    const submit = (values: any) => {
        return registrySets.add(values.name).catch(() => {
            message.error("Failed to create set");
        });
    };

    return (
        <FormModal
            formId={"new-set-form-modal"}
            open={open}
            setModalOpen={setModalOpen}
            onSubmit={submit}
            modalProps={{
                title: "New Set",
                destroyOnClose: true,
                okText: "Create",
            }}
        >
            <Form.Item label={"Name"} name={"name"}>
                <Input/>
            </Form.Item>
        </FormModal>
    );
}
