import {ObjectAttributeFilter, PageFieldFilter} from "./SearchFilters";

class BaseRequestBodyFromFilters {
    constructor(filters) {
        this.filters = filters;

    }

    getBody() {
        return {};
    }
}

export class RequestBodyFromFilters extends BaseRequestBodyFromFilters {
    constructor(filters) {
        super(filters);

        this.bodiesFromFilters = [
            new PageFieldRequestBodyFromFilters(filters),
            new ObjectAttributeRequestBodyFromFilters(filters)
        ];
    }

    getBody() {
        return this.bodiesFromFilters.flatMap(item => item.getBody());
    }
}


class ObjectAttributeRequestBodyFromFilters extends BaseRequestBodyFromFilters {
    getBody() {
        const attrFilters = this.filters.filter(filter => filter instanceof ObjectAttributeFilter);

        return attrFilters.map(filter => (
            {
                target: "object_attr",
                key: filter.key,
                value: filter.value,
                operation: filter.operation
            }
        ));
    }
}

class PageFieldRequestBodyFromFilters extends BaseRequestBodyFromFilters {
    getBody() {
        const pageFieldFilters = this.filters.filter(filter => filter instanceof PageFieldFilter);

        return pageFieldFilters.map(filter => (
            {
                target: "page_field",
                key: filter.key,
                value: filter.value,
                operation: filter.operation
            }
        ));
    }
}