export type Undo = () => void;

export interface ObjectModifier<T> {
    apply(target: T): Undo;
}

type AppliedModifier<T> = {
    self: ObjectModifier<T>,
    undo: Undo
}

export class ObjectModifiers<T> {
    private readonly obj: T;
    private readonly modifiers: AppliedModifier<T>[] = [];

    constructor(obj: T) {
        this.obj = obj;
    }

    add(modifier: ObjectModifier<T>) {
        const isAlreadyAdded = this.findIndex(modifier) !== -1;
        if (isAlreadyAdded) {
            throw new Error("Mod is already added");
        }
        this.modifiers.unshift({
            self: modifier,
            undo: modifier.apply(this.obj)
        });
    }

    replace(modifier: ObjectModifier<T>) {
        const wasNotAdded = this.findIndex(modifier) === -1;
        if (wasNotAdded) {
            throw new Error("Mod was not added");
        }
        const modLastIndex = this.findLastIndex(modifier);
        const tmpRemoved = [];
        for (let i = 0; i <= modLastIndex; i++) {
            const tmpRemovedModifier = this.modifiers.shift()!;
            tmpRemoved.push(tmpRemovedModifier.self);
            tmpRemovedModifier.undo();
        }
        tmpRemoved.forEach(this.add, this);
    }

    remove(modifier: ObjectModifier<T>) {
        const wasNotAdded = this.findIndex(modifier) === -1;
        if (wasNotAdded) {
            throw new Error("Mod was not added");
        }
        const tmpRemoved = [];
        let removeTargetIdx = this.modifiers.findIndex(x => x.self === modifier);
        while (removeTargetIdx !== -1) {
            for (let i = 0; i < removeTargetIdx; i++) {
                const tmpRemovedModifier = this.modifiers.shift()!;
                tmpRemoved.push(tmpRemovedModifier.self);
                tmpRemovedModifier.undo();
            }
            this.modifiers.shift()!.undo();
            removeTargetIdx = this.modifiers.findIndex(x => x.self === modifier);
        }
        tmpRemoved.forEach(this.add, this);
    }

    private findLastIndex(modifier: ObjectModifier<T>): number {
        for (let i = this.modifiers.length - 1; i >= 0; i--) {
            if (this.modifiers[i].self === modifier) {
                return i;
            }
        }
        return -1;
    }

    private findIndex(modifier: ObjectModifier<T>) {
        return this.modifiers.findIndex(x => x.self === modifier);
    }
}
