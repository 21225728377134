import React, {useState} from "react";
import {Form, Select} from "antd";
import {emptyAlias} from "../DataSourceFilter";
import {Option} from "antd/es/mentions";
import {SimpleEditedFilter} from "./Model/SimpleEditedFilter";

export function SimpleFilterEditor({filterSchemas, onEditedFilterChanged}) {
    const [allowedValues, setAllowedValues] = useState([]);
    const allowedAttributes = [...new Set(filterSchemas.map((schema) => schema.attribute))];

    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);

    const searchableSelectProps = {
        size: "small",
        showSearch: true,
        filterOption: filterOption,
    };

    function calculateAllowedValuesBySelectedAttribute(selectedAttribute) {
        let newAllowedValues = [];

        if (selectedAttribute != null) {
            const schema = filterSchemas.find((schema) => schema.attribute === selectedAttribute);

            newAllowedValues = [...new Set(schema.allowedValues)];
        }

        setAllowedValues(newAllowedValues);
    }

    function filterOption(input, option) {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }

    return (
        <>
            <Form.Item label="Attribute">
                <Select
                    id="attribute-select"
                    {...searchableSelectProps}
                    value={selectedAttribute}
                    onSelect={(attr) => {
                        setSelectedAttribute(attr);
                        calculateAllowedValuesBySelectedAttribute(attr);
                        setSelectedValue(null);

                        onEditedFilterChanged(new SimpleEditedFilter(attr, null, filterSchemas));
                    }}
                >
                    {
                        allowedAttributes.map((attr, idx) => {
                            const option = attr === "" ? emptyAlias : attr;
                            return (<Option id={`attribute-select-option_${attr}`} value={attr}>{option}</Option>);
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item label="Value">
                <Select
                    id="value-select"
                    {...searchableSelectProps}
                    value={selectedValue}
                    onSelect={(value) => {
                        setSelectedValue(value);
                        onEditedFilterChanged(new SimpleEditedFilter(selectedAttribute, value, filterSchemas));
                    }}
                >
                    {
                        allowedValues.map((val, idx) => {
                            const option = val === "" ? emptyAlias : val;
                            return (<Option id={`value-select-option_${val}`} value={val}>{option}</Option>);
                        })
                    }
                </Select>
            </Form.Item>
        </>
    );
}