import axios from "axios";
import {API_URL} from "../constants";
import {authHeader} from "../Utilities";

class OrganizationsService {
  fetchOrganizations() {
    return axios.get(API_URL + "/organizations", {headers: authHeader()});
  }

  fetchOrganizationById(organizationId) {
    return axios.get(API_URL + `/organizations/${organizationId}`, {headers: authHeader()});
  }

  addOrganization(organization) {
    return axios.post(API_URL + "/organizations", organization, {headers: authHeader()});
  }

  updateOrganization(organization) {
    return axios.put(API_URL + `/organizations/${organization.id}`, organization, {headers: authHeader()});
  }
}

export default new OrganizationsService();
