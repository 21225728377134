import { connect } from "react-redux";
import { NewProject } from "../components/NewProject";
import { createNewProject } from "../actions/actions";

const mapDispatchToProps = (dispatch) => {
  return {
    createProject: (project) => { dispatch(createNewProject(project)); }
  };
};

const NewProjectContainer = connect(null, mapDispatchToProps)(NewProject);

export default NewProjectContainer;