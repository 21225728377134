import {fabric} from "fabric";
import {Subject} from "rxjs";
import {IPoint} from "fabric/fabric-impl";

export class Canvas {
    public readonly raw: fabric.Canvas;
    public readonly zoom: CanvasZoom;
    public readonly events: CanvasEvents;

    constructor(rawCanvas: fabric.Canvas) {
        this.raw = rawCanvas;
        this.zoom = new CanvasZoom(rawCanvas);
        this.events = new CanvasEvents(rawCanvas);
    }

    setBackgroundImage(img: fabric.Image) {
        this.zoom.setWorkspaceSize(img.width!, img.height!);
        this.zoom.reset();
        this.raw.setBackgroundImage(img, () => {
            this.raw.requestRenderAll();
        });
    }
}

class CanvasEvents {
    public readonly mouseDown$: Subject<fabric.IEvent<MouseEvent>> = new Subject();
    public readonly mouseMove$: Subject<fabric.IEvent<MouseEvent>> = new Subject();
    public readonly mouseUp$: Subject<fabric.IEvent<MouseEvent>> = new Subject();
    public readonly mouseWheel$: Subject<fabric.IEvent<WheelEvent>> = new Subject();

    constructor(canvas: fabric.Canvas) {
        canvas.on("mouse:down", opt => this.mouseDown$.next(opt));
        canvas.on("mouse:move", opt => this.mouseMove$.next(opt));
        canvas.on("mouse:up", opt => this.mouseUp$.next(opt));
        canvas.on("mouse:wheel", opt => this.mouseWheel$.next(opt));
    }
}

type WorkspaceSize = {
    width: number,
    height: number,
}

class CanvasZoom {
    private readonly canvas: fabric.Canvas;
    private readonly maxZoom: number = 5;
    private readonly workspaceSize: WorkspaceSize;

    constructor(canvas: fabric.Canvas) {
        this.canvas = canvas;
        this.workspaceSize = {
            width: canvas.width ?? 0,
            height: canvas.height ?? 0,
        };
    }

    value() {
        return this.canvas.getZoom();
    }

    reset() {
        const ws = this.workspaceSize;
        const minZoom = this.minZoom();
        this.canvas.zoomToPoint(
            {
                x: -(ws.width * 0.5 * minZoom - (this.canvas.width ?? 0) * 0.5),
                y: -(ws.height * 0.5 * minZoom - (this.canvas.height ?? 0) * 0.5)
            },
            minZoom
        );
    }

    toObject(obj: fabric.Object) {
        this.canvas.setZoom(1);
        const vpt = [...this.canvas.viewportTransform ?? []];
        vpt[4] = -((obj.left ?? 0) + 0.5 * (obj.width ?? 0) * (obj.scaleX ?? 0) - (this.canvas.width ?? 0) * 0.5);
        vpt[5] = -((obj.top ?? 0) + 0.5 * (obj.height ?? 0) * (obj.scaleY ?? 0) - (this.canvas.height ?? 0) * 0.5);
        this.canvas.setViewportTransform(vpt);
    }

    toPoint(point: IPoint, zoomLevel: number) {
        this.canvas.zoomToPoint(point, this.clamp(zoomLevel));
    }

    setWorkspaceSize(width: number, height: number) {
        this.workspaceSize.width = width;
        this.workspaceSize.height = height;
    }

    private clamp(zoomLevel: number) {
        return clamp(zoomLevel, this.minZoom(), this.maxZoom);
    }

    private minZoom() {
        const ws = this.workspaceSize;
        if (ws == null) {
            return 0.01;
        }
        return Math.min((this.canvas.width ?? 0) / ws.width, (this.canvas.height ?? 0) / ws.height);
    }
}

function clamp(number: number, min: number, max: number) {
    return Math.min(Math.max(number, min), max);
}
