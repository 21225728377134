import {useCallback, useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../../contexts/AuthContext";

const emptyComment = {
    message: "",
    status: "open",
    author: {name: "???"},
    date: new Date(),
    markers: [],
    assignTargets: []
};


export function CreateCommentContainer({markup, layerCtor, onAddComment, children}) {
    const authContext = useContext(AuthContext);
    const [createdComment, setCreatedComment] = useState(emptyComment);

    const updateCreatedComment = useCallback(comment => {
        setCreatedComment(comment);
    }, []);

    const resetCreatedComment = useCallback(() => {
        setCreatedComment({
            ...emptyComment,
            author: {name: authContext.user.name},
            date: new Date()
        });
    }, [authContext]);

    const addComment = useCallback(comment => {
        onAddComment(comment);
        resetCreatedComment();
    }, [resetCreatedComment, onAddComment]);

    useEffect(() => {
        markup.updateLayer(layerCtor([createdComment]));

        return () => {
            markup.updateLayer(layerCtor([]));
        };
    }, [createdComment, markup, layerCtor]);

    return children({
        initial: createdComment,
        onEditUpdate: updateCreatedComment,
        onEditCancel: resetCreatedComment,
        onEditApprove: addComment,
        onEditStart: resetCreatedComment
    });
}