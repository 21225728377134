import { API_URL } from "../constants";
import axios from "axios";
import {authHeader} from "../Utilities";

export const UPLOAD_FILE_PROGRESS = "UPLOAD_FILE_PROGRESS";
function uploadFileProgress(filename, file, progress, uploadedBytes, completed, status, error) {
  return {
    type: UPLOAD_FILE_PROGRESS,
    filename,
    file,
    progress,
    uploadedBytes,
    completed,
    status,
    error
  };
}

export const PENDING_FILES = "PENDING_FILES";
function pendingFiles(files) {
  return {
    type: PENDING_FILES,
    files
  };
}

export function startUploadFiles(files, getFileName) {
  return dispatch => {
    const filesDict = {};

    files.forEach(file => {
      const fileName = getFileName(file);
      filesDict[fileName] = uploadFileProgress(fileName, file, 0, 0, false, "Pending", null);
    });

    dispatch(pendingFiles(filesDict));
  };
}


export function uploadFile(projectId, file, fileName) {
  return dispatch => {
    let percentCompleted = 0;
    let uploadedBytes = 0;

    return checkFileExists(projectId, fileName)
      .then(result => {
        if (result.exists === true) {
          dispatch(uploadFileProgress(fileName, file, 100, file.size, true, "Existed", null));
        }
        else {
          const onUploadProgress = (progressEvent) => {
            percentCompleted = ((progressEvent.loaded * 100) / progressEvent.total).toFixed(1);
            uploadedBytes = progressEvent.loaded;
            dispatch(uploadFileProgress(fileName, file, percentCompleted, progressEvent.loaded, false, "Uploading", null));
          };

          return uploadFileToServer(projectId, file, fileName, onUploadProgress)
            .then(response => {
              dispatch(uploadFileProgress(fileName, file, 100, file.size, true, "OK", null));
            });
        }
      })
      .catch(err => {
        dispatch(uploadFileProgress(fileName, file, percentCompleted, uploadedBytes, false, err.toString(), err));
      });
  };
}

function checkFileExists(projectId, file_path) {
  const params = { file_path };
  const url = API_URL + `/projects/${projectId}/documents/exists`;
  return axios.get(url, { params, headers: authHeader()}).then(res => res.data);
}

function uploadFileToServer(projectId, file, fileName, progressEventCallback) {
  const config = {
    onUploadProgress: progressEventCallback,
    headers: authHeader()
  };

  const data = new FormData();
  data.append("file", file, fileName);
  const url = API_URL + `/projects/${projectId}/documents`;
  return axios.post(url, data, config);
}