import React, {useState} from "react";
import {
  Table,
  Col,
  Row,
  Select,
  Form,
  Input,
  Spin,
  Tooltip,
  Avatar,
  Popover,
  Button,
  Menu,
  Dropdown,
  message, Tag, Checkbox
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { UserOutlined, EyeOutlined } from "@ant-design/icons";
import {API_URL} from "../../constants";
import axios from "axios";
import {authHeader, download} from "../../Utilities";
import {TagPreview} from "../ExploreResults";
import SearchComponent from "./SearchComponent";
import { DownloadOutlined } from "@ant-design/icons";
import * as queryString from "query-string";
import ExportTags from "./ExportTags";
import {SearchContext} from "../../contexts/SearchContext";
import {FiltersFromURL} from "./SearchFilters/SearchFiltersFromUrl";
import {SearchFilter} from "./SearchFilters/SearchFilters";
import {DefaultSearchQuery} from "./SearchFilters/QueryFromFilters";
import {ShowModeFromQuery} from "../Misc/Query";

class UserViewExploreResultsRun extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      runInfo: null,
      runTotalDrawings: 0
    };
  }

  loadRunInfo = (callback) => {
    axios.get(API_URL + `/user_view/runs/${this.props.match.params.runId}`, {headers: authHeader()}).then(res => {
      this.setState({runInfo: res.data}, callback);
    }).catch(err => {
      message.error("Failed to load run info");
    });
  };

  loadRunTotalDrawings = () => {
    axios.get(API_URL + `/user_view/runs/${this.props.match.params.runId}/total_drawings`, {headers: authHeader()}).then(res => {
      this.setState({runTotalDrawings: res.data.number});
    }).catch(err => {
      message.error("Failed to load drawing count");
    });
  };

  onSearch = (value, searchSettings) => {
    const filters = new FiltersFromURL(this.props.location.search).getArray();
    const showMode = new ShowModeFromQuery(this.props.location.search);

    const searchFilter = new SearchFilter("", value, "Search", searchSettings.useWildcards);

    searchFilter.addTo(filters);

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: filters.length > 0 ? new DefaultSearchQuery(filters, 1, showMode.getValue()).toString() : ""
    });
  };

  componentDidMount() {
    this.loadRunInfo();
    this.loadRunTotalDrawings();

    const searchHandlerSchema = {
      owner: this,
      searchType: "search_in_current_run",
      handler: this.onSearch
    };

    this.context.addSearchHandlerSchema(searchHandlerSchema);
  }

  componentWillUnmount() {
    this.context.removeSearchHandlerSchemasByOwner(this);
  }

  generateFetchOptionsUrl = () => {
    return API_URL + `/user_view/runs/${this.state.runInfo.id}/search_results/possible_search_option_values`;
  };

  render() {
    return (
        <>
          <Spin spinning={false}>
            <Row gutter={24} style={{margin: "16px", justifyContent: "space-between", alignItems: "center"}}>
              <Link to={"/user_view/explore_results"}>
                <Button shape="circle" size="large" icon={<ArrowLeftOutlined />}/>
              </Link>
              {this.state.runInfo &&
                  <React.Fragment>
                    <div>
                      <span id="current-run-info">Current run: {this.state.runInfo.name}</span>
                    </div>
                    <div>
                      <span id="total-drawings">Total drawings: {this.state.runTotalDrawings}</span>
                    </div>
                    <div style={{width: "35%"}}>
                      <ExportTags fetchOptionsUrl={this.generateFetchOptionsUrl()}
                          runId={this.props.match.params.runId} exportFinalResults={false}/>
                    </div>
                  </React.Fragment>
              }
            </Row>

            {this.state.runInfo &&
              <SearchComponent
                  fetchOptionsUrl={this.generateFetchOptionsUrl()}
                  url={API_URL + `/user_view/runs/${this.state.runInfo.id}/search_results`}
              />
            }
          </Spin>
        </>
    );
  }
}

UserViewExploreResultsRun.contextType = SearchContext;

export default UserViewExploreResultsRun;