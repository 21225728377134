import {useEffect} from "react";

export function ExternalCreateCommentContainer({subject, onAddComment, children}) {
    useEffect(() => {
        const sub = subject.subscribe(onAddComment);

        return () => {
            sub.unsubscribe();
        };
    }, [subject, onAddComment]);

    return children();
}