import {CnResult} from "../CnResult";
import {ResultCnCanvasObject} from "../ResultCnCanvasObject";
import {DetailsFromAttributes} from "../DetailsFromAttributes";

export class RemoteCnResult extends CnResult {
    constructor(id, current, another, status) {
        super();

        this._id = id;
        this._current = current;
        this._another = another;
        this._status = status;
    }

    id() {
        return this._id;
    }

    toTag() {
        let tag;

        if (this.status().name() === "REMOVED") {
            tag = this._another.toTag();
        } else {
            tag = this._current.toTag();
        }

        return tag;
    }

    status() {
        return this._status;
    }

    details() {
        return new DetailsFromAttributes(
            this._current.attrs(),
            this._another.attrs()
        ).value();
    }

    toTagHash() {
        const statusName = this.status().name();
        let tagHash;

        if (statusName === "ADDED") {
            tagHash = "";
        } else {
            tagHash = this._another.toTagHash();
        }

        return tagHash;
    }

    selectOnCanvas() {
        if (this.status().name() !== "REMOVED") {
            this._current.selectOnCanvas();
        } else {
            this._another.selectOnCanvas();
        }
    }

    relatedToCanvasObject(canvasObj) {
        if (canvasObj == null) return;

        if (canvasObj.fromAnotherDrawing) {
            return this._another.relatedToCanvasObject(canvasObj);
        } else {
            return this._current.relatedToCanvasObject(canvasObj);
        }
    }

    toCnCanvasObject() {
        return new ResultCnCanvasObject(
            this._current.toCnCanvasObject(),
            this._another.toCnCanvasObject(),
            this.status()
        );
    }
}